import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { RescheduleInvitationResult } from "../types/results/reschedule-invitation-result";

const rescheduleInvitationMutation = gql`
  mutation RescheduleInvitation(
    $id: String!
    $slotId: String!
    $newSlotId: String!
  ) {
    rescheduleInvitation(id: $id, slot_id: $slotId, new_slot_id: $newSlotId) {
      email
      emails_count
      profile_id
      slot_id
      status
    }
  }
`;

export function rescheduleInvitation(
  client: AppApolloClient,
  variables: {
    id: string;
    slotId: string;
    newSlotId: string;
  }
) {
  return client
    .mutate<RescheduleInvitationResult>({
      mutation: rescheduleInvitationMutation,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.rescheduleInvitation;
    });
}
