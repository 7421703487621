import { memo } from "react";
import { Outlet } from "react-router-dom";
import { BREADCRUMBS_DISABLED } from "../../constants/global";

import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { AdminDashboardContextProvider } from "../../contexts/AdminDashboard";

import Navigation from "../Navigation/Navigation";
import styles from "./Dashboard.module.css";

const Dashboard = memo(() => {
  return (
    <AdminDashboardContextProvider>
      <div className={styles.container}>
        <Navigation />
        <div className={styles.mainSection}>
          {!BREADCRUMBS_DISABLED && <Breadcrumb />}
          <Outlet></Outlet>
        </div>
      </div>
    </AdminDashboardContextProvider>
  );
});
export default Dashboard;
