import { ProfileUpdate } from "../../apollo-graphql/types/profile";
import { IUpdatePassword } from "../../apollo-graphql/types/update-password";
import { IResetPassword } from "../../apollo-graphql/types/reset-password";
import { IValidateToken } from "../../apollo-graphql/types/validate-token";
import { ILogin } from "../../apollo-graphql/types/login";
import { IGetInvitation } from "../../apollo-graphql/types/get-invitation";
import { createAction, props } from "../utils";
import { SourceData } from "../../types/source-data";

export const login = createAction(
  "[AUTH] Login",
  props<{
    variables: ILogin;
  }>()
);

export const getInvitation = createAction(
  "[AUTH] Get Invitation",
  props<{
    variables: IGetInvitation;
  }>()
);

export const resetPassword = createAction(
  "[AUTH] Reset Password",
  props<{
    variables: IResetPassword;
  }>()
);

export const validateToken = createAction(
  "[AUTH] Validate Token",
  props<{
    variables: IValidateToken;
  }>()
);

export const updatePassword = createAction(
  "[AUTH] Update Password",
  props<{
    variables: IUpdatePassword;
  }>()
);

export const updateTokens = createAction(
  "[AUTH] Update tokens",
  props<{ accessToken: string; refreshToken: string }>()
);
export const tokenExpired = createAction("[AUTH] Monitor token expired");

export const logout = createAction("[AUTH] Logout");

export const updateProfile = createAction(
  "[AUTH] Update Profile",
  props<{ variables: ProfileUpdate }>()
);

export const updateProfileDialog = createAction(
  "[AUTH] Update Profile Dialog",
  props<
    | {
        open: true;
        type: "profile" | "password";
      }
    | { open: false }
  >()
);

export const uploadProfileImage = createAction(
  "[AUTH] Get Presigned Image Url",
  props<{ file: File }>()
);

export const refreshMyProfile = createAction("[AUTH] Refresh My Profile");
export const refreshJourneyData = createAction("[AUTH] Refresh Journey Data");

export const setPageConfig = createAction(
  "[AUTH] Set page config",
  props<{ pageTitle: string; pageData: any }>()
);

export const deleteProfileImage = createAction("[AUTH] Delete Profile Image");
export const technicalSetupInit = createAction(
  "[TECHNICAL SETUP] Technical Setup Init",
  props<{ htmlVideoElement: HTMLVideoElement; profileId: string }>()
);

export const configureAudio = createAction(
  "[TECHNICAL SETUP] Configure Audio",
  props<{
    availableAudioSources: SourceData[];
    selectedAudioSourceData: SourceData;
  }>()
);

export const configureVideo = createAction(
  "[TECHNICAL SETUP] Configure Video",
  props<{
    availableVideoSources: SourceData[];
    selectedVideoSourceData: SourceData;
  }>()
);

export const saveDeviceSetup = createAction(
  "[TECHNICAL SETUP] Save Device Setup",
  props<{
    selectedAudioDevice: SourceData | null;
    selectedVideoDevice: SourceData | null;
    connectionSuccessful?: boolean;
  }>()
);
export const saveDeviceSetupComplete = createAction(
  "[TECHNICAL SETUP] Save Device Setup Complete"
);

export const setConnectionStatus = createAction(
  "[TECHNICAL SETUP] Set Connection Status",
  props<{ success: boolean }>()
);

export const technicalSetupHelp = createAction("[TECHNICAL SETUP] Setup help");
export const technicalSetupClear = createAction(
  "[TECHNICAL SETUP] Setup help clear"
);
