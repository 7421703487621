import { createMachine, spawnChild, assign, sendTo } from "xstate";
import { AppApolloClient } from "../../../contexts/Apollo";
import * as adminDashboardScheduleActions from "../../actions/dashboard/admin-dashboard-schedule-dialog";
import * as workspaceTagsActions from "../../../apollo-graphql/actions/workspace-tags";
import { SlotType } from "../../../apollo-graphql/types/enums/slot-type";
import { fetchMachineFactory } from "../fetch-factory";
import { getProfiles } from "../../../apollo-graphql/queries/profile";
import {
  createSlot,
  createBulkSlots,
} from "../../../apollo-graphql/mutations/slot";
import { Profile } from "../../../apollo-graphql/types/profile";
import {
  ICreateBulkSlots,
  ICreateSlot,
  Slot,
} from "../../../apollo-graphql/types/slot";
import { Pagination } from "../../../types/pagination";
import { WorkspaceTagsActions } from "../../../apollo-graphql/types/action-types/workspace-tags";
import { WorkspaceTag } from "../../../apollo-graphql/types/workspace-tag";
import {
  getWorkspaceTagsMachine,
  getWorkspaceTagsSuccess,
  getWorkspaceTagsTrigger,
} from "../../../apollo-graphql/machines/workspace-tags";

export enum AdminDashboardScheduleState {
  Idle = "idle",
  Start = "start",
  LoadScheduleData = "loadScheduleData",
  ScheduleReady = "scheduleReady",
  ScheduleReadyBulk = "scheduleReadyBulk",
  Creating = "Creating",
  CreatingBulk = "CreatingBulk",
  Done = "done",
}

export interface AdminDashboardScheduleMachineContext {
  client: AppApolloClient;
  profiles: Profile[] | null;
  workspaceId: string | null;
  workshopId: string | null;
  dateTime: Date | null;
  participantEmails: string[] | null;
  error: any | null;
  slot: Slot | null;
  type: SlotType | null;
  workspaceTags: WorkspaceTag[];
}

type AdminDashboardScheduleActionCreators =
  typeof adminDashboardScheduleActions;
type AdminDashboardScheduleActionCreatorKeys =
  keyof AdminDashboardScheduleActionCreators;
type AdminDashboardScheduleActions = ReturnType<
  AdminDashboardScheduleActionCreators[AdminDashboardScheduleActionCreatorKeys]
>;

type AdminDashboardScheduleMachineTypes = {
  context: AdminDashboardScheduleMachineContext;
  events:
    | AdminDashboardScheduleActions
    | WorkspaceTagsActions
    | ReturnType<typeof getProfilesSuccess>
    | ReturnType<typeof createSlotSuccess>
    | ReturnType<typeof getProfilesFailure>
    | ReturnType<typeof createSlotFailure>
    | ReturnType<typeof createBulkSlotsSuccess>
    | ReturnType<typeof createBulkSlotsFailure>
    | ReturnType<typeof getWorkspaceTagsSuccess>;
};

const {
  machine: getProfilesMachine,
  trigger: getProfilesTrigger,
  success: getProfilesSuccess,
  failure: getProfilesFailure,
} = fetchMachineFactory({
  id: "getProfiles",
  invokeFn: ({
    client,
    workspaceId,
    pagination,
  }: {
    client: AppApolloClient;
    workspaceId: string;
    emails?: string[];
    ids?: string[];
    pagination?: Pagination;
    query?: string;
  }) => {
    return getProfiles(client, { workspaceId, pagination });
  },
});

const {
  machine: createSlotMachine,
  trigger: createSlotTrigger,
  success: createSlotSuccess,
  failure: createSlotFailure,
} = fetchMachineFactory({
  id: "createSlot",
  invokeFn: ({
    client,
    workspaceId,
    workshopId,
    scheduleDate,
    participantEmails,
    type,
    timezoneOffsetInMinutes,
  }: ICreateSlot & { client: AppApolloClient }) => {
    return createSlot(client, {
      workspaceId,
      workshopId,
      type,
      scheduleDate,
      participantEmails,
      timezoneOffsetInMinutes,
    });
  },
});

const {
  machine: createBulkSlotsMachine,
  trigger: createBulkSlotTrigger,
  success: createBulkSlotsSuccess,
  failure: createBulkSlotsFailure,
} = fetchMachineFactory({
  id: "createBulkSlots",
  invokeFn: ({
    client,
    workspaceId,
    workshopId,
    type,
    startDate,
    endDate,
    scheduledDays,
    timezoneOffsetInMinutes,
  }: ICreateBulkSlots & { client: AppApolloClient }) => {
    return createBulkSlots(client, {
      workspaceId,
      workshopId,
      type,
      startDate,
      endDate,
      scheduledDays,
      timezoneOffsetInMinutes,
    });
  },
});

export const adminDashboardScheduleDialogMachine = createMachine({
  types: {} as AdminDashboardScheduleMachineTypes,
  id: "admin-dashboard-schedule-dialog",
  initial: AdminDashboardScheduleState.Idle,
  context: ({ input }): AdminDashboardScheduleMachineContext => {
    const machineInput = input as
      | {
          client?: AppApolloClient;
        }
      | undefined;
    if (!machineInput?.client)
      throw new Error("Apollo client must be provided!");
    return {
      client: machineInput.client,
      workshopId: null,
      workspaceId: null,
      dateTime: null,
      participantEmails: null,
      error: null,
      profiles: null,
      slot: null,
      type: null,
      workspaceTags: [],
    };
  },
  entry: [
    spawnChild(getWorkspaceTagsMachine, {
      id: getWorkspaceTagsMachine.id,
      systemId: getWorkspaceTagsMachine.id,
    }),
    spawnChild(getProfilesMachine, {
      id: getProfilesMachine.id,
      systemId: getProfilesMachine.id,
    }),
    spawnChild(createSlotMachine, {
      id: createSlotMachine.id,
      systemId: createSlotMachine.id,
    }),
    spawnChild(createBulkSlotsMachine, {
      id: createBulkSlotsMachine.id,
      systemId: createBulkSlotsMachine.id,
    }),
  ],
  states: {
    [AdminDashboardScheduleState.Idle]: {
      on: {
        [adminDashboardScheduleActions.openScheduleDialog.type]: {
          target: AdminDashboardScheduleState.Start,
          actions: assign({
            workspaceId: ({ event }) => event.payload.workspaceId,
            workshopId: ({ event }) => event.payload.workshopId,
          }),
        },
      },
    },
    [AdminDashboardScheduleState.Start]: {
      on: {
        [adminDashboardScheduleActions.selectWorkshopStart.type]: [
          {
            target: AdminDashboardScheduleState.LoadScheduleData,
            guard: ({ event }) =>
              event.payload.type === SlotType.SPLIT ||
              event.payload.type === SlotType.BULK,
            actions: assign({
              error: null,
              type: ({ event }) => event.payload.type,
            }),
          },
          {
            target: AdminDashboardScheduleState.Creating,
            guard: ({ event }) => event.payload.type === SlotType.ALL,
            actions: assign({
              error: null,
            }),
          },
        ],
      },
    },
    [AdminDashboardScheduleState.LoadScheduleData]: {
      always: [
        {
          guard: ({ event }) => {
            const scheduleEvent = event as ReturnType<
              typeof adminDashboardScheduleActions.selectWorkshopStart
            >;
            return scheduleEvent.payload.type !== SlotType.ALL;
          },
          actions: [
            sendTo(getProfilesMachine.id, ({ event, context }) => {
              const workspaceId = context.workspaceId!;
              const client = context.client;
              return getProfilesTrigger({
                client,
                workspaceId,
              });
            }),
          ],
        },
      ],
      on: {
        [getProfilesSuccess.type]: [
          {
            target: AdminDashboardScheduleState.ScheduleReady,
            guard: ({ context }) => context.type === SlotType.SPLIT,
            actions: assign({
              profiles: ({ event }) => {
                const evt = event as ReturnType<typeof getProfilesSuccess>;
                return evt.payload.output.nodes;
              },
            }),
          },
          {
            target: AdminDashboardScheduleState.ScheduleReadyBulk,
            guard: ({ context }) => context.type === SlotType.BULK,
            actions: assign({
              profiles: ({ event }) => {
                const evt = event as ReturnType<typeof getProfilesSuccess>;
                return evt.payload.output.nodes;
              },
            }),
          },
        ],
        [getProfilesFailure.type]: {
          target: AdminDashboardScheduleState.Start,
          actions: assign({
            error: ({ event }) => {
              const evt = event as ReturnType<typeof getProfilesFailure>;
              return evt.payload.error?.message;
            },
            dateTime: null,
            participantEmails: null,
            profiles: null,
            slot: null,
          }),
        },
      },
    },
    [AdminDashboardScheduleState.ScheduleReady]: {
      on: {
        [adminDashboardScheduleActions.setScheduleDateTime.type]: {
          actions: assign({
            dateTime: ({ event }) => {
              const evt = event as ReturnType<
                typeof adminDashboardScheduleActions.setScheduleDateTime
              >;
              return evt.payload.dateTime;
            },
          }),
        },
        [adminDashboardScheduleActions.setScheduleParticipants.type]: {
          actions: assign({
            participantEmails: ({ event }) => {
              return event.payload.participantEmails;
            },
          }),
        },
        [adminDashboardScheduleActions.createSlot.type]: {
          target: AdminDashboardScheduleState.Creating,
          guard: ({ event }) =>
            event.payload.type === SlotType.ALL ||
            !!(
              event.payload.type === SlotType.SPLIT &&
              event.payload.dateTime &&
              event.payload.participantEmails.length > 0
            ),
        },
      },
    },
    [AdminDashboardScheduleState.ScheduleReadyBulk]: {
      on: {
        [adminDashboardScheduleActions.createBulkSlots.type]: {
          target: AdminDashboardScheduleState.CreatingBulk,
          guard: ({ event }) =>
            !!(
              event.payload.type === SlotType.BULK &&
              event.payload.startDate &&
              event.payload.endDate &&
              Object.keys(event.payload.scheduledDays).length > 0
            ),
        },
      },
    },
    [AdminDashboardScheduleState.Creating]: {
      entry: [
        sendTo(createSlotMachine.id, ({ event, context }) => {
          event = event as
            | ReturnType<
                typeof adminDashboardScheduleActions.selectWorkshopStart
              >
            | ReturnType<typeof adminDashboardScheduleActions.createSlot>;
          const type = event.payload.type;
          const scheduleDate =
            event.type === adminDashboardScheduleActions.createSlot.type
              ? event.payload.dateTime
              : undefined;
          const participantEmails =
            event.type === adminDashboardScheduleActions.createSlot.type
              ? event.payload.participantEmails
              : undefined;

          const client = context.client;
          const workspaceId = context.workspaceId!;
          const workshopId = context.workshopId!;
          const timezoneOffsetInMinutes =
            scheduleDate?.getTimezoneOffset() || 0;
          return createSlotTrigger({
            client,
            type,
            workshopId,
            workspaceId,
            scheduleDate,
            participantEmails,
            timezoneOffsetInMinutes,
          });
        }),
      ],
      on: {
        [createSlotSuccess.type]: {
          target: AdminDashboardScheduleState.Done,
          actions: assign({
            error: () => null,
            slot: ({ event }) => {
              return event.payload.output;
            },
          }),
        },
        [createSlotFailure.type]: [
          {
            target: AdminDashboardScheduleState.ScheduleReady,
            actions: assign({
              error: ({ event }) => {
                const evt = event as ReturnType<typeof createSlotFailure>;
                return evt.payload.error?.message;
              },
            }),
            guard: ({
              event: {
                payload: { input },
              },
            }) => {
              return input.type === SlotType.SPLIT;
            },
          },
          {
            target: AdminDashboardScheduleState.Start,
            actions: assign({
              error: ({ event }) => {
                const evt = event as ReturnType<typeof createSlotFailure>;
                return evt.payload.error;
              },
              dateTime: null,
              participantEmails: null,
              profiles: null,
              slot: null,
            }),
            guard: ({
              event: {
                payload: { input },
              },
            }) => {
              return input.type === SlotType.ALL;
            },
          },
        ],
      },
    },
    [AdminDashboardScheduleState.CreatingBulk]: {
      entry: [
        sendTo(createBulkSlotsMachine.id, ({ event, context }) => {
          event = event as ReturnType<
            typeof adminDashboardScheduleActions.createBulkSlots
          >;
          const client = context.client;
          const workshopId = context.workshopId!;
          const workspaceId = context.workspaceId!;

          const { type, startDate, endDate, scheduledDays } = event.payload;

          const timezoneOffsetInMinutes = startDate.getTimezoneOffset();

          return createBulkSlotTrigger({
            client,
            workshopId,
            workspaceId,
            type,
            startDate,
            endDate,
            scheduledDays,
            timezoneOffsetInMinutes,
          });
        }),
      ],
      on: {
        [createBulkSlotsSuccess.type]: {
          target: AdminDashboardScheduleState.Done,
          actions: assign({
            error: () => null,
            slot: ({ event }) => {
              return event.payload.output[0];
            },
          }),
        },
        [createBulkSlotsFailure.type]: {
          target: AdminDashboardScheduleState.ScheduleReadyBulk,
          actions: assign({
            error: ({ event }) => {
              return event.payload.error?.message;
            },
          }),
        },
      },
    },
    [AdminDashboardScheduleState.Done]: {},
  },
  on: {
    [adminDashboardScheduleActions.closeScheduleDialog.type]: {
      target: `.${AdminDashboardScheduleState.Idle}`,
      actions: assign({
        dateTime: null,
        participantEmails: null,
        error: null,
        profiles: null,
        slot: null,
        type: null,
      }),
    },
    [workspaceTagsActions.getWorkspaceTags.type]: {
      actions: [
        sendTo(getWorkspaceTagsMachine.id, ({ event, context }) => {
          return getWorkspaceTagsTrigger({
            client: context.client,
            workspaceId: event.payload.workspaceId,
          });
        }),
      ],
    },
    [getWorkspaceTagsSuccess.type]: {
      actions: [assign({ workspaceTags: ({ event }) => event.payload.output })],
    },
  },
});
