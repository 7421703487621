import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { WorkspaceTagsResult } from "../types/results";

const getWorkspaceTagsQuery = gql`
  query GetWorkspaceTags($workspaceId: String) {
    getWorkspaceTags(workspace_id: $workspaceId) {
      id
      text
    }
  }
`;

export function getWorkspaceTags(
  client: AppApolloClient,
  variables: { workspaceId: string }
) {
  return client
    .query<WorkspaceTagsResult>({
      query: getWorkspaceTagsQuery,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data.getWorkspaceTags;
    });
}
