import "./sentry";
import "./patch";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { v4 } from "uuid";
import {
  technicalSetupConfig,
  authContextLocalStorageName,
  instanceUUIDStorageKey,
} from "./constants/environment";
import { checkCameraAndMicPermissions } from "./utils/check-device-permissions";
import { getItem } from "./utils/db";
import { AuthCtx } from "./types/auth-ctx";
import { TechnicalSetupConfig } from "./types/technical-setup-config";
import { GlobalErrorBoundary } from "./GlobalErrorBoundary";

const existingInstanceUUID =
  sessionStorage.getItem(instanceUUIDStorageKey) || null;
const instanceUUID = existingInstanceUUID || v4();
if (!existingInstanceUUID)
  sessionStorage.setItem(instanceUUIDStorageKey, instanceUUID);

// NOTE: Technical setup is not required when accessing the
// sessions instance because will have to go trough technical
// setup anyways
const skipTechnicalSetup = window.location.pathname.includes("/session/");

Promise.all([
  checkCameraAndMicPermissions(),
  getItem<AuthCtx | null>(authContextLocalStorageName),
  getItem<TechnicalSetupConfig | null>(technicalSetupConfig),
]).then(([devicePermissions, authCtx, technicalSetupConfig]) => {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <GlobalErrorBoundary>
        <App
          authCtx={authCtx}
          instanceUUID={instanceUUID}
          devicePermissions={devicePermissions}
          technicalSetupConfig={technicalSetupConfig}
          skipTechnicalSetup={skipTechnicalSetup}
        />
      </GlobalErrorBoundary>
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
