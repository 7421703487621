export const DASHBOARD = "Dashboard";
export const CONVERSATIONS = "Conversations";
export const JOURNEYS = "Journeys";
export const MILESTONES = "Milestones";
export const TEAM_SCHEDULE = "Team's Schedule";
export const MY_SCHEDULE_ADMIN = "My Schedule";
export const MY_SCHEDULE_MEMBER = "My Schedule";
export const AUTO_SCHEDULER = "Auto-scheduler";
export const TEAM_MEMBERS = "Team members";
export const CONVERSATION_BUILDER = "Conversation builder";
