import { memo, PropsWithChildren, useCallback, useEffect, useRef } from "react";
import { useMachine } from "@xstate/react";
import { Doc } from "yjs";

import { editorMachine, actions } from "./machine";

type AwarenessData = {
  [x: string]: any;
} | null;

type EditorProps = PropsWithChildren<{
  name: string;
  doc?: Doc;
  isInput?: boolean;
  profileName: string;
  placeholder?: string;
  className: string;
  value: Uint8Array;
  awareness: Uint8Array | null;
  characterLimit?: number;
  onValueUpdate: (data: {
    event: { type: "update"; update: Uint8Array };
    name: string;
  }) => void;
  onAwarenessUpdate: (data: {
    event: {
      type: "awareness";
      awareness: AwarenessData;
    };
    name: string;
  }) => void;
}>;

const Editor = (props: EditorProps) => {
  const {
    name,
    doc,
    isInput,
    profileName,
    onValueUpdate,
    onAwarenessUpdate,
    value,
    awareness,
    placeholder,
    className,
    characterLimit,
  } = props;
  const [state, send] = useMachine(editorMachine);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (state.context.provider) {
      state.context.provider.updateEditorClass(className);
    }
  }, [className, state.context.provider]);

  const onValueUpdateCallback = useCallback(
    (event: Event) => {
      if (
        event.type === "update" &&
        ((event as CustomEvent).detail as { type: string }).type === "update"
      ) {
        onValueUpdate({
          event: (event as CustomEvent).detail,
          name,
        });
      } else if (
        event.type === "update" &&
        ((event as CustomEvent).detail as { type: string }).type === "awareness"
      ) {
        const dataEvent = (event as CustomEvent).detail;
        onAwarenessUpdate({ name, event: dataEvent });
      }
    },
    [name, onAwarenessUpdate, onValueUpdate]
  );

  useEffect(() => {
    send(
      actions.init({
        profileName,
        textAreaElement: textAreaRef.current!,
        onUpdate: onValueUpdateCallback,
        placeholderValue: placeholder,
        isInput: isInput || false,
        characterLimit,
        doc,
      })
    );
    // NOTE: DO NOT PUT "value" in the dependencies (this is only used for initial value) !!!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    doc,
    isInput,
    characterLimit,
    onValueUpdateCallback,
    placeholder,
    profileName,
    send,
  ]);

  useEffect(() => {
    send(actions.applyRemoteDocUpdate({ update: value }));
  }, [send, value]);

  useEffect(() => {
    send(actions.applyRemoteAwarenessUpdate({ awareness }));
  }, [send, awareness]);

  return <textarea ref={textAreaRef} />;
};

export default memo(Editor);
