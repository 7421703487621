import { PropsWithChildren, memo, useCallback, useMemo } from "react";
import cn from "classnames";
import Dialog from "../Shared/Dialog/Dialog";
import LoadingButton from "../Shared/Buttons/LoadingButton/LoadingButton";

import styles from "./ConfirmationDialog.module.css";

export default memo(function (
  props: PropsWithChildren<{
    title: string;
    description?: string;
    showCloseButton?: boolean;
    continueButtonText?: string;
    cancelButtonText?: string;
    isLoading?: boolean;
    className?: string;
    confirmationHandler: (outcome: boolean | null) => void;
  }>
) {
  const {
    title,
    description,
    cancelButtonText,
    continueButtonText,
    children,
    isLoading,
    className,
    confirmationHandler,
  } = props;

  const showCloseButton =
    typeof props.showCloseButton === "boolean" ? props.showCloseButton : true;

  const dismissHandler = useCallback(() => {
    confirmationHandler(null);
  }, [confirmationHandler]);

  const cancelHandler = useCallback(() => {
    confirmationHandler(false);
  }, [confirmationHandler]);

  const confirmHandler = useCallback(() => {
    confirmationHandler(true);
  }, [confirmationHandler]);

  const actionsContent = useMemo(() => {
    return (
      <div
        className={styles.footer}
        style={{
          display: "flex",
          marginTop: 25,
          justifyContent: "flex-end",
        }}
      >
        {showCloseButton && (
          <button className="btn ghost" type="button" onClick={cancelHandler}>
            {cancelButtonText || "Cancel"}
          </button>
        )}
        <LoadingButton
          isLoading={isLoading}
          className={cn("btn", styles.saveBtn)}
          onClick={confirmHandler}
          type="button"
        >
          {continueButtonText || "Continue"}
        </LoadingButton>
      </div>
    );
  }, [
    cancelButtonText,
    cancelHandler,
    confirmHandler,
    continueButtonText,
    isLoading,
    showCloseButton,
  ]);

  return (
    <Dialog
      open
      heading={title}
      onClose={dismissHandler}
      actionsContent={actionsContent}
      className={className}
    >
      <div className={styles.content}>{children || description}</div>
    </Dialog>
  );
});
