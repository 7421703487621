import { PropsWithChildren, useState, memo } from "react";
import cn from "classnames";
import * as Form from "@radix-ui/react-form";

import styles from "./Password.module.css";

const Password = (
  props: PropsWithChildren<{
    name: string;
    label: string;
    labelClassName?: string;
    placeholder?: string;
    valueMissingMessage?: string;
    compareField?: string;
    compareFiledMessage?: string;
  }>
) => {
  const {
    name,
    label,
    labelClassName,
    placeholder,
    valueMissingMessage,
    compareField,
    compareFiledMessage,
  } = props;
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  return (
    <Form.Field className="FormField" name={name}>
      <div className={styles.field}>
        <Form.Label
          className={cn(styles.label, "caption", "medium", labelClassName)}
        >
          {label}
        </Form.Label>
        <Form.Control
          type={showCurrentPassword ? "text" : "password"}
          className="FormControl"
          placeholder={placeholder}
          required
        />
        <div
          className={styles.iconContainer}
          onClick={() => setShowCurrentPassword(!showCurrentPassword)}
        >
          <i className={`fa fa-eye${!showCurrentPassword ? "-slash" : ""}`} />
        </div>
      </div>

      <Form.Message className="FormMessage" match="valueMissing">
        {valueMissingMessage || "Please enter a password."}
      </Form.Message>
      {compareField && (
        <Form.Message
          className="FormMessage"
          match={(value, formData) => {
            const allFields = Object.fromEntries(formData.entries());
            return value !== allFields[compareField];
          }}
        >
          {compareFiledMessage}
        </Form.Message>
      )}
    </Form.Field>
  );
};
export default memo(Password);
