import { assign, createMachine, spawnChild, sendTo } from "xstate";
import { AppApolloClient } from "../../contexts/Apollo";
import * as rescheduleActions from "../actions/reschedule";
import { Slot } from "../../apollo-graphql/types/slot";
import {
  getSlotsForEmails,
  getSlotsForWorkshopId,
} from "../../apollo-graphql/queries/slot";
import { rescheduleInvitation } from "../../apollo-graphql/mutations/invitation";
import { fetchMachineFactory } from "./fetch-factory";
import { SlotStatus } from "../../apollo-graphql/types/enums";
import { SortDirection } from "../../types/enums/sort-direction";

export enum RescheduleSlotState {
  Initial = "initial",
  LoadingSlots = "loadingSlots",
  Ready = "ready",
}

export enum RescheduleState {
  Idle = "idle",
  LoadingSlots = "loadingSlots",
  Rescheduling = "rescheduling",
  Ready = "ready",
}

export interface RescheduleMachineContext {
  client: AppApolloClient;
  slotIdForReschedule: string | null;
  rescheduleResult: { currentSlotId: string; newSlotId: string } | null;
  slots: Slot[] | null;
  scheduledSlots: Slot[] | null;
}

type RescheduleActionCreators = typeof rescheduleActions;
type RescheduleActionCreatorKeys = keyof RescheduleActionCreators;
type RescheduleActions = ReturnType<
  RescheduleActionCreators[RescheduleActionCreatorKeys]
>;
type RescheduleMachineTypes = {
  context: RescheduleMachineContext;
  events:
    | RescheduleActions
    | ReturnType<typeof getSlotsForEmailSuccess>
    | ReturnType<typeof getSlotsForEmailFailure>
    | ReturnType<typeof getSlotsForWorkshopSuccess>
    | ReturnType<typeof getSlotsForWorkshopFailure>
    | ReturnType<typeof rescheduleInvitationSuccess>
    | ReturnType<typeof rescheduleInvitationFailure>;
};

const {
  machine: getSlotsForEmailMachine,
  trigger: getSlotsForEmailTrigger,
  success: getSlotsForEmailSuccess,
  failure: getSlotsForEmailFailure,
} = fetchMachineFactory({
  id: "getSlotsForEmail",
  invokeFn: ({
    workspaceId,
    email,
    client,
  }: {
    email: string;
    workspaceId: string;
    client: AppApolloClient;
  }) => {
    return getSlotsForEmails(client, {
      emails: [email],
      workspaceId,
    });
  },
});

const {
  machine: getSlotsForWorkshopMachine,
  trigger: getSlotsForWorkshopTrigger,
  success: getSlotsForWorkshopSuccess,
  failure: getSlotsForWorkshopFailure,
} = fetchMachineFactory({
  id: "getSlotsForWorkshop",
  invokeFn: ({
    workspaceId,
    workshopId,
    slotStatuses,
    client,
  }: {
    workshopId: string;
    workspaceId: string;
    slotStatuses: SlotStatus[];
    client: AppApolloClient;
  }) => {
    return getSlotsForWorkshopId(client, {
      workshopId,
      workspaceId,
      statuses: slotStatuses,
      sortDirection: SortDirection.ASC,
    });
  },
});

export const {
  machine: rescheduleInvitationMachine,
  trigger: rescheduleInvitationTrigger,
  success: rescheduleInvitationSuccess,
  failure: rescheduleInvitationFailure,
} = fetchMachineFactory({
  id: "rescheduleInvitation",
  invokeFn: ({
    id,
    newSlotId,
    currentSlotId,
    client,
  }: {
    id: string;
    newSlotId: string;
    currentSlotId: string;
    client: AppApolloClient;
  }) => {
    return rescheduleInvitation(client, {
      id,
      slotId: currentSlotId,
      newSlotId,
    });
  },
});

export const rescheduleMachine = createMachine({
  types: {} as RescheduleMachineTypes,
  id: "reschedule",
  context: ({ input }): RescheduleMachineContext => {
    const machineInput = input as
      | {
          client?: AppApolloClient;
        }
      | undefined;
    if (!machineInput?.client)
      throw new Error("Apollo client must be provided!");
    return {
      client: machineInput.client,
      slotIdForReschedule: null,
      slots: null,
      scheduledSlots: null,
      rescheduleResult: null,
    };
  },
  type: "parallel",
  entry: [
    spawnChild(getSlotsForEmailMachine, {
      id: getSlotsForEmailMachine.id,
      systemId: getSlotsForEmailMachine.id,
    }),
    spawnChild(getSlotsForWorkshopMachine, {
      id: getSlotsForWorkshopMachine.id,
      systemId: getSlotsForWorkshopMachine.id,
    }),
    spawnChild(rescheduleInvitationMachine, {
      id: rescheduleInvitationMachine.id,
      systemId: rescheduleInvitationMachine.id,
    }),
  ],
  states: {
    dashboard: {
      initial: RescheduleSlotState.Initial,
      states: {
        [RescheduleSlotState.Initial]: {
          on: {
            [rescheduleActions.loadMemberSlots.type]: {
              target: [RescheduleSlotState.LoadingSlots],
            },
          },
        },
        [RescheduleSlotState.LoadingSlots]: {
          entry: [
            sendTo(getSlotsForEmailMachine.id, ({ event, context }) => {
              event = event as ReturnType<
                typeof rescheduleActions.loadMemberSlots
              >;
              const payload = {
                email: event.payload.email,
                client: context.client,
                workspaceId: event.payload.workspaceId,
              };

              return getSlotsForEmailTrigger(payload);
            }),
          ],
          on: {
            [getSlotsForEmailSuccess.type]: {
              target: RescheduleSlotState.Ready,
              actions: [
                assign({
                  slots: ({ event }) => {
                    return event.payload.output;
                  },
                }),
              ],
            },
          },
        },
        [RescheduleSlotState.Ready]: {
          on: {
            [rescheduleActions.loadMemberSlots.type]: {
              target: [RescheduleSlotState.LoadingSlots],
              actions: assign({
                slots: null,
              }),
            },
          },
        },
      },
    },
    reschedule: {
      initial: RescheduleState.Idle,
      states: {
        [RescheduleState.Idle]: {
          on: {
            [rescheduleActions.openRescheduleSlot.type]: {
              target: RescheduleState.LoadingSlots,
            },
          },
        },
        [RescheduleState.LoadingSlots]: {
          entry: [
            sendTo(getSlotsForWorkshopMachine.id, ({ event, context }) => {
              event = event as ReturnType<
                typeof rescheduleActions.openRescheduleSlot
              >;
              const payload = {
                client: context.client,
                workshopId: event.payload.workshopId,
                workspaceId: event.payload.workspaceId,
                slotStatuses: [SlotStatus.SCHEDULED, SlotStatus.ONGOING],
              };
              return getSlotsForWorkshopTrigger(payload);
            }),
            assign({
              slotIdForReschedule: ({ event, context }) => {
                let eventSlotId = context.slotIdForReschedule;
                if (!event) return eventSlotId;
                if (!("payload" in event)) return eventSlotId;
                const payload = event.payload;
                if (!payload || !("slotId" in payload)) return eventSlotId;
                return payload.slotId;
              },
            }),
          ],
          on: {
            [getSlotsForWorkshopSuccess.type]: {
              target: RescheduleSlotState.Ready,
              actions: assign({
                scheduledSlots: ({ event }) => {
                  return event.payload.output;
                },
              }),
            },
            [rescheduleActions.closeRescheduleSlot.type]: {
              target: RescheduleState.Idle,
              actions: assign({
                scheduledSlots: null,
              }),
            },
          },
        },
        [RescheduleState.Ready]: {
          on: {
            [rescheduleActions.closeRescheduleSlot.type]: {
              target: RescheduleState.Idle,
              actions: assign({
                rescheduleResult: null,
                scheduledSlots: null,
              }),
            },
            [rescheduleActions.reschedule.type]: {
              target: RescheduleState.Rescheduling,
            },
          },
        },
        [RescheduleState.Rescheduling]: {
          entry: [
            sendTo(rescheduleInvitationMachine.id, ({ event, context }) => {
              event = event as ReturnType<typeof rescheduleActions.reschedule>;

              const payload = {
                client: context.client,
                id: event.payload.invitationId,
                currentSlotId: context.slotIdForReschedule!,
                newSlotId: event.payload.newSlotId,
              };
              return rescheduleInvitationTrigger({ ...payload, force: true });
            }),
          ],
          on: {
            [rescheduleInvitationSuccess.type]: {
              target: RescheduleState.Ready,
              actions: assign({
                rescheduleResult: ({ event }) => ({
                  newSlotId: event.payload.input.newSlotId,
                  currentSlotId: event.payload.input.currentSlotId,
                }),
              }),
            },
            [rescheduleInvitationFailure.type]: {
              target: RescheduleState.Ready,
            },
          },
        },
      },
    },
  },
});
