import { memo } from "react";

import cn from "classnames";

import {
  calculateArrowStyles,
  calculateArrowStylesSpiralPattern,
  calculateOffset,
  calculateOffsetForOffsetPattern,
} from "../utils";

import JourneyCard, { JourneyStatus } from "../JourneyCard/JourneyCard";
import { SectionItemType } from "../../../../types/contentful/workshop/summary";

import styles from "./JourneyConversations.module.css";
export interface JourneyConversation {
  id: string;
  headline: string;
  isCurrent: boolean;
  isCompleted: boolean;
  isLocked: boolean;
  image: string;
  color: string;
  title: string;
  ahaMomentsCount: number;
  behavioursCount: number;
  sectionItemTypes: SectionItemType[];
  completedDate: Date | null;
  scheduleDate: Date | null;
  link: string | null;
}

interface JourneyWorkshopsProps {
  journeyConversations: JourneyConversation[];
  isViewResults?: boolean;
}

const JourneyConversations = ({
  journeyConversations,
  isViewResults = false,
}: JourneyWorkshopsProps) => {
  return (
    <div className={styles.journeyConversationsContainer}>
      {journeyConversations.map((conversation, index) => {
        const {
          id,
          title,
          headline,
          isCompleted,
          isCurrent,
          ahaMomentsCount,
          behavioursCount,
          sectionItemTypes,
          link,
          completedDate,
          scheduleDate,
        } = conversation;

        const status = isCurrent
          ? JourneyStatus.ONGOING
          : isCompleted
          ? JourneyStatus.COMPLETED
          : JourneyStatus.LOCKED;

        const conversationStyle = {
          marginLeft:
            journeyConversations.length < 7
              ? calculateOffset(index, journeyConversations.length)
              : calculateOffsetForOffsetPattern(index, [0, 24, 48, 72, 48, 24]),
        };

        const conversationArrowStyle =
          journeyConversations.length < 7
            ? calculateArrowStyles(
                index,
                journeyConversations.length,
                journeyConversations[index + 1]?.isLocked
              )
            : calculateArrowStylesSpiralPattern(
                index,
                journeyConversations[index + 1]?.isLocked
              );

        return (
          <div
            key={id}
            className={styles.journeyConversation}
            style={conversationStyle}
          >
            <JourneyCard
              status={status}
              title={title}
              headline={headline}
              ahaMomentsCount={ahaMomentsCount}
              behavioursCount={behavioursCount}
              sectionItemTypes={sectionItemTypes}
              completedDate={completedDate}
              scheduleDate={scheduleDate}
              link={link}
              isViewResults={isViewResults}
            />

            {index + 1 < journeyConversations.length && (
              <div
                className={cn(
                  styles.journeyConversationArrow,
                  status.toLocaleLowerCase()
                )}
                style={conversationArrowStyle}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default memo(JourneyConversations);
