import { PropsWithChildren, memo, useCallback, useMemo } from "react";

import { debounce } from "lodash";

import {
  extractMultiValueComplexValue,
  extractMultiValueResult,
  generateDefaultMultiValue,
} from "../utils/ydoc-helpers";
import { getTransitionActionFooterData } from "../utils/get-transition-footer-data";
import { getEntryId, getTransitionData } from "../../../../utils";

import { useAwareness } from "../../../../hooks/useAwareness";
import { useReadyRemovedDueToChange } from "../../../../hooks/useReadyRemovedDueToChange";
import { UseDocComplexValueType, useDocMap } from "../../../../hooks/useDocMap";

import { TranslationActivity } from "../../../../types/contentful/workshop/activities/translation";
import { ActivityComplexValueType } from "../../../../types/enums/activity-complex-value-type";
import { normalConnectionThreshold } from "../../../../contexts/Apollo";
import { ActivityCommon } from "../../../../types/activity-common";
import { ActionFooterType } from "../../../../types/action-footer";
import { FooterType } from "../../../../types/enums/activity-footer";
import { ActivityComplexValue } from "../../../../types/activity-complex-value";
import { IActivityResult } from "../../../../apollo-graphql/types/session-state";
import { Profile } from "../../../../apollo-graphql/types/profile";
import { ACTIVITY_TIMEOUT_VALUE } from "../../../../constants/global";
import { observerFooterData } from "../constants";

import ActionFooter from "../../ActionFooter/ActionFooter";
import NextStepTransition from "../../NextStepTransition/NextStepTransition";
import TranslationItemComponent from "./components/TranslationItem";
import InfoBox from "../../../InfoBox/InfoBox";
import ScrollIndicator from "../../../Shared/ScrollIndicator/ScrollIndicator";
import { HIGH_INPUT_DEBOUNCE_TIME } from "../../../../constants/input-debounce-time";
import ActivityInstructions from "../../../Shared/ActivityInstructions/ActivityInstructions";

import cn from "classnames";
import styles from "./Translation.module.css";

interface TranslationProps extends ActivityCommon {
  activityResults: IActivityResult["value"] | null;
  profile: Profile;
  isViewResults?: boolean;
  setActivityValueHandler: (args: {
    activityId: string;
    value: string;
  }) => void;
}

const Translation = (props: PropsWithChildren<TranslationProps>) => {
  const {
    activity,
    nextActivity,
    profile,
    transition,
    isReady,
    isLoading,
    isConnectionWeak,
    notReadyProfilesCount,
    activityResults,
    currentActiveParticipantCount,
    isViewResults,
    isActivityTimeout,
    isParticipating,
    hasAhaMoments,
    setActivityValueHandler,
    setActivityReadyHandler,
  } = props;

  const items = useMemo(
    () =>
      ((activity as TranslationActivity)?.fields?.items || []).map((item) => {
        return {
          id: getEntryId(item),
          targetPlaceholderText: item?.fields?.targetPlaceholderText,
          text: item?.fields?.text,
          title: item?.fields?.title,
        };
      }),
    [activity]
  );

  const editorDeps = useMemo(() => items?.map(({ id }) => id), [items]);

  const { changeStateText, userValueChangeHandler } =
    useReadyRemovedDueToChange(isReady);

  const { awarenessState, onAwarenessUpdate } = useAwareness(editorDeps);

  const { docs, generateComplexResultValueAsString, parseComplexResultValue } =
    useDocMap(editorDeps);

  const activityData = useMemo(() => {
    return {
      id: getEntryId(activity),
      originalLabel: (activity as TranslationActivity).fields?.originalLabel,
      targetLabel: (activity as TranslationActivity).fields?.targetLabel,
    };
  }, [activity]);

  const nextActivityData = useMemo(
    () => getTransitionData(nextActivity, hasAhaMoments),
    [nextActivity, hasAhaMoments]
  );

  const isTransitioning = useMemo(() => transition > 0, [transition]);

  const delayInMilliseconds = useMemo(
    () => (isConnectionWeak ? 0 : normalConnectionThreshold),
    [isConnectionWeak]
  );

  const debouncedSetActivityValue = useMemo(
    () =>
      debounce((value: string) => {
        setActivityValueHandler({ activityId: activityData.id, value });
        userValueChangeHandler();
      }, HIGH_INPUT_DEBOUNCE_TIME),
    [activityData, setActivityValueHandler, userValueChangeHandler]
  );

  const onValueUpdate = useCallback(() => {
    const activityComplexValue: ActivityComplexValue = {
      value: generateComplexResultValueAsString(),
      type: ActivityComplexValueType.yjs,
    };

    debouncedSetActivityValue(JSON.stringify(activityComplexValue));
  }, [debouncedSetActivityValue, generateComplexResultValueAsString]);

  const parsedData = useMemo(() => {
    const defaultResult = generateDefaultMultiValue(
      editorDeps,
      isViewResults || !isParticipating
    );

    const resultValue = activityResults?.[0]?.value;

    if (!resultValue || resultValue === ACTIVITY_TIMEOUT_VALUE)
      return defaultResult;

    const complexValue = extractMultiValueComplexValue(resultValue, editorDeps);

    if (!complexValue) return defaultResult;

    if (isViewResults) {
      const extractedData = extractMultiValueResult(complexValue, editorDeps);

      return extractedData || defaultResult;
    }

    if (!isParticipating) {
      const extractedData = parseComplexResultValue(
        complexValue,
        UseDocComplexValueType.String
      );
      return extractedData || defaultResult;
    }

    const extractedData = parseComplexResultValue(complexValue);

    return extractedData;
  }, [
    editorDeps,
    isViewResults,
    isParticipating,
    activityResults,
    parseComplexResultValue,
  ]);

  const isReadyToContinue = editorDeps.every(
    (key) => docs[key]?.getText("codemirror")?.toString().trim().length > 0
  );

  const actionFooterData: ActionFooterType = useMemo(() => {
    if (!isParticipating) return observerFooterData;
    if (isTransitioning) {
      return getTransitionActionFooterData({
        text: <>Everyone is ready! Continuing forward...</>,
        buttonText: "I Agree",
        disabledButton: true,
        type: FooterType.Ready,
        isActivityTimeout,
      });
    }
    if (!isReady) {
      const playersClicked =
        currentActiveParticipantCount - notReadyProfilesCount;
      const textPlayersCount =
        playersClicked === 1 ? "Player did it!" : "Players did it!";
      const textPlayersClicked =
        !isReady && playersClicked
          ? `${playersClicked} ${textPlayersCount}`
          : "";
      const text = !isReadyToContinue ? (
        <>Write your collective translations before you can continue.</>
      ) : (
        <>
          {changeStateText}Everyone must agree to continue!{" "}
          <span className="accent">{textPlayersClicked}</span>
        </>
      );
      return {
        text,
        buttonText: "I Agree",
        disabledButton: !isReadyToContinue,
        type: FooterType.Notice,
      };
    }
    return {
      text: (
        <>
          Waiting for{" "}
          <span className="accent">
            {notReadyProfilesCount} more player
            {notReadyProfilesCount > 1 && "s"}...
          </span>
        </>
      ),
      buttonText: "I Agree",
      disabledButton: true,
      type: FooterType.Waiting,
    };
  }, [
    isActivityTimeout,
    isTransitioning,
    isParticipating,
    isReady,
    notReadyProfilesCount,
    currentActiveParticipantCount,
    isReadyToContinue,
    changeStateText,
  ]);

  const headerContent = useMemo(() => {
    const html = activity?.fields?.activity?.fields?.instructions;
    const conferenceMode = activity?.fields?.activity?.fields?.conferenceMode;
    const tags = activity?.fields?.activity?.fields?.tags;

    return (
      <ActivityInstructions
        instructions={html}
        tags={tags}
        type={conferenceMode}
      />
    );
  }, [activity]);

  const content = useMemo(() => {
    return items.map((item, translationIndex) => {
      const id = item.id;
      const doc = docs[id];
      const value = parsedData[id];
      const { awareness } = awarenessState.find(({ name }) => name === id) || {
        awareness: null,
      };

      return (
        <TranslationItemComponent
          key={id}
          doc={doc}
          profile={profile}
          translationIndex={translationIndex}
          translationItem={item}
          currentActiveParticipantCount={currentActiveParticipantCount}
          notReadyProfilesCount={notReadyProfilesCount}
          isReady={isReady}
          showDefaultTextArea={isViewResults || !isParticipating}
          isTransitioning={isTransitioning}
          isLoading={isLoading}
          delayInMilliseconds={delayInMilliseconds}
          textAreaKey={id}
          textAreaValue={value}
          awareness={awareness}
          onValueUpdate={onValueUpdate}
          onAwarenessUpdate={onAwarenessUpdate}
        />
      );
    });
  }, [
    awarenessState,
    currentActiveParticipantCount,
    delayInMilliseconds,
    docs,
    isLoading,
    isParticipating,
    isReady,
    isTransitioning,
    isViewResults,
    items,
    notReadyProfilesCount,
    onAwarenessUpdate,
    onValueUpdate,
    parsedData,
    profile,
  ]);

  return (
    <>
      <ScrollIndicator key={activityData.id} className="activity-container">
        <div className={cn(styles.container, "main-container")}>
          <div className={styles.content}>
            <div className={styles.infoContainer}>
              {headerContent}
              <div className={styles.translationsItemsContainer}>
                <div className={styles.translationsItemsHeader}>
                  <div className="text bold">{activityData.originalLabel}</div>
                  <div></div>
                  <div className="text bold">{activityData.targetLabel}</div>
                </div>
                {content}
              </div>

              {!isViewResults && (
                <InfoBox
                  title="These are synchronized fields!"
                  description="Everyone can write together and everyone can make changes of the texts at the same time."
                  isDismissible
                />
              )}
            </div>
          </div>
        </div>
        {!isViewResults && isTransitioning && (
          <NextStepTransition
            nextStep={nextActivityData.transitionText}
            sessionType={nextActivityData.conferenceMode}
            isActivityTimeout={isActivityTimeout}
            transition={transition}
          />
        )}
      </ScrollIndicator>
      {!isViewResults && (
        <ActionFooter
          buttonText={actionFooterData.buttonText}
          type={actionFooterData.type}
          disabledButton={actionFooterData.disabledButton}
          isLoading={isLoading}
          isConnectionWeak={isConnectionWeak}
          buttonClickHandler={() =>
            setActivityReadyHandler({ activityId: activityData.id })
          }
        >
          {actionFooterData.text}
        </ActionFooter>
      )}
    </>
  );
};

export default memo(Translation);
