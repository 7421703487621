import { isDevelopmentEnv, isProductionEnv, isStagingEnv } from "./environment";
export const serverDomain = isDevelopmentEnv
  ? "localhost"
  : window.location.origin.replace(window.location.protocol + "//", "");

export const serverHttpProtocol =
  isProductionEnv || isStagingEnv ? "https" : "http";
export const serverWSProtocol = isProductionEnv || isStagingEnv ? "wss" : "ws";

export const serverUrl = `${serverHttpProtocol}://${serverDomain}`;
export const apolloUri = `${serverUrl}/graphql`;
export const apolloSubscriptionUri = `${serverWSProtocol}://${serverDomain}/graphql`;
