import { FormEvent, useCallback, useContext, useEffect, useMemo } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";

import cn from "classnames";
import * as Form from "@radix-ui/react-form";

import withRouteConfig from "../../hocs/withRouteConfig";

import { parseServerErrors } from "../../utils/parse-server-errors";
import { GlobalContext } from "../../contexts/Global";
import { AuthState } from "../../+xstate/machines/auth";
import { FetchState } from "../../+xstate/machines/fetch-factory";
import { IUpdatePassword } from "../../apollo-graphql/types/update-password";
import { UpdatePasswordErrors } from "../../types/enums/errors";
import { mappedErrorMessages } from "../../constants/mapped-error-messages";

import Password from "../../components/Shared/Password/Password";
import SkeletonLoader from "../../components/Shared/SkeletonLoader/SkeletonLoader";
import Copyright from "../../components/Copyright/Copyright";
import Toast, { ToastType } from "../../components/Shared/Toast/Toast";

import styles from "./UpdatePassword.module.css";

const UpdatePassword = () => {
  const {
    auth: {
      context,
      matches,
      updatePassword,
      updatePasswordMachineState,
      validatePasswordTokenMachineState,
      validateToken,
    },
  } = useContext(GlobalContext);

  const location = useLocation();
  const [searchParams] = useSearchParams();

  const isLoggedIn =
    matches(AuthState.Authenticated) || matches(AuthState.TechnicalSetup);
  const isLoading = updatePasswordMachineState.value === FetchState.Fetching;
  const isValidating =
    validatePasswordTokenMachineState.value === FetchState.Fetching ||
    matches(AuthState.Authenticating);
  const isChangePassword = location.pathname.includes("change-password");

  const serverError = useMemo(() => {
    return (
      parseServerErrors(validatePasswordTokenMachineState.context.error) ||
      parseServerErrors(updatePasswordMachineState.context.error)
    );
  }, [
    updatePasswordMachineState.context.error,
    validatePasswordTokenMachineState.context.error,
  ]);

  const toastData = useMemo(() => {
    const description =
      isLoading || isValidating
        ? null
        : mappedErrorMessages[serverError as keyof typeof mappedErrorMessages];
    const duration = validatePasswordTokenMachineState.context.error
      ? Infinity
      : 3000;

    return {
      title: isChangePassword ? "Change Password" : "Setup Password",
      description,
      duration,
      type: ToastType.ERROR,
    };
  }, [
    serverError,
    validatePasswordTokenMachineState.context.error,
    isChangePassword,
    isLoading,
    isValidating,
  ]);

  const token = useMemo(() => searchParams.get("token") || "", [searchParams]);
  const email = useMemo(
    () => context.unauthenticatedUserEmail,
    [context.unauthenticatedUserEmail]
  );
  const canAccess = useMemo(() => !!token && !isLoggedIn, [token, isLoggedIn]);
  const redirectUrl = useMemo(() => {
    return !canAccess
      ? "/"
      : updatePasswordMachineState.value === FetchState.Success
      ? "/login"
      : null;
  }, [canAccess, updatePasswordMachineState.value]);

  const submitHandler = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const payload = Object.fromEntries(
        new FormData(event.currentTarget)
      ) as unknown as IUpdatePassword;

      if (!payload.token) return;

      const variables: IUpdatePassword = {
        newPassword: payload.newPassword.trim(),
        repeatPassword: payload.repeatPassword.trim(),
        token: payload.token,
      };

      updatePassword({ variables });
    },
    [updatePassword]
  );

  useEffect(() => {
    if (!token) return;
    validateToken({ variables: { token } });
  }, [validateToken, token]);

  if (redirectUrl) {
    return <Navigate to={redirectUrl} />;
  }

  return (
    <>
      <div className={styles.updatePasswordContainer}>
        <div className={cn("FormContainer", styles.formContainer)}>
          <div className={styles.titleContainer}>
            <h2 className="thin">
              {isChangePassword ? (
                "Change your password"
              ) : (
                <>
                  Step 1 <span className={styles.steps}>(of 2)</span>{" "}
                </>
              )}
            </h2>
            {!isChangePassword && (
              <>
                <h2 className="thin">Setup your password</h2>
                <div className={cn("text", styles.emailInfo)}>
                  Set the password for your account at <b>{email}</b>
                </div>
              </>
            )}
          </div>
          <Form.Root className="Form" onSubmit={submitHandler}>
            <div className="FormFieldsContainer">
              {isValidating ? (
                <>
                  <div>
                    <SkeletonLoader
                      height={14}
                      width={70}
                      containerClassName={styles.label}
                    />
                    <SkeletonLoader height={55.6} />
                  </div>
                  <div>
                    <SkeletonLoader
                      height={14}
                      width={130}
                      containerClassName={styles.label}
                    />
                    <SkeletonLoader height={55.6} />
                  </div>
                </>
              ) : (
                <>
                  <Password
                    name="newPassword"
                    label="Password"
                    placeholder="Enter your new password"
                    compareField="repeatPassword"
                    compareFiledMessage={
                      mappedErrorMessages[
                        UpdatePasswordErrors.PASSWORDS_MISMATCH
                      ]
                    }
                  />
                  <Password
                    name="repeatPassword"
                    label="Confirm password"
                    placeholder="Confirm your password"
                    valueMissingMessage="Please enter a confirm password"
                    compareField="newPassword"
                    compareFiledMessage={
                      mappedErrorMessages[
                        UpdatePasswordErrors.PASSWORDS_MISMATCH
                      ]
                    }
                  />
                </>
              )}
              <Form.Field name="token" style={{ display: "none", height: 0 }}>
                <Form.Control value={token} />
              </Form.Field>
            </div>
            {isValidating || isLoading ? (
              <>
                <SkeletonLoader height={39.6} />
              </>
            ) : (
              <Form.Submit className="btn large">
                {!isChangePassword ? "Setup Password" : "Continue"}
              </Form.Submit>
            )}
          </Form.Root>
        </div>

        <Copyright className={styles.copyright} />
      </div>
      <Toast
        title={toastData.title}
        description={toastData.description}
        duration={toastData.duration}
        type={toastData.type}
      />
    </>
  );
};

export default withRouteConfig(UpdatePassword);
