import { memo, useCallback, useEffect } from "react";
import { format, addMonths } from "date-fns";
import cn from "classnames";
import BulkScheduleDialogWeekDays from "./BulkScheduleDialogWeekDays/BulkScheduleDialogWeekDays";
import {
  DaysOfTheWeek,
  ScheduledDay,
} from "../../../../../../../apollo-graphql/types/slot";
import { IScheduleDialogState } from "../ScheduleDialogButtons/ScheduleDialogButtons";
import { v4 } from "uuid";
import DatePicker from "../../../../../../Shared/DatePicker/DatePicker";

import styles from "./BulkScheduleDialogContent.module.css";

interface BulkScheduleDialogContentProps {
  startDate?: Date;
  endDate?: Date;
  scheduledDays?: ScheduledDay;
  errorMessage?: string;
  setDialogState: (value: React.SetStateAction<IScheduleDialogState>) => void;
}

const BulkScheduleDialogContent = ({
  startDate,
  endDate,
  scheduledDays,
  setDialogState,
  errorMessage,
}: BulkScheduleDialogContentProps) => {
  const handleStartDateChange = useCallback(
    (date: Date | null) => {
      if (!date) {
        return;
      }
      setDialogState((prev) => ({ ...prev, startDate: date }));

      if (!endDate || date > new Date(endDate)) {
        setDialogState((prev) => ({ ...prev, endDate: date }));
      }
    },
    [setDialogState, endDate]
  );

  const handleEndDateChange = useCallback(
    (date: Date | null) => {
      if (!date) {
        return;
      }
      setDialogState((prev) => ({ ...prev, endDate: date }));
    },
    [setDialogState]
  );

  const onSlotAdded = useCallback(
    (day: DaysOfTheWeek, time: string) => {
      setDialogState((prev) => ({
        ...prev,
        scheduledDays: {
          ...scheduledDays,
          [day]: [...(prev.scheduledDays?.[day] || []), { id: v4(), time }],
        },
      }));
    },
    [scheduledDays, setDialogState]
  );

  const onSlotRemoved = useCallback(
    (id: string) => {
      setDialogState((prev) => {
        const updatedSlots = Object.fromEntries(
          Object.entries(prev.scheduledDays || {}).map(([day, slots]) => [
            day,
            slots.filter((slot) => slot.id !== id),
          ])
        );

        return {
          ...prev,
          scheduledDays: updatedSlots,
        };
      });
    },
    [setDialogState]
  );

  const onSelectedSlotsDaysChanged = useCallback(
    (days: DaysOfTheWeek[]) => {
      setDialogState((prev) => ({
        ...prev,
        selectedScheduledDays: days,
      }));
    },
    [setDialogState]
  );

  useEffect(() => {
    if (startDate && endDate) return;
    const now = new Date();

    setDialogState((prev) => ({
      ...prev,
      startDate: now,
      endDate: addMonths(now, 1),
    }));
  }, [endDate, setDialogState, startDate]);

  return (
    <div>
      <div className={styles.pickersWrapper}>
        <div className={styles.inputContainer}>
          <span className={cn(styles.inputLabel, "text", "bold")}>
            Start Date
          </span>
          {startDate && (
            <DatePicker
              onChange={handleStartDateChange}
              value={format(startDate as Date, "yyyy-MM-dd")}
              minDate={new Date()}
              popperPlacement="bottom-end"
            />
          )}
        </div>
        <div className={styles.inputContainer}>
          <span className={cn(styles.inputLabel, "text", "bold")}>
            End Date
          </span>
          {endDate && (
            <DatePicker
              onChange={handleEndDateChange}
              value={format(endDate as Date, "yyyy-MM-dd")}
              minDate={new Date()}
              popperPlacement="bottom-start"
            />
          )}
        </div>
      </div>
      <span className={cn(styles.timeSlotsText, "text", "bold")}>
        Choose the Weekdays with the corresponding Time Slots(1.5h each):
      </span>
      <BulkScheduleDialogWeekDays
        scheduledDays={scheduledDays || {}}
        onSlotAdded={onSlotAdded}
        onSlotRemoved={onSlotRemoved}
        onSelectedSlotsDaysChanged={onSelectedSlotsDaysChanged}
      />
      <div className={styles.participants}>
        <span className={cn(styles.participantsLabel, "text", "small")}>
          Participants:
        </span>
        <input
          type="text"
          placeholder="All Workspace Team Members"
          disabled
          className={cn(styles.participantsInput, "secondary")}
        />
      </div>

      {errorMessage && (
        <div className={styles.errorContainer}>
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
};

export default memo(BulkScheduleDialogContent);
