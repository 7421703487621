import { PropsWithChildren, memo, useCallback, useMemo } from "react";

import Video from "../Shared/Video/Video";
import VideoWithUserImage from "../VideoWithUserImage/VideoWithUserImage";
import Indicators from "./components/Indicators";
import UserImage from "../Shared/UserImage/UserImage";

import { RemoteParticipantData } from "../../types/jitsi";
import { Profile } from "../../apollo-graphql/types/profile";

import cn from "classnames";
import mutedVideoStyles from "../VideoWithUserImage/VideoWithUserImage.module.css";
import styles from "./JitsiList.module.css";

export default memo(function JitsiList(
  props: PropsWithChildren<{
    profile: Profile;
    hasTeamName: boolean;
    isCurrentUserVideoMutedOrUnavailable?: boolean;
    isCurrentUserAudioMutedOrUnavailable?: boolean;
    remoteParticipantsData: RemoteParticipantData[];
    disableAudioAndVideo?: boolean;
    isUserTalking?: boolean;
    teamName?: string;
    isParticipating: boolean;
    currentProfileIsObserver: boolean;
    attachHtmlVideoElementToLocalTracks(videoElement: HTMLVideoElement): void;
    attachHtmlVideoElementToRemoteTracks(
      participantId: string,
      videoElement: HTMLVideoElement,
      audioElement: HTMLAudioElement
    ): void;
  }>
) {
  const {
    remoteParticipantsData,
    disableAudioAndVideo,
    isCurrentUserVideoMutedOrUnavailable,
    isCurrentUserAudioMutedOrUnavailable,
    attachHtmlVideoElementToLocalTracks,
    attachHtmlVideoElementToRemoteTracks,
    profile,
    isUserTalking,
    hasTeamName,
    teamName,
    currentProfileIsObserver,
  } = props;

  const videoRefCallback = useCallback(
    (ref: HTMLVideoElement | null) => {
      if (!ref) return;
      attachHtmlVideoElementToLocalTracks(ref);
    },
    [attachHtmlVideoElementToLocalTracks]
  );

  const participantContent = useMemo(() => {
    return (
      <>
        <Indicators
          hasMicrophone={
            !disableAudioAndVideo && !!isCurrentUserAudioMutedOrUnavailable
          }
          hasVoice={
            !disableAudioAndVideo &&
            !isCurrentUserAudioMutedOrUnavailable &&
            !!isUserTalking
          }
          isObserver={currentProfileIsObserver}
        />

        {!isCurrentUserVideoMutedOrUnavailable ? (
          <Video
            participantId={profile.id}
            width={"100%"}
            height={"100%"}
            noBorderRadius={true}
            ref={videoRefCallback}
            ignoreAudio={true}
          />
        ) : null}

        <div
          className={cn(
            styles.imageContainer,
            !isCurrentUserVideoMutedOrUnavailable && "hidden"
          )}
        >
          <div className={mutedVideoStyles.mutedVideoContainer}>
            <UserImage
              isPublic={false}
              profileId={profile.id}
              profileWorkspaceId={profile.workspace.workspace_id}
              alt="profile-image"
              fallbackFontAwesomeIconClass="icon fa fa-user"
            />
          </div>
        </div>
        <div className={cn(styles.participantName, "text", "tiny", "bold")}>
          {profile?.name}
        </div>
      </>
    );
  }, [
    currentProfileIsObserver,
    disableAudioAndVideo,
    isCurrentUserAudioMutedOrUnavailable,
    isCurrentUserVideoMutedOrUnavailable,
    isUserTalking,
    profile.id,
    profile?.name,
    profile.workspace.workspace_id,
    videoRefCallback,
  ]);

  const remoteParticipantsContent = useMemo(() => {
    return remoteParticipantsData.map(
      ({
        participantId,
        name,
        isConnecting,
        isAudioMuted,
        isVideoMuted,
        isVideoEnabled,
        isTalking,
        profileId,
        workspaceId,
        isObserver,
      }) => (
        <div
          key={`${participantId}-${name}`}
          className={styles.participantContainer}
        >
          <Indicators
            hasMicrophone={!disableAudioAndVideo && !!isAudioMuted}
            hasVoice={!disableAudioAndVideo && isTalking}
            isObserver={!!isObserver}
          />
          <VideoWithUserImage
            profileId={profileId!}
            workspaceId={workspaceId!}
            noBorderRadius={true}
            participantId={participantId}
            width={"100%"}
            height={"100%"}
            isConnecting={isConnecting}
            isVideoMuted={!!isVideoMuted || !isVideoEnabled}
            disableAudioAndVideo={!!disableAudioAndVideo}
            attachHtmlVideoElementToTracks={
              attachHtmlVideoElementToRemoteTracks
            }
          />
          <div className={cn(styles.participantName, "text", "tiny", "bold")}>
            {isConnecting ? "Connecting..." : name}
          </div>
        </div>
      )
    );
  }, [
    attachHtmlVideoElementToRemoteTracks,
    disableAudioAndVideo,
    remoteParticipantsData,
  ]);

  const content = useMemo(() => {
    if (disableAudioAndVideo)
      return (
        <div className={styles.individualSectionCoverContainer}>
          <span className="badge blue">Individual Session</span>
          <h3 className={styles.infoContainer}>
            You should complete this step alone.
          </h3>
        </div>
      );

    return (
      <div
        className={cn(styles.participantsListContainer, {
          [styles.participantsListContainerSinglePlayer]:
            !remoteParticipantsData.length,
        })}
      >
        <div className={styles.participantContainer}>{participantContent}</div>
        {remoteParticipantsContent}
      </div>
    );
  }, [
    disableAudioAndVideo,
    participantContent,
    remoteParticipantsContent,
    remoteParticipantsData.length,
  ]);

  return (
    <div className={styles.container}>
      {hasTeamName && (
        <div className={styles.topControls}>
          <div className="text small team-name">{teamName || "Team name"}</div>
        </div>
      )}
      {content}
    </div>
  );
});
