import { PropsWithChildren, memo, useCallback, useMemo, useRef } from "react";
import { v4 } from "uuid";

import useServerTime from "../../../../hooks/useServerTime";

import { getEntryId, getTransitionData, parseToJson } from "../../../../utils";
import { getTransitionActionFooterData } from "../utils/get-transition-footer-data";

import {
  IDragAndDropCard,
  SectionTypes,
} from "../../../Shared/DragAndDrop/types";
import { BrainstormingActivity } from "../../../../types/contentful/workshop/activities/brainstorming";
import { normalConnectionThreshold } from "../../../../contexts/Apollo";
import { ActivityCommon } from "../../../../types/activity-common";
import { ActionFooterType } from "../../../../types/action-footer";
import { FooterType } from "../../../../types/enums/activity-footer";
import { IActivityResult } from "../../../../apollo-graphql/types/session-state";
import { observerFooterData } from "../constants";
import { ACTIVITY_TIMEOUT_VALUE } from "../../../../constants/global";

import ActionFooter from "../../ActionFooter/ActionFooter";
import InfoBox from "../../../InfoBox/InfoBox";
import NextStepTransition from "../../NextStepTransition/NextStepTransition";
import BrainstormingItemComponent from "./components/BrainstormingItem";
import DragAndDropSectionList from "../../../Shared/DragAndDrop/DragAndDropSectionList/DragAndDropSectionList";
import ScrollIndicator from "../../../Shared/ScrollIndicator/ScrollIndicator";
import ContentfulRichField from "../../../Shared/ContentfulRichField/ContentfulRichField";
import ActivityInstructions from "../../../Shared/ActivityInstructions/ActivityInstructions";

import styles from "./Brainstorming.module.css";

interface BrainstormingProps extends ActivityCommon {
  profileId: string;
  activityResultForCurrentProfile: IActivityResult["value"]["0"] | null;
  activityResults: IActivityResult["value"] | null;
  isViewResults?: boolean;
  setActivityValueHandler: (args: {
    activityId: string;
    value: string;
  }) => void;
}

const Brainstorming = (props: PropsWithChildren<BrainstormingProps>) => {
  const {
    profileId,
    activity,
    nextActivity,
    transition,
    isReady,
    isLoading,
    isConnectionWeak,
    notReadyProfilesCount,
    activityResults,
    activityResultForCurrentProfile,
    isViewResults = false,
    isActivityTimeout,
    isParticipating,
    hasAhaMoments,
    setActivityValueHandler,
    setActivityReadyHandler,
  } = props;
  const newBrainstormingId = useRef<string | null>(null);
  const serverTimestampRef = useServerTime();

  const brainstormingId = useMemo(() => getEntryId(activity), [activity]);

  const brainstormItems = useMemo(
    () => (activity as BrainstormingActivity)?.fields?.items || [],
    [activity]
  );

  const activityData = useMemo(() => {
    return {
      id: getEntryId(activity),
    };
  }, [activity]);

  const nextActivityData = useMemo(
    () => getTransitionData(nextActivity, hasAhaMoments),
    [nextActivity, hasAhaMoments]
  );

  const delayInMilliseconds = useMemo(
    () => (isConnectionWeak ? 0 : normalConnectionThreshold),
    [isConnectionWeak]
  );

  const parsedResultForCurrentProfile = useMemo(() => {
    if (
      !activityResultForCurrentProfile?.value ||
      activityResultForCurrentProfile.value === ACTIVITY_TIMEOUT_VALUE
    )
      return [];

    return parseToJson<IDragAndDropCard[]>(
      activityResultForCurrentProfile.value,
      [],
      "Could not parse brainstorming activity result value"
    );
  }, [activityResultForCurrentProfile]);

  const parsedResultForAllProfiles: IDragAndDropCard[] = useMemo(() => {
    if (!Array.isArray(activityResults)) return [];

    const res: IDragAndDropCard[] = [];
    activityResults.forEach((result) => {
      if (!result?.value || result.value === ACTIVITY_TIMEOUT_VALUE) return;

      const parsedResult = parseToJson<IDragAndDropCard[]>(
        result.value,
        [],
        "Could not parse brainstorming activity result value"
      );

      res.push(...parsedResult);
    });

    const mappedList = res
      .sort((a, b) => b.timestamp! - a.timestamp!)
      .map((item, index, arr) => ({
        ...item,
        title: `${arr.length - index}. ${item.title}`,
      }));

    return mappedList;
  }, [activityResults]);

  const generateUniqueId = useCallback(
    (brainstormingItemId: string) => `${brainstormingItemId}-${profileId}`,
    [profileId]
  );

  const handleValueShare = useCallback(
    (data: {
      value: string;
      brainstormingItem: {
        id: string;
        text: string;
        allowMultipleSubmissions: boolean;
      };
    }) => {
      const parsedText = data.value.trim();
      if (isLoading || !isParticipating || !parsedText.length) return;

      const {
        brainstormingItem: { id, text, allowMultipleSubmissions },
      } = data;
      const uniqueId = allowMultipleSubmissions ? v4() : generateUniqueId(id);

      let alreadySubmittedValue: IDragAndDropCard =
        null as any as IDragAndDropCard;
      const valueRaw: IDragAndDropCard[] = parsedResultForCurrentProfile.filter(
        (item) => {
          const isCurrentItem = item.id === uniqueId;
          if (isCurrentItem) {
            alreadySubmittedValue = item;
          }
          return !isCurrentItem;
        }
      );

      const newBrainstormingItem: IDragAndDropCard = {
        id: uniqueId,
        profileId,
        text: parsedText,
        title: text,
        isDraggable: false,
        timestamp:
          alreadySubmittedValue?.timestamp ||
          serverTimestampRef.current + Math.random(),
      };
      newBrainstormingId.current = newBrainstormingItem.id;
      valueRaw.push(newBrainstormingItem);

      const value = JSON.stringify(valueRaw);
      if (activityResultForCurrentProfile?.value === value) return;

      setActivityValueHandler({
        activityId: activityData.id,
        value,
      });
    },
    [
      isLoading,
      isParticipating,
      generateUniqueId,
      parsedResultForCurrentProfile,
      profileId,
      serverTimestampRef,
      activityResultForCurrentProfile,
      setActivityValueHandler,
      activityData.id,
    ]
  );

  const isReadyToContinue = useMemo(() => {
    if (!!brainstormItems[0]?.fields?.allowMultipleSubmissions) {
      return !!activityResults?.length;
    }

    if (!activityResultForCurrentProfile || !isParticipating) return false;
    const brainstormingItemIds = brainstormItems.map((item) =>
      generateUniqueId(getEntryId(item))
    );

    return brainstormingItemIds.every(
      (id) =>
        parsedResultForCurrentProfile.find((item) => item.id === id) !==
        undefined
    );
  }, [
    activityResultForCurrentProfile,
    brainstormItems,
    generateUniqueId,
    isParticipating,
    parsedResultForCurrentProfile,
    activityResults,
  ]);

  const isTransitioning = useMemo(() => transition > 0, [transition]);
  const actionFooterData: ActionFooterType = useMemo(() => {
    if (!isParticipating) return observerFooterData;
    if (isTransitioning) {
      return getTransitionActionFooterData({
        text: <>Everyone submitted their descriptions. Continuing forward...</>,
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Ready,
        isActivityTimeout,
      });
    }
    if (!isReady) {
      const text = !isReadyToContinue ? (
        <>Add all your descriptions to continue.</>
      ) : (
        <>
          Click on <span className="accent green">“Continue”</span> to submit
          your descriptions.
        </>
      );
      return {
        text,
        buttonText: "Continue",
        disabledButton: !isReadyToContinue,
        type: FooterType.Notice,
      };
    }
    return {
      text: (
        <>
          Waiting for{" "}
          <span className="accent">
            {notReadyProfilesCount} more player
            {notReadyProfilesCount > 1 && "s"}...
          </span>
        </>
      ),
      buttonText: "Continue",
      disabledButton: true,
      type: FooterType.Waiting,
    };
  }, [
    isActivityTimeout,
    isTransitioning,
    isParticipating,
    isReady,
    notReadyProfilesCount,
    isReadyToContinue,
  ]);

  const resultsTitle = useMemo(() => {
    return (activity as BrainstormingActivity)?.fields?.resultsTitle;
  }, [activity]);

  const itemsTitle = useMemo(() => {
    return (activity as BrainstormingActivity)?.fields?.itemsTitle;
  }, [activity]);

  const infoContainerContent = useMemo(() => {
    const html = activity?.fields?.activity?.fields?.instructions;
    const conferenceMode = activity?.fields?.activity?.fields?.conferenceMode;
    const tags = activity?.fields?.activity?.fields?.tags;

    return (
      <ActivityInstructions
        instructions={html}
        tags={tags}
        type={conferenceMode}
      />
    );
  }, [activity]);

  const noContentTitle = useMemo(() => {
    const html = (activity as BrainstormingActivity)?.fields?.resultsInfo;
    if (!html) return "";
    return <ContentfulRichField content={html} />;
  }, [activity]);

  const content = useMemo(() => {
    return brainstormItems.map((brainstormingItem, index) => {
      const brainstormingItemId = getEntryId(brainstormingItem);
      const uniqueId = generateUniqueId(brainstormingItemId);

      const currentItemResult = parsedResultForCurrentProfile.find(
        (item) => item.id === uniqueId
      );

      const currentValue = currentItemResult?.text || null;
      const hasLoading =
        isLoading && newBrainstormingId.current === brainstormingItemId;

      return (
        <BrainstormingItemComponent
          key={brainstormingItemId}
          index={index + 1}
          brainstormingId={brainstormingId}
          currentValue={currentValue}
          canEdit={true}
          brainstormingItem={brainstormingItem}
          isDisabled={isViewResults || !isParticipating}
          isTransitioning={isTransitioning}
          isLoading={hasLoading}
          delayInMilliseconds={delayInMilliseconds}
          shareValue={handleValueShare}
        />
      );
    });
  }, [
    brainstormItems,
    brainstormingId,
    delayInMilliseconds,
    generateUniqueId,
    handleValueShare,
    isLoading,
    isParticipating,
    isTransitioning,
    isViewResults,
    parsedResultForCurrentProfile,
  ]);

  return (
    <>
      <ScrollIndicator
        key={activityData.id}
        className="activity-container main-container"
      >
        <div className={styles.container}>
          <div className={styles.content}>
            {infoContainerContent}
            <div className={styles.contentWrapper}>
              <div className={styles.itemsContainer}>
                <div className="text bold">{resultsTitle}</div>
                {content}
              </div>
              <div className={styles.line}></div>
              <div className={styles.sharedValuesContainer}>
                <DragAndDropSectionList
                  id={SectionTypes.SECTION_B}
                  sectionTitle={itemsTitle}
                  currentProfileId={profileId}
                  isDisabled={true}
                  items={parsedResultForAllProfiles}
                  hasDraggableIcon={false}
                  containerClass={styles.dragAndDropContainer}
                  noContent={<InfoBox transparent title={noContentTitle} />}
                />
              </div>
            </div>
          </div>
        </div>
        {!isViewResults && isTransitioning && (
          <NextStepTransition
            nextStep={nextActivityData.transitionText}
            sessionType={nextActivityData.conferenceMode}
            isActivityTimeout={isActivityTimeout}
            transition={transition}
          />
        )}
      </ScrollIndicator>
      {!isViewResults && (
        <ActionFooter
          buttonText={actionFooterData.buttonText}
          type={actionFooterData.type}
          disabledButton={actionFooterData.disabledButton}
          isLoading={isLoading}
          isConnectionWeak={isConnectionWeak}
          buttonClickHandler={() =>
            setActivityReadyHandler({ activityId: activityData.id })
          }
        >
          {actionFooterData.text}
        </ActionFooter>
      )}
    </>
  );
};
export default memo(Brainstorming);
