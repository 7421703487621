import { PropsWithChildren, memo, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMachine } from "@xstate/react";
import { formatDate } from "date-fns";

import * as adminDashboardScheduleDialogActions from "../../../+xstate/actions/dashboard/admin-dashboard-schedule-dialog";
import {
  adminDashboardScheduleDialogMachine,
  AdminDashboardScheduleState,
} from "../../../+xstate/machines/dashboard/admin-dashboard-schedule-dialog";
import {
  selectWorkshopStart,
  setScheduleDateTime,
  setScheduleParticipants,
  createSlot,
  createBulkSlots,
} from "../../../+xstate/actions/dashboard/admin-dashboard-schedule-dialog";

import { getScheduleMachineDialogState } from "../../../utils/schedule";
import { Workshop } from "../../../types/contentful/workshop/workshop";
import { SlotType } from "../../../apollo-graphql/types/enums";

import WorkshopList from "./components/WorkshopList/WorkshopList";
import ScheduleDialog from "./components/ScheduleDialog/ScheduleDialog";
import { IScheduleDialogState } from "./components/ScheduleDialog/components/ScheduleDialogButtons/ScheduleDialogButtons";
import { getEntryId } from "../../../utils";
import { ScheduledDay } from "../../../apollo-graphql/types/slot";

function AdminWorkshopList(
  props: PropsWithChildren<{
    isDialogOpen: boolean;
    workshopsLoading: boolean;
    workshops: Workshop[];
    scheduleDialogMachine: ReturnType<
      typeof useMachine<typeof adminDashboardScheduleDialogMachine>
    >;
  }>
) {
  const { isDialogOpen, workshopsLoading, workshops, scheduleDialogMachine } =
    props;
  const navigate = useNavigate();

  const [state, send] = scheduleDialogMachine;
  const { workshopId, profiles, slot } = state.context;

  const selectedWorkshop = useMemo(
    () => workshops.find((w) => getEntryId(w) === workshopId),
    [workshopId, workshops]
  );

  const currentState = useMemo(
    () => getScheduleMachineDialogState(state),
    [state]
  );

  const closeDialogHandler = useCallback(() => {
    navigate("/workshops");
    send(adminDashboardScheduleDialogActions.closeScheduleDialog());
  }, [navigate, send]);

  const dialogContinueHandler = useCallback(
    (data?: IScheduleDialogState) => {
      if (!data) return;

      if (currentState === AdminDashboardScheduleState.Start) {
        send(selectWorkshopStart({ type: data.type! }));
      }

      if (currentState === AdminDashboardScheduleState.ScheduleReady) {
        if (!data.type || data.type === SlotType.BULK) return;

        const dateTime = new Date(
          `${data.date}T${formatDate(new Date(data.time!), "HH:mm")}`
        );
        const participantEmails = (data?.profiles || []).map((p) => p.email);
        const createSlotPayload = {
          type: data.type,
          dateTime,
          participantEmails,
        };

        send(setScheduleDateTime({ dateTime }));
        send(setScheduleParticipants({ participantEmails }));
        send(createSlot(createSlotPayload));
      }

      if (currentState === AdminDashboardScheduleState.ScheduleReadyBulk) {
        const scheduledDays = Object.entries(data.scheduledDays!).reduce(
          (acc, [key, value]) => {
            acc[key as keyof typeof acc] = value.map(({ time }) => time);

            return acc;
          },
          {} as ScheduledDay<string>
        );

        send(
          createBulkSlots({
            type: data.type! as SlotType.BULK,
            startDate: data.startDate!,
            endDate: data.endDate!,
            scheduledDays,
          })
        );
      }

      if (currentState === AdminDashboardScheduleState.Done) {
        closeDialogHandler();
      }
    },
    [currentState, send, closeDialogHandler]
  );

  return (
    <WorkshopList
      workshops={workshops}
      workshopsLoading={workshopsLoading}
      buttonText="Start conversation"
    >
      {isDialogOpen && (
        <ScheduleDialog
          workshop={selectedWorkshop!}
          profiles={profiles}
          slot={slot}
          scheduleDialogMachine={scheduleDialogMachine}
          continueButtonClickHandler={dialogContinueHandler}
          closeDialogHandler={closeDialogHandler}
        />
      )}
    </WorkshopList>
  );
}

export default memo(AdminWorkshopList);
