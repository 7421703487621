export enum NetworkError {
  FAILED_TO_FETCH = "FAILED_TO_FETCH",
}

export enum UpdateProfileErrors {
  MISSING_DATA = "UPDATE_PROFILE__MISSING_DATA",
  WRONG_PASSWORD = "UPDATE_PROFILE__WRONG_PASSWORD",
  NOT_FOUND = "UPDATE_PROFILE__NOT_FOUND",
}

export enum InviteProfileErrors {
  MISSING_DATA = "INVITE_PROFILE__MISSING_DATA",
  DOMAIN_NOT_FOUND = "INVITE_PROFILE__DOMAIN_NOT_FOUND",
  PROFILE_ALREADY_EXISTS = "INVITE_PROFILE__PROFILE_ALREADY_EXISTS",
  WORKSPACE_NOT_FOUND = "INVITE_PROFILE__WORKSPACE_NOT_FOUND",
  WORKSPACE_PROFILE_NOT_FOUND = "INVITE_PROFILE__WORKSPACE_PROFILE_NOT_FOUND",
}

export enum ResetPasswordErrors {
  MISSING_DATA = "RESET_PASSWORD__MISSING_DATA",
  DOMAIN_NOT_FOUND = "RESET_PASSWORD__DOMAIN_NOT_FOUND",
  PROFILE_NOT_FOUND = "RESET_PASSWORD__PROFILE_NOT_FOUND",
}

export enum UpdatePasswordErrors {
  MISSING_DATA = "UPDATE_PASSWORD__MISSING_DATA",
  PASSWORDS_MISMATCH = "UPDATE_PASSWORD__PASSWORDS_MISMATCH",
  TOKEN_NOT_FOUND = "UPDATE_PASSWORD__TOKEN_NOT_FOUND",
  WRONG_TOKEN = "UPDATE_PASSWORD__WRONG_TOKEN",
  PROFILE_NOT_FOUND = "UPDATE_PASSWORD__PROFILE_NOT_FOUND",
}

export enum LoginErrors {
  MISSING_DATA = "LOGIN_ERROR__MISSING_DATA",
  DOMAIN_NOT_FOUND = "LOGIN_ERROR__DOMAIN_NOT_FOUND",
  PROFILE_NOT_FOUND = "LOGIN_ERROR__PROFILE_NOT_FOUND",
  WRONG_DATA = "LOGIN_ERROR__WRONG_DATA",
}

export enum ResendInvitationError {
  MISSING_DATA = "RESEND_INVITATION__MISSING_DATA",
  PROFILE_NOT_FOUND = "RESEND_INVITATION__PROFILE_NOT_FOUND",
  PROFILE_ALREADY_REGISTERED = "RESEND_INVITATION__PROFILE_ALREADY_REGISTERED",
  RESEND_INVITATION__DOMAIN_NOT_FOUND = "RESEND_INVITATION__DOMAIN_NOT_FOUND",
  RECAPTCHA_SCORE_TOO_LOW = "RESEND_INVITATION__RECAPTCHA_SCORE_TOO_LOW",
}

export enum RefreshTokenErrors {
  TOKEN_NOT_FOUND = "TOKEN_NOT_FOUND",
  TOKEN_EXPIRED = "TOKEN_EXPIRED",
  CORRUPTED_TOKEN = "CORRUPTED_TOKEN",
  TOKEN_INVALID = "invalid token",
  TOKEN_INVALID_SIGNATURE = "invalid signature",
  TOKEN_MALFORMED = "jwt malformed",
  UNKNOWN = "UNKNOWN",
}
