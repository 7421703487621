import {
  KeyboardEvent,
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { BrainstormingActivityItem } from "../../../../../types/contentful/workshop/activities/brainstorming";

import LoadingButton from "../../../../Shared/Buttons/LoadingButton/LoadingButton";

import cn from "classnames";
import styles from "./BrainstormingItem.module.css";
interface BrainstormingItemProps {
  brainstormingId: string;
  brainstormingItem: BrainstormingActivityItem;
  isTransitioning: boolean;
  isLoading: boolean;
  delayInMilliseconds: number;
  index: number;
  currentValue: string | null;
  isDisabled: boolean;
  canEdit: boolean;
  shareValue: (args: {
    value: string;
    brainstormingItem: {
      id: string;
      text: string;
      allowMultipleSubmissions: boolean;
    };
  }) => void;
}
export default memo(function BrainstormingItem(
  props: PropsWithChildren<BrainstormingItemProps>
) {
  const {
    brainstormingItem,
    isTransitioning,
    isLoading,
    delayInMilliseconds,
    index,
    canEdit,
    isDisabled,
    currentValue,
    shareValue,
  } = props;

  const [isEditing, setIsEditing] = useState(false);
  const isSubmitted = useMemo(() => !!currentValue, [currentValue]);
  const [text, setText] = useState(currentValue || "");

  const brainstormingData = useMemo(() => {
    return {
      id: brainstormingItem.sys.id,
      title: brainstormingItem?.fields?.title,
      placeholderText: brainstormingItem?.fields?.placeholderText,
      image: {
        url: brainstormingItem.fields?.image?.fields?.file?.url,
        fileName: brainstormingItem.fields?.image?.fields?.file?.fileName,
      },
      allowMultipleSubmissions:
        !!brainstormingItem.fields?.allowMultipleSubmissions,
    };
  }, [brainstormingItem]);

  const actionIsEdit = useMemo(
    () => isSubmitted && !isEditing,
    [isEditing, isSubmitted]
  );

  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && e.shiftKey === false) {
      e.preventDefault();

      if (text.trim() !== "") {
        handleButtonClick();
      }
    }
  };

  const handleButtonClick = useCallback(() => {
    if (
      brainstormingData.allowMultipleSubmissions ||
      !isSubmitted ||
      (canEdit && isEditing)
    ) {
      shareValue({
        value: text,
        brainstormingItem: {
          id: brainstormingData.id,
          text: brainstormingData.title,
          allowMultipleSubmissions: brainstormingData.allowMultipleSubmissions,
        },
      });
      if (isEditing) {
        setIsEditing(false);
      }
      if (brainstormingData.allowMultipleSubmissions) {
        setText("");
      }
      return;
    }

    setIsEditing(true);
  }, [
    brainstormingData.id,
    brainstormingData.title,
    canEdit,
    isEditing,
    isSubmitted,
    shareValue,
    text,
    brainstormingData.allowMultipleSubmissions,
  ]);

  useEffect(() => {
    if (!canEdit && isEditing) setIsEditing(false);
  }, [currentValue, canEdit, isEditing, setIsEditing]);

  return (
    <div
      key={brainstormingData.id}
      className={styles.brainstormingItemContainer}
    >
      <div className={styles.content}>
        {brainstormingData?.image?.url && (
          <div className={styles.info}>
            <div
              className={cn(
                styles.infoImageContainer,
                isSubmitted && (!canEdit || !isEditing) && "disabled"
              )}
            >
              {/* TODO: Use FetchImage or UserImage component here */}
              <img
                src={`https:${brainstormingData.image.url}`}
                alt={brainstormingData.image.fileName}
              />
            </div>
          </div>
        )}
        <div className={styles.inputContainer}>
          <textarea
            tabIndex={index}
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={onKeyDown}
            disabled={
              !brainstormingData.allowMultipleSubmissions &&
              (isDisabled ||
                (isSubmitted && (!canEdit || !isEditing)) ||
                isTransitioning ||
                isLoading)
            }
            className={cn("Textarea", "text", {
              [styles.textareaWithImage]: brainstormingData.image.url,
            })}
            placeholder={brainstormingData.placeholderText}
            required
          />
        </div>
      </div>
      {!isDisabled && (
        <div className={styles.actionContainer}>
          <LoadingButton
            size="small"
            iconPosition="end"
            iconClass={
              actionIsEdit ? "fa fa-pen-to-square" : "fa fa-arrow-right"
            }
            disabled={
              brainstormingData.allowMultipleSubmissions
                ? !text.trim()
                : (isSubmitted && !canEdit) ||
                  isTransitioning ||
                  !text.trim() ||
                  isLoading
            }
            isLoading={isLoading}
            delayInMilliseconds={delayInMilliseconds}
            onClick={handleButtonClick}
          >
            <span className="button-text">
              {!brainstormingData.allowMultipleSubmissions && actionIsEdit
                ? "Edit"
                : "Share"}
            </span>
          </LoadingButton>
        </div>
      )}
    </div>
  );
});
