import {
  ApolloServerError,
  ApolloServerErrorCodes,
} from "../types/server-error";

export const retryRequestIf = ({
  graphQLErrors,
  networkError,
}: ApolloServerError) => {
  if (!!networkError) return true;

  const graphqlErrorCode = graphQLErrors?.[0]?.code;
  // TODO: check the logic for retry the request

  return graphqlErrorCode !== ApolloServerErrorCodes.SERVER_ERROR;
};
