import { Profile } from "../apollo-graphql/types";

export function parseAuthenticationJWT(token: string):
  | (Profile & {
      iat: number;
      exp: number;
      iss: string;
    })
  | null {
  if (!token) return null;
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  } catch (err) {
    console.error("Error parsing JWT!");
    return null;
  }
}
