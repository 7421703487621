import cn from "classnames";
import { memo, PropsWithChildren } from "react";
import { ButtonSize, ButtonVariant } from "../../../../types/buttons";

export default memo(function IconButton(
  props: PropsWithChildren<{
    iconName: string;
    variant?: ButtonVariant;
    size?: ButtonSize;
    className?: string;
    onClick: () => void;
  }>
) {
  const {
    variant = "primary",
    size = "medium",
    className,
    iconName,
    children,
    onClick,
  } = props;

  return (
    <button
      type="button"
      className={cn("btn", variant, size, className)}
      onClick={onClick}
    >
      <i className={cn("fa", iconName)} />

      {children && <div className="text">{children}</div>}
    </button>
  );
});
