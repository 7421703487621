import { useMachine } from "@xstate/react";
import { useContext, useEffect, useMemo } from "react";
import { AdminDashboardContext } from "../../../../../contexts/AdminDashboard";
import { enterAdminJourneyDetails } from "../../../../../+xstate/actions/dashboard/admin-dashboard";
import { useParams } from "react-router-dom";
import { useChildMachine } from "../../../../../hooks/useChildMachine";
import { getJourneyMachine } from "../../../../../+xstate/machines/dashboard/admin-dashboard";
import { FetchState } from "../../../../../+xstate/machines/fetch-factory";
import JourneyDetailsBanner from "./JourneyDetailsBanner/JourneyDetailsBanner";
import JourneyDetailsTitle from "./JourneyDetailsTitle/JourneyDetailsTitle";
import { GlobalContext } from "../../../../../contexts/Global";

import JourneyAggregates from "../components/JourneyDescription/JourneyAggregates/JourneyAggregates";
import SkeletonLoader from "../../../../Shared/SkeletonLoader/SkeletonLoader";
import {
  calculateAhaMomentsCount,
  calculateJourneyDuration,
  calculateBehavioursCount,
  calculateJourneyParticipants,
  calculateCompletedConversations,
  getJourneyType,
} from "../utils";

import { useJourneyStatus } from "../../../../../hooks/useJourneyStatus";
import JourneyModals from "./JourneyModals/JourneyModals";
import JourneyTabs from "./JourneyTabs/JourneyTabs";
import JourneyProgress from "../components/JourneyDescription/JourneyProgress/JourneyProgess";
import { fetchTeamMembers } from "../../../../../+xstate/actions/team-members";
import { tmDefaultFilteringState } from "../../TeamMembers/TeamMembers";
import ContentfulRichField from "../../../../Shared/ContentfulRichField/ContentfulRichField";

import styles from "./JourneyDetails.module.css";

const JourneyDetailsLoading = () => (
  <div className={styles.skeletonsContainer}>
    <SkeletonLoader
      containerClassName={styles.bannerSkeleton}
      borderRadius={16}
      height={600}
    />
    <SkeletonLoader
      containerClassName={styles.aggregatesSkeleton}
      height={70}
      borderRadius={16}
    />
    <SkeletonLoader height={100} borderRadius={16} />
    {[350, 450, 450, 650].map((height, index) => (
      <SkeletonLoader
        key={index}
        containerClassName={styles.descriptionSkeleton}
        height={height}
        borderRadius={16}
      />
    ))}
  </div>
);

const JourneyDetails = () => {
  const { journeyId } = useParams();
  const {
    state: adminDashboardState,
    teamMembers: { getTeamMembersState, adminTeamMembersSend },
    send: adminDashboardSend,
  } = useContext(AdminDashboardContext);
  const {
    auth: {
      context: { profile },
      journeyCertificationsWithCertificate,
    },
  } = useContext(GlobalContext);

  useEffect(() => {
    journeyId && adminDashboardSend(enterAdminJourneyDetails({ journeyId }));
  }, [adminDashboardSend, journeyId]);

  const [getJourneyState] = useChildMachine(
    adminDashboardState,
    getJourneyMachine.id
  ) as unknown as ReturnType<typeof useMachine<typeof getJourneyMachine>>;

  const journey = useMemo(
    () => adminDashboardState.context.journeyDetails,
    [adminDashboardState.context.journeyDetails]
  );

  const journeyLeaders = useMemo(
    () => adminDashboardState.context.journeyDetailsLeaders,
    [adminDashboardState.context.journeyDetailsLeaders]
  );

  const isJourneyAvailable = useMemo(
    () =>
      !!(profile?.workspace.workspace.availableJourneys || []).find(
        (aj) => aj.journey_id === journeyId
      ),
    [journeyId, profile?.workspace.workspace.availableJourneys]
  );

  const currentJourneyCertificationWithCertificate = useMemo(
    () =>
      (journeyId && journeyCertificationsWithCertificate[journeyId]) || null,
    [journeyCertificationsWithCertificate, journeyId]
  );

  const journeyLoading = useMemo(
    () =>
      getJourneyState
        ? getJourneyState.matches(FetchState.Fetching) &&
          journeyLeaders !== null
        : true,
    [getJourneyState, journeyLeaders]
  );

  const journeyStatus = useJourneyStatus(journey, profile);

  const workspaceId = useMemo(() => profile!.workspace.workspace_id, [profile]);

  useEffect(() => {
    adminTeamMembersSend(
      fetchTeamMembers({
        workspaceId,
        filters: tmDefaultFilteringState,
      })
    );
  }, [adminTeamMembersSend, workspaceId]);

  const teamMembersTotal = useMemo(
    () => getTeamMembersState?.context.data?.profiles.pageInfo.total || 0,
    [getTeamMembersState.context.data?.profiles]
  );

  const teamMembersLoading = useMemo(
    () => getTeamMembersState.matches(FetchState.Fetching),
    [getTeamMembersState]
  );

  const details = useMemo(() => {
    if (journeyLoading) return null;

    return {
      type: getJourneyType(
        journey?.fields.author.fields.name || "",
        profile?.workspace.workspace.name || ""
      ), // to be added in Contentful
      journeyStatus,
      banner: journey?.fields?.bigBannerImage || null,
      tags: journey?.fields?.tags,
      title: journey?.fields.title,
      description: "Description", // to be added in Contentful
      topic: journey?.fields?.theme?.fields?.name || "",
      authorName: journey?.fields.author.fields.name,
    };
  }, [
    journey?.fields.author.fields.name,
    journey?.fields?.bigBannerImage,
    journey?.fields?.tags,
    journey?.fields?.theme?.fields?.name,
    journey?.fields.title,
    journeyLoading,
    journeyStatus,
    profile?.workspace.workspace.name,
  ]);

  const aggregates = useMemo(() => {
    if (journeyLoading) return null;

    return {
      conversations: {
        quantity: journey?.fields.workshops?.length || 0,
        description: "and topics to master",
      },
      hours: {
        quantity: journey ? calculateJourneyDuration(journey) : 0,
        description: "to invest",
      },
      moments: {
        quantity: calculateAhaMomentsCount(journey?.fields?.workshops),
        description: "to experience per participant",
      },
      behaviours: {
        quantity: calculateBehavioursCount(journey?.fields?.workshops),
        description: "to adapt",
      },
    };
  }, [journey, journeyLoading]);

  const progress = useMemo(() => {
    if (journeyLoading || teamMembersLoading) return null;

    const { workspace } = profile?.workspace || {};

    const participantsCount =
      workspace && journeyId
        ? calculateJourneyParticipants(workspace, journeyId)
        : 0;

    return {
      teamMembers: {
        quantity: participantsCount,
        description: `out of ${teamMembersTotal} participated`,
      },
      coverage: {
        quantity: Math.round((participantsCount / teamMembersTotal) * 100),
        description: "from at least 70% required",
      },
      leaders: {
        quantity: journeyLeaders?.length || 0,
        description: "were nominated for the Journey",
      },
      completedConversations: {
        quantity:
          workspace && journeyId
            ? calculateCompletedConversations(workspace, journeyId)
            : 0,
        description: "completed by at least 70% of the team",
      },
    };
  }, [
    journeyLoading,
    teamMembersLoading,
    journeyId,
    profile?.workspace,
    teamMembersTotal,
    journeyLeaders?.length,
  ]);

  return (
    <>
      {journeyLoading && <JourneyDetailsLoading />}
      {journeyId && journey && details && aggregates && progress && (
        <div className={styles.journeyDetailsContainer}>
          <JourneyDetailsBanner
            banner={details.banner || null}
            type={details.type}
            tags={details.tags || []}
            authorName={details.authorName || ""}
            journeyStatus={details.journeyStatus}
          />
          <JourneyDetailsTitle
            title={details.title}
            journeyId={journeyId}
            conversationId={
              journey.fields.workshops
                ? journey.fields.workshops[0]?.sys?.id
                : null
            }
            authorName={details.authorName || ""}
          />
          <JourneyAggregates {...aggregates} />
          <JourneyProgress {...progress} />
          <div className={styles.descriptionText}>
            {journey.fields.shortDescription && (
              <ContentfulRichField content={journey.fields.shortDescription} />
            )}
          </div>
          <JourneyTabs
            longDescription={journey.fields.longDescription}
            topic={details.topic}
            certificates={journey.fields.certificates}
            currentJourneyCertificationWithCertificate={
              currentJourneyCertificationWithCertificate
            }
            companyName={
              profile?.workspace.workspace.name || "Your company name"
            }
            journey={journey}
            journeyLeaders={journeyLeaders}
            isJourneyAvailable={isJourneyAvailable}
          />
          <JourneyModals
            journey={journey}
            journeyLeaders={journeyLeaders}
            isJourneyAvailable={isJourneyAvailable}
          />
        </div>
      )}
    </>
  );
};

export default JourneyDetails;
