// NOTE: This is done because some libraries that we use use ResizeObserver
// internally and it throws an error due to the fact that the action is
// happening before the actual page render
if (typeof ResizeObserver === "function") {
  const _ResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class {
    constructor(cb: ResizeObserverCallback) {
      return new _ResizeObserver((...args) => {
        requestAnimationFrame(() => cb(...args));
      });
    }
  } as any;
}
