import { ChangeEvent, memo } from "react";
import withLoadingDelay, { LoadingDelay } from "../../../hocs/withLoadingDelay";
import cn from "classnames";

import styles from "./Textarea.module.css";

type TextareaProps = {
  className?: string;
  onInput: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  defaultValue?: string;
  disabled?: boolean;
  isLoading: boolean;
  placeholder?: string;
} & LoadingDelay;

const Textarea = (props: TextareaProps) => {
  const {
    className,
    onInput,
    isLoading = false,
    value,
    defaultValue,
    disabled = false,
    placeholder = "",
  } = props;

  return (
    <textarea
      className={cn(className, "text", { [styles.loading]: isLoading })}
      onInput={onInput}
      value={value}
      defaultValue={defaultValue}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};

export default memo(withLoadingDelay(Textarea));
