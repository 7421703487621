import { PropsWithChildren, memo, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import { calculateWorkshopDuration, getEntryId } from "../../../../../utils";
import { CONVERSATIONS } from "../../../../../constants/navigation";

import { calculateBehavioursCount } from "../../../Admin/Journey/utils";

import { Workshop } from "../../../../../types/contentful/workshop/workshop";

import UserImage from "../../../../Shared/UserImage/UserImage";
import Loader from "../../../../Shared/Loader/Loader";
import VideoPlayer from "../../../../Shared/VideoPlayer/VideoPlayer";
import ContentfulRichField from "../../../../Shared/ContentfulRichField/ContentfulRichField";

import styles from "./WorkshopList.module.css";

const WorkshopList = (
  props: PropsWithChildren<{
    workshopsLoading: boolean;
    workshops: Workshop[];
    buttonText: string;
  }>
) => {
  const { workshopsLoading, workshops, buttonText, children } = props;

  const [collapsedIds, setCollapsedIds] = useState<string[]>([]);

  const handleCollapse = useCallback(
    (workshopId: string) => {
      if (collapsedIds.includes(workshopId)) {
        setCollapsedIds(collapsedIds.filter((id) => id !== workshopId));
      } else {
        setCollapsedIds([...collapsedIds, workshopId]);
      }
    },
    [collapsedIds]
  );

  const content = useMemo(() => {
    if (!!workshopsLoading)
      return <Loader className={styles.loaderContainer} />;

    return !workshops?.length ? (
      <div>No conversations found</div>
    ) : (
      workshops.map((workshop) => {
        const workshopId = getEntryId(workshop);
        const isWorkshopCollapsed = collapsedIds.includes(workshopId);

        const ahaMomentsCount = workshop?.fields?.ahaMoments?.length || 0;
        const behavioursCount = calculateBehavioursCount([workshop]);
        const workshopDuration = calculateWorkshopDuration(workshop);
        const aboutVideoUrl = workshop?.fields?.aboutVideo?.fields?.file?.url;

        if (workshopDuration === 0) return null;

        return (
          <div
            className={cn(styles.workshop, isWorkshopCollapsed && "collapsed")}
            key={`workshop-${workshopId}`}
          >
            <div className={styles.workshopContent}>
              <div className={styles.workshopLine}>
                <div className={cn(styles.lineSection, styles.headlineColumn)}>
                  {!isWorkshopCollapsed && (
                    <div className="text bold">{workshop.fields.title}</div>
                  )}
                  {isWorkshopCollapsed && (
                    <h3 className="bold">{workshop.fields.title}</h3>
                  )}
                </div>
                <div className={cn(styles.lineSection, styles.durationColumn)}>
                  <div className="text small">
                    <i className="icon fa fa-clock-o secondary" />{" "}
                    {workshopDuration}min
                  </div>
                </div>
                <div className={cn(styles.lineSection, styles.actionColumn)}>
                  <Link
                    className="btn small primary"
                    to={`/workshops/schedule/${workshopId}`}
                  >
                    {buttonText}
                  </Link>
                </div>
                <div
                  className={cn(
                    styles.lineSection,
                    styles.collapseControlColumn
                  )}
                  onClick={() => handleCollapse(workshopId)}
                >
                  <i className="icon fa fa-chevron-down secondary" />
                </div>
              </div>

              <div
                className={cn(
                  styles.workshopCollapsedData,
                  isWorkshopCollapsed && "visible"
                )}
              >
                <div className={styles.collapseSection}>
                  <div className={styles.authorContainer}>
                    <UserImage
                      loadOnDemand
                      isPublic={true}
                      containerClass={styles.authorImage}
                      profileId={workshop.fields.journey?.fields.author.sys.id}
                      profileAbbreviation={workshop.fields.journey?.fields.author.fields.name
                        .slice(0, 1)
                        .toLocaleUpperCase()}
                      alt="author-profile"
                    />
                    <div className={styles.authorInfo}>
                      <span className="text bold">
                        {workshop.fields.journey?.fields.author.fields.name}
                      </span>
                      <ContentfulRichField
                        content={
                          workshop?.fields?.journey?.fields?.author?.fields?.bio
                        }
                        className="text tiny bold faded"
                      />
                    </div>
                  </div>
                  <div className={styles.scoreContainer}>
                    <div className={styles.scoreSection}>
                      <div className="text small faded">Aha Moments</div>
                      <div className="text small bold">{ahaMomentsCount}</div>
                    </div>
                    <div className={styles.scoreSection}>
                      <div className="text small faded">Behaviours</div>
                      <div className="text small bold">{behavioursCount}</div>
                    </div>
                    <div className={styles.scoreSection}>
                      <div className="text small faded">Time to Invest</div>
                      <div className="text small bold">
                        {workshopDuration} min.
                      </div>
                    </div>
                  </div>

                  {workshop?.fields?.headline && (
                    <ContentfulRichField
                      content={workshop.fields.headline}
                      className="text small bold"
                    />
                  )}

                  {workshop.fields.objectives && (
                    <div className={styles.objectives}>
                      {workshop.fields.objectives.map((o) => {
                        return (
                          <div
                            className={styles.objective}
                            key={o.fields.title}
                          >
                            <i className="icon fa fa-check"></i>
                            <ContentfulRichField
                              content={o.fields.text}
                              className="text small"
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                {aboutVideoUrl && (
                  <div className={cn(styles.collapseSection, "video")}>
                    <div className={styles.videoContainer}>
                      <VideoPlayer
                        description={workshop.fields.title}
                        src={`https:${aboutVideoUrl}`}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })
    );
  }, [buttonText, collapsedIds, handleCollapse, workshops, workshopsLoading]);

  return (
    <div className={styles.container}>
      <h3 className="thin">{CONVERSATIONS}</h3>
      <div className={styles.workshopListBody}>
        <div className={styles.workshopListHeader}>
          <div className={cn(styles.lineSection, styles.headlineColumn)}>
            <span className="text-subtitle palest">Conversation name</span>
          </div>
          <div className={cn(styles.lineSection, styles.durationColumn)}>
            <span className="text-subtitle palest">Est. time</span>
          </div>
          <div className={cn(styles.lineSection, styles.actionColumn)} />
          <div
            className={cn(styles.lineSection, styles.collapseControlColumn)}
          />
        </div>

        <div className={styles.workshopListBody}>{content}</div>
      </div>
      {children}
    </div>
  );
};

export default memo(WorkshopList);
