import { memo, PropsWithChildren, useCallback, useMemo } from "react";

import { SectionItemType } from "../../../../../../../../types/contentful/workshop/summary";
import LoadingButton from "../../../../../../../Shared/Buttons/LoadingButton/LoadingButton";
import SectionCard from "../../../SectionCard/SectionCard";
import ContentfulRichField from "../../../../../../../Shared/ContentfulRichField/ContentfulRichField";

import styles from "./DocumentItem.module.css";
import cn from "classnames";

export type DocumentItemType = Exclude<
  SectionItemType,
  "Video" | "AhaMoments" | "Overview" | "Journey"
>;

const sectionImagePathMapper = {
  [SectionItemType.Link]: "/images/unlock-link-icon.svg",
  [SectionItemType.Conversation]: "/images/unlock-icon.svg",
  [SectionItemType.Downloadable]: "/images/read-icon.svg",
  [SectionItemType.FreeContent]: "/images/read-icon.svg",
};

const buttonTextMapper = {
  [SectionItemType.Link]: "Visit",
  [SectionItemType.Conversation]: "Schedule",
  [SectionItemType.Downloadable]: "Download PDF",
  [SectionItemType.FreeContent]: "",
};

const DocumentItem = ({
  type,
  title,
  link,
  text,
  workshopAuthor,
}: PropsWithChildren<{
  type: DocumentItemType;
  title: string;
  link: string;
  text: string;
  workshopAuthor: {
    name: string;
    imageUrl: string;
    headline: string;
  };
}>) => {
  const sectionIconPath = useMemo(() => sectionImagePathMapper[type], [type]);
  const buttonText = useMemo(() => buttonTextMapper[type], [type]);

  const authorInfoContent = useMemo(
    () => (
      <div>
        <span className="text bold">{workshopAuthor.name}</span>
        <span>{workshopAuthor.headline}</span>
      </div>
    ),
    [workshopAuthor.headline, workshopAuthor.name]
  );

  const isConversation = useMemo(
    () => type === SectionItemType.Conversation,
    [type]
  );
  const isFreeContent = useMemo(
    () => type === SectionItemType.FreeContent,
    [type]
  );

  const buttonHandler = useCallback(() => {
    if (
      type === SectionItemType.Link ||
      type === SectionItemType.Downloadable
    ) {
      window.open(link, "_blank");
    }
    // TODO: We need to handle button click for Conversation type.
    if (type === SectionItemType.Conversation) {
      console.log("handle schedule action");
    }
  }, [link, type]);

  return (
    <SectionCard
      title={title}
      imagePath={sectionIconPath}
      imageAlt={type.toLocaleLowerCase()}
    >
      <div className={cn(styles.cardBody, type.toLocaleLowerCase())}>
        <div className={styles.content}>
          {isConversation && (
            <div className={styles.iconContainer}>
              <div className={styles.innerIconContainer}>
                <i className="fa fa-unlock" />
              </div>
            </div>
          )}
          <div
            className={
              type === SectionItemType.Conversation
                ? styles.conversationContainer
                : ""
            }
          >
            <div className={cn(styles.contentText, "text")}>
              <ContentfulRichField content={text} />
            </div>
            {isConversation && authorInfoContent}
          </div>

          {!isFreeContent && (
            <div className={styles.contentContainer}>
              {!isConversation && <hr className={styles.contentSep} />}
              <div className={styles.contentDocumentFooter}>
                {!isConversation && (
                  <div className={styles.contentDocumentFooterAuthor}>
                    {workshopAuthor.imageUrl && (
                      <img
                        src={workshopAuthor.imageUrl}
                        alt="author-profile"
                        className={styles.imgContainer}
                      />
                    )}
                    {authorInfoContent}
                  </div>
                )}

                <div className={styles.actions}>
                  <LoadingButton
                    iconClass={
                      type === SectionItemType.Conversation
                        ? "fa fa-unlock"
                        : ""
                    }
                    size="small"
                    className={styles.actionButton}
                    onClick={buttonHandler}
                  >
                    {buttonText}
                  </LoadingButton>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </SectionCard>
  );
};

export default memo(DocumentItem);
