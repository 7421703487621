import { PropsWithChildren, memo } from "react";
import Loader from "../../Loader/Loader";
import { ButtonVariant } from "../../../../types/buttons";
import withLoadingDelay, {
  LoadingDelay,
} from "../../../../hocs/withLoadingDelay";

import cn from "classnames";
import Tooltip from "../../Tooltip/Tooltip";

type Button3DProps = PropsWithChildren<{
  className?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: ButtonVariant;
  hasLoader?: boolean;
  type?: "button" | "reset" | "submit";
  tooltip?: {
    message: string;
    opened?: boolean;
    delayDuration?: number;
    withBorder?: boolean;
  } | null;
}> &
  LoadingDelay;

const Button3D = (props: Button3DProps) => {
  const {
    className,
    disabled,
    variant,
    onClick,
    children,
    tooltip,
    type = "button",
    isLoading = false,
    hasLoader = true,
  } = props;

  return (
    <button
      className={cn("btn", "dbl", className, variant || "primary")}
      disabled={disabled || isLoading}
      onClick={onClick}
      type={type}
    >
      {tooltip ? (
        <Tooltip
          description={tooltip.message}
          delayDuration={tooltip.delayDuration || 100}
          opened={tooltip.opened || false}
          containerStyle={{ width: "360px", textAlign: "center" }}
          withBorder={tooltip.withBorder}
        >
          <div className={cn("front", isLoading && "loading")}>
            {children}
            {hasLoader && isLoading && <Loader className="loading-container" />}
          </div>
        </Tooltip>
      ) : (
        <div className={cn("front", isLoading && "loading")}>
          {children}
          {hasLoader && isLoading && <Loader className="loading-container" />}
        </div>
      )}
    </button>
  );
};

export default memo(withLoadingDelay(Button3D));
