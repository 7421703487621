import { fetchMachineFactory } from "../../+xstate/machines/fetch-factory";
import { AppApolloClient } from "../../contexts/Apollo";
import { getWorkspaceTags } from "../queries/workspace-tags";

export const {
  machine: getWorkspaceTagsMachine,
  success: getWorkspaceTagsSuccess,
  failure: getWorkspaceTagsFailure,
  trigger: getWorkspaceTagsTrigger,
} = fetchMachineFactory({
  id: "get-workspace-tags",
  invokeFn: ({
    client,
    workspaceId,
  }: {
    client: AppApolloClient;
    workspaceId: string;
  }) => {
    return getWorkspaceTags(client, { workspaceId });
  },
});
