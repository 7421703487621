export const env = (process.env.REACT_APP_ENV || "development") as
  | "production"
  | "staging"
  | "development"
  | "test";

export const isProductionEnv = env === "production";
export const isStagingEnv = env === "staging";
export const isDevelopmentEnv = env === "development";
export const isTestingEnv = env === "test";

export const authContextLocalStorageName = "auth-ctx";
export const technicalSetupConfig = "technical-setup-config";
export const instanceUUIDStorageKey = `instance-key`;
