import { memo } from "react";
import { add, format, parse } from "date-fns";
import cn from "classnames";

import styles from "./BulkScheduleSlot.module.css";

interface BulkScheduleSlotProps {
  startTime: string;
  removeSlot: () => void;
}

const BulkScheduleSlot = ({ startTime, removeSlot }: BulkScheduleSlotProps) => {
  const parsedStartTime = parse(startTime, "HH:mm", new Date());
  const formattedStartTime = format(parsedStartTime, "hh:mm a");
  const endTime = add(parsedStartTime, { hours: 1, minutes: 30 });
  const formattedEndTime = format(endTime, "hh:mm a");

  return (
    <div className={cn(styles.container, "primary")}>
      {formattedStartTime} - {formattedEndTime}
      <i
        className={cn(styles.closeIcon, "fa-regular", "fa-xmark")}
        onClick={removeSlot}
      />
    </div>
  );
};

export default memo(BulkScheduleSlot);
