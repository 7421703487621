import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { GetInvitationResult } from "../types/results/get-invitation-result";
import { IGetInvitation } from "../types/get-invitation";

const mutation = gql`
  mutation Mutation($email: String!, $grecaptchaTokens: [String]!, ) {
    resendInvitation(email: $email, grecaptchaTokens: $grecaptchaTokens) {
      message
      success
    }
  }
`;

export function getInvitation(client: AppApolloClient, variables: IGetInvitation) {
  return client
    .mutate<GetInvitationResult>({ mutation, variables, fetchPolicy: "no-cache" })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.resendInvitation;
    });
}
