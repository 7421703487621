import { Asset, Metadata, Sys } from "../../common";

export enum OpeningActivityLayoutItemTypes {
  Objectives = "openningLayoutItemObjectives",
  Journey = "openningLayoutItemJourney",
  Timing = "openningLayoutItemTiming",
  Author = "openningLayoutItemAuthor",
  PeopleInvited = "openningLayoutItemPeopleInvited",
}

export interface OpeningActivityFieldsLayoutItemFields {
  type: string;
  title: string;
  icon?: Asset;
}

export interface OpeningActivityFieldsLayout2 {
  title: string;
  numberOfColumns: number;
}

interface OpeningActivityFields {
  title: string;
  authorTitle: string;
  journeyTitle: string;
  objectivesTitle: string;
  objectivesIcon: Asset;
  timeToCompleteTitle: string;
  timeToCompleteIcon: Asset;
  peopleInvitedTitle: string;
  peopleInvitedIcon: Asset;
}

export interface OpeningActivity {
  metadata: Metadata;
  sys: Sys;
  fields: OpeningActivityFields;
}
