import { PropsWithChildren, memo, useCallback, useMemo } from "react";
import cn from "classnames";

import { SlotStatus } from "../../../../../../apollo-graphql/types/enums";
import { ISlotInvitation } from "../../../../../../apollo-graphql/types/slot";

import SkeletonLoader from "../../../../../Shared/SkeletonLoader/SkeletonLoader";
import UserAvatar from "../../../../../Shared/UserAvatar/UserAvatar";
import Tooltip from "../../../../../Shared/Tooltip/Tooltip";
import CopyToClipboardButton from "../../../../../CopyToClipboardButton/CopyToClipboardButton";

import styles from "./WorkshopResultCard.module.css";

const WorkshopResultCard = (
  props: PropsWithChildren<{
    teamName: string;
    currentLevel: number;
    currentActivity: string | null;
    totalLevels: number | null;
    activityPlayersIds: string[];
    status: SlotStatus.ONGOING | SlotStatus.COMPLETED;
    invitations: ISlotInvitation[];
    sessionKey: string;
    group: string;
    levelProgress: number;
    timeValue?: string;
  }>
) => {
  const {
    teamName,
    currentLevel,
    currentActivity,
    totalLevels,
    activityPlayersIds,
    status,
    invitations,
    sessionKey,
    group,
    levelProgress,
    timeValue,
  } = props;

  const joinLink = useMemo(
    () => `/session/instance/${sessionKey}/${group}`,
    [sessionKey, group]
  );

  const activePlayersContent = useMemo(() => {
    return invitations
      .map((i) => ({
        ...i,
        inActive: activityPlayersIds.includes(i.profile.id) ? 0 : 1,
      }))
      .sort((a, b) => a.inActive - b.inActive)
      .map(({ status, profile, slot_id, inActive }) => {
        return (
          <UserAvatar
            key={`${status}-${slot_id}-${profile.id}`}
            profile={profile}
            status={status}
            inActive={!!inActive}
          />
        );
      });
  }, [activityPlayersIds, invitations]);

  const progress = useMemo(
    () => (levelProgress > 100 ? 100 : levelProgress),
    [levelProgress]
  );

  const onClickHandler = useCallback(() => {
    if (status === SlotStatus.COMPLETED || status === SlotStatus.ONGOING) {
      window.open(joinLink, "_blank");
      return;
    }
  }, [joinLink, status]);

  const tooltipActivityMessage = useMemo(
    () => `Activity: ${currentActivity}`,
    [currentActivity]
  );

  return (
    <div
      className={cn(
        styles.workshopResult,
        status === SlotStatus.COMPLETED
          ? styles.workshopCompleted
          : styles.workshopOngoing
      )}
    >
      <div className={styles.header}>
        <div>
          <div className={styles.teamName}>{teamName}</div>
          <button onClick={onClickHandler} className="btn small">
            {status === SlotStatus.COMPLETED ? "Go to Summary" : "Join"}
          </button>
        </div>
        {currentActivity ? (
          <Tooltip
            description={tooltipActivityMessage}
            delayDuration={0}
            containerClass={styles.progressTooltipContainer}
            containerStyle={{
              right: `calc(60% - ${progress * 1.2}%`,
            }}
          >
            <div
              className={styles.progressBar}
              style={
                status !== SlotStatus.COMPLETED
                  ? {
                      background: `linear-gradient(to right, #1eaed0 ${progress}%, #dcdccc ${progress}%)`,
                    }
                  : {}
              }
            ></div>
          </Tooltip>
        ) : (
          <div
            className={styles.progressBar}
            style={
              status !== SlotStatus.COMPLETED
                ? {
                    background: `linear-gradient(to right, #1eaed0 ${progress}%, #dcdccc ${progress}%)`,
                  }
                : {}
            }
          ></div>
        )}
        <div className={styles.progressData}>
          {!timeValue && status !== SlotStatus.COMPLETED ? (
            <SkeletonLoader width={270} height={25} baseColor="#d9d9d5" />
          ) : (
            <>
              <div className={styles.level}>
                Step {currentLevel}
                {totalLevels &&
                  `/${currentLevel > totalLevels ? currentLevel : totalLevels}`}
                {currentActivity && (
                  <Tooltip
                    description={tooltipActivityMessage}
                    delayDuration={0}
                    containerClass={styles.tooltipContainer}
                  >
                    <i
                      className="fa-regular fa-circle-info"
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                )}
              </div>
              <div className={cn(styles.time, "text", "tiny", "bold")}>
                {timeValue}
              </div>
              <div className={styles.status}>
                {status === SlotStatus.COMPLETED ? "Completed" : "Ongoing"}
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.activePlayersText}>
          {!!activityPlayersIds.length
            ? `${activityPlayersIds.length}${
                invitations.length ? `/${invitations.length}` : ""
              } active
          ${activityPlayersIds.length > 1 ? "players" : "player"}`
            : "No active players"}
        </div>
        <div className={styles.activePlayersContent}>
          {activePlayersContent}
        </div>
        <div className={styles.actions}>
          <div className={styles.spacer} />
          <CopyToClipboardButton
            copyText={`${window.location.origin}${joinLink}`}
            displayText={sessionKey}
            className={cn("ghost", "small", styles.copyToClipboardButton)}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(WorkshopResultCard);
