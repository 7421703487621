import {
  FormEvent,
  PropsWithChildren,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

import * as Form from "@radix-ui/react-form";

import { startCase, toLower } from "lodash";

import { Select } from "../../../../../Shared/Select/Select";
import LoadingButton from "../../../../../Shared/Buttons/LoadingButton/LoadingButton";
import Dialog from "../../../../../Shared/Dialog/Dialog";
import AutoComplete, {
  IAutoCompleteItem,
} from "../../../../../Shared/Autocomplete/AutoComplete";

import {
  ProfileWorkspaceAccess,
  ProfileWorkspaceStatus,
} from "../../../../../../apollo-graphql/types/enums";
import { ProfileInvite } from "../../../../../../apollo-graphql/types/profile";
import { mappedErrorMessages } from "../../../../../../constants/mapped-error-messages";
import { InviteProfileErrors } from "../../../../../../types/enums/errors";
import { WorkspaceTag } from "../../../../../../apollo-graphql/types/workspace-tag";

import styles from "./InviteTeamMemberModal.module.css";
import { generateTagsFromAutoCompleteData } from "../../utils";

const InviteTeamMemberModal = (
  props: PropsWithChildren<{
    workspaceTags: WorkspaceTag[];
    inviteTeamMember: (variables: ProfileInvite) => void;
    errorMessage: string | null;
    isLoading: boolean;
    onClose: () => void;
  }>
) => {
  const { inviteTeamMember, errorMessage, isLoading, onClose, workspaceTags } =
    props;

  const [dialogState, setDialogState] = useState<{
    permissions: ProfileWorkspaceAccess;
    status: ProfileWorkspaceStatus;
    newTags: string[];
    tagIds: string[];
    defaultTags: string[];
  }>({
    permissions: ProfileWorkspaceAccess.TEAM_MEMBER,
    status: ProfileWorkspaceStatus.ACTIVE,
    newTags: [],
    tagIds: [],
    defaultTags: [],
  });

  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const [error, setError] = useState<string>("");

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      setError("");
      e.preventDefault();

      const payload = Object.fromEntries(new FormData(e.currentTarget)) as {
        email: string;
        name: string;
        jobTitle: string;
      };

      if (!payload.email || !payload.name || !payload.jobTitle) {
        setError("* Enter required fields.");
        return;
      }

      inviteTeamMember({
        ...payload,
        ...dialogState,
      });

      return () => {
        if (!errorMessage) {
          onClose();
        }
      };
    },
    [dialogState, inviteTeamMember, errorMessage, onClose]
  );

  const tagChangesHandler = useCallback((items: IAutoCompleteItem[]) => {
    const { newTags, tagIds, tags } = generateTagsFromAutoCompleteData(items);

    setDialogState((prev) => ({
      ...prev,
      defaultTags: tags,
      newTags,
      tagIds,
    }));
  }, []);

  const allTags = useMemo(() => {
    return workspaceTags.map((t) => ({
      id: t.id,
      text: t.text,
    }));
  }, [workspaceTags]);

  const dialogActionsContent = useMemo(() => {
    return (
      <>
        <LoadingButton
          onClick={() => submitButtonRef.current?.click()}
          isLoading={isLoading}
          className={styles.inviteBtn}
          type="submit"
        >
          Send Invite
        </LoadingButton>
      </>
    );
  }, [isLoading]);

  const dialogContent = useMemo(() => {
    return (
      <Form.Root className="form-container" onSubmit={handleSubmit}>
        <Form.Field name="email" className="form-row">
          <Form.Label className="label">
            Email <span className="requiredAsterisk">*</span>
          </Form.Label>
          <Form.Control
            className="FormControl"
            type="email"
            defaultValue=""
            placeholder="name@company.com"
          />
        </Form.Field>
        <Form.Field name="name" className="form-row">
          <Form.Label className="label">
            Name <span className="requiredAsterisk">*</span>
          </Form.Label>
          <Form.Control
            className="FormControl"
            type="text"
            defaultValue=""
            placeholder="ex. John Smith"
          />
        </Form.Field>
        <Form.Field name="jobTitle" className="form-row">
          <Form.Label className="label">
            Job Title <span className="requiredAsterisk">*</span>
          </Form.Label>
          <Form.Control
            className="FormControl"
            type="text"
            defaultValue=""
            placeholder="Engineer, Human Resources etc."
          />
        </Form.Field>
        <Form.Field name="permissions" className="form-row">
          <Form.Label className="label">
            Access Permissions <span className="requiredAsterisk">*</span>
          </Form.Label>

          <Select
            label="Select permissions"
            value={dialogState.permissions}
            options={[...(Object.values(ProfileWorkspaceAccess) || [])].map(
              (access) => ({
                label: startCase(toLower(access)),
                value: access,
                key: `${access}-access`,
              })
            )}
            className={styles.selectInput}
            onChange={(permissions) => {
              setDialogState((prev) => ({
                ...prev,
                permissions: permissions as ProfileWorkspaceAccess,
              }));
            }}
          />
        </Form.Field>

        <Form.Field name="status" className="form-row">
          <Form.Label className="label">
            Profile Status <span className="requiredAsterisk">*</span>
          </Form.Label>
          <Select
            label="Select status"
            value={dialogState.status}
            options={[...(Object.values(ProfileWorkspaceStatus) || [])].map(
              (status) => ({
                label: startCase(toLower(status)),
                value: status,
                key: `${status}-status`,
              })
            )}
            className={styles.selectInput}
            onChange={(status) => {
              setDialogState((prev) => ({
                ...prev,
                status: status as ProfileWorkspaceStatus,
              }));
            }}
          />
        </Form.Field>

        <AutoComplete
          label="Add tags"
          placeholder="Select a tag or create a new one"
          items={allTags}
          defaultValues={dialogState.defaultTags}
          onChanges={tagChangesHandler}
        />

        <div className="submit-hidden">
          <button ref={submitButtonRef} type="submit" />
        </div>

        {(error || errorMessage) && (
          <div className={styles.errorContainer}>
            {error || mappedErrorMessages[errorMessage as InviteProfileErrors]}
          </div>
        )}
      </Form.Root>
    );
  }, [
    handleSubmit,
    dialogState.permissions,
    dialogState.status,
    dialogState.defaultTags,
    allTags,
    tagChangesHandler,
    error,
    errorMessage,
  ]);

  return (
    <Dialog
      open
      onClose={onClose}
      heading="Invite people to AhaPlay"
      actionsContent={dialogActionsContent}
    >
      <div className={styles.content}>{dialogContent}</div>
    </Dialog>
  );
};

export default memo(InviteTeamMemberModal);
