import { PropsWithChildren, memo, useMemo, useRef } from "react";

import { formatSlotScheduleDate } from "../../../utils/format-schedule-date";
import { getTitle } from "../../../utils";

import { InvitationStatus } from "../../../types/enums/invitation-status";
import { Slot } from "../../../apollo-graphql/types/slot";

import Dialog from "../../Shared/Dialog/Dialog";
import LoadingButton from "../../Shared/Buttons/LoadingButton/LoadingButton";
import Loader from "../../Shared/Loader/Loader";

import styles from "./RescheduleDialog.module.css";

export default memo(function RescheduleDialog(
  props: PropsWithChildren<{
    profileId: string;
    slotIdForReschedule: string | null;
    scheduledSlots: Slot[] | null;
    isRescheduleDialogLoadingSlots: boolean;
    isRescheduleDialogRescheduling: boolean;
    rescheduleHandler: (invitationId: string, slot: Slot) => void;
    closeDialogHandler: () => void;
  }>
) {
  const {
    profileId,
    slotIdForReschedule,
    scheduledSlots,
    isRescheduleDialogLoadingSlots,
    isRescheduleDialogRescheduling,
    rescheduleHandler,
    closeDialogHandler,
  } = props;

  const selectedSlotId = useRef<string | null>(null);

  const invitationId = scheduledSlots
    ?.find((s) => s.id === slotIdForReschedule)
    ?.invitations.find(
      (i) =>
        i.profile.id === profileId &&
        i.status !== InvitationStatus.AUTO_GENERATED
    )?.id;

  const availableSlots = scheduledSlots?.filter(
    (s) => new Date((s.schedule_date as unknown as number) * 1000) > new Date()
  );

  const dialogContent = useMemo(() => {
    if (isRescheduleDialogLoadingSlots) {
      return (
        <div className={styles.loadingContainer}>
          <Loader />
        </div>
      );
    }
    // TODO: We need design for this message
    if (!availableSlots || availableSlots.length === 0) {
      return <div>No other slots available</div>;
    }

    return (
      <ul className={styles.content}>
        {availableSlots.map((s) => {
          const invitation = s.invitations.find(
            (i) =>
              i.profile.id === profileId &&
              i.status !== InvitationStatus.AUTO_GENERATED
          )!;

          return (
            <li key={s.id} className={styles.slotItem}>
              <p>Conversation: {getTitle(s.workshop)}</p>
              <div className={styles.actions}>
                <p>Date: {formatSlotScheduleDate(s.schedule_date)}</p>
                <LoadingButton
                  isLoading={
                    isRescheduleDialogRescheduling &&
                    selectedSlotId.current === s.id
                  }
                  disabled={!!invitation || !invitationId}
                  onClick={() => {
                    rescheduleHandler(invitationId!, s);
                    selectedSlotId.current = s.id;
                  }}
                  variant="secondary"
                  iconClass="fa fa-check-double"
                >
                  Select
                </LoadingButton>
              </div>
            </li>
          );
        })}
      </ul>
    );
  }, [
    isRescheduleDialogLoadingSlots,
    availableSlots,
    isRescheduleDialogRescheduling,
    invitationId,
    profileId,
    rescheduleHandler,
  ]);

  return (
    <Dialog open heading="Reschedule" onClose={closeDialogHandler}>
      {dialogContent}
    </Dialog>
  );
});
