import { PropsWithChildren, memo, useCallback, useMemo } from "react";
import { Helmet } from "react-helmet";
import cn from "classnames";

import useActivityTimeout from "../../../hooks/useActivityTimeout";

import { getEntryId, getTitle, getType } from "../../../utils";

import { IActivityResult } from "../../../apollo-graphql/types/session-state";
import { WorkshopActivityType } from "../../../types/enums/activity-type";
import { ActivityCommon } from "../../../types/activity-common";
import { WorkshopState } from "../../../+xstate/machines/session/workshop";
import { Profile } from "../../../apollo-graphql/types/profile";
import { ActivityType } from "../../../types/contentful/workshop/activity-type";
import { RemoteParticipantData } from "../../../types/jitsi";

import Timer from "../../Shared/Timer/Timer";
import Presentation from "../Activities/Presentation/Presentation";
import SelfDirectedDiscussion from "../Activities/SelfDirectedDiscussion/SelfDirectedDiscussion";
import Conceptualisation from "../Activities/Conceptualisation/Conceptualisation";
import Brainstorming from "../Activities/Brainstorming/Brainstorming";
import Translation from "../Activities/Translation/Translation";
import Filtering from "../Activities/Filtering/Filtering";
import Comparison from "../Activities/Comparison/Comparison";
import Question from "../Activities/Question/Question";
import Grouping from "../Activities/Grouping/Grouping";
import OpenQuestion from "../Activities/Question/OpenQuestion";
import Rating from "../Activities/Rating/Rating";

import styles from "./Activity.module.css";

export default memo(function WorkshopActivity(
  props: PropsWithChildren<{
    activity: ActivityType;
    nextActivity?: ActivityType | null;
    profile: Profile;
    isConnectionWeak: boolean;
    isParticipating: boolean;
    currentActivityId: string;
    isReady: boolean;
    transition: number;
    activityResult: IActivityResult[];
    currentActivityResults: IActivityResult["value"];
    activityResultForCurrentProfile: IActivityResult["value"]["0"] | null;
    notReadyProfilesCount: number;
    currentActiveParticipants: string[];
    currentActiveParticipantCount: number;
    workshopActivities: ActivityType[];
    workshopState?: WorkshopState;
    isViewResults?: boolean;
    hasAhaMoments: boolean;
    remoteParticipantsData?: RemoteParticipantData[];
    setActivityValueHandler: (args: {
      activityId: string;
      value: string;
      markAsReady?: boolean;
    }) => void;
    setActivityReadyHandler: (args: { activityId: string }) => void;
  }>
) {
  const {
    activity,
    nextActivity,
    profile,
    isReady,
    transition,
    isConnectionWeak,
    isParticipating,
    // currentActivityId,
    notReadyProfilesCount,
    activityResult,
    currentActivityResults,
    activityResultForCurrentProfile,
    currentActiveParticipants,
    currentActiveParticipantCount,
    workshopActivities,
    hasAhaMoments,
    remoteParticipantsData,
    setActivityValueHandler,
    setActivityReadyHandler,
    workshopState,
    isViewResults = false,
  } = props;

  const { isActivityTimeout, clockInstance } = useActivityTimeout();

  const profileId = useMemo(() => profile.id, [profile]);
  const profileName = useMemo(() => profile.name, [profile]);
  const activityData = useMemo(() => {
    return {
      type: getType(activity),
      title: getTitle(activity),
    };
  }, [activity]);

  const isLoading = useMemo(
    () =>
      activityData.type === WorkshopActivityType.PresentationActivity
        ? workshopState === WorkshopState.SettingReady
        : workshopState === WorkshopState.SettingValue,
    [activityData?.type, workshopState]
  );

  const getActivityResult = useCallback(
    (activityId: string) =>
      activityResult.find((a) => a.key === activityId)?.value || [],
    [activityResult]
  );

  const getReferenceActivityResult = useCallback(
    (activityType: WorkshopActivityType, activityId: string) => {
      const currentActivityIndex = workshopActivities.findIndex(
        (r) => getEntryId(r) === activityId
      );
      if (currentActivityIndex === -1) return null;
      let result: IActivityResult["value"] | null = null;

      for (let index = currentActivityIndex; index >= 0; index--) {
        const element = workshopActivities[index];
        const type = getType(element);

        if (type === activityType) {
          result =
            activityResult.find((r) => r.key === getEntryId(element))?.value ||
            null;
          break;
        }
      }

      return result;
    },
    [activityResult, workshopActivities]
  );

  const activityNode = useMemo(() => {
    const commonProps: ActivityCommon = {
      transition,
      isLoading,
      isConnectionWeak,
      isReady,
      currentActiveParticipantCount,
      notReadyProfilesCount,
      isActivityTimeout,
      isParticipating,
      activity,
      hasAhaMoments,
      ...(nextActivity && { nextActivity }),
      setActivityReadyHandler,
    };

    if (activityData.type === WorkshopActivityType.PresentationActivity) {
      return (
        <Presentation
          {...commonProps}
          isViewResults={isViewResults}
          clockInstance={clockInstance}
        />
      );
    }

    if (activityData.type === WorkshopActivityType.ConceptualisationActivity) {
      return (
        <Conceptualisation
          {...commonProps}
          profile={profile}
          isViewResults={isViewResults}
          activityResults={currentActivityResults}
          setActivityValueHandler={setActivityValueHandler}
        />
      );
    }

    if (activityData.type === WorkshopActivityType.ClosedQuestionActivity)
      return (
        <Question
          {...commonProps}
          profileId={profileId}
          profileName={profileName}
          currentActiveParticipants={currentActiveParticipants}
          activityResults={currentActivityResults}
          activityResultForCurrentProfile={activityResultForCurrentProfile}
          setActivityValueHandler={setActivityValueHandler}
          isViewResults={isViewResults}
          allActivitiesResult={activityResult}
        />
      );

    if (activityData.type === WorkshopActivityType.RatingActivity) {
      return (
        <Rating
          {...commonProps}
          profileId={profileId}
          currentActiveParticipants={currentActiveParticipants}
          activityResults={currentActivityResults}
          activityResultForCurrentProfile={activityResultForCurrentProfile}
          setActivityValueHandler={setActivityValueHandler}
          isViewResults={isViewResults}
          allActivitiesResult={activityResult}
        />
      );
    }

    if (activityData.type === WorkshopActivityType.OpenQuestion)
      return (
        <OpenQuestion
          {...commonProps}
          profile={profile}
          currentActiveParticipants={currentActiveParticipants}
          activityResults={currentActivityResults}
          activityResultForCurrentProfile={activityResultForCurrentProfile}
          setActivityValueHandler={setActivityValueHandler}
          isViewResults={isViewResults}
          allActivitiesResult={activityResult}
        />
      );

    if (activityData.type === WorkshopActivityType.BrainstormingActivity) {
      return (
        <Brainstorming
          {...commonProps}
          profileId={profileId}
          isViewResults={isViewResults}
          activityResults={currentActivityResults}
          activityResultForCurrentProfile={activityResultForCurrentProfile}
          setActivityValueHandler={setActivityValueHandler}
        />
      );
    }

    if (activityData.type === WorkshopActivityType.TranslationActivity) {
      return (
        <Translation
          {...commonProps}
          profile={profile}
          setActivityValueHandler={setActivityValueHandler}
          activityResults={currentActivityResults}
          isViewResults={isViewResults}
        />
      );
    }

    if (activityData.type === WorkshopActivityType.FilteringActivity)
      return (
        <Filtering
          {...commonProps}
          profileId={profileId}
          isViewResults={isViewResults}
          getActivityResult={getActivityResult}
          activityResults={currentActivityResults}
          setActivityValueHandler={setActivityValueHandler}
        />
      );

    if (activityData.type === WorkshopActivityType.ComparisonActivity)
      return (
        <Comparison
          {...commonProps}
          currentActiveParticipants={currentActiveParticipants}
          profileId={profileId}
          isViewResults={isViewResults}
          activityResults={currentActivityResults}
          activityResultForCurrentProfile={activityResultForCurrentProfile}
          getReferenceActivityResult={getReferenceActivityResult}
          setActivityValueHandler={setActivityValueHandler}
        />
      );

    if (activityData.type === WorkshopActivityType.GroupingActivity)
      return (
        <Grouping
          {...commonProps}
          currentActiveParticipants={currentActiveParticipants}
          profileId={profileId}
          isViewResults={isViewResults}
          activityResults={currentActivityResults}
          activityResultForCurrentProfile={activityResultForCurrentProfile}
          getReferenceActivityResult={getReferenceActivityResult}
          setActivityValueHandler={setActivityValueHandler}
        />
      );

    if (activityData.type === WorkshopActivityType.VotingActivity)
      return (
        <Question
          {...commonProps}
          currentActiveParticipants={currentActiveParticipants}
          profileId={profileId}
          profileName={profileName}
          isViewResults={isViewResults}
          activityResults={currentActivityResults}
          activityResultForCurrentProfile={activityResultForCurrentProfile}
          setActivityValueHandler={setActivityValueHandler}
          allActivitiesResult={activityResult}
        />
      );

    if (
      activityData.type ===
        WorkshopActivityType.SelfDirectedDiscussionActivity &&
      remoteParticipantsData
    )
      return (
        <SelfDirectedDiscussion
          profile={profile}
          activityResultForCurrentProfile={activityResultForCurrentProfile}
          setActivityValueHandler={setActivityValueHandler}
          allActivitiesResult={activityResult}
          remoteParticipantsData={remoteParticipantsData}
          {...commonProps}
        />
      );

    return <>Activity of type "{activityData.type}" is not implemented!</>;
  }, [
    transition,
    isLoading,
    isConnectionWeak,
    isReady,
    currentActiveParticipantCount,
    notReadyProfilesCount,
    isActivityTimeout,
    isParticipating,
    activity,
    nextActivity,
    setActivityReadyHandler,
    profileId,
    isViewResults,
    getActivityResult,
    currentActivityResults,
    setActivityValueHandler,
    currentActiveParticipants,
    activityResultForCurrentProfile,
    getReferenceActivityResult,
    clockInstance,
    profile,
    activityResult,
    hasAhaMoments,
    profileName,
    activityData,
    remoteParticipantsData,
  ]);

  return (
    <>
      <Helmet>
        <meta name="description" content={activityData.title} />
      </Helmet>
      {!isViewResults && <Timer title={activityData.title} />}
      <div
        className={cn(
          styles.activityContainer,
          !isParticipating && !isViewResults && "observer-mode"
        )}
      >
        {activityNode}
      </div>
    </>
  );
});
