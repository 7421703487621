import {
  AdminDashboardScheduleState,
  adminDashboardScheduleDialogMachine,
} from "../+xstate/machines/dashboard/admin-dashboard-schedule-dialog";
import { useMachine } from "@xstate/react";

export const getScheduleMachineDialogState = (
  state: ReturnType<
    typeof useMachine<typeof adminDashboardScheduleDialogMachine>
  >["0"]
): AdminDashboardScheduleState => {
  switch (true) {
    case state.matches(AdminDashboardScheduleState.Start):
      return AdminDashboardScheduleState.Start;
    case state.matches(AdminDashboardScheduleState.LoadScheduleData):
      return AdminDashboardScheduleState.LoadScheduleData;
    case state.matches(AdminDashboardScheduleState.ScheduleReady):
      return AdminDashboardScheduleState.ScheduleReady;
    case state.matches(AdminDashboardScheduleState.ScheduleReadyBulk):
      return AdminDashboardScheduleState.ScheduleReadyBulk;
    case state.matches(AdminDashboardScheduleState.Done):
      return AdminDashboardScheduleState.Done;
    case state.matches(AdminDashboardScheduleState.Creating):
      return AdminDashboardScheduleState.Creating;
    case state.matches(AdminDashboardScheduleState.CreatingBulk):
      return AdminDashboardScheduleState.CreatingBulk;
    default:
      return AdminDashboardScheduleState.Idle;
  }
};
