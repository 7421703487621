import { memo, PropsWithChildren } from "react";

import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";

import styles from "./SkipPresentationModal.module.css";
import cn from "classnames";

const SkipPresentationModal = (
  props: PropsWithChildren<{
    onCancel: () => void;
    onSubmit: () => void;
  }>
) => {
  const { onSubmit, onCancel } = props;
  return (
    <Dialog.Root open={true}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title className={cn(styles.dialogTitle, "DialogTitle")}>
            Skip step?
          </Dialog.Title>
          <Dialog.Description className={cn(styles.dialogContent, "text")}>
            <span>
              A solid understanding of the content presented in this step is
              necessary for successfully completing this conversation.{" "}
            </span>
            <span>Are you willing to skip it?</span>
          </Dialog.Description>
          <div className={styles.dialogFooter}>
            <button className={cn("btn", "ghost")} onClick={onCancel}>
              Cancel
            </button>
            <button className={cn("btn", "destructive")} onClick={onSubmit}>
              Skip step
            </button>
          </div>

          <Dialog.Close asChild>
            <button
              onClick={onCancel}
              className={cn(styles.dialogCloseButton, "IconButton")}
              aria-label="Close"
            >
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default memo(SkipPresentationModal);
