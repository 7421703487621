import {
  memo,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import cn from "classnames";
import Dialog from "../../../../Shared/Dialog/Dialog";
import { Select } from "../../../../Shared/Select/Select";
import { startCase, toLower } from "lodash";
import {
  ProfileWorkspaceAccess,
  ProfileWorkspaceStatus,
} from "../../../../../apollo-graphql/types/enums";
import LoadingButton from "../../../../Shared/Buttons/LoadingButton/LoadingButton";
import * as Form from "@radix-ui/react-form";
import styles from "./BulkInviteDialog.module.css";
import IconButton from "../../../../Shared/Buttons/IconButton/IconButton";
import ConfirmationDialog from "../../../../ConfirmationDialog/ConfirmationDialog";
import { parseXLSXInvites } from "../../../../../utils/xlsx-utils";
import { BulkInviteFormData } from "../../../../../types/bulk-invite-from-data";

type BunkInviteProps = PropsWithChildren<{
  invites: Awaited<ReturnType<typeof parseXLSXInvites>> | null;
  isLoading: boolean;
  errorMessage: string | null;
  bulkInviteImportCount: number | null | undefined;
  onClose: () => void;
  onBulkInvite: (data: { formData: BulkInviteFormData }) => void;
  onBulkInviteConfirm: (outcome: boolean | null) => void;
}>;

const BulkInviteDialog = (props: BunkInviteProps) => {
  const {
    invites,
    isLoading,
    errorMessage,
    bulkInviteImportCount,
    onClose,
    onBulkInvite,
    onBulkInviteConfirm,
  } = props;

  const fileInputRef = useRef<null | HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);

  const [formData, setFormData] = useState<BulkInviteFormData>({
    file: null,
    fileName: "",
    emailKey: "",
    nameKey: "",
    jobTitleKey: "",
  });

  const submitHandler: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      const newFormData = Object.fromEntries(
        new FormData(e.currentTarget)
      ) as BulkInviteFormData;
      setFormData((formData) => {
        const updatedFormData = {
          ...formData,
          ...newFormData,
          file: newFormData.file?.name ? newFormData.file! : formData.file,
        };
        onBulkInvite({ formData: updatedFormData });
        return updatedFormData;
      });
    },
    [onBulkInvite]
  );

  const permissionsSelectOptions = useMemo(() => {
    return [...(Object.values(ProfileWorkspaceAccess) || [])].map((access) => ({
      label: startCase(toLower(access)),
      value: access,
      key: `${access}-access`,
    }));
  }, []);

  const profileStatusSelectOptions = useMemo(
    () =>
      [...(Object.values(ProfileWorkspaceStatus) || [])].map((status) => ({
        label: startCase(toLower(status)),
        value: status,
        key: `${status}-status`,
      })),
    []
  );

  const onFileChangeHandler: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((e) => {
      const file = e.target?.files?.[0];
      setFormData((value) => ({
        ...value,
        file: file || null,
        fileName: file?.name || "",
      }));
    }, []);

  const onFileClearHandler: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      setFormData((value) => ({ ...value, file: null, fileName: "" }));
      fileInputRef.current!.value = "";
    }, []);

  const fileContent = useMemo(
    () =>
      !formData?.file ? (
        <IconButton
          className="flex"
          iconName="fa-upload"
          onClick={() => fileInputRef.current?.click()}
        >
          Select .xlsx file
        </IconButton>
      ) : (
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <div style={{ flex: 1 }}>File: {formData.file.name}</div>
          <button type="button" className="btn" onClick={onFileClearHandler}>
            Remove
          </button>
        </div>
      ),
    [formData?.file, onFileClearHandler]
  );

  const actionsContent = useMemo(() => {
    return (
      <div className={styles.saveBtnContainer}>
        <LoadingButton
          onClick={() => submitButtonRef?.current?.click()}
          isLoading={isLoading}
          className={cn("btn", styles.saveBtn)}
          type="submit"
        >
          Upload
        </LoadingButton>
      </div>
    );
  }, [isLoading]);

  return bulkInviteImportCount && !errorMessage ? (
    <ConfirmationDialog
      title="Bulk import success"
      description={`You've successfully uploaded ${bulkInviteImportCount} users.`}
      continueButtonText="Continue"
      confirmationHandler={onClose}
    ></ConfirmationDialog>
  ) : invites && !errorMessage ? (
    <ConfirmationDialog
      title="Bulk import confirmation"
      continueButtonText="Continue"
      confirmationHandler={onBulkInviteConfirm}
      showCloseButton={true}
      cancelButtonText="Go back"
      isLoading={isLoading}
      className={styles.bulkConfirmationDialog}
    >
      <h3>This items will be imported to your workspace:</h3>
      <div style={{ overflowX: "auto" }}>
        <table style={{ whiteSpace: "nowrap" }}>
          <thead>
            <tr>
              <th>Email</th>
              <th>Name</th>
              <th>Job title</th>
              <th>Access</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {invites.map((invite) => (
              <tr key={invite.email}>
                <td>{invite.email}</td>
                <td>{invite.name}</td>
                <td>{invite.jobTitle}</td>
                <td>{invite.access}</td>
                <td>{invite.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </ConfirmationDialog>
  ) : (
    <Dialog
      open
      heading="Bulk invite"
      onClose={onClose}
      actionsContent={actionsContent}
    >
      <div className={styles.content}>
        <Form.Root className="form-container" onSubmit={submitHandler}>
          <input
            type="file"
            accept=".xlsx"
            ref={fileInputRef}
            onChange={onFileChangeHandler}
            className="FormControl"
            style={{ display: "none" }}
            name="file"
          />
          <Form.Field name="fileName" className="form-row">
            {fileContent}
            <Form.Control
              type="text"
              className="FormControl"
              required
              name="fileName"
              value={formData.fileName}
              style={{ display: "none" }}
            />
            {!formData.file && (
              <Form.Message
                className="form-validation-message"
                name="fileName"
                match="valueMissing"
              >
                File is required
              </Form.Message>
            )}
          </Form.Field>
          <Form.Field name="emailKey" className="form-row">
            <Form.Label className="label">Email key*</Form.Label>
            <Form.Control
              type="text"
              className="FormControl"
              required
              defaultValue={formData.emailKey}
            />
            <Form.Message
              className="form-validation-message"
              match="valueMissing"
              name="emailKey"
            >
              Email key is required
            </Form.Message>
          </Form.Field>
          <Form.Field name="nameKey" className="form-row">
            <Form.Label className="label">
              Name key(s)* (, separated)
            </Form.Label>
            <Form.Control
              type="text"
              className="FormControl"
              required
              defaultValue={formData.nameKey}
            />
            <Form.Message
              className="form-validation-message"
              match="valueMissing"
              name="nameKey"
            >
              Name key(s) is required
            </Form.Message>
          </Form.Field>
          <Form.Field name="jobTitleKey" className="form-row">
            <Form.Label className="label">Job title</Form.Label>
            <Form.Control
              type="text"
              className="FormControl"
              defaultValue={formData.jobTitleKey}
            />
          </Form.Field>
          <Form.Field name="accessKey" className="form-row">
            <Form.Label className="label">Access permission key</Form.Label>
            <Form.Control
              type="text"
              className="FormControl"
              defaultValue={formData.accessKey}
            />
          </Form.Field>
          <Form.Field name="statusKey" className="form-row">
            <Form.Label className="label">Profile status key</Form.Label>
            <Form.Control
              type="text"
              className="FormControl"
              defaultValue={formData.statusKey}
            />
          </Form.Field>
          <Form.Field name="defaultAccess" className="form-row">
            <Form.Label className="label">Access permission</Form.Label>
            <Select
              defaultValue={
                formData.defaultAccess || ProfileWorkspaceAccess.TEAM_MEMBER
              }
              label="Select access"
              options={permissionsSelectOptions}
              className={styles.selectInput}
            />
          </Form.Field>
          <Form.Field name="defaultStatus" className="form-row">
            <Form.Label className="label">Profile Status</Form.Label>
            <Select
              label="Select status"
              defaultValue={
                formData.defaultStatus || ProfileWorkspaceStatus.ACTIVE
              }
              options={profileStatusSelectOptions}
              className={styles.selectInput}
            />
          </Form.Field>

          <div className="submit-hidden">
            <button ref={submitButtonRef} type="submit" />
          </div>

          {errorMessage && (
            <div className={styles.errorContainer}>{errorMessage}</div>
          )}
        </Form.Root>
      </div>
    </Dialog>
  );
};

export default memo(BulkInviteDialog);
