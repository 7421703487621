import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { ICreateBulkSlots, ICreateSlot, IEditSlot } from "../types/slot";
import {
  CreateBulkSlotsResult,
  CreateSlotResult,
} from "../types/results/create-slot.results";
import { EditSlotResult } from "../types/results/edit-slot.results";

const createSlotMutation = gql`
  mutation Mutation(
    $type: SlotType!
    $scheduleDate: Date
    $workshopId: String!
    $workspaceId: String!
    $participantEmails: [String]
    $timezoneOffsetInMinutes: Int!
  ) {
    createSlot(
      type: $type
      schedule_date: $scheduleDate
      workshop_id: $workshopId
      workspace_id: $workspaceId
      participant_emails: $participantEmails
      timezoneOffsetInMinutes: $timezoneOffsetInMinutes
    ) {
      id
      creator_id
      ics
      ics_uid
      key
      reminder_status
      schedule_date
      status
      type
      workshop {
        fields {
          title
        }
      }
      workshop_id
      workspace_id
    }
  }
`;

export function createSlot(client: AppApolloClient, variables: ICreateSlot) {
  return client
    .mutate<CreateSlotResult>({
      mutation: createSlotMutation,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.createSlot;
    });
}

const createBulkSlotsMutation = gql`
  mutation Mutation(
    $type: SlotType!
    $workshopId: String!
    $workspaceId: String!
    $startDate: Date!
    $endDate: Date!
    $scheduledDays: ScheduledDay!
    $timezoneOffsetInMinutes: Int!
  ) {
    createBulkSlots(
      type: $type
      workshop_id: $workshopId
      workspace_id: $workspaceId
      startDate: $startDate
      endDate: $endDate
      scheduledDays: $scheduledDays
      timezoneOffsetInMinutes: $timezoneOffsetInMinutes
    ) {
      id
      type
      workshop {
        fields {
          title
        }
      }
    }
  }
`;
export function createBulkSlots(
  client: AppApolloClient,
  variables: ICreateBulkSlots
) {
  return client
    .mutate<CreateBulkSlotsResult>({
      mutation: createBulkSlotsMutation,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.createBulkSlots;
    });
}

const editSlotMutation = gql`
  mutation Mutation(
    $id: String!
    $type: SlotType
    $key: String
    $schedule_date: Date
    $workshop_id: String
    $workspace_id: String
    $ics: String
    $ics_uid: String
    $status: SlotStatus
    $reminder_status: SlotReminderStatus
  ) {
    editSlot(
      id: $id
      type: $type
      key: $key
      schedule_date: $schedule_date
      workshop_id: $workshop_id
      workspace_id: $workspace_id
      ics: $ics
      ics_uid: $ics_uid
      status: $status
      reminder_status: $reminder_status
    ) {
      id
    }
  }
`;

export function editSlot(client: AppApolloClient, variables: IEditSlot) {
  return client
    .mutate<EditSlotResult>({
      mutation: editSlotMutation,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.editSlot;
    });
}
