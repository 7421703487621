export const SESSION_CONTEXT_RESULT_QUERY = `{
  activityResult {
    key
    value {
      profileId
      ready
      value
      disconnectTimestamp
      workshopTimeout
      activityTimeout
    }
  }
  reconnectTimeouts {
    profileId
  }
  currentActiveProfiles {
    profileId
    uuid
  }
  observers
  startTimestamp
  lastUpdatedTimestamp
  lastActivityTimestamp
  readyActiveProfiles
  minimumWorkshopParticipants
  maximumWorkshopParticipants
}
value
sessionKey
sessionId`;

const META_DATA_QUERY = `{
  tags {
    sys {
      id
    }
  }
}`;

const WORKSHOP_SYS_QUERY = `{
  id
  type
  createdAt
  updatedAt
  revision
  locale
  space {
    sys {
      id
      type
      linkType
    }
  }
  environment {
    sys {
      id
      type
      linkType
    }
  }
  contentType {
    sys {
      id
      type
      linkType
    }
  }
}`;

const BASE_WORKSHOP_JOURNEY_FIELDS = `
  title
  author {
    sys {
      id
    }
    fields {
      name
      email
      headline
      image {
        fields {
          title
          description
          file {
            url
            fileName
          }
        }
      }
      bio
    }
  }
  smallBannerImage {
    fields {
      title
      description
      file {
        url
        fileName
      }
    }
  }
  bigBannerImage {
    fields {
      title
      description
      file {
        url
        fileName
      }
    }
  }
  theme {
    sys {
      id
    }
    fields {
      name
      icon {
        fields {
          title
          description
          file {
            url
            fileName
          }
        }
      }
    }
  }
  shortDescription
  longDescription
  certificates {
    sys {
     id
    }
    fields {
      name
      type
      description
      image {
        fields {
          title
          description
          file {
            url
            fileName
          }
        }
      }
    }
  }
  tags {
    fields {
      name
    }
  }
`;

export const JOURNEY_PARTICIPATION_DATA = `
  {
    journeyResults {
      journey {
        sys {
          id
        }
        fields {
          title
        }
      }
      journeyLeaders {
        id
      }
      workshopCount
      workshopParticipation {
        profiles {
          id
        }
        workshop_id
      }
      workshops {
        sys {
          id
        }
        fields {
          title
        }
      }
    }
    workspaceProfilesCount
  }
`;

export const WORKSHOP_JOURNEY_QUERY = `{
  sys {
    id
  }
  fields {
    ${BASE_WORKSHOP_JOURNEY_FIELDS}
  }
}`;

const WORKSHOP_SUMMARY_QUERY = `{
  sys {
    id
  }
  fields {
    title
    sections {
      sys {
        id
      }
      fields {
        title
        type
        items {
          sys {
            id
          }
          fields {
            link
            text
            title
            type
          }
        }
      }
    }
  }
}`;

const WORKSHOP_OBJECTIVES_QUERY = `{
  sys {
    id
  }
  fields {
    title
    text
  }
}`;

const WORKSHOP_AHA_MOMENTS_QUERY = `{
  sys {
    id
  }
  fields {
    title
    text
    behaviours {
     sys {
      id
     }
     fields {
      text
     }
    }
  }
}`;

const WORKSHOP_ICON_QUERY = `{
  sys {
    id
  }
  fields {
    title
    description
    file {
      url
      fileName
    }
  }
}`;

const WORKSHOP_ACTIVITY_QUERY = `
  activity {
    fields {
      title
      conferenceMode
      workMode
      outcomeMode
      duration
      instructions
      transcript
      hasTransition
      transitionDuration
      transitionText
      tags
    }
  }
`;

const WORKSHOP_WARMUP_ACTIVITY = `{
  sys {
    id
    contentType {
      sys {
        id
      }
    }
  }
  fields {
    ${WORKSHOP_ACTIVITY_QUERY}
  }
}`;

const WORKSHOP_BRAINSTORMING_ACTIVITY_QUERY = `{
  sys {
    id
    contentType {
      sys {
        id
      }
    }
  }
  fields {
    title
    itemsTitle
    resultsTitle
    resultsInfo
    ${WORKSHOP_ACTIVITY_QUERY}
    items {
      sys {
        id
      }
      fields {
        title
        placeholderText
        allowMultipleSubmissions
        image {
          fields {
            title
            description
            file {
              url
              fileName
            }
          }
        }
      }
    }
  }
}`;
const WORKSHOP_CLOSED_QUESTION_ACTIVITY_QUERY = `{
  sys {
    id
    contentType {
      sys {
        id
      }
    }
  }
  fields {
    title
    ${WORKSHOP_ACTIVITY_QUERY}
    questionInstructions
    questionTitle
    questionDescription
    canAddNewAnswer
    newAnswerTitle
    newAnswerPlaceholder
    videoUrl
    referenceActivity {
      ... on ClosedQuestionActivity {
        sys {
          id
        }
          fields {
            answers {
              fields {
                title
                text
              }
              sys {
                id
              }
            }
            referenceActivity {
              ... on ClosedQuestionActivity {
                sys {
                  id
                }
                  fields {
                    answers {
                      fields {
                        title
                        text
                      }
                      sys {
                        id
                      }
                    }
                  }
              }
            }
          }
      }
      ... on VotingActivity {
        sys {
          id
          contentType {
            sys {
              id
            }
          }
        }
        fields {
          referenceActivity {
            ... on OpenQuestion {
              sys {
                id
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
    answers {
      fields {
        title
        text
      }
      sys {
        id
      }
    }
  }
}`;
const WORKSHOP_COMPARISON_ACTIVITY_QUERY = `{
  sys {
    id
    contentType {
      sys {
        id
      }
    }
  }
  fields {
    title
    ${WORKSHOP_ACTIVITY_QUERY}
    items {
      sys {
        id
      }
      fields {
        title
        referenceLabel
        generatedLabel
        answers
        referenceText
        question
        itemForGeneratedText {
          sys {
            id
            contentType {
              sys {
                id
              }
            }
          }
          fields {
            title
            placeholderText
            # text
          }
        }
      }
    }
  }
}`;
const WORKSHOP_CONCEPTUALISATION_ACTIVITY_QUERY = `{
  sys {
    id
    contentType {
      sys {
        id
      }
    }
  }
  fields {
    title
    ${WORKSHOP_ACTIVITY_QUERY}
    items {
      sys {
        id
      }
      fields {
        title
        placeholderText
      }
    }
  }
}`;
const WORKSHOP_FILTERING_ACTIVITY_QUERY = `{
  sys {
    id
    contentType {
      sys {
        id
      }
    }
  }
  fields {
    title
    ${WORKSHOP_ACTIVITY_QUERY}
    originalListLabel
    originalListEmptyInfo
    originalPredefinedItems {
      sys {
        id
      }
      fields {
        title
        description
        toBeFiltered
      }
    }

    referenceActivity {
      ... on FilteringActivity {
        sys {
          id
          contentType {
            sys {
              id
            }
          }
        }
        fields {
          originalPredefinedItems {
            sys {
              id
            }
            fields {
              title
              description
              toBeFiltered
            }
          }
          targetPredefinedItems {
            sys {
              id
            }
            fields {
              title
              description
              toBeFiltered
            }
          }
        }
      }
    }

    targetListLabel
    targetListEmptyInfo
    targetPredefinedItems {
      sys {
        id
      }
      fields {
        title
        description
        toBeFiltered
      }
    }
    activityForOriginalItems {
      ... on BrainstormingActivity {
        sys {
          id
        }
      }
    }
    # activityForTargetItems
    enforceMatchement
    showDescriptionOfItems
  }
}`;
const WORKSHOP_GROUPING_ACTIVITY_QUERY = `{
  sys {
    id
    contentType {
      sys {
        id
      }
    }
  }
  fields {
    title
    allowMultipleCategorisation
    allowNoCategory
    enforceMatchement
    labelForCategories
    labelForItems
    ${WORKSHOP_ACTIVITY_QUERY}
    predefinedList {
      sys {
        id
      }
      fields {
        title
        description
        explanation
        categories {
          sys {
            id
          }
        }
      }
    }
    predefinedCategories {
      sys {
        id
      }
      fields {
        title
      }
    }
    activityForItems {
      ... on BrainstormingActivity {
        sys {
          id
          contentType {
            sys {
              id
            }
          }
        }
      }
      ... on OpenQuestion {
        sys {
          id
          contentType {
            sys {
              id
            }
          }
        }
      }
    }
  }
}`;
const WORKSHOP_OPEN_QUESTION_ACTIVITY_QUERY = `{
  sys {
    id
    contentType {
      sys {
        id
      }
    }
  }
  fields {
    title
    ${WORKSHOP_ACTIVITY_QUERY}
    questionInstructions
    questionTitle
    questionDescription
    placeholderText
    referenceValueLabel
    referenceTitle
    referenceActivity {
      ... on OpenQuestion {
        sys {
          id
          contentType {
            sys {
              id
            }
          }
        }
        fields {
          ${WORKSHOP_ACTIVITY_QUERY}
        }
      }
      ... on ClosedQuestionActivity {
        sys {
          id
          contentType {
            sys {
              id
            }
          }
        }
        fields {
          referenceActivity {
            ... on VotingActivity {
              sys {
                id
                contentType {
                  sys {
                    id
                  }
                }
              }
              fields {
                referenceActivity {
                  ... on OpenQuestion {
                    sys {
                      id
                      contentType {
                        sys {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`;
const WORKSHOP_TRANSLATION_ACTIVITY_QUERY = `{
  sys {
    id
    contentType {
      sys {
        id
      }
    }
  }
  fields {
    title
    ${WORKSHOP_ACTIVITY_QUERY}
    originalLabel
    targetLabel
    items {
      sys {
        id
      }
      fields {
        title
        text
        targetPlaceholderText
      }
    }
    enforceMatchement
  }
}`;
const WORKSHOP_PRESENTATION_ACTIVITY_QUERY = `{
  sys {
    id
    contentType {
      sys {
        id
      }
    }
  }
  fields {
    title
    ${WORKSHOP_ACTIVITY_QUERY}
    videoUrl
    caption
    note
  }
}`;
const WORKSHOP_VOTING_ACTIVITY_QUERY = `{
  sys {
    id
    contentType {
      sys {
        id
      }
    }
  }
  fields {
    title
    ${WORKSHOP_ACTIVITY_QUERY}
    questionInstructions
    questionTitle
    questionDescription
    referenceActivity {
      ... on OpenQuestion {
        sys {
          id
          contentType {
            sys {
              id
            }
          }
        }
      }
    }
  }
}`;
const WORKSHOP_RATING_ACTIVITY_QUERY = `{
  sys {
    id
    contentType {
      sys {
        id
      }
    }
  }
  fields {
    title
    ${WORKSHOP_ACTIVITY_QUERY}
    items {
      sys {
       id
      }
      fields {
       scale {
        sys {
         id
        }
        fields {
         items {
          sys {
           id
          }
          fields {
           label
          }
         }
        }
       }
       text
       title
      }
    }
  }
}`;

const WORKSHOP_SELF_DIRECTED_DISCUSSION_ACTIVITY_QUERY = `{
sys {
    id
    contentType {
      sys {
        id
      }
    }
  }
  fields {
    title
    ${WORKSHOP_ACTIVITY_QUERY}
    referenceTitle
    assignmentInstructions
    referenceActivity {
      ... on OpenQuestion {
        sys {
          id
          contentType {
            sys {
              id
            }
          }
        }
      }
    }
  }
}`;

const WORKSHOP_EMOTION_ACTIVITY_QUERY = `{
  sys {
    id
    contentType {
      sys {
        id
      }
    }
  }
  fields {
    title
    icons ${WORKSHOP_ICON_QUERY}
    ${WORKSHOP_ACTIVITY_QUERY}
  }
}`;

const WORKSHOP_OPENING_ACTIVITY_QUERY = `{
  sys {
    id
    contentType {
      sys {
        id
      }
    }
  }
  fields {
    title
    authorTitle
    journeyTitle
    objectivesTitle
    objectivesIcon ${WORKSHOP_ICON_QUERY}
    timeToCompleteTitle
    timeToCompleteIcon ${WORKSHOP_ICON_QUERY}
    peopleInvitedTitle
    peopleInvitedIcon ${WORKSHOP_ICON_QUERY}
  }
}`;

export const GET_WORKSHOP_QUERY = `
  metadata ${META_DATA_QUERY}
  sys ${WORKSHOP_SYS_QUERY}
  fields {
    title
    color
    headline
    showScoreboard
    welcomeText
    journey ${WORKSHOP_JOURNEY_QUERY}
    objectives ${WORKSHOP_OBJECTIVES_QUERY}
    ahaMoments ${WORKSHOP_AHA_MOMENTS_QUERY}
    summary ${WORKSHOP_SUMMARY_QUERY}
    icon ${WORKSHOP_ICON_QUERY}
    aboutVideo ${WORKSHOP_ICON_QUERY}
    duration
    aboutText
    openning ${WORKSHOP_OPENING_ACTIVITY_QUERY}
    startEmotionActivity ${WORKSHOP_EMOTION_ACTIVITY_QUERY}
    endEmotionActivity ${WORKSHOP_EMOTION_ACTIVITY_QUERY}
    warmUpActivity ${WORKSHOP_WARMUP_ACTIVITY}
    activities {
      ... on BrainstormingActivity ${WORKSHOP_BRAINSTORMING_ACTIVITY_QUERY}
      ... on ClosedQuestionActivity ${WORKSHOP_CLOSED_QUESTION_ACTIVITY_QUERY}
      ... on ComparisonActivity ${WORKSHOP_COMPARISON_ACTIVITY_QUERY}
      ... on ConceptualisationActivity ${WORKSHOP_CONCEPTUALISATION_ACTIVITY_QUERY}
      ... on FilteringActivity ${WORKSHOP_FILTERING_ACTIVITY_QUERY}
      ... on GroupingActivity ${WORKSHOP_GROUPING_ACTIVITY_QUERY}
      ... on OpenQuestion ${WORKSHOP_OPEN_QUESTION_ACTIVITY_QUERY}
      ... on TranslationActivity ${WORKSHOP_TRANSLATION_ACTIVITY_QUERY}
      ... on PresentationActivity ${WORKSHOP_PRESENTATION_ACTIVITY_QUERY}
      ... on VotingActivity ${WORKSHOP_VOTING_ACTIVITY_QUERY}
      ... on RatingActivity ${WORKSHOP_RATING_ACTIVITY_QUERY}
      ... on SelfDirectedDiscussionActivity ${WORKSHOP_SELF_DIRECTED_DISCUSSION_ACTIVITY_QUERY}
    }
  }
`;

export const WORKSHOP_JOURNEY_WITH_WORKSHOPS_QUERY = `{
  sys {
    id
  }
  fields {
    ${BASE_WORKSHOP_JOURNEY_FIELDS}
    workshops {
      ${GET_WORKSHOP_QUERY}
    }
  }
}`;

export const GET_WORKSHOP_JOURNEY_QUERY = `
  sys ${WORKSHOP_SYS_QUERY}
  fields {
    title
    color
    headline
    welcomeText
    objectives ${WORKSHOP_OBJECTIVES_QUERY}
    summary ${WORKSHOP_SUMMARY_QUERY}
    journey ${WORKSHOP_JOURNEY_QUERY}
    icon ${WORKSHOP_ICON_QUERY}
  }
`;
