import {
  createMachine,
  fromPromise,
  assign,
  sendParent,
  ActorRef,
} from "xstate";
import { createAction, props } from "../utils";

export enum FetchState {
  Idle = "idle",
  Fetching = "fetching",
  Success = "success",
  Failure = "failure",
  Done = "done",
}

export interface FetchMachineContext<T = any> {
  data: T | null;
  error: any | null;
  input: any;
}

export const fetchMachineFactory = <
  T extends string = string,
  I extends (data: any) => Promise<any> = (data: any) => any
>({
  id,
  invokeFn,
}: {
  id: T;
  invokeFn: I;
}) => {
  const trigger = createAction(
    `[${id} - Fetch] Fetch Trigger`,
    props<I extends (data: infer U) => any ? U & { force?: true } : void>()
  );
  const success = createAction(
    `[${id} - Fetch] Fetch Success`,
    props<{
      output: I extends (data: any) => Promise<infer U> ? U : never;
      input: I extends (data: infer U) => any ? U : void;
    }>()
  );
  const failure = createAction(
    `[${id} - Fetch] Fetch Failure`,
    props<{
      error: any;
      input: I extends (data: infer U) => any ? U : void;
    }>()
  );
  const done = createAction(`[${id} - Fetch] Fetch Done`);
  const reset = createAction(
    `[${id} - Fetch] Fetch Reset`,
    props<{ clean: true } | void>()
  );
  const retry = createAction(
    `[${id} - Fetch] Fetch Retry`,
    props<{ force?: true } | void>()
  );

  type Events = {
    TriggerEvent: ReturnType<typeof trigger>;
    SuccessEvent: ReturnType<typeof success>;
    FailureEvent: ReturnType<typeof failure>;
    DoneEvent: ReturnType<typeof done>;
    ResetEvent: ReturnType<typeof reset>;
    RetryEvent: ReturnType<typeof retry>;
  };

  type FetchMachineTypes = {
    context: FetchMachineContext<
      I extends (data: any) => Promise<infer U> ? U : void
    >;
    events: any; // because XState types are not flexible enough
  };

  return {
    machine: createMachine(
      {
        types: {} as FetchMachineTypes,
        id,
        initial: FetchState.Idle,
        context: (): FetchMachineContext => {
          return {
            data: null,
            error: null,
            input: null,
          };
        },
        states: {
          [FetchState.Idle]: {
            on: {
              [trigger.type]: {
                target: FetchState.Fetching,
              },
              [retry.type]: {
                target: FetchState.Fetching,
              },
              [done.type]: {
                target: FetchState.Done,
              },
            },
          },
          [FetchState.Fetching]: {
            entry: assign({
              input: ({ event }) => {
                event = event as Events["TriggerEvent"] | Events["RetryEvent"];
                return event.payload;
              },
            }),
            invoke: {
              src: fromPromise(({ input }) => invokeFn(input)),
              input: ({ context }) => ({
                ...context.input,
              }),
              onDone: {
                actions: [
                  assign({ data: ({ event }) => event.output }),
                  sendParent(({ event, context }) =>
                    success({ output: event.output, input: context.input })
                  ),
                ],
                target: FetchState.Success,
              },
              onError: {
                actions: [
                  assign({ error: ({ event }) => event.error }),
                  sendParent(({ event, context }) =>
                    failure({ error: event.error, input: context.input })
                  ),
                ],
                target: FetchState.Failure,
              },
            },
            on: {
              [done.type]: {
                target: FetchState.Done,
              },
            },
          },
          [FetchState.Success]: {
            on: {
              [trigger.type]: {
                target: FetchState.Fetching,
              },
              [done.type]: {
                target: FetchState.Done,
              },
              [reset.type]: [
                {
                  target: FetchState.Idle,
                  actions: ["resetHandler"],
                },
              ],
              [retry.type]: {
                actions: [
                  ({
                    self,
                    context,
                  }: {
                    self: ActorRef<any, any>;
                    context: FetchMachineContext;
                  }) => self.send(trigger(context.input)),
                ],
              },
            },
          },
          [FetchState.Failure]: {
            on: {
              [trigger.type]: {
                guard: ({ event }: any) => {
                  const triggerEvent = event as Events["TriggerEvent"];
                  return !!triggerEvent.payload?.force;
                },
                target: FetchState.Fetching,
              },
              [retry.type]: {
                guard: ({ event }: any) => {
                  const triggerEvent = event as Events["TriggerEvent"];
                  return !!triggerEvent.payload?.force;
                },
                actions: [
                  ({
                    self,
                    context,
                  }: {
                    self: ActorRef<any, any>;
                    context: FetchMachineContext;
                  }) => self.send(trigger(context.input)),
                ],
              },
              [done.type]: {
                target: FetchState.Done,
              },
              [reset.type]: {
                target: FetchState.Idle,
                actions: ["resetHandler"],
              },
            },
            exit: [assign({ error: null })],
          },
          [FetchState.Done]: {
            type: "final",
          },
        },
      },
      {
        actions: {
          resetHandler: assign(({ event, context }) => {
            const resetEvent = event as Events["ResetEvent"];
            if (!resetEvent.payload || !resetEvent.payload.clean)
              return context as FetchMachineContext;
            return {
              ...context,
              data: null,
            } as FetchMachineContext;
          }),
        },
      }
    ),
    // trigger: This is exposed so you can send this to the machine
    // to initiate the async action when desired. You need to provide
    // force: true if you want to trigger again from failed state
    // (force: true NOT RECOMMENDED because it can introduce
    // endless loops if not used carefully).
    trigger,
    // success: This is exposed so you can react over the success
    // action when needed. Keep in mind that once a fetch machine is
    // spawn you can select it from it's parent and it holds all the
    // information inside itself so you don't need to do extra assigns
    // on the parent.
    success,
    // success: This is exposed so you can react over the failure
    // action when needed. Keep in mind that once a fetch machine is
    // spawn you can select it from it's parent and it holds all the
    // information inside itself so you don't need to do extra assigns
    // on the parent
    failure,
    // reset: This is exposed so you can reset the state of the machine
    // to IDLE. This is useful when the machine has ended in a FAILURE state
    // because in order for you to trigger or retry the action you either need
    // to pass { force: true } to the trigger or retry or you need to reset the
    // machine
    reset,
    // retry: This is exposed so you can easily retry the last action without the
    // need to pass the same input. The machine holds in its context the last called
    // input and it will pass it to the invokeFn when you call retry. You need
    // to provide force: true if you want to trigger again from failed state
    // (force: true NOT RECOMMENDED because it can introduce
    // endless loops if not used carefully).
    retry,
    // done: This is exposed so you can put the machine into it's final state
    // which will result in this machine not being usable ever again
    done,
  };
};
