import { gql } from "@apollo/client";
import { IInviteProfile } from "../types/invite-profile";
import { IInviteProfileResponse } from "../types/invite-profile-result";
import { AppApolloClient } from "../../contexts/Apollo";

const mutation = gql`
  mutation InviteProfile(
    $email: String!
    $name: String!
    $jobTitle: String!
    $permissions: ProfileWorkspaceAccess!
    $status: ProfileWorkspaceStatus!
    $workspaceId: String!
    $tagIds: [String]
    $newTags: [String]
  ) {
    inviteProfile(
      email: $email
      name: $name
      jobTitle: $jobTitle
      permissions: $permissions
      workspaceId: $workspaceId
      status: $status
      tagIds: $tagIds
      newTags: $newTags
    ) {
      success
    }
  }
`;

export function inviteProfile(
  client: AppApolloClient,
  variables: IInviteProfile
) {
  return client
    .mutate<IInviteProfileResponse>({ mutation, variables })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors);
      return result.data!.inviteProfile;
    });
}
