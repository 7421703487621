import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useMachine } from "@xstate/react";
import {
  AuthMachineContext,
  AuthState,
  authMachine,
  updatePasswordMachine,
  getJourneyParticipationDataMachine,
  resetPasswordMachine,
  updateProfileMachine,
  validateUpdatePasswordTokenMachine,
  refreshTokensMachine,
  refreshTokensTrigger,
  loginMachine,
  getInvitationMachine,
} from "../+xstate/machines/auth";
import * as authActions from "../+xstate/actions/auth";
import { ApolloContext, graphQLError } from "./Apollo";
import { Entries, Writeable } from "../types/helpers";
import { useChildMachine } from "../hooks/useChildMachine";
import { FetchState } from "../+xstate/machines/fetch-factory";
import { JourneyCertificationsWithCertificate } from "../types/journey-certificates";
import { CertificationType } from "../types/enums/certification-type";
import { useIndexedDBState } from "../hooks/useIndexedDb";
import { TechnicalSetupConfig } from "../types/technical-setup-config";
import { DevicePermissions } from "../utils/check-device-permissions";
import { AuthCtx } from "../types/auth-ctx";
import { GraphQLError } from "graphql";

type AuthActions = typeof authActions;
type ActionDispatchers = {
  [K in keyof AuthActions]: (
    payload: ReturnType<AuthActions[K]>["payload"]
  ) => void;
};

type GlobalContextState = {
  instanceUUID: string;
  auth: {
    state: AuthState;
    context: AuthMachineContext;
    matches: ReturnType<typeof useMachine<typeof authMachine>>["0"]["matches"];
    loginMachineState: ReturnType<typeof useMachine<typeof loginMachine>>["0"];
    getInvitationMachineState: ReturnType<typeof useMachine<typeof getInvitationMachine>>["0"];
    getInvitationMachineSend: ReturnType<typeof useMachine<typeof getInvitationMachine>>["1"];
    resetPasswordMachineState: ReturnType<
      typeof useMachine<typeof resetPasswordMachine>
    >["0"];
    updatePasswordMachineState: ReturnType<
      typeof useMachine<typeof updatePasswordMachine>
    >["0"];
    updateProfileMachineState: ReturnType<
      typeof useMachine<typeof updateProfileMachine>
    >["0"];
    validatePasswordTokenMachineState: ReturnType<
      typeof useMachine<typeof validateUpdatePasswordTokenMachine>
    >["0"];
    journeyParticipationDataState: FetchState;
    journeyCertificationsWithCertificate: JourneyCertificationsWithCertificate;
  } & ActionDispatchers;
};

export const GlobalContext = createContext<GlobalContextState>(
  {} as GlobalContextState
);

const actionEntries = Object.entries(authActions) as Entries<
  typeof authActions
>;

export const GlobalContextProvider = (
  props: PropsWithChildren<{
    instanceUUID: string;
    devicePermissions: DevicePermissions;
    authCtx: AuthCtx | null;
    technicalSetupConfig: TechnicalSetupConfig | null;
    skipTechnicalSetup?: boolean;
  }>
) => {
  const {
    authCtx,
    instanceUUID,
    devicePermissions,
    technicalSetupConfig,
    skipTechnicalSetup,
  } = props;
  const apolloContext = useContext(ApolloContext);

  const [deviceConfig] = useIndexedDBState<TechnicalSetupConfig | null>(
    "deviceConfig",
    null
  );

  const client = apolloContext.client;

  const [state, send, actor] = useMachine(authMachine, {
    input: {
      client,
      token: authCtx?.token,
      refresh: authCtx?.refresh,
      profile: authCtx?.profile,
      instanceUUID: props.instanceUUID,
      deviceConfig:
        devicePermissions && devicePermissions.microphone === "granted"
          ? deviceConfig
          : null,
      selectedAudioDevice: technicalSetupConfig?.selectedAudioDevice,
      selectedVideoDevice: technicalSetupConfig?.selectedVideoDevice,
      connectionSuccess: technicalSetupConfig?.connectionSuccessful,
      skipTechnicalSetup: skipTechnicalSetup,
    },
  });

  const [, , refreshTokensMachineActor] = useChildMachine(
    state,
    refreshTokensMachine.id
  ) as unknown as ReturnType<typeof useMachine<typeof refreshTokensMachine>>;

  const graphqlErrorEventTarget = useMemo(
    () => apolloContext.graphqlErrorEventTarget,
    [apolloContext.graphqlErrorEventTarget]
  );

  const setToken = apolloContext.setToken;

  useEffect(() => {
    const graphQLErrorHandler = (event: Event | null) => {
      if (!event || !("detail" in event)) return;
      const detail = event.detail as {
        event?: GraphQLError;
        renewTokensCallback?: (reason?: any) => void;
      };
      if (!("event" in detail || !("message" in (detail.event || {})))) return;

      // If renewTokensCallback we need to renew the tokens and retry again the
      // query so that's why we call the renewTokensCallback so we can return
      // control to Apollo ErrorLink so it can retry the request
      if (detail.renewTokensCallback) {
        const { refresh } = actor.getSnapshot().context;
        if (!refresh)
          return void setTimeout(
            () =>
              detail.renewTokensCallback!(new Error("No refresh token found")),
            0
          );

        refreshTokensMachineActor.send(
          refreshTokensTrigger({ client, token: refresh })
        );

        const subscription = refreshTokensMachineActor.subscribe((update) => {
          console.log("data is", update.context.data);
          subscription.unsubscribe();
          detail.renewTokensCallback!(
            update.context.error ? update.context.error : undefined
          );
        });
        return;
      }

      if (
        ["invalid signature", "TOKEN_EXPIRED", "TOKEN_NOT_FOUND"].includes(
          detail.event!.message
        )
      ) {
        setToken(null);
        return void send(authActions.logout());
      }
    };

    graphqlErrorEventTarget.addEventListener(graphQLError, graphQLErrorHandler);
    return () =>
      graphqlErrorEventTarget.removeEventListener(
        graphQLError,
        graphQLErrorHandler
      );
  }, [
    actor,
    client,
    graphqlErrorEventTarget,
    refreshTokensMachineActor,
    send,
    setToken,
  ]);

  const [loginMachineState] = useChildMachine(state, loginMachine.id);
  const [getInvitationMachineState, getInvitationMachineSend] = useChildMachine(state, getInvitationMachine.id);

  const [updatePasswordMachineState] = useChildMachine(
    state,
    updatePasswordMachine.id
  );

  const [resetPasswordMachineState] = useChildMachine(
    state,
    resetPasswordMachine.id
  );
  const [updateProfileMachineState] = useChildMachine(
    state,
    updateProfileMachine.id
  );
  const [validatePasswordTokenMachineState] = useChildMachine(
    state,
    validateUpdatePasswordTokenMachine.id
  );

  const [{ value: journeyParticipationDataState }] = useChildMachine(
    state,
    getJourneyParticipationDataMachine.id
  );

  const dispatchers = useMemo(
    () =>
      actionEntries.reduce((acc, [key, creator]) => {
        acc[key] = (payload) => {
          const action = creator(payload as any);
          send(action);
        };
        return acc;
      }, {} as Writeable<ActionDispatchers>),
    [send]
  );

  const currentProfileId = state.context.profile?.id;
  const journeyParticipationDataResults =
    state.context.profile?.workspace?.workspace.journeyParticipationData
      ?.journeyResults;
  const workspaceCertificates = useMemo(() => {
    return state.context.profile?.workspace?.workspace.certificates || [];
  }, [state.context.profile?.workspace?.workspace.certificates]);

  const journeyCertificationsWithCertificate: JourneyCertificationsWithCertificate =
    useMemo(() => {
      const journeyCertificates: JourneyCertificationsWithCertificate = {};

      for (const certificate of workspaceCertificates) {
        const currentJourneyParticipationData =
          journeyParticipationDataResults?.find(
            (jr) => jr.journey?.sys?.id === certificate.journey_id
          );
        const journeyCertificatesItem = journeyCertificates[
          certificate.journey_id
        ] || {
          [CertificationType.Workspace]: false,
          [CertificationType.Personal]: false,
          [CertificationType.Leader]: false,
          certificate,
        };
        if (!!certificate.profile_id) journeyCertificatesItem.personal = true;
        if (!!certificate.workspace_id)
          journeyCertificatesItem.workspace = true;
        if (
          certificate.workspace_id &&
          !!currentJourneyParticipationData &&
          !!currentJourneyParticipationData.journeyLeaders.find(
            (jl) => jl.id === currentProfileId
          )
        )
          journeyCertificatesItem.leader = true;
        journeyCertificates[certificate.journey_id] = journeyCertificatesItem;
      }
      return journeyCertificates;
    }, [
      currentProfileId,
      journeyParticipationDataResults,
      workspaceCertificates,
    ]);

  const value = useMemo(
    () => ({
      auth: {
        state: state.value as AuthState,
        context: state.context as AuthMachineContext,
        matches: (value: any) => state.matches(value),
        ...dispatchers,
        loginMachineState,
        getInvitationMachineState,
        getInvitationMachineSend,
        updatePasswordMachineState,
        resetPasswordMachineState,
        validatePasswordTokenMachineState,
        updateProfileMachineState,
        journeyParticipationDataState,
        journeyCertificationsWithCertificate,
      },
      instanceUUID,
    }),
    [
      state,
      dispatchers,
      loginMachineState,
      getInvitationMachineState,
      getInvitationMachineSend,
      updatePasswordMachineState,
      resetPasswordMachineState,
      validatePasswordTokenMachineState,
      updateProfileMachineState,
      journeyParticipationDataState,
      journeyCertificationsWithCertificate,
      instanceUUID,
    ]
  );

  useEffect(() => {
    setToken(state.context.token);
  }, [setToken, state.context.token]);

  return (
    <GlobalContext.Provider value={value}>
      {props.children}
    </GlobalContext.Provider>
  );
};
