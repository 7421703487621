import { memo, useCallback } from "react";
import ConfirmationDialog from "../../ConfirmationDialog/ConfirmationDialog";

export const ApplicationUpdateConfirmation = memo(() => {
  const reloadApplicationHandler = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <ConfirmationDialog
      title="Update required!"
      description="A newer version of the application is now available. Please click the button below to reload the page. Thank you."
      continueButtonText="Reload"
      confirmationHandler={reloadApplicationHandler}
      showCloseButton={false}
    ></ConfirmationDialog>
  );
});
