import * as Collapsible from "@radix-ui/react-collapsible";
import * as Checkbox from "@radix-ui/react-checkbox";
import { memo, useCallback, useEffect, useState } from "react";
import cn from "classnames";
import { formatDate } from "date-fns";
import BulkScheduleSlot from "../BulkScheduleSlot/BulkScheduleSlot";
import {
  DaysOfTheWeek,
  ScheduledDay,
} from "../../../../../../../../../apollo-graphql/types/slot";
import TimePicker from "../../../../../../../../Shared/TimePicker/TimePicker";

import styles from "./BulkScheduleDialogWeekDay.module.css";

interface BulkScheduleDialogWeekDayProps {
  checked: boolean;
  item: {
    id: DaysOfTheWeek;
    day: string;
  };
  slots: ScheduledDay["Fri"];
  handleSelection: (id: DaysOfTheWeek) => void;
  onTimeSelected: (time: string) => void;
  removeSlot: (id: string) => void;
}

const BulkScheduleDialogWeekDay = ({
  checked,
  item,
  slots,
  handleSelection,
  onTimeSelected,
  removeSlot,
}: BulkScheduleDialogWeekDayProps) => {
  const [expanded, setExpanded] = useState(checked);
  const [invalidTimeError, setInvalidTimeError] = useState("");
  const [time, setTime] = useState<Date | null>();
  const { id, day } = item;

  const onTimeSelectedHandler = useCallback(() => {
    const slotExists =
      time &&
      slots?.find((slot) => slot.time === formatDate(new Date(time), "HH:mm"));
    if (slotExists) {
      setInvalidTimeError("This slot already exists");
      return;
    }

    setInvalidTimeError("");
    time && onTimeSelected(formatDate(new Date(time), "HH:mm"));
  }, [onTimeSelected, slots, time]);

  useEffect(() => {
    setExpanded(checked);
  }, [checked]);

  return (
    <Collapsible.Root
      open={expanded}
      onOpenChange={() => handleSelection(id)}
      className={cn(styles.container, {
        [styles.containerChecked]: checked,
        [styles.containerExpanded]: expanded,
      })}
    >
      <Collapsible.Trigger className={styles.collapsibleTrigger} asChild>
        <div className={cn(styles.title, "primary")}>
          <label className={styles.radioItem}>
            <Checkbox.Root
              checked={checked}
              onCheckedChange={() => handleSelection(id)}
              className={styles.checkboxRoot}
            >
              <Checkbox.Indicator className={styles.checkboxIndicator}>
                <i className={cn("fa-solid", "fa-check", styles.checkIcon)}></i>
              </Checkbox.Indicator>
            </Checkbox.Root>
          </label>
          {day}
        </div>
      </Collapsible.Trigger>

      <Collapsible.Content className={styles.collapsibleContent}>
        <div className={styles.collapsibleContentInner}>
          <label className={cn(styles.inputLabel, "text", "small")}>
            Choose Start Time:
          </label>
          <TimePicker
            value={time}
            onCalendarClose={onTimeSelectedHandler}
            handleTimeChange={setTime}
          />
          {invalidTimeError && (
            <span className={cn(styles.timeError, "text", "small")}>
              {invalidTimeError}
            </span>
          )}
          {!!slots?.length && (
            <div className={styles.slotsContainer}>
              {slots?.map((slot) => (
                <BulkScheduleSlot
                  startTime={slot.time}
                  removeSlot={() => removeSlot(slot.id)}
                  key={slot.id}
                />
              ))}
            </div>
          )}
        </div>
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

export default memo(BulkScheduleDialogWeekDay);
