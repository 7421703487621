import { PropsWithChildren, memo, useMemo } from "react";

import { IGroupingItem, IGroupingItemOption } from "../../types";

import GroupingCard from "../GroupingCard/GroupingCard";

interface GroupingItemProps {
  index: number;
  groupingItem: IGroupingItem;
  answers: IGroupingItemOption[];
  isParticipating: boolean;
  currentProfileSelectedOptions: {
    [key: string]: string | null;
  };
  otherProfileSelectedOptions: {
    profileId: string;
    value: { [key: string]: string | null };
  }[];
  hasBorderBottom?: boolean;
  shouldBeOpen?: boolean;
  profileId: string;
  currentActiveParticipants: string[];
  disabled: boolean;
  isViewResults?: boolean;
  enforceMatchement?: boolean;
  handleSelection: (optionId: string, sectionType: string) => void;
}

const GroupingItem = (props: PropsWithChildren<GroupingItemProps>) => {
  const {
    index,
    groupingItem,
    answers,
    currentProfileSelectedOptions,
    otherProfileSelectedOptions,
    hasBorderBottom,
    shouldBeOpen,
    profileId,
    currentActiveParticipants,
    isParticipating,
    disabled,
    isViewResults = false,
    enforceMatchement,
    handleSelection,
  } = props;

  const leftHeaderNode = useMemo(
    () => <span className="text bold">{groupingItem.questionLabel}</span>,
    [groupingItem.questionLabel]
  );
  const rightHeaderNode = useMemo(
    () => <span className="text bold">{groupingItem.answerLabel}</span>,
    [groupingItem.answerLabel]
  );
  const titleNode = useMemo(
    () => (
      <span className="text bold">
        {index + 1}. {groupingItem.title}
      </span>
    ),
    [groupingItem.title, index]
  );

  return (
    <GroupingCard
      isCollapsible
      isViewResults={isViewResults}
      sectionType={groupingItem.id}
      disabled={disabled}
      titleNode={titleNode}
      leftHeaderNode={leftHeaderNode}
      rightHeaderNode={rightHeaderNode}
      optionTitleNode={groupingItem.questionNode}
      answers={answers}
      correctAnswers={groupingItem.correctAnswers}
      shouldBeCollapsed={shouldBeOpen}
      sectionLeftTextNode={groupingItem.questionNode}
      sectionRightText={""}
      isParticipating={isParticipating}
      currentProfileSelectedOptions={currentProfileSelectedOptions}
      otherProfileSelectedOptions={otherProfileSelectedOptions}
      profileId={profileId}
      currentActiveParticipants={currentActiveParticipants}
      hasBorderBottom={hasBorderBottom}
      enforceMatchement={enforceMatchement}
      description={groupingItem.description}
      explanation={groupingItem.explanation}
      handleSelection={(optionId, sectionType) =>
        handleSelection(optionId, sectionType)
      }
    />
  );
};

export default memo(GroupingItem);
