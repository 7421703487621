import { NetworkError } from "../types/enums/errors";
import { ApolloServerError } from "../types/server-error";

export const parseServerErrors = (error: ApolloServerError | null) => {
  if (!error || (error?.graphQLErrors?.length === 0 && !error?.networkError))
    return null;

  const graphqlError = error.graphQLErrors?.[0]?.message || null;

  return (
    graphqlError || (error.networkError ? NetworkError.FAILED_TO_FETCH : null)
  );
};
