import {
  memo,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as ToastRadix from "@radix-ui/react-toast";
import cn from "classnames";

import styles from "./Toast.module.css";

export enum ToastType {
  NEUTRAL = "neutral",
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
  UNKNOWN = "unknown",
}

const iconClassMapper = {
  [ToastType.NEUTRAL]: "info",
  [ToastType.INFO]: "info",
  [ToastType.SUCCESS]: "check",
  [ToastType.WARNING]: "exclamation",
  [ToastType.ERROR]: "xmark",
  [ToastType.UNKNOWN]: "",
};

interface ToastProps extends PropsWithChildren {
  title: string;
  type: ToastType;
  description?: string | null;
  duration?: number;
}
const Toast = (props: ToastProps) => {
  const { title, description, type, duration = 3000 } = props;

  const [open, setOpen] = useState(false);

  const icon = useMemo(() => {
    const className = `fa-duotone fa-solid fa-circle-${iconClassMapper[type]}`;
    return <i className={className} />;
  }, [type]);

  useEffect(() => {
    setOpen(!!description);
  }, [description]);

  if (type === ToastType.UNKNOWN) return null;

  return (
    <ToastRadix.Provider swipeDirection="right" duration={duration}>
      <ToastRadix.Root
        className={cn(styles.toastContainer, type)}
        open={open}
        onOpenChange={setOpen}
      >
        <div className={styles.content}>
          <ToastRadix.Title className={styles.title}>{title}</ToastRadix.Title>
          <ToastRadix.Description className={cn("text", styles.description)}>
            {description}
          </ToastRadix.Description>
        </div>
        <ToastRadix.Close className={styles.action}>
          <i className="fa fa-close" />
        </ToastRadix.Close>

        <div className={styles.iconContainer}>{icon}</div>

        <div className={styles.duration}></div>
      </ToastRadix.Root>
      <ToastRadix.Viewport className={styles.toastViewport} />
    </ToastRadix.Provider>
  );
};

export default memo(Toast);
