import { PropsWithChildren, useMemo } from "react";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

import { FooterType } from "../../../types/enums/activity-footer";
import { ActionFooterType } from "../../../types/action-footer";
import { normalConnectionThreshold } from "../../../contexts/Apollo";
import { stepsSelectors } from "../../Guide/steps";

import Button3D from "../../Shared/Buttons/Button3D/Button3D";

import cn from "classnames";
import styles from "./ActionFooter.module.css";

const iconTypeMap = {
  [FooterType.Notice]: "/animations/exclamation-blue.lottie",
  [FooterType.Ready]: "/animations/tick.lottie",
  [FooterType.Waiting]: "/animations/clock.lottie",
  [FooterType.Error]: "/animations/exclamation-red.lottie",
  [FooterType.Observer]: "/icons/observer-icon.svg",
};

export interface ActionFooterProps extends Omit<ActionFooterType, "text"> {
  buttonClickHandler: () => void;
}
export default function ActionFooter(
  props: PropsWithChildren<ActionFooterProps>
) {
  const {
    type,
    children,
    buttonText,
    disabledButton,
    buttonClickHandler,
    isConnectionWeak,
    isLoading,
  } = props;

  const iconContent = useMemo(
    () => (
      <div className="big-icon">
        {type === FooterType.Observer ? (
          <img
            src={iconTypeMap[type]}
            alt="observer"
            className={cn(styles.iconAnimation, "observer")}
          />
        ) : (
          <DotLottieReact
            key={iconTypeMap[type]}
            src={iconTypeMap[type]}
            autoplay
            autoResizeCanvas={false}
            className={styles.iconAnimation}
          />
        )}
      </div>
    ),
    [type]
  );

  const delayInMilliseconds = useMemo(
    () => (isConnectionWeak ? 0 : normalConnectionThreshold),
    [isConnectionWeak]
  );

  const variantButton = useMemo(() => {
    return type === FooterType.Observer
      ? "warning"
      : type === FooterType.Waiting
      ? "primary"
      : "success";
  }, [type]);

  return (
    <div
      className={cn(
        styles.container,
        type,
        disabledButton && "blue-background",
        "main-container",
        "row",
        "footer",
        stepsSelectors.actionFooter
      )}
    >
      <div className={styles.actionsInfo}>
        {iconContent}
        <h3>{children}</h3>
      </div>
      <div>
        {buttonText && (
          <Button3D
            variant={variantButton}
            onClick={buttonClickHandler}
            disabled={disabledButton}
            className={styles.actionButton}
            hasLoader={!disabledButton}
            isLoading={isLoading}
            delayInMilliseconds={delayInMilliseconds}
          >
            {buttonText}
          </Button3D>
        )}
      </div>
    </div>
  );
}
