import { PropsWithChildren } from "react";
import * as Form from "@radix-ui/react-form";

const Email = (
  props: PropsWithChildren<{
    label?: string;
    placeholder?: string;
    defaultValue?: string;
  }>
) => {
  const { label, defaultValue, placeholder } = props;
  return (
    <Form.Field className="FormField" name="email">
      {label && <Form.Label className="caption medium">Email</Form.Label>}
      <Form.Control
        type="email"
        defaultValue={defaultValue}
        className="FormControl"
        placeholder={placeholder}
        required
      />
      <Form.Message className="FormMessage" match="valueMissing">
        Please enter your email.
      </Form.Message>
      <Form.Message className="FormMessage" match="typeMismatch">
        Please provide a valid email.
      </Form.Message>
    </Form.Field>
  );
};

export default Email;
