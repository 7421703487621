import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { SESSION_CONTEXT_RESULT_QUERY } from "../constants";
import { SessionStateValue } from "../types/session-state";

const mutation = gql`
  mutation Mutation($sessionId: String!, $uuid: String!) {
    join(sessionId: $sessionId, uuid: $uuid) {
     context ${SESSION_CONTEXT_RESULT_QUERY}
    }
  }
`;

export function join(
  client: AppApolloClient,
  variables: { sessionId: string; uuid: string }
) {
  return client
    .mutate<{ join: SessionStateValue }>({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.join;
    });
}
