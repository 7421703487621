import { FormEvent, useCallback, useContext, useMemo } from "react";
import { Link, Navigate } from "react-router-dom";
import cn from "classnames";

import * as Form from "@radix-ui/react-form";

import withRouteConfig from "../../hocs/withRouteConfig";

import { parseServerErrors } from "../../utils/parse-server-errors";
import { GlobalContext } from "../../contexts/Global";
import { AuthState } from "../../+xstate/machines/auth";
import { IResetPassword } from "../../apollo-graphql/types/reset-password";
import { FetchState } from "../../+xstate/machines/fetch-factory";
import { mappedErrorMessages } from "../../constants/mapped-error-messages";

import Toast, { ToastType } from "../../components/Shared/Toast/Toast";

import styles from "./ResetPassword.module.css";
import Email from "../../components/Shared/Email/Email";

const ResetPassword = () => {
  const {
    auth: { resetPassword, resetPasswordMachineState, matches },
  } = useContext(GlobalContext);

  const isLoggedIn =
    matches(AuthState.Authenticated) || matches(AuthState.TechnicalSetup);
  const isLoading = resetPasswordMachineState.value === FetchState.Fetching;
  const isInitial = resetPasswordMachineState.value === FetchState.Idle;

  const serverError = useMemo(() => {
    return parseServerErrors(resetPasswordMachineState.context.error);
  }, [resetPasswordMachineState.context.error]);

  const successMessage = useMemo(
    () =>
      resetPasswordMachineState.value === FetchState.Success
        ? "The link to reset your password has been sent. Please check your email box."
        : "",
    [resetPasswordMachineState.value]
  );

  const toastData = useMemo(() => {
    const description = isInitial
      ? null
      : mappedErrorMessages[serverError as keyof typeof mappedErrorMessages];
    return {
      title: "Login",
      description,
      type: ToastType.ERROR,
    };
  }, [serverError, isInitial]);

  const submitHandler = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const payload = Object.fromEntries(
        new FormData(event.currentTarget)
      ) as unknown as IResetPassword;

      const parsedEmail = payload.email.trim().toLocaleLowerCase();

      resetPassword({ variables: { email: parsedEmail } });
    },
    [resetPassword]
  );

  return isLoggedIn ? (
    <Navigate to={"/"} />
  ) : (
    <>
      <div className={styles.resetPasswordContainer}>
        <div className={cn(styles.formContainer, "FormContainer")}>
          <h2 className="thin">Reset your password</h2>
          <div className={cn(styles.subTitle, "primary")}>
            Enter your email and we'll send you instructions on how to reset
            your password.
          </div>
          <Form.Root className="Form" onSubmit={submitHandler}>
            <Email placeholder="Enter your email address" />
            <button
              className="btn large submit-btn"
              type="submit"
              disabled={isLoading}
            >
              Send instructions
            </button>
            {serverError && !isLoading && !isInitial && (
              <div className="error">
                {
                  mappedErrorMessages[
                    serverError as keyof typeof mappedErrorMessages
                  ]
                }
              </div>
            )}
            {successMessage && <div className="success">{successMessage}</div>}
          </Form.Root>
        </div>
        <div className="login-link">
          or go back to <Link to={"/login"}>login page</Link>
        </div>
      </div>

      <Toast
        title={toastData.title}
        description={toastData.description}
        type={toastData.type}
      />
    </>
  );
};

export default withRouteConfig(ResetPassword);
