import { Metadata, Sys } from "../common";

export enum SectionItemType {
  Video = "Video",
  Link = "Link",
  Downloadable = "Downloadable",
  FreeContent = "Free Content",
  Conversation = "Conversation",
  AhaMoments = "AhaMoments",
  Overview = "Overview",
  Journey = "Journey",
}

interface SummaryFieldsSectionFieldsItemFields {
  link: string;
  text: string;
  title: string;
  type: SectionItemType;
}

export interface SummaryFieldsSectionFieldsItem {
  metadata: Metadata;
  sys: Sys;
  fields: SummaryFieldsSectionFieldsItemFields;
}

interface SummaryFieldsSectionFields {
  title: string;
  items?: SummaryFieldsSectionFieldsItem[];
}

interface SummaryFieldsSection {
  metadata: Metadata;
  sys: Sys;
  fields: SummaryFieldsSectionFields;
}

interface SummaryFields {
  title: string;
  sections: SummaryFieldsSection[];
}

export interface Summary {
  metadata: Metadata;
  sys: Sys;
  fields: SummaryFields;
}
