import { IAutoCompleteItem } from "../../../Shared/Autocomplete/AutoComplete";

export const generateTagsFromAutoCompleteData = (
  items: IAutoCompleteItem[]
) => {
  const [newTags, tagIds, tags]: [string[], string[], string[]] = [[], [], []];

  for (const { id, text } of items) {
    tags.push(text);
    if (id) {
      tagIds.push(id);
    } else {
      newTags.push(text);
    }
  }

  return {
    newTags,
    tagIds,
    tags,
  };
};
