import {
  ChangeEvent,
  FormEvent,
  PropsWithChildren,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import * as Form from "@radix-ui/react-form";
import { startCase, toLower } from "lodash";
import cn from "classnames";

import { AdminDashboardContext } from "../../../../../../contexts/AdminDashboard";
import { FetchState } from "../../../../../../+xstate/machines/fetch-factory";
import {
  deleteTeamMemberImage,
  getPresignedTeamMemberUrl,
  uploadTeamMemberImage,
} from "../../../../../../+xstate/actions/team-members";
import { mappedErrorMessages } from "../../../../../../constants/mapped-error-messages";
import { generateTagsFromAutoCompleteData } from "../../utils";

import {
  Profile,
  ProfileUpdate,
} from "../../../../../../apollo-graphql/types/profile";
import {
  ProfileWorkspaceAccess,
  ProfileWorkspaceStatus,
} from "../../../../../../apollo-graphql/types/enums";
import { UpdateProfileErrors } from "../../../../../../types/enums/errors";
import { WorkspaceTag } from "../../../../../../apollo-graphql/types/workspace-tag";

import { Select } from "../../../../../Shared/Select/Select";
import LoadingButton from "../../../../../Shared/Buttons/LoadingButton/LoadingButton";
import UserImage from "../../../../../Shared/UserImage/UserImage";
import Dialog from "../../../../../Shared/Dialog/Dialog";
import AutoComplete, {
  IAutoCompleteItem,
} from "../../../../../Shared/Autocomplete/AutoComplete";

import styles from "./EditTeamMemberModal.module.css";
import { GlobalContext } from "../../../../../../contexts/Global";

const EditTeamMemberModal = (
  props: PropsWithChildren<{
    teamMember: Profile;
    workspaceTags: WorkspaceTag[];
    errorMessage: string | null;
    isLoading: boolean;
    imageData: { token: null | string; key: null | string };
    updateTeamMember: (params: ProfileUpdate) => void;
    onClose: () => void;
  }>
) => {
  const {
    teamMember,
    workspaceTags,
    imageData,
    errorMessage,
    updateTeamMember,
    isLoading,
    onClose,
  } = props;

  const {
    teamMembers: {
      adminTeamMembersState: state,
      adminTeamMembersSend: send,
      uploadTeamMemberImageMachineState,
      deleteTeamMemberImageMachineState,
    },
  } = useContext(AdminDashboardContext);
  const {
    auth: {
      context: { profile },
    },
  } = useContext(GlobalContext);

  const isCurrentUserOwner =
    profile?.workspace.access === ProfileWorkspaceAccess.OWNER;

  const [dialogState, setDialogState] = useState<{
    permissions: ProfileWorkspaceAccess;
    status: ProfileWorkspaceStatus;
    newTags: string[];
    tagIds: string[];
    workspaceId: string;
  }>({
    permissions: teamMember.workspace.access,
    status: teamMember.workspace.status,
    newTags: [],
    tagIds: [],
    workspaceId: teamMember.workspace.workspace_id,
  });
  const fileUploadRef = useRef<HTMLInputElement | null>(null);
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);

  const [error, setError] = useState<string>("");

  const isFileUploading = useMemo(
    () => uploadTeamMemberImageMachineState.value === FetchState.Fetching,
    [uploadTeamMemberImageMachineState?.value]
  );
  const isFileDeleting = useMemo(
    () => deleteTeamMemberImageMachineState.value === FetchState.Fetching,
    [deleteTeamMemberImageMachineState?.value]
  );

  const selectedTeamMemberImageData = useMemo(
    () => state.context.selectedTeamMemberImageData,
    [state.context.selectedTeamMemberImageData]
  );

  const allTags = useMemo(() => {
    return workspaceTags.map((t) => ({
      id: t.id,
      text: t.text,
    }));
  }, [workspaceTags]);

  const teamMemberTags = useMemo(() => {
    return teamMember.workspaceTags.map((t) => t.text);
  }, [teamMember.workspaceTags]);

  const submitHandler = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      setError("");
      e.preventDefault();

      const payload = Object.fromEntries(new FormData(e.currentTarget)) as {
        name: string;
        jobTitle: string;
      };

      const name = payload.name.trim();
      const jobTitle = payload.jobTitle.trim();

      updateTeamMember({
        id: teamMember.id,
        name,
        jobTitle,
        ...dialogState,
      });
    },
    [updateTeamMember, teamMember, dialogState]
  );

  const uploadImageHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      const file = files && files[0];
      if (
        !file ||
        !selectedTeamMemberImageData.presignedUrl ||
        !fileUploadRef.current
      )
        return;

      send(
        uploadTeamMemberImage({
          url: selectedTeamMemberImageData.presignedUrl,
          body: file,
        })
      );

      fileUploadRef.current.value = "";
    },
    [send, selectedTeamMemberImageData.presignedUrl]
  );

  const deleteImageHandler = useCallback(() => {
    const { key, token } = imageData;
    if (!key || !token) return;
    send(deleteTeamMemberImage({ key, token }));
  }, [imageData, send]);

  const tagChangesHandler = useCallback((items: IAutoCompleteItem[]) => {
    const { newTags, tagIds } = generateTagsFromAutoCompleteData(items);

    setDialogState((prev) => ({ ...prev, newTags, tagIds }));
  }, []);

  const imageContent = useMemo(() => {
    return (
      <>
        <UserImage
          isPublic={false}
          profileId={teamMember.id}
          profileWorkspaceId={teamMember.workspace.workspace_id}
          showLoader={isFileUploading || isFileDeleting}
          containerClass={styles.imageContainer}
          fallbackFontAwesomeIconClass="fa fa-user"
          alt="user-profile"
          lastUpdatedImageTimestamp={
            selectedTeamMemberImageData.lastUpdatedTimeStamp
          }
        />
        <label className="upload-container">
          {(isFileUploading || isFileDeleting) && (
            <i className="fa fa-circle-o-notch fa-spin" />
          )}
          <span className="button-text">Upload new picture</span>
          <input
            type="file"
            disabled={isFileUploading}
            ref={fileUploadRef}
            accept="image/*"
            onChange={uploadImageHandler}
          />
        </label>
        <div>
          <button className="btn secondary small" onClick={deleteImageHandler}>
            Delete
          </button>
        </div>
      </>
    );
  }, [
    deleteImageHandler,
    isFileDeleting,
    isFileUploading,
    selectedTeamMemberImageData.lastUpdatedTimeStamp,
    teamMember.id,
    teamMember.workspace.workspace_id,
    uploadImageHandler,
  ]);

  const formContent = useMemo(() => {
    return (
      <Form.Root className="form-container" onSubmit={submitHandler}>
        <Form.Field name="name" className="form-row">
          <Form.Label className="label">Name</Form.Label>
          <Form.Control
            type="text"
            className="FormControl"
            defaultValue={teamMember?.name || ""}
          />
        </Form.Field>
        <Form.Field name="email" className="form-row">
          <Form.Label className="label">Email</Form.Label>
          <Form.Control
            type="email"
            className="FormControl"
            value={teamMember?.email || ""}
            disabled
          />
        </Form.Field>
        <Form.Field name="jobTitle" className="form-row">
          <Form.Label className="label">Job Title</Form.Label>
          <Form.Control
            type="text"
            className="FormControl"
            defaultValue={teamMember?.workspace?.title || ""}
          />
        </Form.Field>
        {isCurrentUserOwner && (
          <Form.Field name="permissions" className="form-row">
            <Form.Label className="label">Access Permissions</Form.Label>

            <Select
              label="Select permissions"
              value={dialogState.permissions}
              options={[...(Object.values(ProfileWorkspaceAccess) || [])].map(
                (access) => ({
                  label: startCase(toLower(access)),
                  value: access,
                  key: `${access}-access`,
                })
              )}
              className={styles.selectInput}
              onChange={(permissions) => {
                setDialogState((prev) => ({
                  ...prev,
                  permissions: permissions as ProfileWorkspaceAccess,
                }));
              }}
            />
          </Form.Field>
        )}
        <Form.Field name="status" className="form-row">
          <Form.Label className="label">Profile Status</Form.Label>
          <Select
            label="Select status"
            value={dialogState.status}
            options={[...(Object.values(ProfileWorkspaceStatus) || [])].map(
              (status) => ({
                label: startCase(toLower(status)),
                value: status,
                key: `${status}-status`,
              })
            )}
            className={styles.selectInput}
            onChange={(status) => {
              setDialogState((prev) => ({
                ...prev,
                status: status as ProfileWorkspaceStatus,
              }));
            }}
          />
        </Form.Field>
        <AutoComplete
          label="Add tags"
          placeholder="Select a tag or create a new one"
          items={allTags}
          defaultValues={teamMemberTags}
          onChanges={tagChangesHandler}
        />
        <div className="submit-hidden">
          <button ref={submitButtonRef} type="submit" />
        </div>
        {(error || errorMessage) && (
          <div className={styles.footer}>
            <div className={styles.errorContainer}>
              {error ||
                mappedErrorMessages[errorMessage as UpdateProfileErrors]}
            </div>
          </div>
        )}
      </Form.Root>
    );
  }, [
    allTags,
    dialogState.permissions,
    dialogState.status,
    error,
    errorMessage,
    isCurrentUserOwner,
    submitHandler,
    tagChangesHandler,
    teamMember?.email,
    teamMember?.name,
    teamMember?.workspace?.title,
    teamMemberTags,
  ]);

  const dialogActionsContent = useMemo(() => {
    return (
      <>
        <LoadingButton
          onClick={() => submitButtonRef.current?.click()}
          isLoading={isLoading}
          className={cn("btn", styles.saveBtn)}
          type="submit"
        >
          Save
        </LoadingButton>
      </>
    );
  }, [isLoading]);

  const dialogContent = useMemo(() => {
    return (
      <div className={styles.content}>
        <div className="user-container">{imageContent}</div>
        <div className="user-info-container">{formContent}</div>
      </div>
    );
  }, [imageContent, formContent]);

  useEffect(() => {
    const { key, token } = imageData;
    if (!key || !token) return;

    send(getPresignedTeamMemberUrl({ key, token }));
  }, [imageData, send]);

  return (
    <Dialog
      open
      heading="Edit profile"
      onClose={onClose}
      actionsContent={dialogActionsContent}
    >
      {dialogContent}
    </Dialog>
  );
};

export default memo(EditTeamMemberModal);
