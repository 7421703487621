import { memo, useContext, useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useMachine } from "@xstate/react";

import * as adminDashboardScheduleDialogActions from "../../../+xstate/actions/dashboard/admin-dashboard-schedule-dialog";
import {
  AdminDashboardScheduleState,
  adminDashboardScheduleDialogMachine,
} from "../../../+xstate/machines/dashboard/admin-dashboard-schedule-dialog";

import { enterAdminWorkshops } from "../../../+xstate/actions/dashboard/admin-dashboard";

import { getWorkshopsMachine } from "../../../+xstate/machines/dashboard/admin-dashboard";
import { FetchState } from "../../../+xstate/machines/fetch-factory";

import { useChildMachine } from "../../../hooks/useChildMachine";
import withRouteConfig from "../../../hocs/withRouteConfig";
import { hasAdminAccess } from "../../../utils/profile";

import { GlobalContext } from "../../../contexts/Global";
import { ApolloContext } from "../../../contexts/Apollo";
import { AdminDashboardContext } from "../../../contexts/AdminDashboard";

import AdminWorkshopList from "./AdminWorkshopList";
import MemberWorkshopList from "./MemberWorkshopList";

function Workshops() {
  const {
    auth: {
      context: { profile },
    },
  } = useContext(GlobalContext);
  const { client } = useContext(ApolloContext);
  const { workshopId } = useParams();
  const location = useLocation();

  const { state: adminDashboardState, send: adminDashboardSend } = useContext(
    AdminDashboardContext
  );

  const scheduleDialogMachine = useMachine(
    adminDashboardScheduleDialogMachine,
    {
      input: { client },
    }
  );
  const [state, send] = scheduleDialogMachine;

  const [getWorkshopsState] = useChildMachine(
    adminDashboardState,
    getWorkshopsMachine.id
  ) as unknown as ReturnType<typeof useMachine<typeof getWorkshopsMachine>>;

  const workshops = useMemo(
    () => adminDashboardState.context.workshops || [],
    [adminDashboardState.context.workshops]
  );

  const locationPathname = location.pathname;

  const isDialogOpen =
    state.matches(AdminDashboardScheduleState.Start) ||
    state.matches(AdminDashboardScheduleState.LoadScheduleData) ||
    state.matches(AdminDashboardScheduleState.ScheduleReady) ||
    state.matches(AdminDashboardScheduleState.ScheduleReadyBulk) ||
    state.matches(AdminDashboardScheduleState.Creating) ||
    state.matches(AdminDashboardScheduleState.CreatingBulk) ||
    state.matches(AdminDashboardScheduleState.Done);

  const workshopsLoading = useMemo(
    () =>
      getWorkshopsState ? getWorkshopsState.matches(FetchState.Fetching) : true,
    [getWorkshopsState]
  );

  useEffect(() => {
    const shouldOpenDialog = /workshops\/schedule\/(.*)/.test(locationPathname);
    if (
      (isDialogOpen && shouldOpenDialog) ||
      (!shouldOpenDialog && !isDialogOpen)
    )
      return;
    if (isDialogOpen && !shouldOpenDialog)
      return void send(
        adminDashboardScheduleDialogActions.closeScheduleDialog()
      );
    const workspaceId = profile!.workspace.workspace_id;
    if (!isDialogOpen && shouldOpenDialog && workshopId && workspaceId)
      return void send(
        adminDashboardScheduleDialogActions.openScheduleDialog({
          workshopId,
          workspaceId,
        })
      );
  }, [isDialogOpen, locationPathname, profile, send, workshopId]);

  useEffect(() => {
    adminDashboardSend(enterAdminWorkshops({ searchText: "" }));
  }, [adminDashboardSend]);

  const props = useMemo(() => {
    return {
      isDialogOpen,
      workshops,
      workshopsLoading,
      scheduleDialogMachine,
    };
  }, [isDialogOpen, scheduleDialogMachine, workshops, workshopsLoading]);

  return hasAdminAccess(profile) ? (
    <AdminWorkshopList {...props} />
  ) : (
    <MemberWorkshopList {...props} />
  );
}

export default memo(withRouteConfig(Workshops));
