import {
  Fragment,
  PropsWithChildren,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";

import {
  getTransitionData,
  getDuration,
  getType,
  getEntryId,
  getTitle,
} from "../../../../utils";
import { getTransitionActionFooterData } from "../utils/get-transition-footer-data";
import { activityTypeFooterTextFactoryMap } from "../../../../utils/footer";
import { observerFooterData } from "../constants";
import { WorkshopClock } from "../../../../helpers/workshop-clock";

import { PresentationActivity } from "../../../../types/contentful/workshop/activities/presentation";
import { WorkshopActivityType } from "../../../../types/enums/activity-type";
import { ActivityCommon } from "../../../../types/activity-common";
import { ActionFooterType } from "../../../../types/action-footer";
import { FooterType } from "../../../../types/enums/activity-footer";

import ActionFooter from "../../ActionFooter/ActionFooter";
import NextStepTransition from "../../NextStepTransition/NextStepTransition";
import SkipPresentationModal from "./components/SkipPresentationModal";
import VideoPlayer from "../../../Shared/VideoPlayer/VideoPlayer";

import cn from "classnames";
import styles from "./Presentation.module.css";
import ContentfulRichField from "../../../Shared/ContentfulRichField/ContentfulRichField";
import ActivityInstructions from "../../../Shared/ActivityInstructions/ActivityInstructions";

interface PresentationProps extends ActivityCommon {
  clockInstance?: WorkshopClock | null;
  isViewResults: boolean;
}

const Presentation = (props: PropsWithChildren<PresentationProps>) => {
  const {
    activity,
    nextActivity,
    transition,
    isReady,
    isLoading,
    currentActiveParticipantCount,
    notReadyProfilesCount,
    isViewResults,
    isConnectionWeak,
    isActivityTimeout,
    isParticipating,
    clockInstance,
    hasAhaMoments,
    setActivityReadyHandler,
  } = props;
  const [showSkipModal, setSkipModalVisibility] = useState<boolean>(false);

  const isTransitioning = useMemo(() => transition > 0, [transition]);
  const activityData = useMemo(() => {
    return {
      id: getEntryId(activity),
      duration: getDuration(activity),
      type: getType(activity) as WorkshopActivityType,
      description: getTitle(activity),
      videoUrl: (activity as PresentationActivity)?.fields?.videoUrl,
      instructions: activity.fields.activity.fields.instructions,
      tags: [],
      conferenceMode: activity.fields.activity.fields.conferenceMode,
    };
  }, [activity]);

  const nextActivityData = useMemo(
    () => getTransitionData(nextActivity, hasAhaMoments),
    [nextActivity, hasAhaMoments]
  );

  const actionFooterData: ActionFooterType = useMemo(() => {
    if (!isParticipating) return observerFooterData;
    if (isTransitioning) {
      return getTransitionActionFooterData({
        text: <>Everyone is ready. Continuing forward...</>,
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Ready,
        isActivityTimeout,
      });
    }
    if (!isReady) {
      return {
        text: activityTypeFooterTextFactoryMap[activityData.type](
          currentActiveParticipantCount - notReadyProfilesCount
        ),
        buttonText: "Continue",
        disabledButton: false,
        type: FooterType.Notice,
      };
    }
    return {
      text: (
        <>
          Waiting for{" "}
          <span className="accent">
            {notReadyProfilesCount} more player
            {notReadyProfilesCount > 1 && "s"}...
          </span>
        </>
      ),
      buttonText: "Continue",
      disabledButton: true,
      type: FooterType.Waiting,
    };
  }, [
    isParticipating,
    isTransitioning,
    isReady,
    notReadyProfilesCount,
    isActivityTimeout,
    activityData.type,
    currentActiveParticipantCount,
  ]);

  const onButtonClickHandler = useCallback(() => {
    if (!isParticipating) {
      setActivityReadyHandler({ activityId: activityData.id });
      return;
    }
    const [, remainingTimeString] = /(\d+):/.exec(
      clockInstance?.activityParsedTimeRemaining || "00:00"
    ) || ["", "00:00"];

    if (activityData.duration - parseInt(remainingTimeString) < 3) {
      setSkipModalVisibility(true);
      return;
    }
    setActivityReadyHandler({ activityId: activityData.id });
  }, [
    activityData.duration,
    activityData.id,
    clockInstance?.activityParsedTimeRemaining,
    isParticipating,
    setActivityReadyHandler,
  ]);

  const onCancelHandler = useCallback(() => setSkipModalVisibility(false), []);
  const onSubmitHandler = useCallback(() => {
    if (isLoading) return;
    setActivityReadyHandler({ activityId: activityData.id });
    onCancelHandler();
  }, [activityData.id, isLoading, onCancelHandler, setActivityReadyHandler]);

  const videoContent = useMemo(() => {
    if (!activityData.videoUrl) return null;
    return (
      <VideoPlayer
        description={activityData.description}
        src={activityData.videoUrl}
      />
    );
  }, [activityData.description, activityData.videoUrl]);

  const activityInfoContent = useMemo(() => {
    const html = (activity as PresentationActivity)?.fields?.note;
    if (!html) return null;
    return (
      <ContentfulRichField
        content={html}
        className={cn(styles.infoText, "text")}
      />
    );
  }, [activity]);

  return (
    <Fragment key={activityData.id}>
      {showSkipModal && (
        <SkipPresentationModal
          onSubmit={onSubmitHandler}
          onCancel={onCancelHandler}
        />
      )}
      <div className="activity-container">
        <div
          className={cn(
            styles.container,
            "main-container",
            isViewResults && styles.viewResultsContainer
          )}
        >
          <ActivityInstructions
            instructions={activityData.instructions}
            tags={activityData.tags}
            type={activityData.conferenceMode}
          />
          <div className="details column">
            {videoContent}
            {activityInfoContent}
          </div>
        </div>
        {!isViewResults && isTransitioning && (
          <NextStepTransition
            nextStep={nextActivityData.transitionText}
            sessionType={nextActivityData.conferenceMode}
            isActivityTimeout={isActivityTimeout}
            transition={transition}
          />
        )}
      </div>
      {!isViewResults && (
        <ActionFooter
          buttonText={actionFooterData.buttonText}
          type={actionFooterData.type}
          isLoading={isLoading}
          isConnectionWeak={isConnectionWeak}
          disabledButton={actionFooterData.disabledButton}
          buttonClickHandler={onButtonClickHandler}
        >
          {actionFooterData.text}
        </ActionFooter>
      )}
    </Fragment>
  );
};

export default memo(Presentation);
