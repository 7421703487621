import React, {
  PropsWithChildren,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as Collapsible from "@radix-ui/react-collapsible";

import { IGroupingItemOption } from "../../types";
import { Select, SelectType } from "../../../../../Shared/Select/Select";
import { ACTIVITY_TIMEOUT_VALUE } from "../../../../../../constants/global";

import CircledIcon from "../../../../../Shared/CircledIcon/CircledIcon";
import ToggleChevron from "../../../../../Shared/ToggleChevron/ToggleChevron";

import styles from "./GroupingCard.module.css";
import cn from "classnames";
import ContentfulRichField from "../../../../../Shared/ContentfulRichField/ContentfulRichField";

const BADGE_CORRECT_ANSWER = "Correct Answer";
const BADGE_WRONG_ANSWER = "Wrong Answer";
const BADGE_PENDING_ALIGNMENT = "Pending Alignment";
const BADGE_PENDING_ANSWER = "Pending Answer";
const BADGE_ALIGNED = "Aligned";

const defaultSelectTriggerStyles: React.CSSProperties = {
  border: "1.5px solid var(--Colors-Neutral-gray-400, #BEBEB3)",
};
const hasValueSelectTriggerStyles: React.CSSProperties = {
  border: "1.5px dashed var(--Colors-Functional-Info, #1EAED0)",
  //backgroundColor: "var(--Colors-Functional-info-light, #EDFCFE)",
};
const wrongValueSelectTriggerStyles: React.CSSProperties = {
  border: "1.5px dashed var(--Colors-Functional-danger-dark, #941D3F)",
  backgroundColor: "#FEE",
};
const correctValueSelectTriggerStylesWithEnforce: React.CSSProperties = {
  border: "1.5px solid var(--Colors-Functional-success-dark, #15A457)",
  backgroundColor: "var(--Colors-Green-50, #F0FDF5)",
  cursor: "not-allowed",
};

const correctValueSelectTriggerStylesWithoutEnforce: React.CSSProperties = {
  border: "1.5px dashed var(--Colors-Functional-Info, #1EAED0)",
  backgroundColor: "var(--Colors-Functional-info-light, #EDFCFE)",
  cursor: "not-allowed",
};

interface GroupingCardProps {
  disabled: boolean;
  sectionType: string;
  leftHeaderNode: ReactNode;
  rightHeaderNode: ReactNode;
  optionTitleNode: ReactNode;
  answers: IGroupingItemOption[];
  correctAnswers?: IGroupingItemOption["id"][];
  sectionLeftTextNode: ReactNode;
  sectionRightText: string;
  currentProfileSelectedOptions: { [key: string]: string | null };
  otherProfileSelectedOptions: {
    profileId: string;
    value: { [key: string]: string | null };
  }[];
  titleNode: ReactNode;
  currentActiveParticipants: string[];
  profileId: string;
  isParticipating: boolean;
  isCollapsible?: boolean;
  shouldBeCollapsed?: boolean;
  hasBorderBottom?: boolean;
  enforceMatchement?: boolean;
  isViewResults?: boolean;
  description?: string;
  explanation?: string;
  handleSelection: (optionId: string, sectionType: string) => void;
}

const GroupingCard = (props: PropsWithChildren<GroupingCardProps>) => {
  const {
    disabled,
    sectionType,
    leftHeaderNode,
    rightHeaderNode,
    answers,
    correctAnswers,
    sectionLeftTextNode,
    currentProfileSelectedOptions,
    otherProfileSelectedOptions,
    titleNode,
    profileId,
    currentActiveParticipants,
    isParticipating,
    shouldBeCollapsed = false,
    isCollapsible = false,
    isViewResults = false,
    enforceMatchement,
    description,
    explanation,
    handleSelection,
  } = props;

  const [isOpen, setIsOpen] = useState(shouldBeCollapsed || !isCollapsible);

  useEffect(() => {
    if (isOpen === shouldBeCollapsed) return;
    setIsOpen(shouldBeCollapsed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldBeCollapsed]);

  const allParticipantsHaveAnswered = useMemo(
    () =>
      currentActiveParticipants
        .filter((d) => d !== profileId)
        .every((participant) => {
          const participantAnswer = otherProfileSelectedOptions.find(
            (res) => res.profileId === participant
          )?.value;

          return (
            !!participantAnswer &&
            participantAnswer[sectionType] &&
            participantAnswer[sectionType] !== ACTIVITY_TIMEOUT_VALUE
          );
        }),
    [
      currentActiveParticipants,
      otherProfileSelectedOptions,
      profileId,
      sectionType,
    ]
  );

  const currentUserHasAnswered = useMemo(
    () =>
      !isParticipating
        ? allParticipantsHaveAnswered
        : !!currentProfileSelectedOptions[sectionType] &&
          currentProfileSelectedOptions[sectionType] !== ACTIVITY_TIMEOUT_VALUE,

    [
      allParticipantsHaveAnswered,
      currentProfileSelectedOptions,
      isParticipating,
      sectionType,
    ]
  );

  const groupIsAligned = useMemo(() => {
    if (currentActiveParticipants.length === 1) return !!currentUserHasAnswered;
    const otherProfileAnswers = otherProfileSelectedOptions.map((d) => d.value);
    const currentAnswer = otherProfileAnswers?.[0]?.[sectionType];

    if (!currentAnswer || currentAnswer === ACTIVITY_TIMEOUT_VALUE)
      return false;

    return (
      allParticipantsHaveAnswered &&
      [
        isParticipating ? currentProfileSelectedOptions : null,
        ...otherProfileAnswers,
      ]
        .filter(Boolean)
        .every((res) => res![sectionType] === currentAnswer)
    );
  }, [
    allParticipantsHaveAnswered,
    currentActiveParticipants.length,
    currentProfileSelectedOptions,
    isParticipating,
    otherProfileSelectedOptions,
    sectionType,
    currentUserHasAnswered,
  ]);

  const groupIsAlignedOnCorrectAnswer = useMemo(() => {
    if (!groupIsAligned) return false;
    const selectedAnswer = currentProfileSelectedOptions[sectionType];

    const isCorrect = correctAnswers
      ? correctAnswers.includes(selectedAnswer!)
      : true;
    return isCorrect;
  }, [
    correctAnswers,
    currentProfileSelectedOptions,
    groupIsAligned,
    sectionType,
  ]);

  const groupIsAlignedOnWrongAnswer = useMemo(
    () => groupIsAligned && !groupIsAlignedOnCorrectAnswer,
    [groupIsAligned, groupIsAlignedOnCorrectAnswer]
  );

  const badgeText = useMemo(() => {
    switch (true) {
      case !currentUserHasAnswered:
        return BADGE_PENDING_ANSWER;

      case currentUserHasAnswered && !groupIsAligned:
      case currentUserHasAnswered &&
        groupIsAligned &&
        !groupIsAlignedOnCorrectAnswer &&
        !enforceMatchement:
        return BADGE_PENDING_ALIGNMENT;
      case currentUserHasAnswered &&
        groupIsAligned &&
        groupIsAlignedOnCorrectAnswer &&
        enforceMatchement:
        return BADGE_CORRECT_ANSWER;
      case currentUserHasAnswered &&
        groupIsAligned &&
        !groupIsAlignedOnCorrectAnswer &&
        enforceMatchement:
        return BADGE_WRONG_ANSWER;
      case currentUserHasAnswered &&
        groupIsAligned &&
        !groupIsAlignedOnCorrectAnswer:
        return BADGE_PENDING_ALIGNMENT;
      case currentUserHasAnswered &&
        groupIsAligned &&
        groupIsAlignedOnCorrectAnswer &&
        !enforceMatchement:
        return BADGE_ALIGNED;
      default:
        return BADGE_PENDING_ANSWER;
    }
  }, [
    currentUserHasAnswered,
    groupIsAligned,
    groupIsAlignedOnCorrectAnswer,
    enforceMatchement,
  ]);

  const isAlignedWithEnforce = useMemo(
    () =>
      badgeText === BADGE_CORRECT_ANSWER || badgeText === BADGE_WRONG_ANSWER,
    [badgeText]
  );

  const isAlignedWithoutEnforce = useMemo(
    () => badgeText === BADGE_ALIGNED,
    [badgeText]
  );

  const onOpenChangeHandler = useCallback(() => {
    if (!isCollapsible) return;
    setIsOpen((prev) => !prev);
  }, [isCollapsible, setIsOpen]);

  const selectLabelNode = useMemo(() => {
    const hasOwnSelection = !!currentProfileSelectedOptions[sectionType];
    const othersHaveSelection = otherProfileSelectedOptions.some(
      (res) => res.value[sectionType]
    );

    const isGroupSelection =
      hasOwnSelection && othersHaveSelection && groupIsAligned;

    return (
      <div className={cn(styles.iconContainer)}>
        {!isGroupSelection && hasOwnSelection && (
          <CircledIcon
            iconClass={cn("icon", "fa", "fa-user")}
            isSelected={true}
            className="icon"
          />
        )}
        {!isGroupSelection && othersHaveSelection && (
          <CircledIcon
            iconClass={cn("icon", "fa", "fa-user")}
            isSelected={false}
            className="icon"
          />
        )}
        {isGroupSelection && (
          <CircledIcon
            iconClass={cn("icon", "fa", "fa-users")}
            isSelected={true}
            className="icon"
          />
        )}
      </div>
    );
  }, [
    currentProfileSelectedOptions,
    groupIsAligned,
    otherProfileSelectedOptions,
    sectionType,
  ]);

  const selectClassNames = useMemo(() => {
    const hasOwnSelection = !!currentProfileSelectedOptions[sectionType];

    return cn(
      styles.optionSelect,
      hasOwnSelection && "selected",
      groupIsAlignedOnCorrectAnswer && "correct",
      groupIsAlignedOnWrongAnswer && "wrong"
    );
  }, [
    currentProfileSelectedOptions,
    groupIsAlignedOnCorrectAnswer,
    groupIsAlignedOnWrongAnswer,
    sectionType,
  ]);

  const triggerStyles: React.CSSProperties = useMemo(() => {
    const hasAnswered = !!currentProfileSelectedOptions[sectionType];
    if (hasAnswered) {
      const isAligned = groupIsAligned;
      if (!isAligned) {
        return hasValueSelectTriggerStyles;
      }

      if (enforceMatchement) {
        return groupIsAlignedOnWrongAnswer
          ? wrongValueSelectTriggerStyles
          : correctValueSelectTriggerStylesWithEnforce;
      } else {
        return groupIsAlignedOnWrongAnswer
          ? wrongValueSelectTriggerStyles
          : correctValueSelectTriggerStylesWithoutEnforce;
      }
    }

    return defaultSelectTriggerStyles;
  }, [
    currentProfileSelectedOptions,
    groupIsAligned,
    groupIsAlignedOnWrongAnswer,
    sectionType,
    enforceMatchement,
  ]);

  const extraStyles: { trigger: React.CSSProperties } = useMemo(
    () => ({ trigger: triggerStyles }),
    [triggerStyles]
  );

  return (
    <Collapsible.Root
      className={cn(
        styles.container,
        !isOpen && "closed",
        isAlignedWithEnforce && "enforceAligned",
        isAlignedWithoutEnforce && "aligned",
        disabled && "disabled",
        groupIsAlignedOnCorrectAnswer && "correct",
        groupIsAlignedOnWrongAnswer && "wrong"
      )}
      open={isOpen}
      onOpenChange={onOpenChangeHandler}
    >
      <Collapsible.Trigger
        disabled={disabled}
        className={cn(
          styles.collapseTrigger,
          !disabled && isCollapsible && "is-collapsible"
        )}
      >
        <div className={styles.infoContainer}>
          <div className="text">
            {titleNode}
            {groupIsAlignedOnCorrectAnswer && (
              <>
                <span className="text bold">{` - ${
                  answers.find(
                    (a) => a.id === currentProfileSelectedOptions[sectionType]
                  )?.text
                }`}</span>
                {explanation && !isOpen && (
                  <p className={styles.explanation}>
                    <ContentfulRichField content={explanation} />
                  </p>
                )}
              </>
            )}
          </div>
          {isCollapsible && (
            <div
              className={cn(
                styles.collapsibleIconContainer,
                disabled && "disabled"
              )}
            >
              <ToggleChevron
                containerClass={styles.toggleChevronContainer}
                expanded={isOpen}
              />
            </div>
          )}

          {!disabled && (
            <div
              className={cn(
                styles.badge,
                isAlignedWithEnforce && "enforceAligned",
                isAlignedWithoutEnforce && "aligned",
                groupIsAlignedOnCorrectAnswer && "correct",
                groupIsAlignedOnWrongAnswer && "wrong",
                "text",
                "tiny",
                "bolder"
              )}
            >
              {badgeText}
            </div>
          )}
        </div>
      </Collapsible.Trigger>
      <Collapsible.Content className={styles.collapsibleContentContainer}>
        <div className={styles.collapsibleContentContainerContent}>
          <div className={styles.separator} />
          <div className={styles.section}>
            <div className={styles.cardContent}>
              <div className={styles.leftSection}>
                <div className={styles.header}>{leftHeaderNode}</div>
                <div className={styles.sectionContent}>
                  <div className="text">
                    {sectionLeftTextNode}{" "}
                    {description && (
                      <p className={styles.description}>
                        <ContentfulRichField content={description} />
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.rightSection}>
                <div className={styles.header}>{rightHeaderNode}</div>
                <div className={styles.sectionContent}>
                  <Select
                    type={SelectType.GROUPING}
                    disabled={
                      disabled || groupIsAlignedOnCorrectAnswer || isViewResults
                    }
                    label="Choose a value"
                    value={currentProfileSelectedOptions[sectionType]}
                    options={answers.map((answer) => ({
                      label: answer.text,
                      value: answer.id,
                      key: `${answer.id}-category`,
                    }))}
                    extraStyles={extraStyles}
                    labelNode={selectLabelNode}
                    className={selectClassNames}
                    onChange={(answerId) => {
                      handleSelection(answerId, sectionType);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

export default memo(GroupingCard);
