import { memo } from "react";
import { ConferenceMode } from "../../../types/contentful/enums";
import Badge from "../Badge/Badge";
import ContentfulRichField from "../ContentfulRichField/ContentfulRichField";
import cn from "classnames";

import styles from "./ActivityInstructions.module.css";

const activityInstructionsMapper = {
  [ConferenceMode.Solo]: "Solo Work",
  [ConferenceMode.All]: "Group Work",
};

const activityInstructionsClassNameMapper = {
  [ConferenceMode.Solo]: "solo-work",
  [ConferenceMode.All]: "group-work",
};

const badgeVariantMapper: Record<ConferenceMode, "primary" | "secondary"> = {
  [ConferenceMode.All]: "primary",
  [ConferenceMode.Solo]: "secondary",
};

interface ActivityInstructionsProps {
  instructions: string;
  type: ConferenceMode;
  tags: string[];
}

const ActivityInstructions = ({
  instructions,
  type,
  tags,
}: ActivityInstructionsProps) => {
  return instructions ? (
    <div
      className={cn(
        styles.container,
        activityInstructionsClassNameMapper[type]
      )}
    >
      <div className={styles.topLine}>
        <span className={styles.instructionsLabel}>Instructions:</span>
        <Badge
          text={activityInstructionsMapper[type]}
          variant={badgeVariantMapper[type]}
        />
        {tags &&
          tags.length !== 0 &&
          tags.map((tag) => (
            <Badge text={tag} variant={badgeVariantMapper[type]} />
          ))}
      </div>
      <ContentfulRichField
        content={instructions}
        className={styles.instructions}
      />
    </div>
  ) : null;
};

export default memo(ActivityInstructions);
