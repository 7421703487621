import { IInviteProfile } from "../../apollo-graphql/types/invite-profile";
import {
  ProfileUpdate,
  ProfileDelete,
  Profile,
} from "../../apollo-graphql/types/profile";
import { BulkInviteFormData } from "../../types/bulk-invite-from-data";
import { createAction, props } from "../utils";

export const fetchTeamMembers = createAction(
  "[Team Members] Fetching Team Members",
  props<{
    workspaceId: string;
    filters: {
      currentPage?: number;
      pageSize?: number;
      query?: string;
    };
    persisted?: Profile[];
  }>()
);

export const inviteOpen = createAction("[Team Members] Invite Open");

export const inviteSend = createAction(
  "[Team Members] Send",
  props<{ variables: IInviteProfile }>()
);

export const inviteClose = createAction("[Team Members] Invite Close");

export const editOpen = createAction(
  "[Team Members] Edit Open",
  props<{ id: string }>()
);

export const editSubmit = createAction(
  "[Team Members] Edit Team Member",
  props<{ variables: ProfileUpdate }>()
);

export const editClose = createAction("[Team Members] Edit Close");

export const deleteOpen = createAction(
  "[Team Members] Delete Open",
  props<{ teamMember: Profile }>()
);

export const deleteSubmit = createAction(
  "[Team Members] Delete Team Member",
  props<{ variables: ProfileDelete }>()
);

export const deleteClose = createAction("[Team Members] Delete Close");

export const bulkInviteDialogOpen = createAction(
  "[Team Members] Bulk Invite Dialog Open"
);

export const bulkInviteSubmit = createAction(
  "[Team Members] Bulk Invite Submit",
  props<{
    formData: BulkInviteFormData;
  }>()
);

export const bulkInviteSubmitConfirmation = createAction(
  "[Team Members] Bulk Invite Submit Confirmation",
  props<{ outcome: boolean | null }>()
);

export const bulkInviteDialogClose = createAction(
  "[Team Members] Bulk Invite Dialog Close"
);

export const getPresignedTeamMemberUrl = createAction(
  "[Team Members] Get Presigned Team Image Url",
  props<{ token: string; key: string }>()
);

export const uploadTeamMemberImage = createAction(
  "[Team Members] Upload Team Member Image",
  props<{ url: string; body: File | ReadableStream }>()
);

export const deleteTeamMemberImage = createAction(
  "[Team Members] Delete Team Member Image",
  props<{ key: string; token: string }>()
);
