import { PropsWithChildren, memo, useMemo } from "react";

import cn from "classnames";

import {
  calculateAhaMomentsCount,
  calculateBehavioursCount,
} from "../../Dashboard/Admin/Journey/utils";
import { calculateOffset, calculateArrowStyles } from "./utils";
import { getEntryId } from "../../../utils";

import { Workshop } from "../../../types/contentful/workshop/workshop";
import { SectionItemType } from "../../../types/contentful/workshop/summary";
import JourneyConversations, {
  JourneyConversation,
} from "./JourneyConversations/JourneyConversations";
import SkeletonLoader from "../../Shared/SkeletonLoader/SkeletonLoader";

import styles from "./JourneyInformation.module.css";

const loaderCount = 5;
const variableLoaderWidths = [200, 300, 270, 170, 250];
const loaderArray = new Array(loaderCount).fill(0);

interface JourneyProps {
  currentWorkshopId: string;
  journeyTitle: string;
  image: string | null;
  journeyItems: Workshop[] | null;
  profileId: string;
  isViewResults?: boolean;
  currentWorkshopTitle: string;
  isLoading?: boolean;
}

const JourneyInformation = (props: PropsWithChildren<JourneyProps>) => {
  const {
    currentWorkshopId,
    journeyItems,
    image,
    journeyTitle,
    isLoading,
    isViewResults = false,
  } = props;

  const journeyConversations: JourneyConversation[] = useMemo(() => {
    if (!journeyItems) return [];
    const currentWorkshopIndex = journeyItems.findIndex(
      (i) => getEntryId(i) === currentWorkshopId
    );

    return journeyItems.map((workshop, index) => {
      const {
        title,
        headline,
        welcomeText,
        isCompleted,
        icon,
        color,
        summary,
        link,
        completedDate,
        scheduleDate,
      } = workshop.fields;
      const workshopId = getEntryId(workshop);
      const ahaMomentsCount = calculateAhaMomentsCount([workshop]);
      const behavioursCount = calculateBehavioursCount([workshop]);
      const sectionItemTypes = (summary?.fields?.sections || [])
        ?.flatMap((s) => s?.fields.items?.map((i) => i?.fields?.type))
        .filter((type) => !!type && Object.values(SectionItemType).includes(type))
        .filter((x) => !!x) as SectionItemType[];

      const isCurrent = isViewResults
        ? index === currentWorkshopIndex + 1
        : index === currentWorkshopIndex;
      const isLocked =
        !isCompleted &&
        index >
          (isViewResults ? currentWorkshopIndex + 1 : currentWorkshopIndex);

      return {
        id: workshopId,
        title,
        headline: headline || welcomeText || "",
        isCurrent,
        isCompleted:
          !!isCompleted ||
          !!(isViewResults && workshopId === currentWorkshopId),
        isLocked,
        ahaMomentsCount,
        behavioursCount,
        sectionItemTypes,
        completedDate,
        scheduleDate,
        link,
        // image and color should be removed?
        image: icon?.fields?.file?.url,
        color: color,
      };
    });
  }, [journeyItems, currentWorkshopId, isViewResults]);

  const content = useMemo(() => {
    return !isLoading ? (
      <>
        {!isViewResults && (
          <>
            <h3 className={cn(styles.journeyTitle, "bold")}>{journeyTitle}</h3>
            {image && (
              <div className={styles.journeyCoverImageContainer}>
                <img src={image} alt={journeyTitle} />
              </div>
            )}
          </>
        )}
        {isViewResults && <div className="spacer" />}
        <JourneyConversations
          journeyConversations={journeyConversations}
          isViewResults={isViewResults}
        />
      </>
    ) : null;
  }, [image, isLoading, isViewResults, journeyTitle, journeyConversations]);

  const skeletonLoaderContent = useMemo(() => {
    return isLoading ? (
      <>
        <div className={cn(styles.journeyTitle, styles.loader)}>
          <SkeletonLoader
            width={200}
            height={24}
            borderRadius={12}
            className={styles.journeyTextSkeletonLoader}
          />
        </div>
        <div className={styles.journeyConversationsContainer}>
          {loaderArray.map((_, index) => {
            const conversationStyle = {
              marginLeft: calculateOffset(index, loaderCount),
            };

            const conversationArrowStyle = calculateArrowStyles(
              index,
              loaderCount,
              false
            );

            return (
              <div
                key={index}
                className={cn(styles.journeyConversation, styles.loading)}
                style={conversationStyle}
              >
                <div className={styles.journeyConversationCoverImageContainer}>
                  <SkeletonLoader
                    width={62}
                    height={62}
                    borderRadius={100}
                    className={styles.journeyImageSkeletonLoader}
                  />
                </div>
                <div className={styles.journeyConversationInfo}>
                  <SkeletonLoader
                    width={variableLoaderWidths[index]}
                    height={24}
                    borderRadius={12}
                    className={styles.journeyTextSkeletonLoader}
                  />
                </div>

                {index + 1 < loaderCount && (
                  <div
                    className={styles.journeyConversationArrow}
                    style={conversationArrowStyle}
                  />
                )}
              </div>
            );
          })}
        </div>
      </>
    ) : null;
  }, [isLoading]);

  return (
    <div className={styles.container}>
      {content}
      {skeletonLoaderContent}
    </div>
  );
};

export default memo(JourneyInformation);
