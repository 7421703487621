import {
  PropsWithChildren,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Navigate, useLocation } from "react-router-dom";

import * as Dialog from "@radix-ui/react-dialog";

import { useMachine } from "@xstate/react";
import {
  rescheduleMachine,
  RescheduleSlotState,
  RescheduleState,
} from "../../+xstate/machines/reschedule";
import {
  loadMemberSlots,
  openRescheduleSlot,
  closeRescheduleSlot,
  reschedule,
} from "../../+xstate/actions/reschedule";

import { getUnixTime } from "date-fns";
import { getTitle } from "../../utils";
import { getServerTime } from "../../helpers/fetch-server-time";

import { Slot } from "../../apollo-graphql/types/slot";
import { ApolloContext } from "../../contexts/Apollo";
import { GlobalContext } from "../../contexts/Global";
import { SlotType } from "../../apollo-graphql/types/enums/slot-type";
import { InvitationStatus } from "../../types/enums/invitation-status";
import { SlotStatus } from "../../apollo-graphql/types/enums";
import {
  WorkshopClock,
  WorkshopClockEvent,
} from "../../helpers/workshop-clock";

import Button3D from "../Shared/Buttons/Button3D/Button3D";
import Reschedule from "./Reschedule";
import Workshop from "./Workshop";

import cn from "classnames";
import styles from "./WaitingRoom.module.css";

const WaitingRoom = (
  props: PropsWithChildren<{
    slot: Slot;
    invitationId: string;
    invitationStatus: InvitationStatus;
    millisecondsToStart: number;
    splitMillisecondsWaitingTime: number;
    sessionOpeningTimeInMilliseconds: number;
    navigateToSlotInstance: (slotId: string) => void;
  }>
) => {
  const {
    slot,
    invitationId,
    invitationStatus,
    millisecondsToStart,
    splitMillisecondsWaitingTime,
    sessionOpeningTimeInMilliseconds,
    navigateToSlotInstance,
  } = props;

  const { client } = useContext(ApolloContext);
  const globalContext = useContext(GlobalContext);

  const { pathname } = useLocation();

  const [state, send] = useMachine(rescheduleMachine, { input: { client } });

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [splitRemainingTimeString, setSplitRemainingTimeString] = useState<
    string | null
  >(null);
  const internalTickCount = useRef<number>(0);
  const clockRef = useRef<WorkshopClock | null>(null);

  const rescheduleResult = state.context.rescheduleResult;
  const scheduledSlots = state.context.scheduledSlots;
  const profile = globalContext.auth.context.profile!;
  const showLoader = !state.matches({ dashboard: RescheduleSlotState.Ready });
  const showReschedule = !state.matches({ reschedule: RescheduleState.Idle });
  const isRescheduleLoadingSlots = state.matches({
    reschedule: RescheduleState.LoadingSlots,
  });

  // Note: If the current user is not invited to workshop we
  // disable the reschedule button
  const rescheduleButtonIsDisabled = useMemo(
    () => invitationStatus === InvitationStatus.AUTO_GENERATED,
    [invitationStatus]
  );

  const navigateToReschedule = useCallback(() => {
    send(
      loadMemberSlots({
        email: profile.email,
        workspaceId: profile.workspace.workspace_id,
      })
    );

    send(
      openRescheduleSlot({
        workshopId: slot.workshop_id,
        workspaceId: slot.workspace_id,
        slotId: slot.id,
      })
    );
  }, [
    profile.email,
    profile.workspace.workspace_id,
    send,
    slot.id,
    slot.workshop_id,
    slot.workspace_id,
  ]);

  const navigateToWorkshop = useCallback(() => {
    if (slot.schedule_date.valueOf() <= getUnixTime(new Date()))
      return navigateToSlotInstance(slot.id);
    send(closeRescheduleSlot());
  }, [navigateToSlotInstance, send, slot.schedule_date, slot.id]);

  const rescheduleHandler = useCallback(
    (invitationId: string, slot: Slot) => {
      if (rescheduleButtonIsDisabled) return;
      send(
        reschedule({
          newSlotId: slot.id,
          invitationId,
        })
      );
    },
    [send, rescheduleButtonIsDisabled]
  );

  const notificationJoinHandler = useCallback(() => {
    navigateToSlotInstance(slot.id);
    setShowDialog(false);
  }, [navigateToSlotInstance, slot.id]);

  const millisecondsToRedirect = useMemo(() => {
    return slot.type === SlotType.ALL
      ? millisecondsToStart - sessionOpeningTimeInMilliseconds
      : millisecondsToStart;
  }, [millisecondsToStart, sessionOpeningTimeInMilliseconds, slot.type]);

  const notificationModal = useMemo(() => {
    return (
      <Dialog.Root open={true}>
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className={cn(styles.dialogContent, "DialogContent")}>
            <h1>Workshop is starting!</h1>
            <div className="text">Do you want to join?</div>
            <div className={styles.dialogFooter}>
              <Button3D
                className="cancel"
                variant="success"
                onClick={() => setShowDialog(false)}
              >
                Cancel
              </Button3D>
              <Button3D
                className="submit"
                variant="success"
                onClick={notificationJoinHandler}
              >
                Join
              </Button3D>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  }, [notificationJoinHandler]);

  const content = useMemo(
    () =>
      showLoader || isRescheduleLoadingSlots ? (
        <div>Loading ...</div>
      ) : showReschedule ? (
        <Reschedule
          invitationId={invitationId}
          workshopTitle={getTitle(slot.workshop)}
          profileId={profile.id}
          scheduledSlots={scheduledSlots}
          rescheduleHandler={rescheduleHandler}
          navigateToWorkshop={navigateToWorkshop}
          hideGoBackButton={pathname.includes("reschedule")}
        />
      ) : slot ? (
        <Workshop
          workshopTitle={getTitle(slot.workshop)}
          millisecondsToStart={millisecondsToStart}
          workshopDate={
            slot.status === SlotStatus.CANCELLED || slot.status === SlotStatus.NOT_ENOUGH_PLAYERS
              ? slot.update_date
              : slot.schedule_date
          }
          invitations={slot.invitations}
          navigateToReschedule={navigateToReschedule}
          rescheduleButtonIsDisabled={rescheduleButtonIsDisabled}
          workshopStartTimeText={splitRemainingTimeString}
          status={slot.status}
        />
      ) : null,
    [
      showLoader,
      isRescheduleLoadingSlots,
      showReschedule,
      invitationId,
      slot,
      profile.id,
      scheduledSlots,
      rescheduleHandler,
      navigateToWorkshop,
      millisecondsToStart,
      navigateToReschedule,
      rescheduleButtonIsDisabled,
      splitRemainingTimeString,
      pathname,
    ]
  );

  useEffect(() => {
    if (pathname.includes("reschedule") && !rescheduleButtonIsDisabled) {
      navigateToReschedule();
    } else {
      send(
        loadMemberSlots({
          email: profile.email,
          workspaceId: profile.workspace.workspace_id,
        })
      );
    }
  }, [
    pathname,
    navigateToReschedule,
    profile.email,
    profile.workspace.workspace_id,
    send,
    rescheduleButtonIsDisabled,
  ]);

  useEffect(() => {
    const secondsToStart = millisecondsToRedirect / 1000;
    const completeCallback = () => {
      if (showReschedule) {
        setShowDialog(true);
        return;
      }
      setTimeout(() => navigateToSlotInstance(slot.id), 1000);
    };

    if (clockRef.current) return;

    clockRef.current = new WorkshopClock({ durationInSeconds: secondsToStart });

    clockRef.current.addEventListener(
      WorkshopClockEvent.TIMEOUT,
      completeCallback
    );

    clockRef.current.addEventListener(WorkshopClockEvent.TICK, () => {
      internalTickCount.current += 1;

      if (internalTickCount.current % 5 === 0)
        getServerTime().then((serverTime) => {
          clockRef.current?.adjustTime(serverTime);
        });

      if (
        slot.type !== SlotType.SPLIT ||
        clockRef.current!.workshopRemainingTimeInMilliseconds >
          splitMillisecondsWaitingTime
      )
        return;

      setSplitRemainingTimeString(
        clockRef.current!.workshopParsedTimeRemaining
      );
    });

    getServerTime().then((serverTime) => {
      clockRef.current?.start(serverTime);
    });

    return () => {
      clockRef.current?.dispose();
    };
  }, [
    millisecondsToRedirect,
    navigateToSlotInstance,
    showReschedule,
    slot.id,
    slot.type,
    splitMillisecondsWaitingTime,
  ]);

  if (rescheduleResult)
    return (
      <Navigate
        to={`/session/waiting-room/reschedule-redirect/${rescheduleResult.currentSlotId}/${rescheduleResult.newSlotId}`}
        replace={true}
      />
    );

  return (
    <>
      {showDialog && notificationModal}
      <div className={styles.container}>{content}</div>
    </>
  );
};

export default memo(WaitingRoom);
