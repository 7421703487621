import { gql } from "@apollo/client";
import {
  MyProfileResult,
  ProfileResult,
  ProfilesResult,
} from "../types/results";
import { AppApolloClient } from "../../contexts/Apollo";
import { Pagination } from "../../types/pagination";
import { JOURNEY_PARTICIPATION_DATA } from "../constants";
import { Profile } from "../types";

const MY_PROFILE_QUERY = `
  create_date
  email
  id
  headline
  hasRegisteredSuccessfully
  hasCompletedSetupSuccessfully
  is_completed
  login_date
  name
  update_date
  workspaceTags {
    id
    text
  }
  journeyCompleteness {
    journey {
      sys {
        id
      }
      fields {
        title
      }
    }
    percentage
    completedWorkshops {
      sys {
        id
      }
      fields {
        title
      }
    }
  }
  workspace {
    access
    profile_id
    status
    title
    workspace_id
    workspace {
      create_date
      id
      name
      availableJourneys {
        journey_id
      }
      update_date
      workspace_key
      theme_id
      theme {
        id
        name
      }
      certificates {
        id
        create_date
        update_date
        workspace_id
        journey_id
        profile_id
        is_valid
        last_invalidation_date
      }
    }
  }
`;

const PROFILE_QUERY = `
  create_date
  email
  headline
  id
  hasRegisteredSuccessfully
  hasCompletedSetupSuccessfully
  is_completed
  login_date
  name
  update_date
  workspaceTags {
    id
    text
  }
  workspace {
    access
    profile_id
    status
    title
    workspace {
      create_date
      id
      name
      update_date
      workspace_key
      certificates {
        id
        create_date
        update_date
        workspace_id
        journey_id
        profile_id
        is_valid
        last_invalidation_date
      }
    }
  }
`;

const getMyProfileQuery = gql`
  query GetMyProfile {
    getMyProfile {
      ${MY_PROFILE_QUERY}
    }
  }
`;

const setProfileAsCompletedQuery = gql`
  query SetProfileAsCompleted {
    markProfileAsCompleted {
      email
      id
      hasRegisteredSuccessfully
      hasCompletedSetupSuccessfully
      is_completed
      name
      update_date
    }
  }
`;

const getMyProfileJourneyParticipationDataQuery = gql`
  query GetMyProfile {
    getMyProfile {
      workspaceTags {
        id
        text
      }
      workspace {
        title
        workspace {
          availableJourneys {
            journey_id
          }
          journeyParticipationData ${JOURNEY_PARTICIPATION_DATA}
        }
      }
    }
  }
`;

export function getMyProfile(client: AppApolloClient) {
  return client
    .query<MyProfileResult>({
      query: getMyProfileQuery,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.getMyProfile;
    });
}

export function setProfileAsCompleted(client: AppApolloClient) {
  return client
    .query<{ SetProfileAsCompleted: Profile }>({
      query: setProfileAsCompletedQuery,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.SetProfileAsCompleted;
    });
}

export function getJourneyParticipationData(client: AppApolloClient) {
  return client
    .query<MyProfileResult>({
      query: getMyProfileJourneyParticipationDataQuery,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.getMyProfile;
    });
}

const getProfileQuery = gql`
  query GetProfile($id: String!) {
    getProfile(id: $id) {
      create_date
      email
      headline
      id
      hasRegisteredSuccessfully
      hasCompletedSetupSuccessfully
      is_completed
      name
      workspaceTags {
        id
        text
      }
      workspace {
        workspace_id
        access
        status
        title
      }
    }
  }
`;

export function getProfile(client: AppApolloClient, variables: { id: string }) {
  return client
    .query<ProfileResult>({
      query: getProfileQuery,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.getProfile;
    });
}

const getProfilesQuery = gql`
  query GetProfiles($data: GetProfilesInput) {
  getProfiles(data: $data) {
      nodes {
        ${PROFILE_QUERY}
      }
      pageInfo {
        total
      }
    }
  }
`;

export function getProfiles(
  client: AppApolloClient,
  data: {
    workspaceId: string;
    emails?: string[];
    ids?: string[];
    pagination?: Pagination;
    query?: string;
  }
) {
  return client
    .query<ProfilesResult>({
      query: getProfilesQuery,
      variables: { data },
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.getProfiles;
    });
}
