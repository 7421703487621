import { memo } from "react";
import ReactDatePicker, { DatePickerProps } from "react-datepicker";
import cn from "classnames";

import styles from "./DatePicker.module.css";
import "react-datepicker/dist/react-datepicker.css";

type Props = DatePickerProps & {
  classNames?: string;
};

const DatePicker = ({ value, classNames, ...props }: Props) => {
  return (
    <ReactDatePicker
      selected={value ? new Date(value) : undefined}
      dateFormat="MMM d, yyyy"
      icon={<i className="fa-regular fa-calendar-days"></i>}
      className={cn(styles.reactDatePickerWrapper, classNames)}
      calendarIconClassName={styles.iconClassName}
      clearButtonClassName={styles.clearIcon}
      clearButtonTitle="Clear"
      toggleCalendarOnIconClick
      showIcon
      {...props}
    />
  );
};

export default memo(DatePicker);
