import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { IValidateTokenResult } from "../types/results";
import { IValidateToken } from "../types/validate-token";

const query = gql`
  query ValidateUpdatePasswordToken($token: String!) {
    validateUpdatePasswordToken(token: $token) {
      success
      email
    }
  }
`;

export function validateUpdatePasswordToken(
  client: AppApolloClient,
  variables: IValidateToken
) {
  return client
    .query<IValidateTokenResult>({
      query,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data.validateUpdatePasswordToken;
    });
}
