import { Doc, AbstractConnector, applyUpdate } from "yjs";
import { yCollab } from "y-codemirror.next";
import {
  Awareness,
  encodeAwarenessUpdate,
  applyAwarenessUpdate,
} from "y-protocols/awareness";
import { EditorView, placeholder } from "@codemirror/view";
import { EditorState } from "@codemirror/state";
import { minimalSetup } from "codemirror";
import { cursorPalette } from "./constants";
import { randomIntFromInterval } from "../../../utils/random-int-from-interval";

export class CustomProvider extends AbstractConnector {
  static cursorColor: { color: string; light: string } | null = null;

  doc: Doc;
  awareness: Awareness;
  editor: EditorView;

  updateEventTarget = new EventTarget();
  latestDocumentValue: string | null = null;

  constructor(
    doc: Doc,
    awareness: Awareness,
    public textArea: HTMLTextAreaElement,
    profileName: string,
    isInput: boolean,
    characterLimit?: number,
    private placeholderValue = "",
    private styles?: {
      height: string;
    }
  ) {
    super(doc, awareness);
    this.doc = doc;
    this.awareness = awareness;

    CustomProvider.cursorColor =
      CustomProvider.cursorColor ||
      cursorPalette[randomIntFromInterval(0, cursorPalette.length - 1)];

    const profileConfig = {
      name: profileName,
      color: CustomProvider.cursorColor.color,
      colorLight: CustomProvider.cursorColor.light,
    };

    this.editor = this.setupEditor(
      textArea,
      profileConfig,
      isInput,
      characterLimit
    );

    this.awareness.on("update", this.handleAwarenessChange);
    this.doc.on("update", this.handleDocUpdate);
  }

  applyRemoteUpdate = (update: Uint8Array | number[]) => {
    update = update instanceof Uint8Array ? update : new Uint8Array(update);
    if (update.length > 0) applyUpdate(this.doc, update);
  };

  applyRemoteAwarenessUpdate = (awareness: Uint8Array | null) => {
    if (!awareness || awareness.length === 0) return;
    applyAwarenessUpdate(this.awareness, awareness, undefined);
  };

  setupEditor = (
    textArea: HTMLTextAreaElement,
    profileConfig: { name: string; color: string; colorLight: string },
    isInput: boolean,
    characterLimit?: number
  ) => {
    const yText = this.doc.getText("codemirror");

    const inputStyles = {
      width: "100%",
      height: "100%",
      background: " var(--Colors-Functional-Mono-white, #fff)",
      textAlign: "center",
      color: "var(--Colors-Text-text-primary, #292929)",
    };

    const textAreaStyles = {
      display: "flex",
      flexDirection: "column",
      "font-family": "Pally Variable",
      alignItems: isInput ? "center" : "flex-start",
      gap: "2px",
      flex: "1 0 0",
      alignSelf: "stretch",
      width: "100%",
      fontSize: "16px",
      background: "var(--Colors-Functional-white, #FFF)",
      boxShadow: "0px 0px 0px 3px transparent, 0px 0px 0px 6px transparent",
    };

    const borderStyles = {
      "&.is-transitioning": {
        borderRadius: "var(--br-12, 12px)",
        border: "2px solid var(--Colors-Cyan-400, #30C0E0)",
        background: "var(--Colors-Functional-Info-100, #F6FEFF)",
      },

      "&.ready-and-players-clicked": {
        borderRadius: "var(--br-12, 12px)",
        border: "2px dashed var(--Colors-Cyan-400, #30C0E0)",
        background: "var(--Colors-Functional-Info-100, #F6FEFF)",
      },

      "&.not-ready-and-players-clicked": {
        borderRadius: "var(--br-12, 12px)",
        border: "2px dashed var(--Colors-Neutral-gray-700, #797466)",
        background: "var(--Colors-Functional-white, #FFF)",
      },
    };

    const editorTheme = EditorView.theme({
      "&": {
        outline: "none !important",
        borderRadius: "var(--br-12, 12px)",
        ...(isInput ? inputStyles : textAreaStyles),
        "& .cm-scroller": {
          overflowX: "hidden !important",
          overflowY: "hidden !important",
          height: "100%",
          width: "100%",

          "& .cm-content": {
            padding: "16px",
            outline: "none",
            borderRadius: "var(--br-8, 8px)",
            border: "1.5px solid var(--Colors-Text-text-palest, #DCDCCC)",
            ...borderStyles,
          },

          "& .cm-ySelectionCaretDot": {
            opacity: 0,
          },

          "& .cm-ySelectionInfo": {
            paddingLeft: "10px",
            paddingRight: "10px",
            borderRadius: "12px",
            "font-family": "Pally Variable",
            opacity: 1,
          },
        },

        "&.cm-focused .cm-scroller": {
          borderRadius: "var(--br-8, 8px)",
          "box-shadow":
            "0px 0px 0px 3px #FFF, 0px 0px 0px 6px rgba(34, 181, 209, 0.25)",
        },

        "& .cm-placeholder": {
          color: "var(--Colors-Text-text-palest, #BEBEB3)",
        },
      },
    });

    this.awareness.setLocalStateField("user", profileConfig);

    const collabExt = yCollab(yText, this.awareness);

    const transactionFilter = EditorState.transactionFilter.of((tr) =>
      isInput && tr.newDoc.lines > 1 ? [] : tr
    );

    const transactionCharacterLimit = characterLimit
      ? EditorView.updateListener.of((update) => {
          if (update.docChanged) {
            const newText = update.state.doc.toString();

            if (newText.length <= characterLimit) {
              this.latestDocumentValue = newText;
              return;
            }
            if (!this.latestDocumentValue) return;

            update.view.dispatch({
              changes: {
                from: 0,
                to: newText.length,
                insert: this.latestDocumentValue,
              },
            });
          }
        })
      : [];

    const handlers = EditorView.domEventHandlers({
      blur: (event, view: EditorView) => {
        this.awareness.setLocalStateField("cursor", null);
      },
    });

    const state = EditorState.create({
      doc: yText.toString(),
      extensions: [
        minimalSetup,
        collabExt,
        handlers,
        transactionFilter,
        transactionCharacterLimit,
        EditorView.lineWrapping,
        editorTheme,
        placeholder(this.placeholderValue),
      ],
    });

    const view = new EditorView({
      state,
      parent: textArea.parentElement!,
    });

    textArea.parentNode!.insertBefore(view.dom, textArea);
    textArea.style.display = "none";

    if (textArea.form) {
      textArea.form.addEventListener("submit", () => {
        textArea.value = view.state.doc.toString().trim();
      });
    }

    return view;
  };

  private handleDocUpdate = (update: Uint8Array) => {
    const data = { type: "update", update };
    this.updateEventTarget.dispatchEvent(
      new CustomEvent("update", { detail: data })
    );
  };

  private handleAwarenessChange = ({
    added,
    updated,
    removed,
  }: {
    added: number[];
    updated: number[];
    removed: number[];
  }) => {
    // Always send the full state for now
    const awareness = encodeAwarenessUpdate(
      this.awareness,
      Array.from(this.awareness.getStates().keys())
    );

    // This sends partial update (keep here just in case)
    // const changedClients = added.concat(updated).concat(removed);

    // const awareness: null | Uint8Array = changedClients
    //   ? encodeAwarenessUpdate(this.awareness, changedClients)
    //   : null;

    const data = {
      type: "awareness",
      awareness,
    };

    this.updateEventTarget.dispatchEvent(
      new CustomEvent("update", { detail: data })
    );
  };

  updateEditorClass(newClass: string) {
    const content = this.editor.dom.querySelector(".cm-content");
    if (!content) return;

    content.classList.remove("is-transitioning");
    content.classList.remove("ready-and-players-clicked");
    content.classList.remove("not-ready-and-players-clicked");

    if (newClass.length === 0) return;

    content.classList.add(newClass);
  }

  destroy() {
    this.awareness.off("update", this.handleAwarenessChange);
    this.doc.off("update", this.handleDocUpdate);
    this.doc.destroy();
    this.awareness.destroy();
    this.editor.destroy();
    super.destroy();
  }
}
