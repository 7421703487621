import { memo, PropsWithChildren, useCallback, useMemo, useRef } from "react";

import {
  getEntryId,
  activityTypeFooterTextFactoryMap,
  getTransitionData,
} from "../../../../utils";
import { getGroupText } from "../utils/get-group-text";
import { getTransitionActionFooterData } from "../utils/get-transition-footer-data";

import {
  ConferenceMode,
  OutcomeMode,
} from "../../../../types/contentful/enums";
import { ActivityCommon } from "../../../../types/activity-common";
import { IActivityResult } from "../../../../apollo-graphql/types/session-state";
import { ACTIVITY_TIMEOUT_VALUE } from "../../../../constants/global";
import { normalConnectionThreshold } from "../../../../contexts/Apollo";
import { ActionFooterType } from "../../../../types/action-footer";
import { FooterType } from "../../../../types/enums/activity-footer";
import { WorkshopActivityType } from "../../../../types/enums/activity-type";
import { AnswerUnionType, AnswerType } from "../../../Shared/Answer/types";
import { RatingActivity } from "../../../../types/contentful/workshop/activities/rating";
import { observerFooterData } from "../constants";

import ActionFooter from "../../ActionFooter/ActionFooter";
import NextStepTransition from "../../NextStepTransition/NextStepTransition";
import Answer from "../../../Shared/Answer/Answer";
import ContentfulRichField from "../../../Shared/ContentfulRichField/ContentfulRichField";
import ActivityInstructions from "../../../Shared/ActivityInstructions/ActivityInstructions";

import cn from "classnames";
import styles from "./Rating.module.css";

interface IRatingProps extends ActivityCommon {
  profileId: string;
  currentActiveParticipants: string[];
  activityResultForCurrentProfile: IActivityResult["value"]["0"] | null;
  activityResults: IActivityResult["value"] | null;
  setActivityValueHandler: (args: {
    activityId: string;
    value: string;
  }) => void;
  isViewResults: boolean;
  allActivitiesResult?: IActivityResult[];
}

const Rating = (props: PropsWithChildren<IRatingProps>) => {
  const {
    activity: rawActivity,
    profileId,
    nextActivity,
    currentActiveParticipants,
    currentActiveParticipantCount,
    notReadyProfilesCount,
    transition,
    isReady,
    isLoading,
    isConnectionWeak,
    activityResultForCurrentProfile,
    activityResults,
    isViewResults,
    isActivityTimeout,
    isParticipating,
    hasAhaMoments,
    setActivityValueHandler,
    setActivityReadyHandler,
  } = props;

  const activity = rawActivity as RatingActivity;

  const selectedAnswerIdRef = useRef<string | null>(null);
  const actionFooterDataRef = useRef<ActionFooterType>();

  const questions = useMemo(
    () => activity.fields?.items || [],
    [activity?.fields?.items]
  );

  const currentActivityConferenceMode =
    activity.fields.activity.fields.conferenceMode;
  const isNextGoToDiscussion =
    currentActivityConferenceMode === ConferenceMode.Solo &&
    nextActivity?.fields.activity.fields.conferenceMode === ConferenceMode.All;

  const activityData = useMemo(
    () => ({
      id: getEntryId(activity),
    }),
    [activity]
  );

  const isTransitioning = useMemo(() => transition > 0, [transition]);

  const currentActivityResultsForOtherProfiles = useMemo(() => {
    return activityResults?.filter((d) => d.profileId !== profileId);
  }, [activityResults, profileId]);

  const answerIds = useMemo(
    () =>
      (activityResults || [])
        .filter(({ profileId }) =>
          currentActiveParticipants.includes(profileId)
        )
        .map(({ value }) => value),
    [currentActiveParticipants, activityResults]
  );

  const groupIsAligned = useMemo(
    () =>
      answerIds.length === currentActiveParticipantCount &&
      answerIds.every(
        (id: string) =>
          !!id && id !== ACTIVITY_TIMEOUT_VALUE && id === answerIds[0]
      ),
    [answerIds, currentActiveParticipantCount]
  );

  const isConsensusMode = useMemo(
    () => activity.fields.activity.fields.outcomeMode === OutcomeMode.Consensus,
    [activity]
  );

  const actionFooterData: ActionFooterType = useMemo(() => {
    if (!isParticipating) return observerFooterData;
    if (isLoading && actionFooterDataRef.current)
      return actionFooterDataRef.current;
    if (isTransitioning) {
      actionFooterDataRef.current = getTransitionActionFooterData({
        text: "Everyone is ready. Continuing forward...",
        buttonText: isNextGoToDiscussion ? "Go to discussion" : "Continue",
        disabledButton: true,
        type: FooterType.Ready,
        isActivityTimeout,
      });
      return actionFooterDataRef.current;
    }

    if (!isReady) {
      const playersClicked =
        currentActiveParticipantCount - notReadyProfilesCount;
      const playerHasAnswered =
        !!activityResultForCurrentProfile?.value &&
        activityResultForCurrentProfile?.value !== ACTIVITY_TIMEOUT_VALUE;

      actionFooterDataRef.current = {
        text: activityTypeFooterTextFactoryMap[
          WorkshopActivityType.RatingActivity
        ](playersClicked, isNextGoToDiscussion),
        buttonText: isNextGoToDiscussion ? "Go to discussion" : "Continue",
        disabledButton: false,
        type: FooterType.Notice,
      };

      if (!isConsensusMode) {
        actionFooterDataRef.current.disabledButton = !playerHasAnswered;
        actionFooterDataRef.current.text = playerHasAnswered
          ? actionFooterDataRef.current.text
          : activityTypeFooterTextFactoryMap[
              WorkshopActivityType.RatingActivity
            ](playersClicked, isNextGoToDiscussion);
      }

      if (isConsensusMode) {
        actionFooterDataRef.current.disabledButton =
          currentActiveParticipantCount === 1
            ? !groupIsAligned || !playerHasAnswered
            : !groupIsAligned;

        actionFooterDataRef.current.text = getGroupText(
          currentActiveParticipantCount === 1
            ? playerHasAnswered
            : groupIsAligned,
          playersClicked,
          playerHasAnswered
        );
      }

      return actionFooterDataRef.current;
    }

    actionFooterDataRef.current = {
      text: (
        <>
          Waiting for{" "}
          <span className="accent">
            {notReadyProfilesCount} more player
            {notReadyProfilesCount > 1 && "s"}...
          </span>
        </>
      ),
      buttonText: !isConsensusMode ? "Go to discussion" : "Continue",
      disabledButton: true,
      type: FooterType.Waiting,
    };
    return actionFooterDataRef.current;
  }, [
    isParticipating,
    isLoading,
    isTransitioning,
    isReady,
    notReadyProfilesCount,
    isConsensusMode,
    isActivityTimeout,
    currentActiveParticipantCount,
    activityResultForCurrentProfile?.value,
    isNextGoToDiscussion,
    groupIsAligned,
  ]);

  const getGroupProfileIdsSelectedAnswer = useCallback(
    (answerId: string) => {
      if (!isConsensusMode) {
        return [];
      }
      const answerData = currentActivityResultsForOtherProfiles || [];
      return answerData
        .filter(
          ({ value, profileId }) =>
            value === answerId && currentActiveParticipants.includes(profileId)
        )
        .map(({ profileId }) => profileId);
    },
    [
      currentActiveParticipants,
      isConsensusMode,
      currentActivityResultsForOtherProfiles,
    ]
  );

  const onSelectAnswerHandler = useCallback(
    (answerId: string) => {
      if (isLoading) return;
      selectedAnswerIdRef.current = answerId;

      setActivityValueHandler({
        activityId: activityData.id,
        value:
          activityResultForCurrentProfile?.value === answerId
            ? ACTIVITY_TIMEOUT_VALUE
            : answerId,
      });
    },
    [
      activityData.id,
      activityResultForCurrentProfile?.value,
      isLoading,
      setActivityValueHandler,
    ]
  );

  const nextActivityData = useMemo(
    () => getTransitionData(nextActivity, hasAhaMoments),
    [nextActivity, hasAhaMoments]
  );

  const delayInMilliseconds = useMemo(
    () => (isConnectionWeak ? 0 : normalConnectionThreshold),
    [isConnectionWeak]
  );

  const answerProps: AnswerUnionType = useMemo(
    () => ({
      type: AnswerType.STATIC,
      content: null,
      expanded: false,
      onExpandCollapseHandler: () => {},
    }),
    []
  );

  // NOTE: FUTURE IMPROVEMENTS
  // We need to extend this logic to maintain multiple questions with many answers.
  // The result value must be an object { [questionId] : [answerId] }
  // Maybe we need to have pagination to switch between the questions

  return (
    <>
      <div key={activityData.id} className="activity-container">
        <div
          className={cn(
            styles.container,
            "main-container",
            isViewResults && styles.viewResultsContainer
          )}
        >
          <ActivityInstructions
            instructions={activity.fields.activity.fields.instructions}
            tags={activity.fields.activity.fields.tags}
            type={currentActivityConferenceMode}
          />
          <div className="details">
            {questions.map((item) => {
              const questionId = getEntryId(item);
              const questionTitleAsHtml = item?.fields?.text;
              const answers = item?.fields?.scale?.fields?.items || [];

              return (
                <div key={questionId} className={styles.answersDataContainer}>
                  <div>
                    <ContentfulRichField
                      content={questionTitleAsHtml}
                      className={cn(styles.instructions, "text")}
                    />
                  </div>
                  <div className={cn(styles.answersContainer, "horizontal")}>
                    {answers.map((answer) => {
                      const answerId = getEntryId(answer);
                      const answerLabel = answer?.fields?.label;

                      const loading =
                        isLoading && selectedAnswerIdRef.current === answerId;
                      const currentProfileHasSelectedAnswer =
                        activityResultForCurrentProfile?.value === answerId;
                      const isSelected =
                        currentProfileHasSelectedAnswer && !loading;

                      if (isConsensusMode) {
                        const otherProfileSelectedAnswerIds =
                          getGroupProfileIdsSelectedAnswer(answerId);
                        const otherProfileHasSelectedAnswer =
                          otherProfileSelectedAnswerIds.length > 0;
                        const hasGroupSelectedAnswer =
                          groupIsAligned && otherProfileHasSelectedAnswer;

                        const showIcon =
                          !loading &&
                          (otherProfileHasSelectedAnswer ||
                            currentProfileHasSelectedAnswer);
                        const iconClass = hasGroupSelectedAnswer
                          ? "fa-users"
                          : "fa-user";

                        return (
                          <Answer
                            key={answerId + "-group"}
                            isLoading={loading}
                            isSelected={isSelected}
                            othersSelected={!isSelected && showIcon}
                            groupSelected={hasGroupSelectedAnswer}
                            isTransitioning={isTransitioning}
                            onSelectAnswerHandler={() =>
                              onSelectAnswerHandler(answerId)
                            }
                            iconClass={iconClass}
                            showIcon={showIcon}
                            answerTitle={answerLabel}
                            delayInMilliseconds={delayInMilliseconds}
                            isHorizontal
                            {...answerProps}
                          />
                        );
                      }

                      return (
                        <Answer
                          key={answerId + "-individual"}
                          isLoading={loading}
                          isSelected={isSelected}
                          isTransitioning={isTransitioning}
                          onSelectAnswerHandler={() =>
                            onSelectAnswerHandler(answerId)
                          }
                          iconClass="fa-user"
                          showIcon={isSelected}
                          answerTitle={answerLabel}
                          delayInMilliseconds={delayInMilliseconds}
                          isHorizontal
                          {...answerProps}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {!isViewResults && isTransitioning && (
          <NextStepTransition
            nextStep={nextActivityData.transitionText}
            sessionType={nextActivityData.conferenceMode}
            isActivityTimeout={isActivityTimeout}
            transition={transition}
          />
        )}
      </div>
      {!isViewResults && (
        <ActionFooter
          buttonText={actionFooterData.buttonText}
          type={actionFooterData.type}
          disabledButton={actionFooterData.disabledButton}
          isLoading={isLoading}
          isConnectionWeak={isConnectionWeak}
          buttonClickHandler={() => {
            setActivityReadyHandler({ activityId: activityData.id });
          }}
        >
          {actionFooterData.text}
        </ActionFooter>
      )}
    </>
  );
};

export default memo(Rating);
