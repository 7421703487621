import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { GetSlotsResult } from "../types/results/get-slots-result";
import { IGetSlots } from "../types/slot";
import { SlotStatus } from "../types/enums";
import { SortDirection } from "../../types/enums/sort-direction";

const ACTIVITY_QUERY = `{
  sys {
    id
  }
  fields {
    title
    activity {
     fields {
      duration
     }
    }
  }
}`;

const WORKSHOP_QUERY = `
  sys {
   id
  }
  fields {
  title
  activities {
      ... on BrainstormingActivity ${ACTIVITY_QUERY}
      ... on ClosedQuestionActivity ${ACTIVITY_QUERY}
      ... on ComparisonActivity ${ACTIVITY_QUERY}
      ... on ConceptualisationActivity ${ACTIVITY_QUERY}
      ... on FilteringActivity ${ACTIVITY_QUERY}
      ... on GroupingActivity ${ACTIVITY_QUERY}
      ... on OpenQuestion ${ACTIVITY_QUERY}
      ... on TranslationActivity ${ACTIVITY_QUERY}
      ... on PresentationActivity ${ACTIVITY_QUERY}
      ... on VotingActivity ${ACTIVITY_QUERY}
      ... on RatingActivity ${ACTIVITY_QUERY}
      ... on SelfDirectedDiscussionActivity ${ACTIVITY_QUERY}
    }
  }
`;

const getSlotsForEmailQuery = gql`
  query GetSlots($workspaceId: String!, $emails: [String]) {
    getSlots(workspace_id: $workspaceId, emails: $emails) {
      id
      key
      type
      status
      schedule_date
      workshop_id
      workspace_id
      workshop {
        ${WORKSHOP_QUERY}
      }
    }
  }
`;

export function getSlotsForEmails(
  client: AppApolloClient,
  variables: {
    workspaceId: string;
    emails: string[];
  }
) {
  return client
    .query<GetSlotsResult>({
      query: getSlotsForEmailQuery,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data.getSlots;
    });
}

const getSlotsForWorkshopIdQuery = gql`
  query GetSlots(
    $workspaceId: String!
    $workshopId: String
    $statuses: [SlotStatus]
    $sortDirection: SortDirection
  ) {
    getSlots(
      workspace_id: $workspaceId
      workshop_id: $workshopId
      statuses: $statuses
      sortDirection: $sortDirection
    ) {
      id
      key
      type
      status
      schedule_date
      workshop_id
      workspace_id
      workshop {
        fields {
          title
        }
      }
      invitations {
        id
        status
        email
        session_group
        profile {
          id
          name
          email
          workspace {
            workspace_id
          }
        }
      }
    }
  }
`;

export function getSlotsForWorkshopId(
  client: AppApolloClient,
  variables: {
    workspaceId: string;
    workshopId: string;
    statuses: SlotStatus[];
    sortDirection: SortDirection;
  }
) {
  return client
    .query<GetSlotsResult>({
      query: getSlotsForWorkshopIdQuery,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data.getSlots;
    });
}

const getSlotsQuery = gql`
  query GetSlots(
    $workspaceId: String!
    $statuses: [SlotStatus]
    $sortDirection: SortDirection
    $emails: [String]
    $autoGenerateFilters: AutoGenerateFilters
  ) {
    getSlots(
      workspace_id: $workspaceId
      sortDirection: $sortDirection
      statuses: $statuses
      emails: $emails
      autoGenerateFilters: $autoGenerateFilters
    ) {
      id
      key
      type
      status
      create_date
      schedule_date
      invitations {
        email
        status
        slot_id
        session_group
        profile {
          id
          name
          email
          workspace {
            workspace_id
          }
        }
      }
      sessions {
        id
        create_date
        update_date
        complete_date
        session_key
        state
        status
        slot_id
        workshop_id
        workspace_id
      }
      workshop_id
      workspace_id
      workshop {
        ${WORKSHOP_QUERY}
      }
    }
  }
`;

export function getSlots(client: AppApolloClient, variables: IGetSlots) {
  return client
    .query<GetSlotsResult>({
      query: getSlotsQuery,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data.getSlots;
    });
}
