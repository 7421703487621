import { isDevelopmentEnv } from "./environment";

export const ACTIVITY_TIMEOUT_VALUE = "<TIMEOUT_NO_VALUE>";
export const SORT_DIRECTION_QUERY_KEY = "sortDirection";
export const SPLIT_KEY_REG_EX =
  /^(\d+):([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})$/i;

const isDevEnv = window.location.host.startsWith("l5") || isDevelopmentEnv;

export const DASHBOARD_DISABLED_ADMIN = true;
export const DASHBOARD_DISABLED_MEMBER = true;
export const CONVERSATION_DISABLED_MEMBER = true;
export const JOURNEYS_DISABLED = true;
export const SCHEDULE_DISABLED = false;
export const TEAM_MEMBERS_DISABLED = false;
export const MILESTONES_DISABLED = true;
export const BREADCRUMBS_DISABLED = true;
export const SCHEDULE_WORKSHOPS_DISABLED = !isDevEnv;
export const BULK_SCHEDULE_WORKSHOPS_DISABLED = false;
export const MASTER_DOMAIN = "app.ahaplay.com";
