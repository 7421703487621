import {
  forwardRef,
  memo,
  ReactNode,
  useCallback,
  useId,
  useRef,
  useState,
} from "react";

import cn from "classnames";
import * as Ariakit from "@ariakit/react";
import { CompositeInput } from "@ariakit/react-core/composite/composite-input";

import ToggleChevron from "../../../ToggleChevron/ToggleChevron";

import styles from "./TagInput.module.css";

interface TagInputProps extends Omit<Ariakit.ComboboxProps, "onChange"> {
  label: string;
  value?: string;
  onChange?: (value: string) => void;
  defaultValue?: string;
  values?: string[];
  onValuesChange?: (values: string[]) => void;
  defaultValues?: string[];
}

interface TagInputItemProps extends Ariakit.ComboboxItemProps {
  children?: ReactNode;
}

export const TagInput = memo(
  forwardRef<HTMLInputElement, TagInputProps>(function TagInput(props, ref) {
    const {
      label,
      defaultValue,
      value,
      onChange,
      defaultValues = [],
      values,
      onValuesChange,
      children,
      ...comboboxProps
    } = props;

    const comboboxRef = useRef<HTMLInputElement>(null);
    const defaultComboboxId = useId();
    const comboboxId = comboboxProps.id || defaultComboboxId;

    const combobox = Ariakit.useComboboxStore<string[]>({
      value,
      defaultValue,
      setValue: onChange,
      selectedValue: values,
      setSelectedValue: onValuesChange,
      defaultSelectedValue: defaultValues,
      resetValueOnHide: true,
    });
    const selectedValues = combobox.useState("selectedValue");
    const [isExpanded, setIsExpanded] = useState(combobox.getState().open);

    const toggleValueFromSelectedValues = useCallback(
      (value: string) => {
        combobox.setSelectedValue((prevSelectedValues) => {
          const index = prevSelectedValues.indexOf(value);
          if (index !== -1) {
            return prevSelectedValues.filter((v) => v !== value);
          }
          return [...prevSelectedValues, value];
        });
      },
      [combobox]
    );

    const onItemClick = useCallback(
      (value: string) => {
        if (comboboxProps.disabled) return;
        toggleValueFromSelectedValues(value);
      },
      [comboboxProps.disabled, toggleValueFromSelectedValues]
    );

    const onItemKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === "Backspace" || event.key === "Delete") {
          event.currentTarget.click();
        }
      },
      []
    );

    const onInputKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== "Backspace") return;
        const { selectionStart, selectionEnd } = event.currentTarget;
        const isCaretAtTheBeginning =
          selectionStart === 0 && selectionEnd === 0;
        if (!isCaretAtTheBeginning) return;
        combobox.setSelectedValue((values) => {
          if (!values.length) return values;
          return values.slice(0, values.length - 1);
        });
        combobox.hide();
      },
      [combobox]
    );

    const onExpandHandler = useCallback(() => {
      comboboxRef.current?.focus();
      setIsExpanded(combobox.getState().open);
    }, [combobox]);

    const onPopoverCloseHandler = useCallback(() => {
      setIsExpanded(false);
    }, []);

    return (
      <Ariakit.CompositeProvider defaultActiveId={comboboxId}>
        <Ariakit.Composite
          role="grid"
          aria-label={label}
          className={cn(styles.tagGrid, {
            [styles.tagGridDisabled]: comboboxProps.disabled,
          })}
          onClick={onExpandHandler}
        >
          <Ariakit.CompositeRow role="row" className={styles.tagRow}>
            {selectedValues.map((value) => (
              <Ariakit.CompositeItem
                key={value}
                role="gridcell"
                className={styles.tag}
                onClick={() => onItemClick(value)}
                onKeyDown={onItemKeyDown}
                onFocus={combobox.hide}
              >
                {value}
                {!comboboxProps.disabled && <i className="fa fa-xmark" />}
              </Ariakit.CompositeItem>
            ))}
            {!comboboxProps.disabled && (
              <div role="gridcell" className={styles.gridCell}>
                <Ariakit.CompositeItem
                  id={comboboxId}
                  className={cn(styles.comboBox, comboboxProps.className)}
                  render={
                    <CompositeInput
                      ref={comboboxRef}
                      onKeyDown={onInputKeyDown}
                      render={
                        <Ariakit.Combobox
                          ref={ref}
                          autoSelect
                          store={combobox}
                          {...comboboxProps}
                        />
                      }
                    />
                  }
                />
              </div>
            )}
            <Ariakit.ComboboxPopover
              store={combobox}
              portal
              sameWidth
              gutter={8}
              className={styles.popover}
              onClose={onPopoverCloseHandler}
            >
              {children}
            </Ariakit.ComboboxPopover>
          </Ariakit.CompositeRow>
          {!comboboxProps.disabled && <ToggleChevron expanded={isExpanded} />}
        </Ariakit.Composite>
      </Ariakit.CompositeProvider>
    );
  })
);

export const TagInputItem = memo(
  forwardRef<HTMLDivElement, TagInputItemProps>(function TagInputItem(
    props,
    ref
  ) {
    return (
      <Ariakit.ComboboxItem
        ref={ref}
        {...props}
        className={cn(styles.comboBoxItem, props.className)}
      >
        <Ariakit.ComboboxItemCheck />
        {props.children || props.value}
      </Ariakit.ComboboxItem>
    );
  })
);
