import { memo } from "react";
import cn from "classnames";

import styles from "./Badge.module.css";

interface BadgeProps {
  text: string;
  variant: "primary" | "secondary";
}

const Badge = ({ text, variant }: BadgeProps) => {
  return (
    <span
      className={cn(styles.container, {
        [styles.primary]: variant === "primary",
        [styles.secondary]: variant === "secondary",
      })}
    >
      {text}
    </span>
  );
};

export default memo(Badge);
