import { memo } from "react";
import useCopyFromClipboard from "../../hooks/useCopyFromClipboard";
import cn from "classnames";

import styles from "./CopyToClipboardButton.module.css";

interface CopyToClipboardButtonProps {
  copyText: string;
  displayText: string;
  className: string;
  hasIcon?: boolean;
}

const CopyToClipboardButton = ({
  copyText,
  displayText,
  className,
  hasIcon = true,
}: CopyToClipboardButtonProps) => {
  const { copyHandler, isCopied } = useCopyFromClipboard({
    copyText,
    durationInMilliseconds: 1000,
  });

  return (
    <button
      className={cn("btn", styles.button, className)}
      onClick={copyHandler}
    >
      {hasIcon && <i className="icon fa fa-clone" />}
      <span className={styles.ellipsis}>
        {isCopied ? "Copied" : displayText}
      </span>
    </button>
  );
};

export default memo(CopyToClipboardButton);
