import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { SESSION_CONTEXT_RESULT_QUERY } from "../constants";
import { SessionStateValue } from "../types/session-state";

const mutation = gql`
  mutation SetActivityValue($sessionId: String!, $activityId: String!, $value: String!, $markAsReady: Boolean) {
    setActivityValue(sessionId: $sessionId, activityId: $activityId, value: $value, markAsReady: $markAsReady) {
      context ${SESSION_CONTEXT_RESULT_QUERY}
    }
  }
`;

export function setActivityValue(
  client: AppApolloClient,
  variables: {
    sessionId: string;
    activityId: string;
    value: string;
    markAsReady?: boolean;
  }
) {
  return client
    .mutate<{ disconnect: SessionStateValue }>({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.disconnect;
    });
}
