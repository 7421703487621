import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";

import cn from "classnames";
import { GlobalContext } from "../../contexts/Global";
import { TechnicalSetupHelpOutcome } from "../../+xstate/machines/auth";

import Button3D from "../../components/Shared/Buttons/Button3D/Button3D";
import SkeletonLoader from "../../components/Shared/SkeletonLoader/SkeletonLoader";
import withRouteConfig from "../../hocs/withRouteConfig";
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import Copyright from "../../components/Copyright/Copyright";
import { VideoPreview } from "../../components/VideoPreview/VideoPreview";

import styles from "./TechnicalSetup.module.css";

export default memo(
  withRouteConfig(function TechnicalSetup() {
    const videoElementRef = useRef<HTMLVideoElement | null>(null);
    const {
      auth: {
        context: {
          profile,
          availableAudioDevices,
          availableVideoDevices,
          selectedAudioDevice,
          selectedVideoDevice,
          technicalSetupHelpOutcome,
          connectionSuccess,
        },
        technicalSetupInit,
        technicalSetupHelp,
        configureAudio,
        configureVideo,
        saveDeviceSetup,
        technicalSetupClear,
      },
    } = useContext(GlobalContext);

    const workspaceName = useMemo(
      () => profile?.workspace?.workspace?.name || "",
      [profile?.workspace?.workspace?.name]
    );

    const profileId = useMemo(() => profile!.id, [profile]);
    const hasCompletedSetupSuccessfully =
      profile?.hasCompletedSetupSuccessfully;
    const isLoading =
      availableAudioDevices === null ||
      availableVideoDevices === null ||
      !connectionSuccess;

    useEffect(() => {
      if (!videoElementRef.current) return;
      technicalSetupInit({
        profileId,
        htmlVideoElement: videoElementRef.current,
      });
    }, [profileId, technicalSetupInit]);

    const continueHandler = useCallback(() => {
      saveDeviceSetup({ selectedAudioDevice, selectedVideoDevice });
    }, [saveDeviceSetup, selectedAudioDevice, selectedVideoDevice]);

    const audioDeviceChangeHandler = useCallback(
      (newSourceId: string) => {
        const newDevice = availableAudioDevices?.find(
          (d) => d.sourceId === newSourceId
        );
        if (!newDevice) return;
        configureAudio({
          selectedAudioSourceData: newDevice,
          availableAudioSources: availableAudioDevices!,
        });
      },
      [availableAudioDevices, configureAudio]
    );

    const toggleAudioHandler = useCallback(() => {
      if (!selectedAudioDevice) return;
      configureAudio({
        selectedAudioSourceData: {
          ...selectedAudioDevice,
          isMuted: !selectedAudioDevice.isMuted,
        },
        availableAudioSources: availableAudioDevices!,
      });
    }, [availableAudioDevices, configureAudio, selectedAudioDevice]);

    const videoDeviceChangeHandler = useCallback(
      (newSourceId: string) => {
        const newDevice = availableVideoDevices?.find(
          (d) => d.sourceId === newSourceId
        );
        if (!newDevice) return;
        configureVideo({
          selectedVideoSourceData: newDevice,
          availableVideoSources: availableVideoDevices!,
        });
      },
      [availableVideoDevices, configureVideo]
    );

    const toggleVideoHandler = useCallback(() => {
      if (!selectedVideoDevice) return;
      configureVideo({
        selectedVideoSourceData: {
          ...selectedVideoDevice,
          isMuted: !selectedVideoDevice.isMuted,
        },
        availableVideoSources: availableVideoDevices!,
      });
    }, [availableVideoDevices, configureVideo, selectedVideoDevice]);

    const technicalSetupHelpHandler = useCallback(() => {
      technicalSetupHelp();
    }, [technicalSetupHelp]);

    const content = useMemo(() => {
      return (
        <>
          <div className={cn(styles.loaderContent, !isLoading && "hidden")}>
            <SkeletonLoader className={styles.loaderVideo} borderRadius={16} />
            <SkeletonLoader
              className={styles.loaderActions}
              width={120}
              height={32}
              baseColor="#f9f9f9"
            />
          </div>

          <VideoPreview
            className={cn(styles.videoContent, isLoading && "hidden")}
            width={390}
            height={390}
            ref={videoElementRef}
            profileId={profileId}
            isLoading={false}
            selectedAudioDevice={selectedAudioDevice}
            selectedVideoDevice={selectedVideoDevice}
            hideConfigurations={false}
            audioAndVideoConfigureDone={false}
            availableAudioSources={availableAudioDevices}
            availableVideoSources={availableVideoDevices}
            audioDeviceChangeHandler={audioDeviceChangeHandler}
            videoDeviceChangeHandler={videoDeviceChangeHandler}
            toggleAudioHandler={toggleAudioHandler}
            toggleVideoHandler={toggleVideoHandler}
          />
        </>
      );
    }, [
      audioDeviceChangeHandler,
      availableAudioDevices,
      availableVideoDevices,
      isLoading,
      profileId,
      selectedAudioDevice,
      selectedVideoDevice,
      toggleAudioHandler,
      toggleVideoHandler,
      videoDeviceChangeHandler,
    ]);

    const actionsContent = useMemo(() => {
      return isLoading ? (
        <SkeletonLoader
          className={styles.actionsBtnLoader}
          width={390}
          height={55}
          borderRadius={16}
        />
      ) : (
        <div className={styles.actionContainer}>
          <Button3D
            className={styles.continueButton}
            disabled={isLoading}
            variant="success"
            onClick={continueHandler}
            isLoading={isLoading}
          >
            Continue
          </Button3D>
        </div>
      );
    }, [isLoading, continueHandler]);

    const technicalSetupContent = useMemo(() => {
      return (
        <div className="text">
          <p>Experiencing issues with the setup?</p>
          <p
            className={styles.technicalSetupAction}
            onClick={technicalSetupHelpHandler}
          >
            Ask for help!
          </p>
        </div>
      );
    }, [technicalSetupHelpHandler]);

    const technicalSetupHelpRequestDescription = useMemo(
      () =>
        !technicalSetupHelpOutcome
          ? null
          : technicalSetupHelpOutcome === TechnicalSetupHelpOutcome.Success
          ? "Your admin will come back to you asap!"
          : "There was an issue sending your help request. Please try again or contact your workspace administrator.",

      [technicalSetupHelpOutcome]
    );

    const technicalSetupHelpRequestHandler = useCallback(() => {
      technicalSetupClear();
    }, [technicalSetupClear]);

    return (
      <>
        {technicalSetupHelpRequestDescription && (
          <ConfirmationDialog
            title="Technical setup help request"
            description={technicalSetupHelpRequestDescription}
            confirmationHandler={technicalSetupHelpRequestHandler}
          />
        )}
        <div className={styles.container}>
          <div className={styles.inner}>
            <div className={styles.heading}>
              <h2>
                {!profile?.hasCompletedSetupSuccessfully && (
                  <>
                    Step 2 <span className="faded">(of 2)</span>{" "}
                  </>
                )}
                <p>Technical setup</p>
              </h2>
            </div>
            <div className={styles.setupTextContainer}>
              {hasCompletedSetupSuccessfully && (
                <p className={cn(styles.newDeviceText, "text", "bold")}>
                  <i className="fa fa-triangle-exclamation" />
                  You’re again here, because you’re using a new browser or
                  device!
                </p>
              )}
              <div className="text">
                <p>
                  <span className="bold">
                    Enable your microphone and camera
                  </span>{" "}
                  to test your technical setup before you can continue to your{" "}
                  <span className="bold">{workspaceName}'s</span> workspace at
                  AhaPlay.
                </p>
              </div>
            </div>
            <div className={styles.content}>{content}</div>
            {actionsContent}
            {technicalSetupContent}
          </div>
          <Copyright />
        </div>
      </>
    );
  })
);
