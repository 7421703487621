import { NetworkError } from "@apollo/client/errors";
import { GraphQLFormattedError } from "graphql";

export enum ApolloServerErrorCodes {
  // build in error codes
  GRAPHQL_PARSE_FAILED = "GRAPHQL_PARSE_FAILED",
  GRAPHQL_VALIDATION_FAILED = "GRAPHQL_VALIDATION_FAILED",
  BAD_USER_INPUT = "BAD_USER_INPUT",
  PERSISTED_QUERY_NOT_FOUND = "PERSISTED_QUERY_NOT_FOUND",
  PERSISTED_QUERY_NOT_SUPPORTED = "PERSISTED_QUERY_NOT_SUPPORTED",
  OPERATION_RESOLUTION_FAILURE = "OPERATION_RESOLUTION_FAILURE",
  BAD_REQUEST = "BAD_REQUEST",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",

  // custom error codes
  SERVER_ERROR = "SERVER_ERROR",
  TOKEN_EXPIRED = "TOKEN_EXPIRED",
  INTENDED_RETRY = "INTENDED_RETRY",
}

export interface ApolloServerError {
  graphQLErrors: (GraphQLFormattedError & { code: ApolloServerErrorCodes })[];
  networkError: NetworkError;
}
