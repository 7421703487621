import {
  ChangeEvent,
  FormEvent,
  PropsWithChildren,
  memo,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from "react";

import * as Form from "@radix-ui/react-form";

import { mappedErrorMessages } from "../../../constants/mapped-error-messages";
import { Profile, ProfileUpdate } from "../../../apollo-graphql/types/profile";
import { AuthState, ProfileSettings } from "../../../+xstate/machines/auth";
import { GlobalContext } from "../../../contexts/Global";
import { FetchState } from "../../../+xstate/machines/fetch-factory";

import UserImage from "../../Shared/UserImage/UserImage";
import Dialog from "../../Shared/Dialog/Dialog";
import LoadingButton from "../../Shared/Buttons/LoadingButton/LoadingButton";

import styles from "./EditProfileModal.module.css";

// For testing purpose
const showDeleteProfileButton = false;

const EditProfileModal = (
  props: PropsWithChildren<{
    profile: Profile;
    updateProfile: (payload: { variables: ProfileUpdate }) => void;
    closeDialogHandler: () => void;
    showChangePasswordDialog: () => void;
  }>
) => {
  const {
    profile,
    updateProfile,
    closeDialogHandler,
    showChangePasswordDialog,
  } = props;

  const {
    auth: {
      matches,
      uploadProfileImage,
      deleteProfileImage,
      updateProfileMachineState,
    },
  } = useContext(GlobalContext);

  const fileUploadRef = useRef<HTMLInputElement | null>(null);
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);

  const isLoading = updateProfileMachineState.value === FetchState.Fetching;

  const isFileUploading = matches({
    [AuthState.Authenticated]: {
      [ProfileSettings.UpdateProfile]:
        ProfileSettings.UpdateProfile_UploadingProfileImage,
    },
  });
  const isFileDeleting = matches({
    [AuthState.Authenticated]: {
      [ProfileSettings.UpdateProfile]:
        ProfileSettings.UpdateProfile_DeleteProfileImage,
    },
  });

  const errorMessage = useMemo(() => {
    const message = updateProfileMachineState.context.error?.message;
    if (!message) return null;

    return mappedErrorMessages[message as keyof typeof mappedErrorMessages];
  }, [updateProfileMachineState.context.error?.message]);

  const onFileInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      const file: File | null = files && files[0];
      if (!file) return;
      uploadProfileImage({ file });
    },
    [uploadProfileImage]
  );

  const onSubmitHandler = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const payload = Object.fromEntries(new FormData(event.currentTarget)) as {
        name: string;
      };

      const variables = {
        id: profile.id,
        name: payload.name.trim(),
      };

      // Validations: Only the name field is required
      if (variables.name.length === 0) return;

      updateProfile({ variables });
    },
    [profile, updateProfile]
  );

  const deleteImageHandler = useCallback(() => {
    deleteProfileImage();
  }, [deleteProfileImage]);

  const actionsContent = useMemo(() => {
    return (
      <>
        <div className={styles.actions}>
          <button
            className="btn ghost"
            type="button"
            onClick={showChangePasswordDialog}
          >
            Change password
          </button>

          <LoadingButton
            onClick={() => submitButtonRef.current?.click()}
            isLoading={isLoading}
            type="submit"
          >
            Save
          </LoadingButton>
        </div>
      </>
    );
  }, [isLoading, showChangePasswordDialog]);

  const dialogContent = useMemo(() => {
    return (
      <div className={styles.content}>
        <div className="user-container">
          <UserImage
            isPublic={false}
            profileId={profile.id}
            profileWorkspaceId={profile.workspace.workspace_id}
            showLoader={isFileUploading || isFileDeleting}
            containerClass={styles.imageContainer}
            fallbackFontAwesomeIconClass="fa fa-user"
            alt="user-profile"
          />
          <label className="upload-container">
            {(isFileUploading || isFileDeleting) && (
              <i className="fa fa-circle-o-notch fa-spin" />
            )}
            Upload picture
            <input
              type="file"
              disabled={isFileUploading}
              ref={fileUploadRef}
              accept="image/*"
              onChange={onFileInputChange}
            />
          </label>
          {showDeleteProfileButton && (
            <button onClick={deleteImageHandler}>Delete picture</button>
          )}
        </div>

        <div className="user-info-container">
          <Form.Root className="form-container" onSubmit={onSubmitHandler}>
            <Form.Field name="name" className="form-row">
              <Form.Label className="label">Name</Form.Label>
              <Form.Control
                required
                type="text"
                className="FormControl"
                defaultValue={profile?.name}
              />
              <Form.Message className="FormMessage" match="valueMissing">
                Please enter your name
              </Form.Message>
            </Form.Field>
            <Form.Field name="email" className="form-row">
              <Form.Label className="label disabled">Email</Form.Label>
              <Form.Control
                disabled
                className="FormControl"
                type="email"
                value={profile?.email}
              />
            </Form.Field>
            <Form.Field name="jobTitle" className="form-row">
              <Form.Label className="label disabled">Job Title</Form.Label>
              <Form.Control
                disabled
                className="FormControl"
                type="text"
                value={profile?.workspace?.title}
              />
            </Form.Field>

            <div className="submit-hidden">
              <button ref={submitButtonRef} type="submit" />
            </div>
          </Form.Root>
        </div>
      </div>
    );
  }, [
    profile.id,
    profile.workspace.workspace_id,
    profile.workspace?.title,
    profile?.name,
    profile?.email,
    isFileUploading,
    isFileDeleting,
    onFileInputChange,
    deleteImageHandler,
    onSubmitHandler,
  ]);

  return (
    <Dialog
      open
      heading="Edit Profile"
      errorMessage={errorMessage}
      onClose={closeDialogHandler}
      actionsContent={actionsContent}
    >
      {dialogContent}
    </Dialog>
  );
};

export default memo(EditProfileModal);
