import {
  PropsWithChildren,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cn from "classnames";
import { ConnectionStrength } from "../../types/enums/connection-strength";

import Tooltip from "../Shared/Tooltip/Tooltip";

import styles from "./InternetConnectionStrength.module.css";

const strengthIconMap = {
  [ConnectionStrength.Unknown]: "/icons/bad-connection.svg",
  [ConnectionStrength.High]: "/icons/good-connection.svg",
  [ConnectionStrength.Normal]: "/icons/good-connection.svg",
  [ConnectionStrength.Slow]: "/icons/poor-connection.svg",
};

const connectionStatusMessage = {
  [ConnectionStrength.Unknown]: "No internet connection.",
  [ConnectionStrength.High]: "Your internet connection is good.",
  [ConnectionStrength.Normal]: "Your internet connection is unstable.",
  [ConnectionStrength.Slow]: "Your internet connection is poor.",
};

const InternetConnectionStrength = (
  props: PropsWithChildren<{ strength: ConnectionStrength }>
) => {
  const { strength } = props;

  const [showTooltip, setShowTooltip] = useState(false);
  const timeoutIdRef = useRef<number | null>(null);

  const connectionStrengthMessage = useMemo(() => {
    return connectionStatusMessage[strength];
  }, [strength]);

  const imageSrc = useMemo(() => strengthIconMap[strength], [strength]);

  useEffect(() => {
    if (
      timeoutIdRef.current &&
      strength !== ConnectionStrength.Slow &&
      strength !== ConnectionStrength.Unknown
    ) {
      clearTimeout(timeoutIdRef.current);
      setShowTooltip(false);
      return;
    }

    timeoutIdRef.current = setTimeout(() => {
      setShowTooltip(true);
    }, 10_000) as unknown as number;

    return () => {
      if (!timeoutIdRef.current) return;
      clearTimeout(timeoutIdRef.current);
    };
  }, [showTooltip, strength]);

  return (
    <div
      className={cn(styles.container, { [styles.showTooltip]: showTooltip })}
    >
      <Tooltip
        description={connectionStrengthMessage}
        containerClass={styles.tooltipContainer}
        delayDuration={0}
        withClose
        withBorder
      >
        <img src={imageSrc} alt="internet connection strength" />
      </Tooltip>
    </div>
  );
};

export default memo(InternetConnectionStrength);
