import {
  PropsWithChildren,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as Collapsible from "@radix-ui/react-collapsible";

import { IComparisonItemOption } from "../../types";
import { ACTIVITY_TIMEOUT_VALUE } from "../../../../../../constants/global";

import CircledIcon from "../../../../../Shared/CircledIcon/CircledIcon";
import ToggleChevron from "../../../../../Shared/ToggleChevron/ToggleChevron";

import styles from "./ComparisonCard.module.css";
import cn from "classnames";

const BADGE_ALIGNED = "Aligned";
const BADGE_PENDING_ALIGNMENT = "Pending Alignment";
const BADGE_PENDING_ANSWER = "Pending Answer";

interface ComparisonCardProps {
  sectionType: string;
  leftHeaderNode: ReactNode;
  rightHeaderNode: ReactNode;
  optionTitleNode: ReactNode;
  options: IComparisonItemOption[];
  sectionLeftTextNode: ReactNode;
  sectionRightText: string;
  currentProfileSelectedOptions: { [key: string]: string | null };
  otherProfileSelectedOptions: {
    profileId: string;
    value: { [key: string]: string | null };
  }[];
  titleNode: ReactNode;
  currentActiveParticipants: string[];
  profileId: string;
  isParticipating: boolean;
  isCollapsible?: boolean;
  shouldBeCollapsed?: boolean;
  hasBorderBottom?: boolean;
  disabled: boolean;
  handleSelection: (optionId: string, sectionType: string) => void;
}

const ComparisonCard = (props: PropsWithChildren<ComparisonCardProps>) => {
  const {
    sectionType,
    leftHeaderNode,
    rightHeaderNode,
    optionTitleNode,
    options,
    sectionLeftTextNode,
    sectionRightText,
    currentProfileSelectedOptions,
    otherProfileSelectedOptions,
    titleNode,
    profileId,
    currentActiveParticipants,
    isParticipating,
    hasBorderBottom = false,
    shouldBeCollapsed = false,
    isCollapsible = false,
    disabled,
    handleSelection,
  } = props;

  const [isOpen, setIsOpen] = useState(shouldBeCollapsed || !isCollapsible);

  useEffect(() => {
    if (isOpen === shouldBeCollapsed) return;
    setIsOpen(shouldBeCollapsed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldBeCollapsed]);

  const allParticipantsHaveAnswered = useMemo(
    () =>
      currentActiveParticipants
        .filter((d) => d !== profileId)
        .every((participant) => {
          const participantAnswer = otherProfileSelectedOptions.find(
            (res) => res.profileId === participant
          )?.value;

          return (
            !!participantAnswer &&
            participantAnswer[sectionType] &&
            participantAnswer[sectionType] !== ACTIVITY_TIMEOUT_VALUE
          );
        }),
    [
      currentActiveParticipants,
      otherProfileSelectedOptions,
      profileId,
      sectionType,
    ]
  );

  const currentUserHasAnswered = useMemo(
    () =>
      !isParticipating
        ? allParticipantsHaveAnswered
        : !!currentProfileSelectedOptions[sectionType] &&
          currentProfileSelectedOptions[sectionType] !== ACTIVITY_TIMEOUT_VALUE,

    [
      allParticipantsHaveAnswered,
      currentProfileSelectedOptions,
      isParticipating,
      sectionType,
    ]
  );

  const groupIsAligned = useMemo(() => {
    if (currentActiveParticipants.length === 1) return !!currentUserHasAnswered;
    const otherProfileAnswers = otherProfileSelectedOptions.map((d) => d.value);
    const currentAnswer = otherProfileAnswers?.[0]?.[sectionType];

    if (!currentAnswer || currentAnswer === ACTIVITY_TIMEOUT_VALUE)
      return false;

    return (
      allParticipantsHaveAnswered &&
      [
        isParticipating ? currentProfileSelectedOptions : null,
        ...otherProfileAnswers,
      ]
        .filter(Boolean)
        .every((res) => res![sectionType] === currentAnswer)
    );
  }, [
    allParticipantsHaveAnswered,
    currentActiveParticipants.length,
    currentProfileSelectedOptions,
    isParticipating,
    otherProfileSelectedOptions,
    sectionType,
    currentUserHasAnswered,
  ]);

  const badgeText = useMemo(
    () =>
      currentUserHasAnswered && groupIsAligned
        ? BADGE_ALIGNED
        : currentUserHasAnswered
        ? BADGE_PENDING_ALIGNMENT
        : BADGE_PENDING_ANSWER,
    [currentUserHasAnswered, groupIsAligned]
  );
  const isAligned = useMemo(() => badgeText === BADGE_ALIGNED, [badgeText]);

  const checkIfGroupAnswer = useCallback(
    (id: string) => {
      return [
        ...otherProfileSelectedOptions.map((res) => res.value),
        currentProfileSelectedOptions,
      ].every((res) => {
        return res[sectionType] === id;
      });
    },
    [currentProfileSelectedOptions, otherProfileSelectedOptions, sectionType]
  );

  const onOpenChangeHandler = useCallback(() => {
    if (!isCollapsible) return;
    setIsOpen((prev) => !prev);
  }, [isCollapsible, setIsOpen]);

  return (
    <Collapsible.Root
      className={cn(
        styles.container,
        !isOpen && "closed",
        isAligned && "aligned",
        disabled && "disabled"
      )}
      open={isOpen}
      onOpenChange={onOpenChangeHandler}
    >
      <Collapsible.Trigger
        disabled={disabled}
        className={cn(
          styles.collapseTrigger,
          !disabled && isCollapsible && "is-collapsible"
        )}
      >
        <div className={styles.infoContainer}>
          <div className="text">{titleNode}</div>
          {isCollapsible && (
            <div
              className={cn(
                styles.collapsibleIconContainer,
                disabled && "disabled"
              )}
            >
              <ToggleChevron
                containerClass={styles.toggleChevronContainer}
                expanded={isOpen}
              />
            </div>
          )}
          {!disabled && (
            <div
              className={cn(
                styles.badge,
                isAligned && "aligned",
                "text",
                "tiny",
                "bolder"
              )}
            >
              {badgeText}
            </div>
          )}
        </div>
      </Collapsible.Trigger>
      <Collapsible.Content className={styles.collapsibleContentContainer}>
        <div className={styles.collapsibleContentContainerContent}>
          <div className={styles.separator} />
          <div className={styles.section}>
            <div className={styles.cardContent}>
              <div className={styles.leftSection}>
                <div className={styles.header}>{leftHeaderNode}</div>
                <div className={styles.sectionContent}>
                  <div className="text">{sectionLeftTextNode}</div>
                </div>
              </div>
              <div className={styles.rightSection}>
                <div className={styles.header}>{rightHeaderNode}</div>
                <div className={styles.sectionContent}>
                  <p className="text description">{sectionRightText}</p>
                </div>
              </div>
            </div>
            <div className={cn(styles.cardFooter, hasBorderBottom && "line")}>
              <div className={styles.optionTitle}>{optionTitleNode}</div>
              <div className={styles.optionsContainer}>
                {options.map(({ id, text }) => {
                  const hasOwnSelection =
                    currentProfileSelectedOptions[sectionType] === id;
                  const hasTeamSelection = otherProfileSelectedOptions.find(
                    (res) => res.value[sectionType] === id
                  );
                  const isGroupSelection =
                    !!otherProfileSelectedOptions.length &&
                    groupIsAligned &&
                    checkIfGroupAnswer(id);
                  const iconClasses =
                    (hasOwnSelection || hasTeamSelection) && !isGroupSelection
                      ? cn("icon", "fa", "fa-user")
                      : isGroupSelection
                      ? cn("icon", "fa", "fa-users")
                      : null;
                  return (
                    <button
                      className={cn(
                        styles.option,
                        "comparison-button-option",
                        hasOwnSelection && "selected",
                        !hasOwnSelection &&
                          hasTeamSelection &&
                          "other-selected",
                        isGroupSelection && "group-selected",
                        groupIsAligned && "disabled"
                      )}
                      disabled={groupIsAligned}
                      key={id}
                      onClick={() =>
                        handleSelection(
                          hasOwnSelection ? ACTIVITY_TIMEOUT_VALUE : id,
                          sectionType
                        )
                      }
                    >
                      <span className="text bold">{text}</span>
                      {iconClasses && (
                        <CircledIcon
                          iconClass={iconClasses}
                          isSelected={hasOwnSelection || isGroupSelection}
                          className="icon"
                        />
                      )}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

export default memo(ComparisonCard);
