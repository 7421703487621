import * as Sentry from "@sentry/react";
import { env, isDevelopmentEnv, isTestingEnv } from "./constants/environment";
import { apolloUri } from "./constants/endpoints";

Sentry.init({
  environment: env,
  beforeSend(event) {
    return isDevelopmentEnv || isTestingEnv ? null : event;
  },
  beforeSendTransaction(event) {
    return isDevelopmentEnv || isTestingEnv ? null : event;
  },
  dsn: "https://6fc3e7ce2713e2c93dab0658f6041a63@o4504128828538880.ingest.us.sentry.io/4508037402394624",
  integrations: [
    Sentry.captureConsoleIntegration(),
    Sentry.extraErrorDataIntegration(),
    Sentry.httpClientIntegration(),
    Sentry.replayIntegration({
      stickySession: true,
      networkDetailAllowUrls: [apolloUri],
      networkCaptureBodies: true,
      networkRequestHeaders: [apolloUri],
      networkResponseHeaders: [apolloUri],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
