import { PropsWithChildren, memo, useCallback, useMemo } from "react";

import { diff } from "deep-diff";

import { useReadyRemovedDueToChange } from "../../../../hooks/useReadyRemovedDueToChange";
import { getEntryId, getTransitionData, parseToJson } from "../../../../utils";
import { getTransitionActionFooterData } from "../utils/get-transition-footer-data";

import {
  IDragAndDropCard,
  ISection,
  SectionTypes,
} from "../../../Shared/DragAndDrop/types";
import {
  FilteringActivity,
  FilteringActivityPredefinedItem,
} from "../../../../types/contentful/workshop/activities/filtering";
import { ActivityCommon } from "../../../../types/activity-common";
import { ActionFooterType } from "../../../../types/action-footer";
import { FooterType } from "../../../../types/enums/activity-footer";
import { IActivityResult } from "../../../../apollo-graphql/types/session-state";
import { WorkshopActivityType } from "../../../../types/enums/activity-type";
import { ACTIVITY_TIMEOUT_VALUE } from "../../../../constants/global";
import { observerFooterData } from "../constants";

import ScrollIndicator from "../../../Shared/ScrollIndicator/ScrollIndicator";
import NextStepTransition from "../../NextStepTransition/NextStepTransition";
import ActionFooter from "../../ActionFooter/ActionFooter";
import DragAndDrop from "../../../Shared/DragAndDrop/DragAndDrop";
import InfoBox from "../../../InfoBox/InfoBox";
import ActivityInstructions from "../../../Shared/ActivityInstructions/ActivityInstructions";

import styles from "./Filtering.module.css";
import cn from "classnames";

interface FilteringProps extends ActivityCommon {
  activityResults: IActivityResult["value"] | null;
  profileId: string;
  isViewResults?: boolean;
  getActivityResult: (activityId: string) => IActivityResult["value"];
  setActivityValueHandler: (args: {
    activityId: string;
    value: string;
  }) => void;
}

const Filtering = (props: PropsWithChildren<FilteringProps>) => {
  const {
    activity,
    nextActivity,
    transition,
    isReady,
    isLoading,
    isConnectionWeak,
    notReadyProfilesCount,
    currentActiveParticipantCount,
    isViewResults,
    profileId,
    activityResults,
    isActivityTimeout,
    isParticipating,
    hasAhaMoments,
    getActivityResult,
    setActivityValueHandler,
    setActivityReadyHandler,
  } = props;

  const { changeStateText, userValueChangeHandler } =
    useReadyRemovedDueToChange(isReady);

  const activityData = useMemo(() => {
    const filteringActivity = activity as FilteringActivity;
    return {
      id: getEntryId(filteringActivity),
      originalListEmptyInfo: filteringActivity?.fields?.originalListEmptyInfo,
      originalListLabel: filteringActivity?.fields?.originalListLabel,
      originalPredefinedItems:
        filteringActivity?.fields?.originalPredefinedItems,
      targetPredefinedItems: filteringActivity?.fields?.targetPredefinedItems,
      targetListEmptyInfo: filteringActivity?.fields?.targetListEmptyInfo,
      targetListLabel: filteringActivity?.fields?.targetListLabel,
      enforceMatchement: filteringActivity?.fields?.enforceMatchement,
      showDescriptionOfItems: filteringActivity?.fields?.showDescriptionOfItems,
      referenceActivity: filteringActivity?.fields?.referenceActivity,
      referenceActivityId:
        filteringActivity?.fields?.referenceActivity?.sys?.contentType?.sys?.id,
      activityForOriginalItemsId:
        filteringActivity?.fields?.activityForOriginalItems?.sys?.id,
    };
  }, [activity]);

  const nextActivityData = useMemo(
    () => getTransitionData(nextActivity, hasAhaMoments),
    [nextActivity, hasAhaMoments]
  );

  const isTransitioning = useMemo(() => transition > 0, [transition]);

  const referenceActivityResult = useMemo(() => {
    if (!activityData.referenceActivityId) return [];
    const activityResults = getActivityResult(activityData.referenceActivityId);

    const results = activityResults?.flatMap((r) => {
      if (!r.value || r.value === ACTIVITY_TIMEOUT_VALUE) return [];

      return parseToJson<IDragAndDropCard[]>(r.value, []);
    });

    return results
      .sort((a, b) => b.timestamp! - a.timestamp!)
      .map((item, idx, arr) => ({
        ...item,
        isDraggable: true,
        title: `${arr.length - idx}. ${item.title}`,
      }));
  }, [activityData.referenceActivityId, getActivityResult]);

  const activityForOriginalItemsResult = useMemo(() => {
    if (!activityData.activityForOriginalItemsId) return [];
    const activityResults = getActivityResult(
      activityData.activityForOriginalItemsId
    );

    const results = activityResults?.flatMap((r) => {
      if (!r.value || r.value === ACTIVITY_TIMEOUT_VALUE) return [];

      return parseToJson<IDragAndDropCard[]>(r.value, []);
    });

    return results
      .sort((a, b) => b.timestamp! - a.timestamp!)
      .map((item, idx, arr) => ({
        ...item,
        isDraggable: true,
        title: `${arr.length - idx}. ${item.title}`,
      }));
  }, [activityData.activityForOriginalItemsId, getActivityResult]);

  const showDescriptions = !!activityData.showDescriptionOfItems;

  const applyReferenceActivityUpdatedToValue: (d: ISection) => ISection =
    useCallback(
      (currParsedData: ISection) => {
        if (!activityData.referenceActivity) return currParsedData;
        if (
          activityData.referenceActivityId ===
          WorkshopActivityType.FilteringActivity
        ) {
          const listItemsHaveDescriptions = currParsedData[
            SectionTypes.SECTION_A
          ].some((item) => item.text && item.title);
          const descriptionVisibilityDiscrepancy =
            listItemsHaveDescriptions !== showDescriptions;
          if (descriptionVisibilityDiscrepancy) {
            const idTextArr =
              activityData.referenceActivity.fields.originalPredefinedItems.map(
                (item) => ({ id: item.sys.id, title: item.fields.title, text: item.fields.description })
              );
            const updateItemTextValue = (item: IDragAndDropCard) => ({
              ...item,
              text:
                idTextArr.find((idText) => idText.id === item.id)?.text || "",
              title:
                idTextArr.find((idText) => idText.id === item.id)?.title || "",
            });
            currParsedData[SectionTypes.SECTION_A] =
              currParsedData[SectionTypes.SECTION_A].map(updateItemTextValue);
            currParsedData[SectionTypes.SECTION_B] =
              currParsedData[SectionTypes.SECTION_B].map(updateItemTextValue);
          }
        }
        return currParsedData;
      },
      [
        activityData.referenceActivity,
        activityData.referenceActivityId,
        showDescriptions,
      ]
    );

  const predefinedItemToDragAndDropCard = useCallback(
    (item: FilteringActivityPredefinedItem) =>
      ({
        id: getEntryId(item),
        profileId: "",
        text: showDescriptions
          ? item.fields.description || ""
          : item.fields.title,
        title: showDescriptions ? item.fields.title : undefined,
        isDraggable: true,
      } as IDragAndDropCard),
    [showDescriptions]
  );

  const parsedResult: ISection = useMemo(() => {
    const rawActivityResult = activityResults?.[0]?.value || null;
    const activityHasNoResultValue =
      !rawActivityResult || rawActivityResult === ACTIVITY_TIMEOUT_VALUE;

    if (activityHasNoResultValue) {
      if (referenceActivityResult.length) {
        return {
          [SectionTypes.SECTION_A]: referenceActivityResult,
          [SectionTypes.SECTION_B]: [],
        };
      }

      const originalPredefinedItemCards: IDragAndDropCard[] =
        (
          activityData.originalPredefinedItems ||
          activityData.referenceActivity?.fields.originalPredefinedItems
        )?.map(predefinedItemToDragAndDropCard) ||
        activityForOriginalItemsResult;

      const targetPredefinedItemCards: IDragAndDropCard[] = (
        activityData.targetPredefinedItems ||
        activityData.referenceActivity?.fields.targetPredefinedItems
      )?.map(predefinedItemToDragAndDropCard);

      return {
        [SectionTypes.SECTION_A]: originalPredefinedItemCards || [],
        [SectionTypes.SECTION_B]: targetPredefinedItemCards || [],
      };
    }

    const parsedData = parseToJson<ISection>(rawActivityResult, {
      [SectionTypes.SECTION_A]: [],
      [SectionTypes.SECTION_B]: [],
    });

    if (activityData.referenceActivity) {
      applyReferenceActivityUpdatedToValue(parsedData);
    }

    return {
      [SectionTypes.SECTION_A]: parsedData[SectionTypes.SECTION_A],
      [SectionTypes.SECTION_B]: parsedData[SectionTypes.SECTION_B],
    };
  }, [
    activityData,
    activityResults,
    referenceActivityResult,
    activityForOriginalItemsResult,
    predefinedItemToDragAndDropCard,
    applyReferenceActivityUpdatedToValue,
  ]);

  const readyToContinue = useMemo(() => {
    if (!activityData.enforceMatchement) return true;

    const {
      referenceActivity,
      referenceActivityId,
      originalPredefinedItems,
      targetPredefinedItems,
    } = activityData;
    const hasFilteringInfo =
      originalPredefinedItems?.some((d) => d.fields.toBeFiltered) ||
      targetPredefinedItems?.some((d) => d.fields.toBeFiltered) ||
      (referenceActivityId === WorkshopActivityType.FilteringActivity &&
        (referenceActivity?.fields?.originalPredefinedItems.some(
          (d) => d.fields.toBeFiltered
        ) ||
          referenceActivity?.fields?.targetPredefinedItems.some(
            (d) => d.fields.toBeFiltered
          )));
    if (!hasFilteringInfo) return true;

    const itemsForOriginalList = (
      targetPredefinedItems ||
      referenceActivity.fields.targetPredefinedItems ||
      []
    )
      .filter((d) => d.fields.toBeFiltered)
      .map((d) => d.sys.id);
    const currentOriginalListIds = parsedResult[SectionTypes.SECTION_A].map(
      (d) => d.id
    );
    const originalListIsOkay = itemsForOriginalList.every((id) =>
      currentOriginalListIds.includes(id)
    );

    const itemsForTargetList = (
      originalPredefinedItems ||
      referenceActivity.fields.originalPredefinedItems ||
      []
    )
      .filter((d) => d.fields.toBeFiltered)
      .map((d) => d.sys.id);

    const currentTargetListIds = parsedResult[SectionTypes.SECTION_B].map(
      (d) => d.id
    );
    const targetListIsOkay = itemsForTargetList.every((id) =>
      currentTargetListIds.includes(id)
    );

    return originalListIsOkay && targetListIsOkay;
  }, [activityData, parsedResult]);

  const actionFooterData: ActionFooterType = useMemo(() => {
    if (!isParticipating) return observerFooterData;
    if (isTransitioning) {
      return getTransitionActionFooterData({
        text: <>Everyone is ready! Continuing forward...</>,
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Ready,
        isActivityTimeout,
      });
    }
    if (isReady) {
      return {
        text: (
          <>
            Waiting for{" "}
            <span className="accent">
              {notReadyProfilesCount} more player
              {notReadyProfilesCount > 1 && "s"}...
            </span>
          </>
        ),
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Waiting,
      };
    }

    const playersClicked =
      currentActiveParticipantCount - notReadyProfilesCount;

    const baseText = activityData.enforceMatchement
      ? "Filter the listed items correctly to continue."
      : "Click “Continue” when ready!";
    return {
      text: (
        <>
          {changeStateText}
          {baseText}{" "}
          {playersClicked > 0 && (
            <span className="accent">
              {playersClicked} player{playersClicked > 1 && "s"} clicked!
            </span>
          )}
        </>
      ),
      buttonText: "Continue",
      disabledButton: !readyToContinue || !isParticipating,
      type: FooterType.Notice,
    };
  }, [
    activityData.enforceMatchement,
    isReady,
    isTransitioning,
    isParticipating,
    isActivityTimeout,
    currentActiveParticipantCount,
    notReadyProfilesCount,
    changeStateText,
    readyToContinue,
  ]);

  const setGroupValueHandler = useCallback(
    (updatedSection: ISection) => {
      if (isViewResults || !isParticipating) return;
      const sectionAChanges = diff(
        parsedResult[SectionTypes.SECTION_A],
        updatedSection.sectionA
      );
      const sectionBChanges = diff(
        parsedResult[SectionTypes.SECTION_B],
        updatedSection.sectionB
      );

      if (!sectionAChanges?.length && !sectionBChanges?.length) return;

      const updatedGroupingValue: ISection = {
        [SectionTypes.SECTION_A]: updatedSection[SectionTypes.SECTION_A],
        [SectionTypes.SECTION_B]: updatedSection[SectionTypes.SECTION_B],
      };

      setActivityValueHandler({
        activityId: activityData.id,
        value: JSON.stringify(updatedGroupingValue),
      });
      userValueChangeHandler();
    },
    [
      isViewResults,
      isParticipating,
      parsedResult,
      activityData.id,
      setActivityValueHandler,
      userValueChangeHandler,
    ]
  );

  const headerContent = useMemo(() => {
    const html = activity?.fields?.activity?.fields?.instructions;
    const conferenceMode = activity?.fields.activity.fields.conferenceMode;
    const tags = activity?.fields?.activity?.fields?.tags;

    return (
      <ActivityInstructions
        instructions={html}
        tags={tags}
        type={conferenceMode}
      />
    );
  }, [
    activity?.fields.activity.fields.conferenceMode,
    activity?.fields.activity.fields?.instructions,
    activity?.fields.activity.fields?.tags,
  ]);

  return (
    <>
      <div
        key={activityData.id}
        className={cn(styles.activityContainer, "activity-container")}
      >
        <ScrollIndicator className={cn(styles.container, "main-container")}>
          <div className={cn(styles.content, isViewResults && "view-results")}>
            {headerContent}
            <DragAndDrop
              sectionA={parsedResult[SectionTypes.SECTION_A]}
              sectionB={parsedResult[SectionTypes.SECTION_B]}
              sectionATitle={activityData.originalListLabel}
              sectionBTitle={activityData.targetListLabel}
              currentProfileId={profileId}
              isTransitioning={isTransitioning}
              isLoading={isLoading}
              setValueHandler={setGroupValueHandler}
              disableDragAndDrop={isViewResults || !isParticipating}
              sectionANoContent={
                referenceActivityResult.length === 0 ? (
                  <InfoBox
                    title={activityData.originalListEmptyInfo}
                    transparent
                  />
                ) : null
              }
              sectionBNoContent={
                referenceActivityResult.length === 0 ||
                (isViewResults &&
                  parsedResult[SectionTypes.SECTION_B].length === 0) ? (
                  <InfoBox
                    title={activityData.originalListEmptyInfo}
                    transparent
                  />
                ) : null
              }
              hasDraggableIcon
              hasBadge
            />
          </div>
        </ScrollIndicator>
        {!isViewResults && isTransitioning && (
          <NextStepTransition
            nextStep={nextActivityData.transitionText}
            sessionType={nextActivityData.conferenceMode}
            isActivityTimeout={isActivityTimeout}
            transition={transition}
          />
        )}
      </div>
      {!isViewResults && (
        <ActionFooter
          buttonText={actionFooterData.buttonText}
          type={actionFooterData.type}
          disabledButton={actionFooterData.disabledButton}
          isLoading={isLoading}
          isConnectionWeak={isConnectionWeak}
          buttonClickHandler={() =>
            setActivityReadyHandler({ activityId: activityData.id })
          }
        >
          {actionFooterData.text}
        </ActionFooter>
      )}
    </>
  );
};

export default memo(Filtering);
