import { useMachine } from "@xstate/react";
import { PropsWithChildren, createContext, useContext, useMemo } from "react";
import {
  adminDashboardMachine,
  editSlotMachine,
  getSlotsMachine,
} from "../+xstate/machines/dashboard/admin-dashboard";
import { ApolloContext, AppApolloClient } from "./Apollo";
import { useChildMachine } from "../hooks/useChildMachine";
import {
  deleteTeamMemberImageMachine,
  editTeamMemberMachine,
  deleteTeamMemberMachine,
  getPreSignedTeamMemberUrlMachine,
  getTeamMembersMachine,
  inviteTeamMemberMachine,
  teamMembersMachine,
  uploadTeamMemberImageMachine,
  bulkInviteMachine,
} from "../+xstate/machines/team-members";
import { getWorkspaceTagsMachine } from "../apollo-graphql/machines/workspace-tags";
import { GlobalContext } from "./Global";

type AdminDashboardState = {
  state: ReturnType<typeof useMachine<typeof adminDashboardMachine>>["0"];
  send: ReturnType<typeof useMachine<typeof adminDashboardMachine>>["1"];
  actor: ReturnType<typeof useMachine<typeof adminDashboardMachine>>["2"];
  schedule: {
    getSlotsState: ReturnType<typeof useMachine<typeof getSlotsMachine>>["0"];
    getSlotsSend: ReturnType<typeof useMachine<typeof getSlotsMachine>>["1"];
    editSlotState: ReturnType<typeof useMachine<typeof editSlotMachine>>["0"];
  };
  teamMembers: {
    adminTeamMembersState: ReturnType<
      typeof useMachine<typeof teamMembersMachine>
    >["0"];
    adminTeamMembersSend: ReturnType<
      typeof useMachine<typeof teamMembersMachine>
    >["1"];
    getTeamMembersState: ReturnType<
      typeof useMachine<typeof getTeamMembersMachine>
    >["0"];
    inviteTeamMemberState: ReturnType<
      typeof useMachine<typeof inviteTeamMemberMachine>
    >["0"];
    bulkInviteState: ReturnType<
      typeof useMachine<typeof bulkInviteMachine>
    >["0"];
    editTeamMemberState: ReturnType<
      typeof useMachine<typeof editTeamMemberMachine>
    >["0"];
    deleteTeamMemberState: ReturnType<
      typeof useMachine<typeof deleteTeamMemberMachine>
    >["0"];
    getPreSignedTeamMemberUrlState: ReturnType<
      typeof useMachine<typeof getPreSignedTeamMemberUrlMachine>
    >["0"];
    uploadTeamMemberImageMachineState: ReturnType<
      typeof useMachine<typeof uploadTeamMemberImageMachine>
    >["0"];
    deleteTeamMemberImageMachineState: ReturnType<
      typeof useMachine<typeof deleteTeamMemberImageMachine>
    >["0"];
    getWorkspaceTagsMachineState: ReturnType<
      typeof useMachine<typeof getWorkspaceTagsMachine>
    >["0"];
  };
};

export const AdminDashboardContext = createContext<
  AdminDashboardState & { client: AppApolloClient }
>({} as AdminDashboardState & { client: AppApolloClient });

export const AdminDashboardContextProvider = (props: PropsWithChildren) => {
  const {
    auth: {
      context: { profile },
    },
  } = useContext(GlobalContext);
  const workspaceId = profile!.workspace.workspace_id;
  const { client } = useContext(ApolloContext);
  const [state, send, actor] = useMachine(adminDashboardMachine, {
    input: { client, workspaceId },
  });
  const [getSlotsState, getSlotsSend] = useChildMachine(
    state,
    getSlotsMachine.id
  ) as unknown as ReturnType<typeof useMachine<typeof getSlotsMachine>>;

  const [editSlotState] = useChildMachine(
    state,
    editSlotMachine.id
  ) as unknown as ReturnType<typeof useMachine<typeof editSlotMachine>>;

  const [adminTeamMembersState, adminTeamMembersSend] = useMachine(
    teamMembersMachine,
    {
      input: { client },
    }
  ) as unknown as ReturnType<typeof useMachine<typeof teamMembersMachine>>;

  const [getTeamMembersState] = useChildMachine(
    adminTeamMembersState,
    getTeamMembersMachine.id
  ) as unknown as ReturnType<typeof useMachine<typeof getTeamMembersMachine>>;

  const [inviteTeamMemberState] = useChildMachine(
    adminTeamMembersState,
    inviteTeamMemberMachine.id
  ) as unknown as ReturnType<typeof useMachine<typeof inviteTeamMemberMachine>>;

  const [bulkInviteState] = useChildMachine(
    adminTeamMembersState,
    bulkInviteMachine.id
  ) as unknown as ReturnType<typeof useMachine<typeof bulkInviteMachine>>;

  const [editTeamMemberState] = useChildMachine(
    adminTeamMembersState,
    editTeamMemberMachine.id
  ) as unknown as ReturnType<typeof useMachine<typeof editTeamMemberMachine>>;

  const [deleteTeamMemberState] = useChildMachine(
    adminTeamMembersState,
    deleteTeamMemberMachine.id
  ) as unknown as ReturnType<typeof useMachine<typeof deleteTeamMemberMachine>>;

  const [getPreSignedTeamMemberUrlState] = useChildMachine(
    adminTeamMembersState,
    getPreSignedTeamMemberUrlMachine.id
  ) as unknown as ReturnType<
    typeof useMachine<typeof getPreSignedTeamMemberUrlMachine>
  >;

  const [uploadTeamMemberImageMachineState] = useChildMachine(
    adminTeamMembersState,
    uploadTeamMemberImageMachine.id
  ) as unknown as ReturnType<
    typeof useMachine<typeof uploadTeamMemberImageMachine>
  >;

  const [deleteTeamMemberImageMachineState] = useChildMachine(
    adminTeamMembersState,
    uploadTeamMemberImageMachine.id
  ) as unknown as ReturnType<
    typeof useMachine<typeof deleteTeamMemberImageMachine>
  >;

  const [getWorkspaceTagsMachineState] = useChildMachine(
    adminTeamMembersState,
    getWorkspaceTagsMachine.id
  ) as unknown as ReturnType<typeof useMachine<typeof getWorkspaceTagsMachine>>;

  const value: AdminDashboardState & { client: AppApolloClient } = useMemo(
    () => ({
      state,
      send,
      actor,
      client,
      schedule: {
        getSlotsState,
        getSlotsSend,
        editSlotState,
      },
      teamMembers: {
        adminTeamMembersState,
        adminTeamMembersSend,
        getTeamMembersState,
        inviteTeamMemberState,
        bulkInviteState,
        editTeamMemberState,
        deleteTeamMemberState,
        getPreSignedTeamMemberUrlState,
        uploadTeamMemberImageMachineState,
        deleteTeamMemberImageMachineState,
        getWorkspaceTagsMachineState,
      },
    }),
    [
      state,
      send,
      actor,
      client,
      getSlotsState,
      getSlotsSend,
      editSlotState,
      adminTeamMembersState,
      bulkInviteState,
      adminTeamMembersSend,
      getTeamMembersState,
      inviteTeamMemberState,
      editTeamMemberState,
      deleteTeamMemberState,
      getPreSignedTeamMemberUrlState,
      uploadTeamMemberImageMachineState,
      deleteTeamMemberImageMachineState,
      getWorkspaceTagsMachineState,
    ]
  );

  return (
    <AdminDashboardContext.Provider value={value}>
      {props.children}
    </AdminDashboardContext.Provider>
  );
};
