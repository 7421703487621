import {
  memo,
  PropsWithChildren,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { v4 } from "uuid";
import deepDiff from "deep-diff";
import { matchSorter } from "match-sorter";

import { TagInput, TagInputItem } from "./components/TagInput/TagInput";

import styles from "./AutoComplete.module.css";

export interface IAutoCompleteItem {
  id: string | null;
  text: string;
}

interface AutoCompleteProps extends PropsWithChildren {
  label: string;
  placeholder: string;
  items: { id: string; text: string }[];
  onChanges: (data: IAutoCompleteItem[]) => void;
  defaultValues?: string[];
  disableCreation?: boolean;
  readOnly?: boolean;
}

const AutoComplete = (props: AutoCompleteProps) => {
  const {
    label,
    placeholder,
    items,
    defaultValues = [],
    onChanges,
    disableCreation,
    readOnly,
  } = props;

  const changedValuesRef = useRef<IAutoCompleteItem[]>([]);
  const [list, setList] = useState<{ id: string; text: string }[]>(items);
  const [values, setValues] = useState<string[]>(defaultValues);
  const [value, setValue] = useState("");
  const searchValue = useDeferredValue(value);

  const matches = useMemo(() => {
    return matchSorter(list, searchValue, { keys: ["text"] });
  }, [list, searchValue]);

  const changedValues = useMemo(() => {
    return values.map((text) => {
      return {
        id: items.find((i) => i.text === text)?.id || null,
        text,
      };
    });
  }, [items, values]);

  const onClickHandler = useCallback(() => {
    setList((list) => [...list, { id: v4(), text: value }]);
    setValues((values) => [...values, value]);
  }, [value]);

  useEffect(() => {
    if (!!readOnly) return;
    const changes = deepDiff(changedValuesRef.current, changedValues);
    if (!changes?.length) return;

    changedValuesRef.current = changedValues;
    onChanges(changedValues);
  }, [label, onChanges, changedValues, values, readOnly]);

  useEffect(() => {
    setValues(defaultValues);
  }, [defaultValues]);

  return (
    <div className={styles.wrapper}>
      <label htmlFor="combobox-id" className="caption medium">
        {label}
      </label>
      <TagInput
        disabled={!!readOnly}
        defaultValues={defaultValues}
        label={label}
        id="combobox-id"
        value={value}
        onChange={setValue}
        values={values}
        onValuesChange={setValues}
        placeholder={placeholder}
      >
        {matches.length > 0 &&
          matches.map(({ id, text }) => <TagInputItem key={id} value={text} />)}
        {value &&
          !list.find((i) => i.text.includes(value.toLocaleLowerCase())) &&
          !disableCreation && (
            <TagInputItem onClick={onClickHandler}>
              Click enter to create "{value}"
            </TagInputItem>
          )}
        {disableCreation && value && !matches.length && (
          <TagInputItem disabled value="No results found." />
        )}
      </TagInput>
    </div>
  );
};

export default memo(AutoComplete);
