import { useMachine, useSelector } from "@xstate/react";

export function useChildMachine<T extends string = string>(
  parentState: ReturnType<typeof useMachine>[0],
  machineId: T
): [any, any, any] {
  const childActor = parentState.children[machineId];
  return useSelector(childActor, (state) => {
    return [state, childActor?.send, childActor];
  });
}
