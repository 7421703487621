import { PropsWithChildren, memo, useCallback, useMemo } from "react";

import {
  getEntryId,
  getTransitionData,
  getType,
  parseToJson,
} from "../../../../utils";
import { ComparisonActivity } from "../../../../types/contentful/workshop/activities/comparison";
import { useReadyRemovedDueToChange } from "../../../../hooks/useReadyRemovedDueToChange";
import { getTransitionActionFooterData } from "../utils/get-transition-footer-data";
import { ActivityCommon } from "../../../../types/activity-common";
import { ActionFooterType } from "../../../../types/action-footer";
import { FooterType } from "../../../../types/enums/activity-footer";
import { IActivityResult } from "../../../../apollo-graphql/types/session-state";
import { ACTIVITY_TIMEOUT_VALUE } from "../../../../constants/global";
import { WorkshopActivityType } from "../../../../types/enums/activity-type";
import { observerFooterData } from "../constants";

import ActionFooter from "../../ActionFooter/ActionFooter";
import NextStepTransition from "../../NextStepTransition/NextStepTransition";
import ComparisonItem from "./components/ComparisonItem/ComparisonItem";
import ScrollIndicator from "../../../Shared/ScrollIndicator/ScrollIndicator";
import ContentfulRichField from "../../../Shared/ContentfulRichField/ContentfulRichField";
import ActivityInstructions from "../../../Shared/ActivityInstructions/ActivityInstructions";

import cn from "classnames";
import styles from "./Comparison.module.css";

interface ComparisonProps extends ActivityCommon {
  currentActiveParticipants: string[];
  activityResultForCurrentProfile: IActivityResult["value"]["0"] | null;
  activityResults: IActivityResult["value"] | null;
  isViewResults?: boolean;
  profileId: string;
  getReferenceActivityResult: (
    activityType: WorkshopActivityType,
    activityId: string
  ) => IActivityResult["value"] | null;
  setActivityValueHandler: (args: {
    activityId: string;
    value: string;
  }) => void;
}

const Comparison = (props: PropsWithChildren<ComparisonProps>) => {
  const {
    transition,
    isReady,
    activity,
    nextActivity,
    isLoading,
    activityResults,
    activityResultForCurrentProfile,
    isConnectionWeak,
    currentActiveParticipants,
    currentActiveParticipantCount,
    profileId,
    notReadyProfilesCount,
    isViewResults,
    isActivityTimeout,
    isParticipating,
    hasAhaMoments,
    getReferenceActivityResult,
    setActivityValueHandler,
    setActivityReadyHandler,
  } = props;

  const { changeStateText, userValueChangeHandler } =
    useReadyRemovedDueToChange(isReady);

  const items = useMemo(() => {
    return (activity as ComparisonActivity)?.fields?.items?.map((item) => {
      const id = getEntryId(item);
      return {
        id,
        referenceId: item?.fields?.itemForGeneratedText?.sys?.id,
        title: item?.fields?.title,
        generatedLabel: item?.fields.generatedLabel,
        questionNode: <ContentfulRichField content={item?.fields?.question} />,
        referenceLabel: item?.fields.referenceLabel,
        referenceTextNode: (
          <ContentfulRichField content={item?.fields?.referenceText} />
        ),
        itemForGeneratedText: {
          textNode: (
            <ContentfulRichField
              content={item?.fields?.itemForGeneratedText?.fields?.text}
            />
          ),
          title: item?.fields?.itemForGeneratedText?.fields?.title,
          placeholderText:
            item?.fields?.itemForGeneratedText?.fields?.placeholderText,
        },
        answers: item?.fields.answers.map((text) => ({
          id: `${id}-${text}`,
          text,
        })),
      };
    });
  }, [activity]);

  const itemIds = useMemo(() => items.map(({ id }) => id), [items]);

  const activityData = useMemo(() => {
    const filteringActivity = activity as ComparisonActivity;
    return {
      id: getEntryId(filteringActivity),
    };
  }, [activity]);

  const nextActivityData = useMemo(
    () => getTransitionData(nextActivity, hasAhaMoments),
    [nextActivity, hasAhaMoments]
  );

  const referenceActivityType = useMemo(() => {
    const item = (activity as ComparisonActivity)?.fields?.items?.[0];
    const referenceItem = item?.fields?.itemForGeneratedText;
    if (!referenceItem) return null;

    return getType(referenceItem) as string;
  }, [activity]);

  const referenceActivityResult = useMemo(() => {
    if (!referenceActivityType) return null;
    // TODO: BETTER SOLUTION HERE
    const type =
      referenceActivityType === "translationItem"
        ? WorkshopActivityType.TranslationActivity
        : WorkshopActivityType.ConceptualisationActivity;
    return getReferenceActivityResult(type, activityData.id);
  }, [activityData.id, getReferenceActivityResult, referenceActivityType]);

  const defaultSelectedOptionsData = useMemo(() => {
    const defaultResult = itemIds.reduce(
      (acc, id) => ({ ...acc, [id]: null }),
      {} as { [key: string]: string | null }
    );
    return defaultResult;
  }, [itemIds]);

  const currentProfileSelectedOptions = useMemo(() => {
    const resultValue = activityResultForCurrentProfile?.value;

    if (!resultValue || resultValue === ACTIVITY_TIMEOUT_VALUE)
      return defaultSelectedOptionsData;

    const data = parseToJson<{ [key: string]: string | null }>(
      activityResultForCurrentProfile.value,
      {}
    );

    return itemIds.reduce((acc, id) => {
      const value = data[id] || null;
      return { ...acc, [id]: value };
    }, {} as { [key: string]: string | null });
  }, [
    activityResultForCurrentProfile?.value,
    defaultSelectedOptionsData,
    itemIds,
  ]);

  const otherProfileSelectedOptions = useMemo(() => {
    return (activityResults || [])
      .filter(
        (result) =>
          currentActiveParticipants.includes(result.profileId) &&
          result.profileId !== profileId
      )
      .map((profileData) => {
        const data = parseToJson<{ [key: string]: string | null }>(
          profileData.value,
          defaultSelectedOptionsData
        );
        const value = itemIds.reduce((acc, id) => {
          const value = data[id] || null;
          return { ...acc, [id]: value };
        }, {} as { [key: string]: string | null });

        return { profileId: profileData.profileId, value };
      });
  }, [
    activityResults,
    currentActiveParticipants,
    defaultSelectedOptionsData,
    itemIds,
    profileId,
  ]);

  const isTransitioning = useMemo(() => transition > 0, [transition]);
  const isButtonDisabled = useMemo(() => {
    const parsedCurrentProfileSelectedOptions = Object.entries(
      currentProfileSelectedOptions
    );
    if (
      itemIds.some(
        (id) => !parsedCurrentProfileSelectedOptions.find(([key]) => key === id)
      ) ||
      parsedCurrentProfileSelectedOptions.some(([, value]) => !value)
    )
      return true;

    if (currentActiveParticipantCount === 1)
      return parsedCurrentProfileSelectedOptions.some(
        ([, value]) => value === ACTIVITY_TIMEOUT_VALUE
      );

    const allParticipantsHaveAnswered = currentActiveParticipants
      .filter((d) => d !== profileId)
      .every((participant) => {
        const participantAnswer = otherProfileSelectedOptions.find(
          (res) => res.profileId === participant
        )?.value;
        if (!participantAnswer) return false;
        return itemIds.every((id) => {
          const otherValue = participantAnswer[id];
          return otherValue !== ACTIVITY_TIMEOUT_VALUE;
        });
      });

    return (
      !allParticipantsHaveAnswered ||
      !otherProfileSelectedOptions.every((otherProfileData) => {
        if (!otherProfileData?.value) return false;
        const otherProfileParsedData = Object.entries(otherProfileData.value);
        return parsedCurrentProfileSelectedOptions.every(([key, value]) => {
          const otherValue = otherProfileParsedData.find(
            ([k]) => k === key
          )?.[1];

          return value === otherValue && value !== ACTIVITY_TIMEOUT_VALUE;
        });
      })
    );
  }, [
    currentActiveParticipantCount,
    currentActiveParticipants,
    currentProfileSelectedOptions,
    itemIds,
    otherProfileSelectedOptions,
    profileId,
  ]);

  const actionFooterData: ActionFooterType = useMemo(() => {
    if (!isParticipating) return observerFooterData;
    if (isTransitioning) {
      return getTransitionActionFooterData({
        text: <>Everyone is ready! Continuing forward...</>,
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Ready,
        isActivityTimeout,
      });
    }
    if (isReady) {
      return {
        text: (
          <>
            Waiting for{" "}
            <span className="accent">
              {notReadyProfilesCount} more player
              {notReadyProfilesCount > 1 && "s"}...
            </span>
          </>
        ),
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Waiting,
      };
    }

    const playersClicked =
      currentActiveParticipantCount - notReadyProfilesCount;

    const parsedCurrentProfileSelectedOptions = Object.entries(
      currentProfileSelectedOptions
    );
    const playerHasNotAnsweredAllItems =
      items.some(
        ({ id }) =>
          !parsedCurrentProfileSelectedOptions.find(([key]) => key === id)
      ) ||
      parsedCurrentProfileSelectedOptions.some(
        ([, value]) => !value || value === ACTIVITY_TIMEOUT_VALUE
      );

    const buttonText = playerHasNotAnsweredAllItems
      ? "You have to select your answers to continue."
      : isButtonDisabled
      ? `${changeStateText}You have to match your opinions to continue.`
      : "Your opinions match! Click continue to confirm them as collective opinion!";
    return {
      text: (
        <>
          {buttonText}{" "}
          {playersClicked > 0 && (
            <span className="accent">
              {playersClicked} player{playersClicked > 1 && "s"} clicked!
            </span>
          )}
        </>
      ),
      buttonText: "Continue",
      disabledButton: isButtonDisabled,
      type: FooterType.Notice,
    };
  }, [
    isParticipating,
    isTransitioning,
    isReady,
    currentActiveParticipantCount,
    notReadyProfilesCount,
    currentProfileSelectedOptions,
    items,
    isButtonDisabled,
    changeStateText,
    isActivityTimeout,
  ]);

  const handleSelection = useCallback(
    (optionId: string, sectionType: string) => {
      if (isLoading || !isParticipating) return;

      const value = JSON.stringify({
        ...currentProfileSelectedOptions,
        [sectionType]: optionId,
      });

      setActivityValueHandler({ activityId: activityData.id, value });
      userValueChangeHandler();
    },
    [
      activityData?.id,
      isLoading,
      isParticipating,
      currentProfileSelectedOptions,
      setActivityValueHandler,
      userValueChangeHandler,
    ]
  );

  const parsedTranslationResults = useMemo(() => {
    let res = null;
    try {
      const parsedInitialRes = JSON.parse(
        referenceActivityResult?.[0]?.value || ""
      );
      res = JSON.parse(parsedInitialRes.value);
    } catch (e) {
      console.error(e);
    }

    return res;
  }, [referenceActivityResult]);

  const headerContent = useMemo(() => {
    const html = activity?.fields?.activity?.fields?.instructions;
    const conferenceMode = activity?.fields?.activity?.fields?.conferenceMode;
    const tags = activity?.fields?.activity?.fields?.tags;

    return (
      <ActivityInstructions
        instructions={html}
        tags={tags}
        type={conferenceMode}
      />
    );
  }, [
    activity?.fields?.activity?.fields?.conferenceMode,
    activity?.fields?.activity?.fields?.instructions,
    activity?.fields?.activity?.fields?.tags,
  ]);

  const content = useMemo(() => {
    return items.map((item, index) => {
      const value = parsedTranslationResults?.[item.referenceId] || "";

      let disabled = true;
      let shouldBeOpen = false;

      const currentUserAnswer = currentProfileSelectedOptions[item.id];
      const currentUserHasAnswered =
        !!currentUserAnswer && currentUserAnswer !== ACTIVITY_TIMEOUT_VALUE;
      const otherProfileAnswers = otherProfileSelectedOptions.map(
        (d) => d.value[item.id]
      );

      const allGroupMembersHaveAnswered =
        otherProfileAnswers.filter(Boolean).length ===
        currentActiveParticipants.length - 1;
      const groupIsAligned =
        !!currentUserHasAnswered &&
        allGroupMembersHaveAnswered &&
        otherProfileAnswers.every((res) => res === currentUserAnswer);

      if (index === 0) {
        disabled = false;
        if (!groupIsAligned && !isViewResults) {
          shouldBeOpen = true;
        }
      } else {
        const previousItem = items[index - 1];

        const currentUserAnswerOnPrevious =
          currentProfileSelectedOptions[previousItem.id];
        const currentUserHasAnsweredOnPrevious =
          !!currentUserAnswerOnPrevious &&
          currentUserAnswerOnPrevious !== ACTIVITY_TIMEOUT_VALUE;
        const otherProfileAnswersOnPrevious = otherProfileSelectedOptions.map(
          (d) => d.value[previousItem.id]
        );

        const allGroupMembersHaveAnsweredPrevious =
          otherProfileAnswersOnPrevious.filter(Boolean).length ===
          currentActiveParticipants.length - 1;
        const groupIsAlignedOnPrevious =
          !!currentUserHasAnsweredOnPrevious &&
          allGroupMembersHaveAnsweredPrevious &&
          otherProfileAnswersOnPrevious.every(
            (res) => res === currentUserAnswerOnPrevious
          );

        disabled = !groupIsAlignedOnPrevious;
        shouldBeOpen = groupIsAlignedOnPrevious && !groupIsAligned;
      }

      return (
        <ComparisonItem
          key={item.id}
          comparisonItem={item}
          shouldBeOpen={shouldBeOpen}
          currentActiveParticipants={currentActiveParticipants}
          profileId={profileId}
          isParticipating={isParticipating}
          currentProfileSelectedOptions={currentProfileSelectedOptions}
          otherProfileSelectedOptions={otherProfileSelectedOptions}
          value={value}
          handleSelection={handleSelection}
          disabled={disabled}
        />
      );
    });
  }, [
    currentActiveParticipants,
    currentProfileSelectedOptions,
    handleSelection,
    isParticipating,
    items,
    otherProfileSelectedOptions,
    parsedTranslationResults,
    profileId,
    isViewResults,
  ]);

  return (
    <>
      <ScrollIndicator key={activityData.id} className="activity-container">
        <div className={cn(styles.container, "main-container")}>
          <div className={styles.infoContainer}>
            {headerContent}
            <div className={styles.itemsContainer}>{content}</div>
          </div>
        </div>
        {!isViewResults && isTransitioning && (
          <NextStepTransition
            nextStep={nextActivityData.transitionText}
            sessionType={nextActivityData.conferenceMode}
            isActivityTimeout={isActivityTimeout}
            transition={transition}
          />
        )}
      </ScrollIndicator>
      {!isViewResults && (
        <ActionFooter
          buttonText={actionFooterData.buttonText}
          type={actionFooterData.type}
          disabledButton={actionFooterData.disabledButton}
          isLoading={isLoading}
          isConnectionWeak={isConnectionWeak}
          buttonClickHandler={() =>
            setActivityReadyHandler({ activityId: activityData.id })
          }
        >
          {actionFooterData.text}
        </ActionFooter>
      )}
    </>
  );
};

export default memo(Comparison);
