import { createMachine, assign, spawnChild, sendTo } from "xstate";

import * as adminDashboardActions from "../../actions/dashboard/admin-dashboard";
import { AppApolloClient } from "../../../contexts/Apollo";
import { Workshop } from "../../../types/contentful/workshop/workshop";
import { getJourneyWorkshops } from "../../../apollo-graphql/queries/workshop";
import { IEditSlot, Slot } from "../../../apollo-graphql/types/slot";
import { getSlots } from "../../../apollo-graphql/queries/slot";
import { fetchMachineFactory } from "../fetch-factory";
import {
  AutoGenerateFilters,
  SlotStatus,
} from "../../../apollo-graphql/types/enums";
import { SortDirection } from "../../../types/enums/sort-direction";
import { editSlot } from "../../../apollo-graphql/mutations/slot";
import { teamMembersMachine } from "../team-members";
import { Journey } from "../../../types/contentful/workshop/journey";
import {
  getJourney,
  getJourneys,
} from "../../../apollo-graphql/queries/journey";
import { getJourneyLeaders } from "../../../apollo-graphql/queries/journey-leaders";
import { JourneyLeader } from "../../../apollo-graphql/types";
import { requestJourney } from "../../../apollo-graphql/mutations/request-journey";
import { requestWorkshop } from "../../../apollo-graphql/mutations/request-workshop";

export enum AdminDashboardState {
  Dashboard = "dashboard",
  Workshops = "workshops",
  Journeys = "journeys",
  Schedule = "schedule",
  TeamMembers = "teamMembers",
  JourneyDetails = "journeyDetails",
}

export interface AdminDashboardMachineContext {
  client: AppApolloClient;
  workshops: Workshop[] | null;
  slots: Slot[] | null;
  workspaceId: string | null;
  journeys: Journey[] | null;
  journeyDetails: Journey | null;
  journeyDetailsLeaders: JourneyLeader[] | null;
  error: any | null;
}

type AdminDashboardActionCreators = typeof adminDashboardActions;
type AdminDashboardActionCreatorKeys = keyof AdminDashboardActionCreators;
type AdminDashboardActions = ReturnType<
  AdminDashboardActionCreators[AdminDashboardActionCreatorKeys]
>;

export const {
  machine: getWorkshopsMachine,
  trigger: getWorkshopsTrigger,
  success: getWorkshopsSuccess,
  failure: getWorkshopsFailure,
} = fetchMachineFactory({
  id: "getWorkshops",
  invokeFn: ({
    client,
    workspaceId,
    searchText,
  }: {
    workspaceId: string;
    searchText?: string;
    client: AppApolloClient;
  }) => {
    return getJourneyWorkshops(client, { workspaceId, searchText });
  },
});

export const {
  machine: getJourneysMachine,
  trigger: getJourneysTrigger,
  success: getJourneysSuccess,
  failure: getJourneysFailure,
} = fetchMachineFactory({
  id: "getJourneys",
  invokeFn: ({ client }: { client: AppApolloClient }) => {
    return getJourneys(client, { includeWorkshops: true });
  },
});

export const {
  machine: getSlotsMachine,
  success: getSlotsSuccess,
  trigger: getSlotsTrigger,
  failure: getSlotsFailure,
  retry: reTriggerGetSlots,
} = fetchMachineFactory({
  id: "getSlots",
  invokeFn: ({
    workspaceId,
    client,
    slotStatuses,
    sortDirection,
    emails,
    autoGenerateFilters,
  }: {
    workspaceId: string;
    client: AppApolloClient;
    slotStatuses: SlotStatus[];
    sortDirection: SortDirection;
    emails?: string[];
    autoGenerateFilters?: AutoGenerateFilters;
  }) => {
    return getSlots(client, {
      workspaceId,
      statuses: slotStatuses,
      sortDirection,
      emails,
      autoGenerateFilters,
    }).then((slots) => ({
      slots,
      workspaceId,
    }));
  },
});

export const {
  machine: editSlotMachine,
  success: editSlotSuccess,
  trigger: editSlotTrigger,
  failure: editSlotFailure,
  reset: editSlotReset,
} = fetchMachineFactory({
  id: "editSlot",
  invokeFn: ({
    client,
    ...others
  }: IEditSlot & { client: AppApolloClient }) => {
    return editSlot(client, others);
  },
});

export const {
  machine: getJourneyMachine,
  trigger: getJourneyTrigger,
  success: getJourneySuccess,
  failure: getJourneyFailure,
} = fetchMachineFactory({
  id: "getJourney",
  invokeFn: ({
    client,
    journeyId,
  }: {
    journeyId: string;
    client: AppApolloClient;
  }) => {
    return getJourney(client, { journeyId, includeWorkshops: true });
  },
});

export const {
  machine: requestJourneyMachine,
  trigger: requestJourneyTrigger,
  success: requestJourneySuccess,
  failure: requestJourneyFailure,
} = fetchMachineFactory({
  id: "requestJourney",
  invokeFn: ({
    client,
    message,
    journeyId,
  }: {
    journeyId: string;
    message: string;
    client: AppApolloClient;
  }) => {
    return requestJourney(client, { journeyId, message });
  },
});

export const {
  machine: requestWorkshopMachine,
  trigger: requestWorkshopTrigger,
  success: requestWorkshopSuccess,
  failure: requestWorkshopFailure,
} = fetchMachineFactory({
  id: "requestWorkshop",
  invokeFn: ({
    client,
    id,
    message,
  }: {
    client: AppApolloClient;
    id: string;
    message: string;
  }) => {
    return requestWorkshop(client, { id, message });
  },
});

export const {
  machine: getJourneyLeadersMachine,
  trigger: getJourneyLeadersTrigger,
  success: getJourneyLeadersSuccess,
  failure: getJourneyLeadersFailure,
} = fetchMachineFactory({
  id: "getJourneyLeaders",
  invokeFn: ({
    client,
    journeyId,
    workspaceId,
  }: {
    journeyId: string;
    workspaceId?: string;
    client: AppApolloClient;
  }) => {
    return getJourneyLeaders(client, { journeyId, workspaceId });
  },
});

type AdminDashboardMachineTypes = {
  context: AdminDashboardMachineContext;
  events:
    | AdminDashboardActions
    | ReturnType<typeof getWorkshopsSuccess>
    | ReturnType<typeof getWorkshopsFailure>
    | ReturnType<typeof getSlotsSuccess>
    | ReturnType<typeof getSlotsFailure>
    | ReturnType<typeof editSlotSuccess>
    | ReturnType<typeof editSlotFailure>
    | ReturnType<typeof getJourneysSuccess>
    | ReturnType<typeof getJourneysFailure>
    | ReturnType<typeof getJourneySuccess>
    | ReturnType<typeof getJourneyFailure>
    | ReturnType<typeof getJourneyLeadersSuccess>
    | ReturnType<typeof getJourneyLeadersFailure>
    | ReturnType<typeof requestWorkshopSuccess>
    | ReturnType<typeof requestWorkshopFailure>;
};

export const adminDashboardMachine = createMachine({
  types: {} as AdminDashboardMachineTypes,
  id: "admin-dashboard",
  initial: AdminDashboardState.Dashboard,
  context: ({ input }): AdminDashboardMachineContext => {
    const machineInput = input as
      | {
          client?: AppApolloClient;
          workspaceId?: string;
        }
      | undefined;
    if (!machineInput?.client || !machineInput?.workspaceId)
      throw new Error("Apollo client and workspaceId must be provided!");
    return {
      client: machineInput.client,
      workspaceId: machineInput.workspaceId,
      workshops: null,
      slots: null,
      journeyDetails: null,
      journeyDetailsLeaders: null,
      error: null,
      journeys: null,
    };
  },
  entry: [
    spawnChild(getWorkshopsMachine, {
      id: getWorkshopsMachine.id,
      systemId: getWorkshopsMachine.id,
      syncSnapshot: true,
    }),
    spawnChild(requestWorkshopMachine, {
      id: requestWorkshopMachine.id,
      systemId: requestWorkshopMachine.id,
      syncSnapshot: true,
    }),
    spawnChild(getJourneysMachine, {
      id: getJourneysMachine.id,
      systemId: getJourneysMachine.id,
      syncSnapshot: true,
    }),
    spawnChild(getSlotsMachine, {
      id: getSlotsMachine.id,
      systemId: getSlotsMachine.id,
      syncSnapshot: true,
    }),
    spawnChild(editSlotMachine, {
      id: editSlotMachine.id,
      systemId: editSlotMachine.id,
      syncSnapshot: true,
    }),
    spawnChild(getJourneyMachine, {
      id: getJourneyMachine.id,
      systemId: getJourneyMachine.id,
      syncSnapshot: true,
    }),
    spawnChild(getJourneyLeadersMachine, {
      id: getJourneyLeadersMachine.id,
      systemId: getJourneyLeadersMachine.id,
      syncSnapshot: true,
    }),
    spawnChild(requestJourneyMachine, {
      id: requestJourneyMachine.id,
      systemId: requestJourneyMachine.id,
      syncSnapshot: true,
    }),
    spawnChild(teamMembersMachine, {
      id: teamMembersMachine.id,
      systemId: teamMembersMachine.id,
      input: ({ context }: { context: AdminDashboardMachineContext }) => {
        return {
          workspaceId: context.workspaceId,
          client: context.client,
        };
      },
      syncSnapshot: true,
    }),
  ],
  states: {
    [AdminDashboardState.Dashboard]: {},
    [AdminDashboardState.Workshops]: {
      entry: [
        sendTo(getWorkshopsMachine.id, ({ context, event }) => {
          event = event as ReturnType<
            typeof adminDashboardActions.enterAdminWorkshops
          >;
          const { client, workspaceId } = context;
          const searchText = event.payload.searchText;
          return getWorkshopsTrigger({
            client,
            workspaceId: workspaceId!,
            searchText,
          });
        }),
        assign({
          error: null,
          workshops: null,
        }),
      ],
      on: {
        [getWorkshopsSuccess.type]: {
          actions: assign({
            workshops: ({ event }) => {
              return event.payload.output;
            },
          }),
        },
        [getWorkshopsFailure.type]: {
          actions: assign({
            error: ({ event }) => {
              return event.payload.error;
            },
          }),
        },
        [adminDashboardActions.requestWorkshop.type]: {
          actions: [
            sendTo(requestWorkshopMachine.id, ({ event, context }) => {
              const { client } = context;
              const { id, message } = event.payload;
              return requestWorkshopTrigger({ id, message, client });
            }),
          ],
        },
        [requestWorkshopFailure.type]: {
          actions: assign({
            error: ({ event }) => {
              return event.payload.error;
            },
          }),
        },
      },
    },
    [AdminDashboardState.Journeys]: {
      entry: [
        assign({
          error: null,
          journeys: null,
        }),
        sendTo(getJourneysMachine.id, ({ event, context }) => {
          event = event as ReturnType<
            typeof adminDashboardActions.enterAdminJourneys
          >;
          const { client } = context;
          return getJourneysTrigger({ client });
        }),
      ],
      on: {
        [getJourneysSuccess.type]: {
          actions: assign({
            journeys: ({ event }) => {
              return event.payload.output;
            },
          }),
        },
        [getJourneysFailure.type]: {
          actions: assign({
            error: ({ event }) => {
              return event.payload.error;
            },
          }),
        },
      },
    },
    [AdminDashboardState.TeamMembers]: {
      entry: [
        assign({
          error: null,
        }),
      ],
    },
    [AdminDashboardState.Schedule]: {
      entry: [
        assign({
          error: null,
          slots: null,
        }),
        sendTo(getSlotsMachine.id, ({ event, context }) => {
          const currentEvent = event as ReturnType<
            typeof adminDashboardActions.enterAdminSchedule
          >;
          const payload = {
            workspaceId: context.workspaceId!,
            sortDirection: currentEvent.payload.sortDirection,
            //TODO: use one constant that defines the filters for the different routes (we already have this inside the Schedule.tsx)
            slotStatuses: currentEvent.payload.slotStatuses || [
              SlotStatus.SCHEDULED,
              SlotStatus.ONGOING,
            ],
            client: context.client,
            emails: currentEvent.payload.emails,
            autoGenerateFilters: currentEvent.payload.autoGenerateFilters,
          };
          return getSlotsTrigger(payload);
        }),
      ],
      on: {
        [getSlotsSuccess.type]: {
          actions: [
            assign({
              slots: ({ event }) => {
                return event.payload.output.slots;
              },
            }),
            sendTo(editSlotMachine.id, () => editSlotReset()),
          ],
        },
        [getSlotsFailure.type]: {
          actions: assign({
            error: ({ event }) => {
              return event.payload.error;
            },
          }),
        },
        [adminDashboardActions.editSlot.type]: {
          actions: [
            sendTo(editSlotMachine.id, ({ event, context }) => {
              event = event as ReturnType<
                AdminDashboardActionCreators["editSlot"]
              >;
              const payload = { client: context.client, ...event.payload };
              return editSlotTrigger(payload);
            }),
          ],
        },
        [editSlotSuccess.type]: {
          actions: [
            sendTo(getSlotsMachine.id, () => {
              return reTriggerGetSlots();
            }),
          ],
        },
        [editSlotFailure.type]: {
          actions: assign({
            error: ({ event }) => {
              return event.payload.error;
            },
          }),
        },
      },
    },
    [AdminDashboardState.JourneyDetails]: {
      entry: [
        assign({
          error: null,
          journeyDetails: null,
          journeyDetailsLeaders: null,
        }),
        sendTo(getJourneyMachine.id, ({ event, context }) => {
          event = event as ReturnType<
            typeof adminDashboardActions.enterAdminJourneyDetails
          >;
          return getJourneyTrigger({
            client: context.client,
            journeyId: event.payload.journeyId,
          });
        }),
        sendTo(getJourneyLeadersMachine.id, ({ event, context }) => {
          event = event as ReturnType<
            typeof adminDashboardActions.enterAdminJourneyDetails
          >;
          return getJourneyLeadersTrigger({
            client: context.client,
            journeyId: event.payload.journeyId,
          });
        }),
      ],
      on: {
        [adminDashboardActions.requestJourney.type]: {
          actions: [
            sendTo(requestJourneyMachine.id, ({ event, context }) => {
              const { client } = context;
              const { journeyId, message } = event.payload;
              return requestJourneyTrigger({ journeyId, message, client });
            }),
          ],
        },
        [getJourneySuccess.type]: {
          actions: assign({
            journeyDetails: ({ event }) => {
              return event.payload.output;
            },
          }),
        },
        [getJourneyLeadersSuccess.type]: {
          actions: assign({
            journeyDetailsLeaders: ({ event }) => {
              return event.payload.output;
            },
          }),
        },
        [getJourneyFailure.type]: {
          actions: assign({
            error: ({ event }) => {
              return event.payload.error;
            },
          }),
        },
      },
    },
  },
  on: {
    [adminDashboardActions.enterAdminDashboard.type]: {
      target: `.${AdminDashboardState.Dashboard}`,
    },
    [adminDashboardActions.enterAdminWorkshops.type]: {
      target: `.${AdminDashboardState.Workshops}`,
    },
    [adminDashboardActions.enterAdminJourneys.type]: {
      target: `.${AdminDashboardState.Journeys}`,
    },
    [adminDashboardActions.enterAdminSchedule.type]: {
      target: `.${AdminDashboardState.Schedule}`,
    },
    [adminDashboardActions.enterAdminTeamMembers.type]: {
      target: `.${AdminDashboardState.TeamMembers}`,
    },
    [adminDashboardActions.enterAdminJourneyDetails.type]: {
      target: `.${AdminDashboardState.JourneyDetails}`,
    },
  },
});
