import {
  ChangeEvent,
  PropsWithChildren,
  memo,
  useCallback,
  useMemo,
  useEffect,
  useContext,
  useRef,
} from "react";
import {
  Navigate,
  useLocation,
  useMatches,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { InfiniteLoader, Table, Column, AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css";

import styles from "./TeamMembers.module.css";
import cn from "classnames";
import { debounce, startCase, toLower } from "lodash";
import * as HoverCard from "@radix-ui/react-hover-card";

import InviteTeamMemberModal from "./components/InviteTeamMemberModal/InviteTeamMemberModal";
import EditTeamMemberModal from "./components/EditTeamMemberModal/EditTeamMemberModal";
import DeleteTeamMemberModal from "./components/DeleteTeamMemberModal/DeleteTeamMemberModal";
import Loader from "../../../Shared/Loader/Loader";

import { generateWorkspaceProfileImageKey } from "../../../../utils";
import { defaultPage, defaultPageSize } from "../../../../utils/filters";
import {
  deserializePageParams,
  serializePageParams,
} from "../../../../utils/filters";

import withRouteConfig from "../../../../hocs/withRouteConfig";
import { FetchState } from "../../../../+xstate/machines/fetch-factory";
import {
  TeamMemberBulkInviteState,
  TeamMembersState,
} from "../../../../+xstate/machines/team-members";
import * as teamMemberActions from "../../../../+xstate/actions/team-members";
import * as workspaceTagsActions from "../../../../apollo-graphql/actions/workspace-tags";

import {
  Profile,
  ProfileInvite,
} from "../../../../apollo-graphql/types/profile";
import UserImage from "../../../Shared/UserImage/UserImage";
import BulkInviteDialog from "./BulkInviteDialog/BulkInviteDialog";

import {
  ProfileWorkspaceAccess,
  ProfileWorkspaceStatus,
} from "../../../../apollo-graphql/types/enums";
import {
  ProfileUpdate,
  ProfileDelete,
} from "../../../../apollo-graphql/types/profile";
import { AdminDashboardContext } from "../../../../contexts/AdminDashboard";
import { GlobalContext } from "../../../../contexts/Global";

import { enterAdminTeamMembers } from "../../../../+xstate/actions/dashboard/admin-dashboard";
import { TEAM_MEMBERS_DISABLED } from "../../../../constants/global";
import { TEAM_MEMBERS } from "../../../../constants/navigation";
import { HIGH_INPUT_DEBOUNCE_TIME } from "../../../../constants/input-debounce-time";
import { BulkInviteFormData } from "../../../../types/bulk-invite-from-data";
import { IBaseTag } from "../../../../apollo-graphql/types/workspace-tag";
import SkeletonLoader from "../../../Shared/SkeletonLoader/SkeletonLoader";

const calculateColumnWidths = (totalWidth: number) => {
  const totalDefinedWidth = 200 + 250 + 120 + 100 + 140 + 120;

  return {
    name: (200 / totalDefinedWidth) * totalWidth,
    email: (250 / totalDefinedWidth) * totalWidth,
    accountType: (120 / totalDefinedWidth) * totalWidth,
    status: (100 / totalDefinedWidth) * totalWidth,
    tags: (140 / totalDefinedWidth) * totalWidth,
    action: (120 / totalDefinedWidth) * totalWidth,
  };
};

const mapFieldToStyle: {
  readonly type: {
    readonly [key in ProfileWorkspaceAccess]: string;
  };
  readonly status: {
    readonly [key in ProfileWorkspaceStatus]?: string;
  };
} = {
  type: {
    [ProfileWorkspaceAccess.ADMIN]: styles.adminType,
    [ProfileWorkspaceAccess.OWNER]: styles.ownerType,
    [ProfileWorkspaceAccess.SUPER_ADMIN]: styles.adminType,
    [ProfileWorkspaceAccess.TEAM_MEMBER]: styles.teamMemberType,
    [ProfileWorkspaceAccess.NONE]: styles.teamMemberType,
  },
  status: {
    [ProfileWorkspaceStatus.ACTIVE]: styles.activeStatus,
    [ProfileWorkspaceStatus.ABSENCE]: styles.absenceStatus,
    [ProfileWorkspaceStatus.LEFT]: styles.absenceStatus,
    [ProfileWorkspaceStatus.NONE]: styles.absenceStatus,
    [ProfileWorkspaceStatus.PROHIBITED]: styles.absenceStatus,
    [ProfileWorkspaceStatus.UNSUBSCRIBED]: styles.absenceStatus,
  },
};

export const tmDefaultFilteringState = {
  currentPage: defaultPage,
  pageSize: defaultPageSize,
};

export interface TeamMembersFilteringState {
  currentPage: number;
  pageSize: number;
  query?: string;
}

type TeamMembersProps = PropsWithChildren<{}>;

const TeamMembers = (props: TeamMembersProps) => {
  const {
    teamMembers: {
      adminTeamMembersState: state,
      adminTeamMembersSend: send,
      getTeamMembersState,
      inviteTeamMemberState,
      bulkInviteState,
      editTeamMemberState,
      deleteTeamMemberState,
      getWorkspaceTagsMachineState,
    },
    send: adminDashboardSend,
  } = useContext(AdminDashboardContext);
  const {
    auth: {
      context: { token, profile },
    },
  } = useContext(GlobalContext);

  const isCurrentAccountOwner =
    profile?.workspace.access === ProfileWorkspaceAccess.OWNER;

  const workspaceId = useMemo(() => profile!.workspace.workspace_id, [profile]);

  useEffect(() => {
    adminDashboardSend(enterAdminTeamMembers({ ...tmDefaultFilteringState }));
  }, [adminDashboardSend, workspaceId]);

  useEffect(() => {
    if (!workspaceId) return;
    send(workspaceTagsActions.getWorkspaceTags({ workspaceId }));
  }, [send, workspaceId]);

  const matches = useMatches();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams({
    currentPage: `${defaultPage}`,
  });

  const queryRef = useRef<HTMLInputElement>(null);

  const teamMembersLoading = useMemo(
    () =>
      getTeamMembersState.matches(FetchState.Fetching) &&
      !getTeamMembersState?.context?.data?.profiles?.nodes?.length,
    [getTeamMembersState]
  );

  const isEditLoading = useMemo(
    () => editTeamMemberState.matches(FetchState.Fetching),
    [editTeamMemberState]
  );

  const isDeleteLoading = useMemo(
    () => deleteTeamMemberState.matches(FetchState.Fetching),
    [deleteTeamMemberState]
  );

  const { selectedTeamMember, teamMemberToBeDeleted, error } = useMemo(
    () => state.context,
    [state.context]
  );

  const teamMembers = useMemo(
    () =>
      getTeamMembersState.context.data?.profiles || {
        nodes: [],
        pageInfo: {
          total: 0,
        },
      },
    [getTeamMembersState.context.data?.profiles]
  );

  const updateUrlFilters = useCallback(
    (filters: Partial<TeamMembersFilteringState>) => {
      const params = deserializePageParams(searchParams);
      if (filters.query) {
        params.currentPage = "1";
      }
      const newParams = { ...params, ...filters } as TeamMembersFilteringState;
      setSearchParams(serializePageParams(newParams));
    },
    [setSearchParams, searchParams]
  );

  useEffect(() => {
    const { currentPage, query } = deserializePageParams(searchParams);
    send(
      teamMemberActions.fetchTeamMembers({
        workspaceId,
        filters: {
          currentPage: Number(currentPage),
          pageSize: defaultPageSize,
          query,
        },
      })
    );
  }, [workspaceId, send, searchParams]);

  useEffect(() => {
    const matchesEditUrl = matches.find(
      ({ id }) => id === "dashboard-team-members-edit"
    );

    if (matchesEditUrl?.params.id) {
      send(teamMemberActions.editOpen({ id: matchesEditUrl.params.id }));
    }
  }, [searchParams, workspaceId, matches, send]);

  const showInviteTeamMember = useMemo(
    () => state.matches(TeamMembersState.InviteDialog),
    [state]
  );

  const showBulkInviteDialog = useMemo(
    () => state.matches(TeamMembersState.BulkInvite),
    [state]
  );

  useEffect(() => {
    if (
      editTeamMemberState.matches(FetchState.Success) ||
      deleteTeamMemberState.matches(FetchState.Success)
    ) {
      navigate(`/team-members${location.search}`);
    }
  }, [deleteTeamMemberState, editTeamMemberState, location.search, navigate]);

  const imageData = useMemo(() => {
    if (!selectedTeamMember || !token) return { token: null, key: null };
    return {
      token,
      key: generateWorkspaceProfileImageKey(
        selectedTeamMember.id,
        selectedTeamMember.workspace.workspace_id
      ),
    };
  }, [selectedTeamMember, token]);

  const toggleInviteModal = useCallback(() => {
    if (showInviteTeamMember) {
      send(teamMemberActions.inviteClose());
    } else {
      send(teamMemberActions.inviteOpen());
    }
  }, [send, showInviteTeamMember]);

  const toggleBulkInviteModal = useCallback(() => {
    if (showBulkInviteDialog) {
      send(teamMemberActions.bulkInviteDialogClose());
    } else {
      send(teamMemberActions.bulkInviteDialogOpen());
    }
  }, [send, showBulkInviteDialog]);

  const handleInviteTeamMember = useCallback(
    (params: ProfileInvite) => {
      send(
        teamMemberActions.inviteSend({
          variables: { ...params, workspaceId },
        })
      );
    },
    [send, workspaceId]
  );

  const handleBulkInvite = useCallback(
    ({ formData }: { formData: BulkInviteFormData }) => {
      send(teamMemberActions.bulkInviteSubmit({ formData }));
    },
    [send]
  );

  const handleBulkInviteConfirm = useCallback(
    (outcome: boolean | null) => {
      send(teamMemberActions.bulkInviteSubmitConfirmation({ outcome }));
    },
    [send]
  );

  const isInviteLoading = useMemo(
    () => inviteTeamMemberState.matches(FetchState.Fetching),
    [inviteTeamMemberState]
  );

  const isBulkInviteLoading = useMemo(
    () =>
      bulkInviteState.matches(FetchState.Fetching) ||
      state.matches({
        [TeamMembersState.BulkInvite]: TeamMemberBulkInviteState.Parsing,
      }),
    [bulkInviteState, state]
  );

  const bulkInviteImportCount = bulkInviteState.context.data?.importCount;
  const invites = state.context.invites;

  const handleDeleteOpen = useCallback(
    (teamMember: Profile) => {
      send(teamMemberActions.deleteOpen({ teamMember }));
    },
    [send]
  );

  const handleDeleteTeamMember = useCallback(
    (params: ProfileDelete) => {
      send(
        teamMemberActions.deleteSubmit({
          variables: { ...params },
        })
      );
    },
    [send]
  );

  const handleEditOpen = useCallback(
    (id: string) => navigate(`/team-members/edit/${id}${location.search}`),
    [location.search, navigate]
  );

  const handleEditTeamMember = useCallback(
    (params: ProfileUpdate) => {
      send(
        teamMemberActions.editSubmit({
          variables: { ...params },
        })
      );
    },
    [send]
  );

  const handleDeleteClose = useCallback(() => {
    send(teamMemberActions.deleteClose());
  }, [send]);

  const handleEditClose = useCallback(() => {
    navigate(`/team-members${location.search}`);
    send(teamMemberActions.editClose());
  }, [location.search, navigate, send]);

  const debouncedQueryChange = useMemo(
    () =>
      debounce((query: string) => {
        updateUrlFilters({ query });
      }, HIGH_INPUT_DEBOUNCE_TIME),
    [updateUrlFilters]
  );

  const handleQueryChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const query = e.currentTarget.value.trim();
      debouncedQueryChange(query);
    },
    [debouncedQueryChange]
  );

  const renderTeamMemberTagsContent = useCallback((tags: IBaseTag[]) => {
    if (!tags?.length) return null;
    const firstTag = (
      <p
        key={`tag-${tags[0].id}`}
        className={cn("text", "tiny", "bold", "tag", styles.status)}
      >
        {tags[0].text}
      </p>
    );

    return (
      <div className={styles.tagsContainer}>
        {firstTag}
        {tags.length > 1 && (
          <HoverCard.Root>
            <HoverCard.Trigger asChild>
              <span className="text tiny underline see-all">
                See all ({tags.length})
              </span>
            </HoverCard.Trigger>
            <HoverCard.Portal>
              <HoverCard.Content
                className={styles.tagsContent}
                sideOffset={5}
                side="top"
              >
                <div className={styles.tags}>
                  {tags.map((t) => {
                    return (
                      <p
                        key={`tag-${t.id}`}
                        className={cn(
                          "text",
                          "tiny",
                          "bold",
                          "tag",
                          styles.status
                        )}
                      >
                        {t.text}
                      </p>
                    );
                  })}
                </div>
                <HoverCard.Arrow className="hover-arrow" />
              </HoverCard.Content>
            </HoverCard.Portal>
          </HoverCard.Root>
        )}
      </div>
    );
  }, []);

  const workspaceTags = useMemo(() => {
    return getWorkspaceTagsMachineState?.context?.data || [];
  }, [getWorkspaceTagsMachineState?.context?.data]);

  const isRowLoaded = useCallback(
    ({ index }: { index: number }) => !!teamMembers.nodes[index],
    [teamMembers.nodes]
  );

  const loadMoreRows = useCallback(
    async ({ startIndex }: { startIndex: number }) => {
      const currentPage = Math.floor(startIndex / defaultPageSize) + 1;

      teamMembers.nodes.length < teamMembers.pageInfo.total &&
        send(
          teamMemberActions.fetchTeamMembers({
            workspaceId,
            filters: {
              currentPage,
              pageSize: defaultPageSize,
            },
            persisted: teamMembers.nodes,
          })
        );
    },
    [send, teamMembers.nodes, teamMembers.pageInfo.total, workspaceId]
  );

  const cellRenderer = useCallback(
    ({ rowIndex, dataKey }: { dataKey: string; rowIndex: number }) => {
      const teamMember = teamMembers.nodes[rowIndex];

      switch (dataKey) {
        case "name":
          return !teamMember ? (
            <SkeletonLoader />
          ) : (
            <div className={cn(styles.lineSection, styles.nameColumn)}>
              <div className={styles.userImageContainer}>
                <UserImage
                  isPublic={false}
                  profileId={teamMember.id}
                  containerClass={styles.imageContainer}
                  profileWorkspaceId={workspaceId}
                  fallbackFontAwesomeIconClass="fa fa-user"
                  alt="user-profile"
                />
                <p className="text">{teamMember.name}</p>
              </div>
            </div>
          );
        case "email":
          return !teamMember ? (
            <SkeletonLoader />
          ) : (
            <div className={cn(styles.lineSection, styles.emailColumn)}>
              <p className="text">{teamMember.email}</p>
            </div>
          );
        case "accountType":
          return !teamMember ? (
            <SkeletonLoader />
          ) : (
            <div className={cn(styles.lineSection, styles.accountTypeColumn)}>
              <p
                className={cn(
                  "text",
                  "tiny",
                  "bold",
                  styles.status,
                  mapFieldToStyle.type[teamMember.workspace?.access]
                )}
              >
                {startCase(toLower(teamMember.workspace?.access))}
              </p>
            </div>
          );
        case "status":
          return !teamMember ? (
            <SkeletonLoader />
          ) : (
            <div className={cn(styles.lineSection, styles.statusColumn)}>
              <p
                className={cn(
                  "text",
                  "tiny",
                  "bold",
                  styles.status,
                  mapFieldToStyle.status[teamMember.workspace?.status]
                )}
              >
                {startCase(toLower(teamMember.workspace?.status))}
              </p>
            </div>
          );
        case "tags":
          return !teamMember ? (
            <SkeletonLoader />
          ) : (
            <div className={cn(styles.lineSection, styles.tagsColumn)}>
              {renderTeamMemberTagsContent(teamMember.workspaceTags)}
            </div>
          );
        case "action":
          return !teamMember ? (
            <SkeletonLoader style={{ minWidth: "180px" }} />
          ) : (
            <div className={cn(styles.lineSection, styles.actionColumn)}>
              <button
                id="edit"
                onClick={() => handleEditOpen(teamMember.id)}
                type="button"
                className="btn secondary small"
              >
                <i className="fa-regular fa-pen" /> Edit
              </button>
              <button
                onClick={() => handleDeleteOpen(teamMember)}
                type="button"
                className="btn destructive small"
              >
                Delete
              </button>
            </div>
          );
        case "hasCompletedSetupSuccessfully":
          return !teamMember ? <SkeletonLoader style={{ minWidth: "50px" }} /> :  (teamMember.hasCompletedSetupSuccessfully ? "Yes" : "No");
        case "hasRegisteredSuccessfully":
          return !teamMember ? <SkeletonLoader style={{ minWidth: "50px" }} /> :  (teamMember.hasRegisteredSuccessfully ? "Yes" : "No");
        default:
          return null;
      }
    },
    [
      handleDeleteOpen,
      handleEditOpen,
      renderTeamMemberTagsContent,
      teamMembers.nodes,
      workspaceId,
    ]
  );

  const headerRowRenderer = ({
    className,
    columns,
    style,
  }: {
    className: string;
    columns: any[];
    style: any;
  }) => (
    <div className={cn(className, styles.headerRow)} role="row" style={style}>
      {columns}
    </div>
  );

  const teamMembersContent = useMemo(
    () => (
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={teamMembers.pageInfo.total}
        threshold={10}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ width, height }) => {
              return (
                <Table
                  width={width}
                  height={height}
                  headerHeight={40}
                  rowHeight={50}
                  onRowsRendered={onRowsRendered}
                  ref={registerChild}
                  rowCount={teamMembers.pageInfo.total}
                  rowGetter={({ index }) => teamMembers.nodes[index]}
                  rowClassName={({ index }) =>
                    cn(
                      styles.teamMember,
                      teamMembers.nodes[index]?.id === profile?.id &&
                        styles.current
                    )
                  }
                  headerRowRenderer={headerRowRenderer}
                >
                  <Column
                    width={calculateColumnWidths(width).name}
                    label={<span className="text-subtitle palest">Name</span>}
                    dataKey="name"
                    cellRenderer={cellRenderer}
                    className={cn(styles.lineSection, styles.nameColumn)}
                    cellDataGetter={(data) => data}
                  />
                  <Column
                    width={calculateColumnWidths(width).email}
                    label={<span className="text-subtitle palest">Email</span>}
                    dataKey="email"
                    cellRenderer={cellRenderer}
                    className={cn(styles.lineSection, styles.emailColumn)}
                    cellDataGetter={(data) => data}
                  />
                  <Column
                    width={calculateColumnWidths(width).accountType}
                    label={
                      <span className="text-subtitle palest">Account Type</span>
                    }
                    dataKey="accountType"
                    cellRenderer={cellRenderer}
                    className={cn(styles.lineSection, styles.accountTypeColumn)}
                    cellDataGetter={(data) => data}
                  />
                  <Column
                    width={calculateColumnWidths(width).status}
                    label={<span className="text-subtitle palest">Status</span>}
                    dataKey="status"
                    cellRenderer={cellRenderer}
                    className={cn(styles.lineSection, styles.statusColumn)}
                    cellDataGetter={(data) => data}
                  />
                  <Column
                    width={calculateColumnWidths(width).status}
                    label={
                      <span className="text-subtitle palest">Registered</span>
                    }
                    dataKey="hasRegisteredSuccessfully"
                    cellRenderer={cellRenderer}
                    className={cn(styles.lineSection, styles.statusColumn)}
                    cellDataGetter={(data) => data}
                  />
                  <Column
                    width={calculateColumnWidths(width).status}
                    label={
                      <span className="text-subtitle palest">
                        Completed Setup
                      </span>
                    }
                    dataKey="hasCompletedSetupSuccessfully"
                    cellRenderer={cellRenderer}
                    className={cn(styles.lineSection, styles.statusColumn)}
                    cellDataGetter={(data) => data}
                  />
                  <Column
                    width={calculateColumnWidths(width).tags}
                    label={<span className="text-subtitle palest">Tags</span>}
                    dataKey="tags"
                    cellRenderer={cellRenderer}
                    className={cn(styles.lineSection, styles.tagsColumn)}
                    cellDataGetter={(data) => data}
                  />
                  <Column
                    width={calculateColumnWidths(width).action}
                    minWidth={180}
                    dataKey="action"
                    cellRenderer={cellRenderer}
                    className={cn(styles.lineSection, styles.actionColumn)}
                    cellDataGetter={(data) => data}
                  />
                </Table>
              );
            }}
          </AutoSizer>
        )}
      </InfiniteLoader>
    ),
    [
      isRowLoaded,
      teamMembers.pageInfo.total,
      teamMembers.nodes,
      loadMoreRows,
      cellRenderer,
      profile?.id,
    ]
  );

  if (TEAM_MEMBERS_DISABLED) {
    return <Navigate to="/workshops" />;
  }

  return (
    <div className={styles.container}>
      <h3 className="thin">{TEAM_MEMBERS}</h3>

      <div className={styles.filtersAndPagination}>
        <div className={styles.inputStyle}>
          <i className="fa fa-search" />
          <input
            type="text"
            placeholder="Search by name or email..."
            onChange={handleQueryChange}
            ref={queryRef}
          />
        </div>

        <div className={styles.paginationAndInvite}>
          <button
            className={cn(styles.inviteBtn, "btn small")}
            onClick={toggleInviteModal}
          >
            <i className="fa-regular fa-plus" /> Invite people
          </button>

          {isCurrentAccountOwner && (
            <button
              className={cn(styles.inviteBtn, "btn small")}
              onClick={toggleBulkInviteModal}
            >
              <i className="fa-regular fa-plus" /> Bulk invite people
            </button>
          )}
        </div>
      </div>

      <div className={styles.teamMemberListContainer}>
        <div className={styles.teamMemberListBody}>
          {teamMembersLoading ? (
            <Loader className={styles.loaderContainer} />
          ) : teamMembers?.nodes.length ? (
            teamMembersContent
          ) : (
            <p className="text">Team Members not found.</p>
          )}
        </div>
      </div>

      {showInviteTeamMember && (
        <InviteTeamMemberModal
          workspaceTags={workspaceTags}
          inviteTeamMember={handleInviteTeamMember}
          onClose={toggleInviteModal}
          isLoading={isInviteLoading}
          errorMessage={error}
        />
      )}

      {showBulkInviteDialog && (
        <BulkInviteDialog
          isLoading={isBulkInviteLoading}
          errorMessage={error}
          invites={invites}
          bulkInviteImportCount={bulkInviteImportCount}
          onClose={toggleBulkInviteModal}
          onBulkInvite={handleBulkInvite}
          onBulkInviteConfirm={handleBulkInviteConfirm}
        />
      )}

      {selectedTeamMember && token && (
        <EditTeamMemberModal
          teamMember={selectedTeamMember}
          workspaceTags={workspaceTags}
          updateTeamMember={handleEditTeamMember}
          imageData={imageData}
          onClose={handleEditClose}
          isLoading={isEditLoading}
          errorMessage={error}
        />
      )}
      {teamMemberToBeDeleted && token && (
        <DeleteTeamMemberModal
          memberProfile={teamMemberToBeDeleted}
          deleteTeamMember={handleDeleteTeamMember}
          onClose={handleDeleteClose}
          isLoading={isDeleteLoading}
          errorMessage={error}
        />
      )}
    </div>
  );
};

export default memo(withRouteConfig(TeamMembers));
