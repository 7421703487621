import {
  PropsWithChildren,
  memo,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";

import { useMachine } from "@xstate/react";

import * as adminDashboardScheduleDialogActions from "../../../+xstate/actions/dashboard/admin-dashboard-schedule-dialog";

import { requestWorkshop } from "../../../+xstate/actions/dashboard/admin-dashboard";
import { requestWorkshopMachine } from "../../../+xstate/machines/dashboard/admin-dashboard";
import { adminDashboardScheduleDialogMachine } from "../../../+xstate/machines/dashboard/admin-dashboard-schedule-dialog";

import { FetchState } from "../../../+xstate/machines/fetch-factory";

import { AdminDashboardContext } from "../../../contexts/AdminDashboard";
import { Workshop } from "../../../types/contentful/workshop/workshop";

import TextInputDialog from "../../Shared/TextInputDialog/TextInputDialog";
import WorkshopList from "./components/WorkshopList/WorkshopList";
import { CONVERSATION_DISABLED_MEMBER } from "../../../constants/global";

function MemberWorkshopList(
  props: PropsWithChildren<{
    isDialogOpen: boolean;
    workshopsLoading: boolean;
    workshops: Workshop[];
    scheduleDialogMachine: ReturnType<
      typeof useMachine<typeof adminDashboardScheduleDialogMachine>
    >;
  }>
) {
  const { isDialogOpen, workshops, workshopsLoading, scheduleDialogMachine } =
    props;
  const { workshopId } = useParams();
  const navigate = useNavigate();
  const { actor: adminDashboardActor } = useContext(AdminDashboardContext);

  const [state, send] = scheduleDialogMachine;
  const { workshopId: contextWorkshopId } = state.context;

  const selectedWorkshopTitle = useMemo(
    () =>
      workshops.find((w) => w.sys.id === contextWorkshopId)?.fields.title || "",
    [contextWorkshopId, workshops]
  );

  const submitWorkshopRequest = useCallback(
    (message: string) => {
      return new Promise((res, rej) => {
        const snapshot = adminDashboardActor.getSnapshot();
        const requestJourneyActor = snapshot.children[
          requestWorkshopMachine.id
        ] as ReturnType<typeof useMachine<typeof requestWorkshopMachine>>["2"];
        workshopId &&
          adminDashboardActor.send(
            requestWorkshop({ id: workshopId, message })
          );
        const subscription = requestJourneyActor.subscribe((state) => {
          if (state.value === FetchState.Success) {
            res(state.context.data);
            subscription.unsubscribe();
          } else if (state.value === FetchState.Failure) {
            rej(state.context.data);
            subscription.unsubscribe();
          }
        });
      });
    },
    [adminDashboardActor, workshopId]
  );

  const closeDialogHandler = useCallback(() => {
    navigate("/workshops");
    send(adminDashboardScheduleDialogActions.closeScheduleDialog());
  }, [navigate, send]);

  if (CONVERSATION_DISABLED_MEMBER)
    return <Navigate to={"/schedule/my/upcoming"} />;

  return (
    <WorkshopList
      workshops={workshops}
      workshopsLoading={workshopsLoading}
      buttonText="Request"
    >
      {isDialogOpen && (
        <TextInputDialog
          submitButtonText="Request access"
          dialogTitle={`Request ${selectedWorkshopTitle}`}
          label={"Your message here (optional)"}
          textAreaPlaceholder={"Your message here..."}
          successAlertHeading={"Conversation requested successfully"}
          errorAlertHeading={"Error requesting conversations"}
          closeDialogHandler={closeDialogHandler}
          submitDialogHandler={submitWorkshopRequest}
        />
      )}
    </WorkshopList>
  );
}

export default memo(MemberWorkshopList);
