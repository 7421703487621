import { read, utils } from "xlsx";
import {
  ProfileWorkspaceAccess,
  ProfileWorkspaceStatus,
} from "../apollo-graphql/types/enums";

export function parseXLSX<T = Record<string, any>[]>(file: File) {
  return new Promise<{ data: T; columns: string[] | null }>((res, rej) => {
    if (!file) return void rej(new Error("No file!"));
    const reader = new FileReader();
    reader.onload = function (e) {
      if (!e.target) return rej(new Error("No target found!"));
      const data = e.target.result as ArrayBuffer;
      const workbook = read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = utils.sheet_to_json(worksheet);
      const columns = jsonData ? Object.keys(jsonData[0] as object) : null;
      res({ data: jsonData as T, columns });
    };
    reader.readAsArrayBuffer(file);
  });
}

export function parseXLSXInvites(config: {
  file: File;
  nameKey: string | [string] | [string, string] | [string, string, string];
  emailKey: string;
  jobTitleKey?: string;
  accessKey?: string;
  statusKey?: string;
  defaultAccess?: ProfileWorkspaceAccess;
  defaultStatus?: ProfileWorkspaceStatus;
}): Promise<
  {
    name: string;
    email: string;
    jobTitle: string;
    access: ProfileWorkspaceAccess;
    status: ProfileWorkspaceStatus;
  }[]
> {
  const {
    file,
    nameKey,
    emailKey,
    jobTitleKey,
    accessKey,
    statusKey,
    defaultAccess,
    defaultStatus,
  } = config;
  return parseXLSX(file).then(({ data, columns }) => {
    const missingKeys = [emailKey, jobTitleKey, accessKey, statusKey]
      .concat(nameKey)
      .map((key) => (key && columns?.includes(key) ? null : key))
      .filter((val) => !!val);
    if (missingKeys.length > 0) {
      const error = new Error(
        `The key(s) that you've entered: ${missingKeys.join(
          ", "
        )} do not match any of the columns in the document: ${columns?.join(
          ", "
        )}`
      );
      return Promise.reject(error);
    }
    return data.map((r) => {
      const name = ([] as string[])
        .concat(nameKey)
        .reduce((acc, curr) => acc + " " + r[curr], "")
        .trim();
      const email = r[emailKey];
      const jobTitle = jobTitleKey ? r[jobTitleKey] : "";
      const access =
        (accessKey ? r[accessKey] : defaultAccess) ||
        ProfileWorkspaceAccess.TEAM_MEMBER;
      const status =
        (statusKey ? r[statusKey] : defaultStatus) ||
        ProfileWorkspaceStatus.ACTIVE;
      return { name, email, jobTitle, access, status };
    });
  });
}
