export function props<D extends object | void>() {
  return {} as unknown as D;
}

export function createAction<T extends string, P extends object | void>(
  type: T,
  payload?: P
) {
  function actionCreator(payload: P) {
    return {
      type,
      payload,
    };
  }
  actionCreator.type = type;
  return actionCreator;
}

export function checkState<MS extends object = any, TS = any>(
  machineState: MS,
  targetState: TS[]
) {
  let isMatch = false;
  const currentMachineState = machineState;
  for (const ts of targetState) {
    isMatch = !!currentMachineState[ts as keyof MS];
    if (isMatch) return false;
  }
  return true;
}
