import { memo, useMemo } from "react";

import { Cross2Icon } from "@radix-ui/react-icons";
import * as Dialog from "@radix-ui/react-dialog";
import * as Form from "@radix-ui/react-form";

import { Slot } from "../../../apollo-graphql/types/slot";
import CopyToClipboardButton from "../../CopyToClipboardButton/CopyToClipboardButton";

import styles from "./InviteTeamMembersModal.module.css";
import cn from "classnames";

interface InviteTeamMembersModalProps {
  slot: Slot | null;
  closeDialogHandler: () => void;
}

const InviteTeamMembersModal = ({
  slot,
  closeDialogHandler,
}: InviteTeamMembersModalProps) => {
  const slotUrl = useMemo(
    () => (slot ? `${window.location.origin}/session/slot/${slot.id}` : ""),
    [slot]
  );

  return (
    <Dialog.Root open={true} onOpenChange={closeDialogHandler}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className={cn("DialogContent", styles.dialogContent)}>
          <Dialog.Title className="DialogTitle">
            Invite team members
          </Dialog.Title>
          <div className={styles.content}>
            <div>
              <Form.Root>
                <Form.Field name="password" className={styles.formField}>
                  <Form.Label className={cn("caption", "small", styles.label)}>
                    Invitation link
                  </Form.Label>
                  <Form.Control
                    className={cn("input", "small", styles.inputField)}
                    value={slotUrl}
                    readOnly
                    onClick={(event) =>
                      (event.target as HTMLInputElement).setSelectionRange(
                        0,
                        slotUrl.length
                      )
                    }
                  />
                </Form.Field>
              </Form.Root>
            </div>
            <CopyToClipboardButton
              copyText={slotUrl}
              displayText="Copy link"
              className={styles.button}
              hasIcon={false}
            />
          </div>
          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close">
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default memo(InviteTeamMembersModal);
