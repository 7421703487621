import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { IUpdatePasswordResponse } from "../types/results/update-password-result";
import { IUpdatePassword } from "../types/update-password";

const mutation = gql`
  mutation Mutation(
    $token: String!
    $newPassword: String!
    $repeatPassword: String!
  ) {
    updatePassword(
      token: $token
      newPassword: $newPassword
      repeatPassword: $repeatPassword
    ) {
      success
    }
  }
`;

export function updatePassword(
  client: AppApolloClient,
  variables: IUpdatePassword
) {
  return client
    .mutate<IUpdatePasswordResponse>({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.updatePassword;
    });
}
