import { PropsWithChildren, memo, useMemo } from "react";

import { IComparisonItem } from "../../types";

import ComparisonCard from "../ComparisonCard/ComparisonCard";

interface ComparisonItemProps {
  comparisonItem: IComparisonItem;
  value: string;
  isParticipating: boolean;
  currentProfileSelectedOptions: {
    [key: string]: string | null;
  };
  otherProfileSelectedOptions: {
    profileId: string;
    value: { [key: string]: string | null };
  }[];
  hasBorderBottom?: boolean;
  shouldBeOpen?: boolean;
  profileId: string;
  currentActiveParticipants: string[];
  disabled: boolean;
  handleSelection: (optionId: string, sectionType: string) => void;
}

const ComparisonItem = (props: PropsWithChildren<ComparisonItemProps>) => {
  const {
    comparisonItem,
    currentProfileSelectedOptions,
    otherProfileSelectedOptions,
    hasBorderBottom,
    shouldBeOpen,
    profileId,
    currentActiveParticipants,
    isParticipating,
    value,
    disabled,
    handleSelection,
  } = props;

  const leftHeaderNode = useMemo(
    () => <span className="text bold">{comparisonItem.referenceLabel}</span>,
    [comparisonItem.referenceLabel]
  );
  const rightHeaderNode = useMemo(
    () => <span className="text bold">{comparisonItem.generatedLabel}</span>,
    [comparisonItem]
  );
  const titleNode = useMemo(
    () => <span className="text bold">{comparisonItem.title}</span>,
    [comparisonItem?.title]
  );

  return (
    <ComparisonCard
      isCollapsible
      sectionType={comparisonItem.id}
      titleNode={titleNode}
      leftHeaderNode={leftHeaderNode}
      rightHeaderNode={rightHeaderNode}
      optionTitleNode={comparisonItem.questionNode}
      options={comparisonItem.answers}
      shouldBeCollapsed={shouldBeOpen}
      sectionLeftTextNode={comparisonItem.referenceTextNode}
      sectionRightText={value}
      isParticipating={isParticipating}
      currentProfileSelectedOptions={currentProfileSelectedOptions}
      otherProfileSelectedOptions={otherProfileSelectedOptions}
      profileId={profileId}
      currentActiveParticipants={currentActiveParticipants}
      hasBorderBottom={hasBorderBottom}
      disabled={disabled}
      handleSelection={(optionId, sectionType) =>
        handleSelection(optionId, sectionType)
      }
    />
  );
};

export default memo(ComparisonItem);
