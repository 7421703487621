import {
  FormEvent,
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";

import { v4 } from "uuid";
import { diff } from "deep-diff";

import useServerTime from "../../../../hooks/useServerTime";
import { useReadyRemovedDueToChange } from "../../../../hooks/useReadyRemovedDueToChange";

import { getEntryId, parseToJson } from "../../../../utils";
import {
  IDragAndDropCard,
  ISection,
  SectionTypes,
} from "../../../Shared/DragAndDrop/types";
import { normalConnectionThreshold } from "../../../../contexts/Apollo";
import { ActionFooterType } from "../../../../types/action-footer";
import { FooterType } from "../../../../types/enums/activity-footer";
import { ActivityCommon } from "../../../../types/activity-common";
import { IActivityResult } from "../../../../apollo-graphql/types/session-state";
import { AhaMoment } from "../../../../types/contentful/workshop/aha-moment";
import { StandardSessionActivity } from "../../../../apollo-graphql/types/enums";

import { observerFooterData } from "../constants";
import { ACTIVITY_TIMEOUT_VALUE } from "../../../../constants/global";

import ActionFooter from "../../ActionFooter/ActionFooter";
import InfoBox from "../../../InfoBox/InfoBox";
import LoadingButton from "../../../Shared/Buttons/LoadingButton/LoadingButton";
import DragAndDrop from "../../../Shared/DragAndDrop/DragAndDrop";
import DragAndDropSectionList from "../../../Shared/DragAndDrop/DragAndDropSectionList/DragAndDropSectionList";
import ScrollIndicator from "../../../Shared/ScrollIndicator/ScrollIndicator";
import ActivityInstructions from "../../../Shared/ActivityInstructions/ActivityInstructions";
import { ConferenceMode } from "../../../../types/contentful/enums";

import cn from "classnames";
import styles from "./Moment.module.css";

interface MomentProps
  extends Omit<
    ActivityCommon,
    "activity" | "isActivityTimeout" | "hasAhaMoments"
  > {
  activityResult: IActivityResult[];
  activityId:
    | StandardSessionActivity.MomentIndividual
    | StandardSessionActivity.MomentGroup;
  profileId: string;
  workshopAuthor: {
    id: string;
    name: string;
    headline: string;
    image: {
      url: string;
      fileName: string;
    };
  };
  ahaMoments: AhaMoment[];
  setActivityValueHandler: (args: {
    activityId: string;
    value: string;
  }) => void;
}

const Moment = (props: PropsWithChildren<MomentProps>) => {
  const {
    activityId,
    activityResult,
    profileId,
    workshopAuthor,
    ahaMoments,
    isReady,
    transition,
    isLoading,
    isConnectionWeak,
    notReadyProfilesCount,
    currentActiveParticipantCount,
    isParticipating,
    setActivityValueHandler,
    setActivityReadyHandler,
  } = props;

  const serverTimestampRef = useServerTime();
  const { changeStateText, userValueChangeHandler } =
    useReadyRemovedDueToChange(isReady);

  const allMomentsRef = useRef<IDragAndDropCard[]>([]);
  const newMomentIdRef = useRef<string | null>(null);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const isTransitioning = useMemo(() => transition > 0, [transition]);

  const delayInMilliseconds = useMemo(
    () => (isConnectionWeak ? 0 : normalConnectionThreshold),
    [isConnectionWeak]
  );

  const setValueHandler = useCallback(
    (value: string) => {
      setActivityValueHandler({
        activityId,
        value,
      });
      userValueChangeHandler();
    },
    [activityId, setActivityValueHandler, userValueChangeHandler]
  );

  const { currentProfileMoments, teamMoments } = useMemo(() => {
    const individualResults = (
      activityResult?.find(
        (v) => v.key === StandardSessionActivity.MomentIndividual
      )?.value || []
    ).filter((res) => !!res.value && res.value !== ACTIVITY_TIMEOUT_VALUE);
    const groupResults = (
      activityResult?.find((v) => v.key === StandardSessionActivity.MomentGroup)
        ?.value || []
    ).filter((res) => !!res.value && res.value !== ACTIVITY_TIMEOUT_VALUE);

    const currentProfileMoments = parseToJson<{
      moments: IDragAndDropCard[];
    }>(
      individualResults?.find((r) => r.profileId === profileId)?.value || null,
      { moments: [] }
    ).moments;

    const teamMomentsRaw =
      (activityId === StandardSessionActivity.MomentIndividual
        ? individualResults
        : groupResults.length > 0
        ? groupResults
        : individualResults) || [];

    let teamMoments: IDragAndDropCard[] = [];
    if (activityId === StandardSessionActivity.MomentIndividual) {
      teamMoments = teamMomentsRaw
        .filter((r) => r.profileId !== profileId)
        ?.flatMap(
          (r) =>
            parseToJson<{
              moments: IDragAndDropCard[];
            }>(r.value, { moments: [] }).moments
        );
    }

    if (activityId === StandardSessionActivity.MomentGroup) {
      if (groupResults.length === 0) {
        teamMoments = teamMomentsRaw?.flatMap(
          (r) =>
            parseToJson<{
              moments: IDragAndDropCard[];
            }>(r.value, { moments: [] }).moments
        );

        setValueHandler(JSON.stringify({ moments: teamMoments }));
      } else {
        teamMoments = parseToJson<{
          moments: IDragAndDropCard[];
        }>(teamMomentsRaw[0]?.value, { moments: [] }).moments;
      }
    }

    teamMoments = teamMoments.map((t, idx, arr) => ({
      ...t,
      index: arr.length - idx,
    }));

    return {
      currentProfileMoments,
      teamMoments,
    };
  }, [activityId, activityResult, profileId, setValueHandler]);

  const allMoments = useMemo(() => {
    if (isLoading) return allMomentsRef.current;

    const moments = currentProfileMoments
      .concat(teamMoments)
      .sort((a, b) => (a.timestamp! > b.timestamp! ? -1 : 1))
      .map((m, idx, arr) => {
        const index = arr.length - idx;
        const title = `${
          m.profileId === profileId
            ? "Your “Aha Moment”"
            : "A team member’s “Aha Moment”"
        }`;

        return { ...m, index, title };
      });

    allMomentsRef.current = moments;

    return moments;
  }, [profileId, currentProfileMoments, isLoading, teamMoments]);

  const mappedAuthorMoments = useMemo(() => {
    return (ahaMoments || []).map((m) => {
      const dragAndDropCard: IDragAndDropCard = {
        id: getEntryId(m),
        profileId: workshopAuthor.id,
        title: `${workshopAuthor.name}’s “Aha Moment”`,
        text: m.fields.title,
        isDraggable: true,
      };
      return dragAndDropCard;
    });
  }, [ahaMoments, workshopAuthor.id, workshopAuthor.name]);

  const authorMoments = useMemo(() => {
    return activityId === StandardSessionActivity.MomentGroup
      ? mappedAuthorMoments.filter(
          (m) => !teamMoments.find((am) => am.id === m.id)
        )
      : mappedAuthorMoments;
  }, [activityId, mappedAuthorMoments, teamMoments]);

  const setGroupValueHandler = useCallback(
    (updatedSection: ISection) => {
      const changes = diff(teamMoments, updatedSection.sectionB);
      if (!changes?.length) return;

      setValueHandler(JSON.stringify({ moments: updatedSection.sectionB }));
    },
    [setValueHandler, teamMoments]
  );

  const onSubmitHandler = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!isParticipating) return;

      const payload = Object.fromEntries(new FormData(event.currentTarget)) as {
        text: string;
      };
      const text = payload.text.trim();
      if (!text.length) return;

      newMomentIdRef.current = v4();

      const newMoment: IDragAndDropCard = {
        id: newMomentIdRef.current,
        text,
        title: "Your “Aha Moment”",
        profileId: profileId,
        isDraggable: false,
        timestamp: serverTimestampRef.current + Math.random(),
      };

      setValueHandler(
        JSON.stringify({ moments: [newMoment, ...currentProfileMoments] })
      );
    },
    [
      isParticipating,
      profileId,
      serverTimestampRef,
      setValueHandler,
      currentProfileMoments,
    ]
  );

  const actionFooterData: ActionFooterType = useMemo(() => {
    if (!isParticipating) return observerFooterData;
    if (isTransitioning) {
      return {
        text: <>Everyone is ready. Continuing forward...</>,
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Ready,
      };
    }

    if (isReady) {
      return {
        text: (
          <>
            Waiting for{" "}
            <span className="accent">
              {notReadyProfilesCount} more player
              {notReadyProfilesCount > 1 && "s"}...
            </span>
          </>
        ),
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Waiting,
      };
    }

    const playersClicked =
      currentActiveParticipantCount - notReadyProfilesCount;

    return {
      text: (
        <>
          {changeStateText}Once you are ready, click “Continue”.{" "}
          {playersClicked > 0 && (
            <span className="accent">
              {playersClicked} player{playersClicked > 1 && "s"} clicked.
            </span>
          )}
        </>
      ),
      buttonText: "Continue",
      disabledButton: false,
      type: FooterType.Notice,
    };
  }, [
    isTransitioning,
    isParticipating,
    isReady,
    notReadyProfilesCount,
    currentActiveParticipantCount,
    changeStateText,
  ]);

  const individualPartContent = useMemo(
    () => (
      <>
        <h3 className="thin">What “Aha Moments” have you experienced?</h3>
        <ActivityInstructions
          instructions={`Let’s summarise what insights or “Aha Moments” you’ve experienced
              during this workshop:`}
          tags={[]}
          type={ConferenceMode.All}
        />
        <div className={styles.individualContent}>
          <form
            className={styles.shareMomentContainer}
            onSubmit={onSubmitHandler}
          >
            <div className={styles.textAreaContainer}>
              <p className="text bold">
                Share your “Aha Moments” with your team:
              </p>
              <textarea
                ref={textAreaRef}
                className="text input-element"
                name="text"
                placeholder="Share your Aha moment..."
                disabled={isLoading || !isParticipating}
              />

              <div className={styles.actionContainer}>
                <LoadingButton
                  type="submit"
                  size="small"
                  iconPosition="end"
                  iconClass="fa fa-arrow-right"
                  disabled={isTransitioning || isLoading || !isParticipating}
                  isLoading={isLoading}
                  delayInMilliseconds={delayInMilliseconds}
                >
                  <span className="button-text">Share</span>
                </LoadingButton>
              </div>
            </div>
          </form>
          <div className={styles.line}></div>
          <DragAndDropSectionList
            id={SectionTypes.SECTION_B}
            sectionTitle={
              "Shared “Aha Moments” <span class='text secondary'>(visible to everyone)</span>:"
            }
            currentProfileId={profileId}
            isDisabled={isTransitioning || isLoading || !isParticipating}
            items={allMoments}
            hasDraggableIcon={false}
            noContent={
              <InfoBox
                transparent
                title="All shared “Aha Moments” will be listed here."
              />
            }
          />
        </div>
      </>
    ),
    [
      onSubmitHandler,
      isParticipating,
      isLoading,
      isTransitioning,
      delayInMilliseconds,
      profileId,
      allMoments,
    ]
  );

  const workshopAuthorImage = useMemo(
    () => ({
      profileId: workshopAuthor.id,
      src: workshopAuthor.image.url,
      fallbackFontAwesomeIconClass: "fa fa-user",
    }),
    [workshopAuthor.id, workshopAuthor.image.url]
  );

  const groupPartContent = useMemo(() => {
    return (
      <>
        <h3 className="thin">
          Did you also experience the author’s “Aha Moments”?
        </h3>
        <ActivityInstructions
          instructions={`<p className="subtitle">
            <span className="text bold">
              Have you also experienced the “Aha Moments” designed by${" "}
              ${workshopAuthor.name}
            </span>
            ? If “Yes” add them to your list by dragging them from left to right.
          </p>`}
          tags={[]}
          type={ConferenceMode.All}
        />
        <DragAndDrop
          sectionA={authorMoments}
          sectionB={teamMoments}
          sectionATitle={`${workshopAuthor.name}’s “Aha Moments”:`}
          sectionBTitle={"Your team’s “Aha Moments”:"}
          currentProfileId={profileId}
          isTransitioning={isTransitioning}
          isLoading={isLoading}
          disableDragAndDrop={!isParticipating}
          setValueHandler={setGroupValueHandler}
          sectionANoContent={
            <InfoBox
              title={`All "Aha Moments" were selected!`}
              description={`All of ${workshopAuthor.name}’s “Aha Moments” have been added to your team's "Aha Moments" list.`}
            />
          }
          sectionBNoContent={
            <InfoBox
              title="No “Aha Moments” were shared yet!"
              description="All shared “Aha Moments” will be listed here."
            />
          }
          image={workshopAuthorImage}
          dropElementAtFirstPosition
          hasDraggableIcon
          hasBadge
        />
      </>
    );
  }, [
    workshopAuthor.name,
    authorMoments,
    teamMoments,
    profileId,
    isTransitioning,
    isLoading,
    isParticipating,
    setGroupValueHandler,
    workshopAuthorImage,
  ]);

  const content = useMemo(() => {
    return (
      <>
        <div
          className={cn(
            styles.momentContainer,
            activityId === StandardSessionActivity.MomentIndividual
              ? "individual"
              : "group"
          )}
        >
          {activityId === StandardSessionActivity.MomentIndividual
            ? individualPartContent
            : groupPartContent}
        </div>
      </>
    );
  }, [activityId, individualPartContent, groupPartContent]);

  useEffect(() => {
    if (
      !newMomentIdRef.current ||
      !textAreaRef.current ||
      activityId !== StandardSessionActivity.MomentIndividual
    )
      return;
    if (allMoments.find(({ id }) => id === newMomentIdRef.current)) {
      newMomentIdRef.current = null;
      textAreaRef.current.value = "";
    }
  }, [allMoments, activityId]);

  return (
    <div className={styles.container}>
      <ScrollIndicator className={cn(styles.contentWrapper, "main-container")}>
        <div className={cn(styles.content, "moment-content")}>{content}</div>
      </ScrollIndicator>
      <ActionFooter
        buttonText={actionFooterData.buttonText}
        type={actionFooterData.type}
        isLoading={isLoading}
        isConnectionWeak={isConnectionWeak}
        disabledButton={actionFooterData.disabledButton}
        buttonClickHandler={() => {
          setActivityReadyHandler({ activityId });
        }}
      >
        {actionFooterData.text}
      </ActionFooter>
    </div>
  );
};

export default memo(Moment);
