import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { Workshop } from "../../types/contentful/workshop/workshop";
import { GET_WORKSHOP_QUERY } from "../constants";

const getWorkshopsQuery = gql`
  query getContentfulWorkshops($workspaceId: String!, $searchText: String) {
    getContentfulWorkshops(workspaceId: $workspaceId, searchText: $searchText) {
     ${GET_WORKSHOP_QUERY}
  }
  }
`;

const getWorkshopQuery = gql`
  query GetContentfulWorkshop($workspaceId: String!, $id: String!) {
    getContentfulWorkshop(workspaceId: $workspaceId, id: $id) {
      ${GET_WORKSHOP_QUERY}
    }
  }
`;

// Get all workshops for all journeys
export function getJourneyWorkshops(
  client: AppApolloClient,
  variables: {
    workspaceId: string;
    searchText?: string;
  }
) {
  return client
    .query<{ getContentfulWorkshops: Workshop[] }>({
      query: getWorkshopsQuery,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data.getContentfulWorkshops;
    });
}

// Get specific workshop
export function getWorkshop(
  client: AppApolloClient,
  variables: { workspaceId: string; id: string }
) {
  return client
    .query<{ getContentfulWorkshop: Workshop }>({
      query: getWorkshopQuery,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data.getContentfulWorkshop;
    });
}
