import "./App.css";
import Router from "./Router";
import { GlobalContextProvider } from "./contexts/Global";
import { ApolloProvider } from "./contexts/Apollo";
import { PropsWithChildren, useEffect, useState } from "react";
import { DocumentVisibilityContextProvider } from "./contexts/DocumentVisibility";
import { InternetConnectionContextProvider } from "./contexts/InternetConnection";
import { DevicePermissions } from "./utils/check-device-permissions";
import { AuthCtx } from "./types/auth-ctx";
import { TechnicalSetupConfig } from "./types/technical-setup-config";
import { checkForApplicationUpdate } from "./utils/application-update-check";
import { ApplicationUpdateConfirmation } from "./components/Shared/ApplicationUpdateConfirmation/ApplicationUpdateConfirmation";
import { isDevelopmentEnv } from "./constants/environment";

function App(
  props: PropsWithChildren<{
    authCtx: AuthCtx | null;
    instanceUUID: string;
    devicePermissions: DevicePermissions;
    technicalSetupConfig: TechnicalSetupConfig | null;
    skipTechnicalSetup?: boolean;
  }>
) {
  const {
    authCtx,
    instanceUUID,
    devicePermissions,
    technicalSetupConfig,
    skipTechnicalSetup,
  } = props;

  const [hasApplicationUpdate, setHasApplicationUpdate] = useState(false);

  useEffect(() => {
    if (isDevelopmentEnv) return;
    const checkForUpdate = () => {
      checkForApplicationUpdate().then((hasUpdate) => {
        if (hasUpdate) setHasApplicationUpdate(hasUpdate);
      });
    };

    setInterval(checkForUpdate, 5 * 60000);
  }, []);

  return (
    <div className="App">
      {hasApplicationUpdate && <ApplicationUpdateConfirmation />}
      <InternetConnectionContextProvider>
        <DocumentVisibilityContextProvider>
          <ApolloProvider>
            <GlobalContextProvider
              authCtx={authCtx}
              instanceUUID={instanceUUID}
              devicePermissions={devicePermissions}
              technicalSetupConfig={technicalSetupConfig}
              skipTechnicalSetup={skipTechnicalSetup}
            >
              <Router />
            </GlobalContextProvider>
          </ApolloProvider>
        </DocumentVisibilityContextProvider>
      </InternetConnectionContextProvider>
    </div>
  );
}

export default App;
