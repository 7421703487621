import {
  createMachine,
  assign,
  spawnChild,
  sendTo,
  raise,
  fromCallback,
} from "xstate";
import * as actions from "../actions/auth";
import { login } from "../../apollo-graphql/mutations/login";
import { updateProfile } from "../../apollo-graphql/mutations/update-profile";
import { Profile } from "../../apollo-graphql/types/profile";
import {
  getJourneyParticipationData,
  getMyProfile,
  setProfileAsCompleted,
} from "../../apollo-graphql/queries/profile";
import { AppApolloClient } from "../../contexts/Apollo";
import { fetchMachineFactory, FetchState } from "./fetch-factory";
import { updatePassword } from "../../apollo-graphql/mutations/update-password";
import { resetPassword } from "../../apollo-graphql/queries/reset-password";
import {
  deleteImageFromS3,
  getPreSignedUrl,
  uploadImageToS3,
} from "../../fetch/image";
import { generateWorkspaceProfileImageKey } from "../../utils";
import { getUnixTime } from "date-fns";
import { refreshMyProfileChannel } from "../../constants/channels";
import {
  technicalSetupConfig,
  authContextLocalStorageName,
} from "../../constants/environment";
import { useMachine } from "@xstate/react";
import { Jitsi, JitsiEvents } from "../../jitsi";
import { putItem, deleteItem } from "../../utils/db";
import { SourceData } from "../../types/source-data";
import { TechnicalSetupConfig } from "../../types/technical-setup-config";
import { RouteConfigData } from "../../hocs/withRouteConfig";
import { technicalSetupHelp } from "../../apollo-graphql/queries/technical-setup-help";
import { validateUpdatePasswordToken } from "../../apollo-graphql/queries/validate-update-password-token";
import { parseAuthenticationJWT } from "../../utils/parse-jwt";
import { refreshTokens } from "../../apollo-graphql/mutations/refresh-tokens";
import { ApolloServerErrorCodes } from "../../types/server-error";
import { getInvitation } from "../../apollo-graphql/mutations/get-invitation";

export enum AuthState {
  Initial = "Initial",
  Authenticated = "authenticated",
  Validating = "validating",
  Unauthenticated = "unauthenticated",
  Authenticating = "authenticating",
  UpdateProfile = "updateProfile",
  TechnicalSetup = "technicalSetup",
  GettingInvitation = "gettingInvitation",
}

export enum TechnicalSetupHelpOutcome {
  Success = "success",
  Failure = "failure",
}

export interface AuthMachineContext {
  client: AppApolloClient;
  profile: Profile | null;
  unauthenticatedUserEmail: string | null;
  token: string | null;
  refresh: string | null;
  presignedUrl: null | string;
  fileForUpload: null | File;
  imageLastUpdatedTimeStamp: null | number;
  jitsiInstance: Jitsi | null;
  availableAudioDevices: SourceData[] | null;
  selectedAudioDevice: SourceData | null;
  availableVideoDevices: SourceData[] | null;
  selectedVideoDevice: SourceData | null;
  connectionSuccess: boolean | null;
  routeTitle: string | null;
  routeData: RouteConfigData | null;
  skipTechnicalSetup: boolean;
  technicalSetupHelpOutcome: TechnicalSetupHelpOutcome | null;
  tokenExpiryTimeoutId: number | null;
}

export enum ProfileSettings {
  Default = "DEFAULT",
  UpdateProfile = "UPDATE_PROFILE",
  UpdateProfile_Default = "UPDATE_PROFILE__DEFAULT",
  UpdateProfile_GettingPresignedUrl = "UPDATE_PROFILE__GETTING_PRESIGNED_URL",
  UpdateProfile_UploadingProfileImage = "UPDATE_PROFILE__UPLOADING_PROFILE_IMAGE",
  UpdateProfile_DeleteProfileImage = "UPDATE_PROFILE__DELETE_PROFILE_IMAGE",
  ChangePassword = "CHANGE_PASSWORD",
}

type AllAuthActionCreators = typeof actions;
type AllAuthActionCreatorKeys = keyof AllAuthActionCreators;
type AllAuthActions =
  | ReturnType<AllAuthActionCreators[AllAuthActionCreatorKeys]>
  | ReturnType<typeof validateUpdatePasswordTokenSuccess>
  | ReturnType<typeof validateUpdatePasswordTokenFailure>
  | ReturnType<typeof updateProfileSuccess>
  | ReturnType<typeof updateProfileFailure>
  | ReturnType<typeof resetPasswordSuccess>
  | ReturnType<typeof resetPasswordFailure>
  | ReturnType<typeof updatePasswordSuccess>
  | ReturnType<typeof updatePasswordFailure>
  | ReturnType<typeof getPreSignedUrlSuccess>
  | ReturnType<typeof getPreSignedUrlFailure>
  | ReturnType<typeof uploadProfileImageSuccess>
  | ReturnType<typeof uploadProfileImageFailure>
  | ReturnType<typeof deleteProfileImageSuccess>
  | ReturnType<typeof deleteProfileImageFailure>
  | ReturnType<typeof getMyProfileSuccess>
  | ReturnType<typeof getMyProfileFailure>
  | ReturnType<typeof getJourneyParticipationDataSuccess>
  | ReturnType<typeof getJourneyParticipationDataFailure>
  | ReturnType<typeof technicalSetupHelpSuccess>
  | ReturnType<typeof technicalSetupHelpFailure>
  | ReturnType<typeof setProfileAsCompletedSuccess>
  | ReturnType<typeof setProfileAsCompletedFailure>
  | ReturnType<typeof refreshTokensSuccess>
  | ReturnType<typeof refreshTokensFailure>
  | ReturnType<typeof loginSuccess>
  | ReturnType<typeof loginFailure>
  | ReturnType<typeof getInvitationSuccess>
  | ReturnType<typeof getInvitationFailure>
  | ReturnType<typeof getInvitationReset>;

type AuthMachineTypes = {
  context: AuthMachineContext;
  events: AllAuthActions;
};

export const {
  machine: loginMachine,
  success: loginSuccess,
  failure: loginFailure,
  trigger: loginTrigger,
} = fetchMachineFactory({
  id: "login",
  invokeFn: ({
    client,
    payload,
  }: {
    client: AppApolloClient;
    payload: ReturnType<AllAuthActionCreators["login"]>["payload"];
  }) => {
    return login(client, payload.variables);
  },
});

export const {
  machine: getInvitationMachine,
  success: getInvitationSuccess,
  failure: getInvitationFailure,
  trigger: getInvitationTrigger,
  reset: getInvitationReset,
} = fetchMachineFactory({
  id: "getInvitation",
  invokeFn: ({
    client,
    payload,
  }: {
    client: AppApolloClient;
    payload: ReturnType<AllAuthActionCreators["getInvitation"]>["payload"];
  }) => {
    return getInvitation(client, payload.variables);
  },
});

export const {
  machine: validateUpdatePasswordTokenMachine,
  success: validateUpdatePasswordTokenSuccess,
  failure: validateUpdatePasswordTokenFailure,
  trigger: validateUpdatePasswordTokenTrigger,
} = fetchMachineFactory({
  id: "validateToken",
  invokeFn: ({
    client,
    payload,
  }: {
    client: AppApolloClient;
    payload: ReturnType<AllAuthActionCreators["validateToken"]>["payload"];
  }) => {
    return validateUpdatePasswordToken(client, payload.variables);
  },
});

export const {
  machine: getMyProfileMachine,
  success: getMyProfileSuccess,
  failure: getMyProfileFailure,
  trigger: getMyProfileTrigger,
} = fetchMachineFactory({
  id: "getMyProfile",
  invokeFn: ({ client }: { client: AppApolloClient }) => {
    return getMyProfile(client);
  },
});

export const {
  machine: setProfileAsCompletedMachine,
  success: setProfileAsCompletedSuccess,
  failure: setProfileAsCompletedFailure,
  trigger: setProfileAsCompletedTrigger,
} = fetchMachineFactory({
  id: "setProfileAsCompleted",
  invokeFn: ({ client }: { client: AppApolloClient }) => {
    return setProfileAsCompleted(client);
  },
});

export const {
  machine: getJourneyParticipationDataMachine,
  success: getJourneyParticipationDataSuccess,
  failure: getJourneyParticipationDataFailure,
  trigger: getJourneyParticipationDataTrigger,
} = fetchMachineFactory({
  id: "getJourneyParticipationData",
  invokeFn: ({ client }: { client: AppApolloClient }) => {
    return getJourneyParticipationData(client);
  },
});

export const {
  machine: updateProfileMachine,
  success: updateProfileSuccess,
  failure: updateProfileFailure,
  trigger: updateProfileTrigger,
  reset: updateProfileReset,
} = fetchMachineFactory({
  id: "updateProfile",
  invokeFn: ({
    client,
    payload,
  }: {
    client: AppApolloClient;
    payload: ReturnType<AllAuthActionCreators["updateProfile"]>["payload"];
  }) => {
    return updateProfile(client, payload.variables);
  },
});

export const {
  machine: resetPasswordMachine,
  success: resetPasswordSuccess,
  failure: resetPasswordFailure,
  trigger: resetPasswordTrigger,
} = fetchMachineFactory({
  id: "resetPassword",
  invokeFn: ({
    client,
    payload,
  }: {
    client: AppApolloClient;
    payload: ReturnType<AllAuthActionCreators["resetPassword"]>["payload"];
  }) => {
    return resetPassword(client, payload.variables);
  },
});

export const {
  machine: updatePasswordMachine,
  success: updatePasswordSuccess,
  failure: updatePasswordFailure,
  trigger: updatePasswordTrigger,
} = fetchMachineFactory({
  id: "updatePassword",
  invokeFn: ({
    client,
    payload,
  }: {
    client: AppApolloClient;
    payload: ReturnType<AllAuthActionCreators["updatePassword"]>["payload"];
  }) => {
    return updatePassword(client, payload.variables);
  },
});

const {
  machine: getPreSignedUrlMachine,
  success: getPreSignedUrlSuccess,
  failure: getPreSignedUrlFailure,
  trigger: getPreSignedUrlTrigger,
} = fetchMachineFactory({
  id: "getPreSignedUrl",
  invokeFn: ({ token, key }: { token: string; key: string }) => {
    return getPreSignedUrl(token, key);
  },
});

const {
  machine: uploadProfileImageMachine,
  success: uploadProfileImageSuccess,
  failure: uploadProfileImageFailure,
  trigger: uploadProfileImageTrigger,
} = fetchMachineFactory({
  id: "uploadProfileImage",
  invokeFn: ({ url, body }: { url: string; body: File | ReadableStream }) => {
    return uploadImageToS3(url, body);
  },
});

const {
  machine: deleteProfileImageMachine,
  success: deleteProfileImageSuccess,
  failure: deleteProfileImageFailure,
  trigger: deleteProfileImageTrigger,
} = fetchMachineFactory({
  id: "deleteProfileImage",
  invokeFn: ({ key, token }: { key: string; token: string }) => {
    return deleteImageFromS3(key, token);
  },
});

const {
  machine: technicalSetupHelpMachine,
  success: technicalSetupHelpSuccess,
  failure: technicalSetupHelpFailure,
  trigger: technicalSetupHelpTrigger,
} = fetchMachineFactory({
  id: "technicalSetupHelp",
  invokeFn: ({ client }: { client: AppApolloClient }) => {
    return technicalSetupHelp(client);
  },
});

export const {
  machine: refreshTokensMachine,
  success: refreshTokensSuccess,
  failure: refreshTokensFailure,
  trigger: refreshTokensTrigger,
} = fetchMachineFactory({
  id: "refreshTokens",
  invokeFn: ({ client, token }: { client: AppApolloClient; token: string }) => {
    return refreshTokens(client, { token });
  },
});

function tokenExpiryAction(
  token: string | null,
  expiryCallback: () => void,
  bufferTimeInSeconds = 120
) {
  if (!token) return null;
  const parsedToken = parseAuthenticationJWT(token);
  if (!parsedToken) return null;

  const currentTime = Math.floor(Date.now() / 1000);
  const timeUntilExpiration = parsedToken.exp - currentTime;

  if (timeUntilExpiration <= 0) {
    expiryCallback();
    return null;
  }

  const renewalTime =
    Math.max(timeUntilExpiration - bufferTimeInSeconds, 0) * 1000;
  return setTimeout(expiryCallback, renewalTime) as unknown as number;
}

export const authMachine = createMachine(
  {
    types: {} as AuthMachineTypes,
    id: "auth",
    initial: AuthState.Initial,
    context: ({ input }): AuthMachineContext => {
      const machineInput = input as
        | {
            client?: AppApolloClient;
            token?: string | null;
            refresh?: string | null;
            profile?: Profile | null;
            selectedAudioDevice?: SourceData | null;
            selectedVideoDevice?: SourceData | null;
            connectionSuccess?: boolean | null;
            skipTechnicalSetup?: boolean;
          }
        | undefined;
      if (!machineInput?.client)
        throw new Error("Apollo client must be provided!");
      return {
        client: machineInput.client,
        profile: machineInput?.profile || null,
        token: machineInput?.token || null,
        refresh: machineInput?.refresh || null,
        unauthenticatedUserEmail: null,
        presignedUrl: null,
        fileForUpload: null,
        imageLastUpdatedTimeStamp: null,
        jitsiInstance: null,
        availableAudioDevices: null,
        availableVideoDevices: null,
        selectedAudioDevice: machineInput.selectedAudioDevice || null,
        selectedVideoDevice: machineInput.selectedVideoDevice || null,
        connectionSuccess: machineInput?.connectionSuccess || null,
        routeTitle: null,
        routeData: null,
        skipTechnicalSetup: machineInput.skipTechnicalSetup || false,
        technicalSetupHelpOutcome: null,
        tokenExpiryTimeoutId: null,
      };
    },
    entry: [
      spawnChild(loginMachine, {
        id: loginMachine.id,
        systemId: loginMachine.id,
      }),
      spawnChild(getInvitationMachine, {
        id: getInvitationMachine.id,
        systemId: getInvitationMachine.id,
      }),
      spawnChild(validateUpdatePasswordTokenMachine, {
        id: validateUpdatePasswordTokenMachine.id,
        systemId: validateUpdatePasswordTokenMachine.id,
      }),
      spawnChild(updateProfileMachine, {
        id: updateProfileMachine.id,
        systemId: updateProfileMachine.id,
      }),
      spawnChild(resetPasswordMachine, {
        id: resetPasswordMachine.id,
        systemId: resetPasswordMachine.id,
      }),
      spawnChild(updatePasswordMachine, {
        id: updatePasswordMachine.id,
        systemId: updatePasswordMachine.id,
      }),
      spawnChild(getMyProfileMachine, {
        id: getMyProfileMachine.id,
        systemId: getMyProfileMachine.id,
      }),
      spawnChild(setProfileAsCompletedMachine, {
        id: setProfileAsCompletedMachine.id,
        systemId: setProfileAsCompletedMachine.id,
      }),
      spawnChild(getJourneyParticipationDataMachine, {
        id: getJourneyParticipationDataMachine.id,
        systemId: getJourneyParticipationDataMachine.id,
      }),
      spawnChild(getPreSignedUrlMachine, {
        id: getPreSignedUrlMachine.id,
        systemId: getPreSignedUrlMachine.id,
      }),
      spawnChild(uploadProfileImageMachine, {
        id: uploadProfileImageMachine.id,
        systemId: uploadProfileImageMachine.id,
      }),
      spawnChild(deleteProfileImageMachine, {
        id: deleteProfileImageMachine.id,
        systemId: deleteProfileImageMachine.id,
      }),
      spawnChild(technicalSetupHelpMachine, {
        id: technicalSetupHelpMachine.id,
        systemId: technicalSetupHelpMachine.id,
      }),
      spawnChild(refreshTokensMachine, {
        id: refreshTokensMachine.id,
        systemId: refreshTokensMachine.id,
      }),
    ],
    states: {
      [AuthState.Initial]: {
        always: [
          {
            guard: ({ context }) => !!context.token,
            target: AuthState.Validating,
          },
          {
            target: AuthState.Unauthenticated,
            actions: assign({
              profile: null,
              token: null,
              refresh: null,
            }),
          },
        ],
      },
      [AuthState.Validating]: {
        entry: [
          sendTo(getMyProfileMachine.id, ({ context }) => {
            return getMyProfileTrigger({
              client: context.client,
            });
          }),
        ],
        on: {
          [getMyProfileSuccess.type]: [
            {
              target: AuthState.Authenticated,
              guard: ({ event, context }) => {
                return (
                  event.payload.output !== null &&
                  ((context.selectedAudioDevice !== null &&
                    context.selectedVideoDevice !== null &&
                    context.connectionSuccess === true) ||
                    context.skipTechnicalSetup)
                );
              },
              actions: assign({
                profile: ({ event }) => event.payload.output,
              }),
            },
            {
              target: AuthState.TechnicalSetup,
              guard: ({ event, context }) => {
                return (
                  event.payload.output !== null &&
                  (context.selectedAudioDevice === null ||
                    context.selectedVideoDevice === null ||
                    !context.connectionSuccess) &&
                  !context.skipTechnicalSetup
                );
              },
              actions: assign({
                profile: ({ event }) => event.payload.output,
              }),
            },
            {
              target: AuthState.Unauthenticated,
              actions: assign({
                profile: null,
                token: null,
                refresh: null,
              }),
            },
          ],
          [getMyProfileFailure.type]: [
            {
              target: AuthState.Validating,
              guard: ({ event }) => {
                return (
                  event.payload.error?.graphQLErrors?.[0]?.code ===
                  ApolloServerErrorCodes.TOKEN_EXPIRED
                );
              },
              actions: [
                sendTo(refreshTokensMachine.id, ({ context }) =>
                  refreshTokensTrigger({
                    client: context.client,
                    token: context.refresh!,
                  })
                ),
              ],
            },
            {
              target: AuthState.Unauthenticated,
              actions: assign({
                profile: null,
              }),
            },
          ],
          [refreshTokensSuccess.type]: {
            actions: [
              sendTo(getMyProfileMachine.id, ({ context }) => {
                return getMyProfileTrigger({
                  client: context.client,
                });
              }),
              assign({
                token: ({ event }) => event.payload.output.token,
                refresh: ({ event }) => event.payload.output.refresh,
                profile: ({ event }) => event.payload.output.profile,
              }),
              "asyncSaveLoggedUserData",
            ],
          },
          [refreshTokensFailure.type]: {
            target: AuthState.Unauthenticated,
            actions: assign({
              profile: null,
              token: null,
              refresh: null,
            }),
          },
        },
      },
      [AuthState.Unauthenticated]: {
        entry: [
          () => {
            deleteItem(authContextLocalStorageName);
          },
        ],
        on: {
          [actions.login.type]: {
            target: AuthState.Authenticating,
          },
          [actions.getInvitation.type]: {
            target: AuthState.GettingInvitation,
          },
          [actions.validateToken.type]: {
            actions: [
              sendTo(
                validateUpdatePasswordTokenMachine.id,
                ({ event, context }: any) => {
                  event = event as ReturnType<
                    AllAuthActionCreators["validateToken"]
                  >;
                  return validateUpdatePasswordTokenTrigger({
                    client: context.client,
                    payload: event.payload,
                  });
                }
              ),
            ],
          },
          [validateUpdatePasswordTokenSuccess.type]: {
            actions: [
              assign({
                unauthenticatedUserEmail: ({ event }) =>
                  event.payload.output.email,
              }),
            ],
          },
          [actions.resetPassword.type]: {
            actions: [
              sendTo(resetPasswordMachine.id, ({ event, context }) => {
                event = event as ReturnType<
                  AllAuthActionCreators["resetPassword"]
                >;
                return resetPasswordTrigger({
                  client: context.client,
                  payload: event.payload,
                });
              }),
            ],
          },
          [actions.updatePassword.type]: {
            actions: [
              sendTo(updatePasswordMachine.id, ({ event, context }) => {
                event = event as ReturnType<
                  AllAuthActionCreators["updatePassword"]
                >;
                return updatePasswordTrigger({
                  client: context.client,
                  payload: event.payload,
                  force: true,
                });
              }),
            ],
          },
        },
      },
      [AuthState.Authenticating]: {
        entry: [
          sendTo(
            loginMachine.id,
            ({
              context,
              event: { payload },
            }: {
              context: AuthMachineContext;
              event: {
                payload: ReturnType<AllAuthActionCreators["login"]>["payload"];
              };
            }) => {
              return loginTrigger({
                client: context.client,
                force: true,
                payload,
              });
            }
          ),
        ],
        on: {
          [loginSuccess.type]: [
            {
              target: AuthState.Authenticated,
              guard: ({ event, context }) => {
                return (
                  event.payload.output.profile !== null &&
                  event.payload.output.token !== null &&
                  ((context.selectedAudioDevice !== null &&
                    context.selectedVideoDevice !== null &&
                    !!context.connectionSuccess) ||
                    context.skipTechnicalSetup)
                );
              },
              actions: [
                assign({
                  profile: ({ event }) => event.payload.output.profile,
                  token: ({ event }) => event.payload.output.token,
                  refresh: ({ event }) => event.payload.output.refresh,
                  unauthenticatedUserEmail: () => null,
                }),
                "asyncSaveLoggedUserData",
              ],
            },
            {
              target: AuthState.TechnicalSetup,
              guard: ({ event, context }) => {
                return (
                  event.payload.output.profile !== null &&
                  event.payload.output.token !== null &&
                  (context.selectedAudioDevice === null ||
                    context.selectedVideoDevice === null ||
                    !context.connectionSuccess) &&
                  !context.skipTechnicalSetup
                );
              },
              actions: [
                assign({
                  profile: ({ event }) => event.payload.output.profile,
                  token: ({ event }) => event.payload.output.token,
                  refresh: ({ event }) => event.payload.output.refresh,
                }),
                "asyncSaveLoggedUserData",
              ],
            },
            {
              target: AuthState.Unauthenticated,
            },
          ],
          [loginFailure.type]: {
            target: AuthState.Unauthenticated,
          },
        },
      },
      [AuthState.GettingInvitation]: {
        entry: [
          sendTo(
            getInvitationMachine.id,
            ({
              context,
              event: { payload },
            }: {
              context: AuthMachineContext;
              event: {
                payload: ReturnType<AllAuthActionCreators["getInvitation"]>["payload"];
              };
            }) => {
              return getInvitationTrigger({
                client: context.client,
                force: true,
                payload,
              });
            }
          ),
        ],
        on: {
          [getInvitationSuccess.type]: {
            target: AuthState.Unauthenticated,
          },
          [getInvitationFailure.type]: {
            target: AuthState.Unauthenticated,
          },
        },
      },
      [AuthState.Authenticated]: {
        initial: ProfileSettings.Default,
        entry: [
          ({ context, self }) => {
            // Do this async to allow the token to get set in the apollo context
            setTimeout(() => {
              const getJourneyParticipationDataActor =
                self.getSnapshot().children[
                  getJourneyParticipationDataMachine.id
                ]!;
              getJourneyParticipationDataActor.send(
                getJourneyParticipationDataTrigger({
                  client: context.client,
                })
              );
            }, 0);
          },
          // sendTo(
          //   getJourneyParticipationDataMachine.id,
          //   ({ context }: { context: AuthMachineContext }) => {
          //     return getJourneyParticipationDataTrigger({
          //       client: context.client,
          //     });
          //   }
          // ),
          assign({
            tokenExpiryTimeoutId: ({ context, self }) => {
              const token = context.token;
              return tokenExpiryAction(token, () => {
                self.send(actions.tokenExpired());
              });
            },
          }),
        ],
        states: {
          [ProfileSettings.Default]: {
            on: {
              [actions.updateProfileDialog.type]: [
                {
                  target: ProfileSettings.UpdateProfile,
                  guard: ({ event }) =>
                    event.payload.open && event.payload.type === "profile",
                },
                {
                  target: ProfileSettings.ChangePassword,
                  guard: ({ event }) =>
                    event.payload.open && event.payload.type === "password",
                },
              ],
            },
          },
          [ProfileSettings.UpdateProfile]: {
            initial: ProfileSettings.UpdateProfile_Default,
            states: {
              [ProfileSettings.UpdateProfile_Default]: {
                on: {
                  [actions.uploadProfileImage.type]: [
                    {
                      target: ProfileSettings.UpdateProfile_GettingPresignedUrl,
                      guard: ({ context }) => !context.presignedUrl,
                      actions: [
                        assign({
                          fileForUpload: ({ event }) => event.payload.file,
                        }),
                      ],
                    },
                  ],
                  [actions.deleteProfileImage.type]: [
                    {
                      target: ProfileSettings.UpdateProfile_DeleteProfileImage,
                    },
                  ],
                },
              },
              [ProfileSettings.UpdateProfile_GettingPresignedUrl]: {
                entry: [
                  sendTo(getPreSignedUrlMachine.id, ({ context }) => {
                    return getPreSignedUrlTrigger({
                      token: context.token!,
                      key: generateWorkspaceProfileImageKey(
                        context.profile!.id,
                        context.profile!.workspace.workspace_id
                      ),
                    });
                  }),
                ],
                on: {
                  [getPreSignedUrlSuccess.type]: {
                    target: ProfileSettings.UpdateProfile_UploadingProfileImage,
                    actions: assign({
                      presignedUrl: ({ event }) => event.payload.output,
                    }),
                  },
                  [getPreSignedUrlFailure.type]: {
                    target: ProfileSettings.UpdateProfile_Default,
                    // TODO: Handler error message
                    actions: [
                      assign({
                        presignedUrl: null,
                        fileForUpload: null,
                      }),
                    ],
                  },
                },
              },
              [ProfileSettings.UpdateProfile_UploadingProfileImage]: {
                entry: [
                  sendTo(uploadProfileImageMachine.id, ({ context, self }) => {
                    const file = context.fileForUpload!;

                    return uploadProfileImageTrigger({
                      url: context.presignedUrl!,
                      body: file,
                    });
                  }),
                ],
                on: {
                  [uploadProfileImageSuccess.type]: {
                    target: ProfileSettings.UpdateProfile_Default,
                    actions: [
                      assign({
                        presignedUrl: null,
                        fileForUpload: null,
                        imageLastUpdatedTimeStamp: () =>
                          getUnixTime(new Date()),
                      }),
                    ],
                  },
                  [uploadProfileImageFailure.type]: {
                    target: ProfileSettings.UpdateProfile_Default,
                    // TODO: Handler error message
                    actions: [
                      assign({
                        presignedUrl: null,
                        fileForUpload: null,
                      }),
                    ],
                  },
                },
              },
              [ProfileSettings.UpdateProfile_DeleteProfileImage]: {
                entry: [
                  sendTo(deleteProfileImageMachine.id, ({ context, self }) => {
                    return deleteProfileImageTrigger({
                      token: context.token!,
                      key: generateWorkspaceProfileImageKey(
                        context.profile!.id,
                        context.profile!.workspace.workspace_id
                      ),
                    });
                  }),
                ],
                on: {
                  [deleteProfileImageSuccess.type]: {
                    target: ProfileSettings.UpdateProfile_Default,
                    actions: [
                      assign({
                        imageLastUpdatedTimeStamp: () =>
                          getUnixTime(new Date()),
                      }),
                    ],
                  },
                  [deleteProfileImageFailure.type]: {
                    target: ProfileSettings.UpdateProfile_Default,
                    // TODO: Handler error message
                  },
                },
              },
            },
            entry: [
              sendTo(updateProfileMachine.id, () => updateProfileReset()),
            ],
            exit: [
              assign({
                presignedUrl: null,
              }),
            ],
            on: {
              [actions.updateProfileDialog.type]: [
                {
                  target: ProfileSettings.ChangePassword,
                  guard: ({ event }) =>
                    event.payload.open && event.payload.type === "password",
                },
                {
                  target: ProfileSettings.Default,
                  guard: ({ event }) => !event.payload.open,
                },
              ],
            },
          },
          [ProfileSettings.ChangePassword]: {
            on: {
              [actions.updateProfileDialog.type]: [
                {
                  target: ProfileSettings.UpdateProfile,
                  guard: ({ event }) =>
                    event.payload.open && event.payload.type === "profile",
                },
                {
                  target: ProfileSettings.Default,
                  guard: ({ event }) => !event.payload.open,
                },
              ],
            },
          },
        },
        invoke: [
          {
            input: ({ self }) => ({ authActor: self }),
            src: fromCallback(({ input }) => {
              const authActor = input.authActor as ReturnType<
                typeof useMachine<typeof authMachine>
              >["2"];
              const messageHandler = () => {
                authActor.send(actions.refreshJourneyData());
              };

              refreshMyProfileChannel.addEventListener(
                "message",
                messageHandler
              );

              return () => {
                refreshMyProfileChannel.removeEventListener(
                  "message",
                  messageHandler
                );
              };
            }),
          },
        ],
        on: {
          [refreshTokensSuccess.type]: {
            actions: [
              assign({
                token: ({ event }) => event.payload.output.token,
                refresh: ({ event }) => event.payload.output.refresh,
                profile: ({ event }) => event.payload.output.profile,
                tokenExpiryTimeoutId: ({ event, self }) => {
                  return tokenExpiryAction(event.payload.output.token, () => {
                    self.send(actions.tokenExpired());
                  });
                },
              }),
              "asyncSaveLoggedUserData",
            ],
          },
          [refreshTokensFailure.type]: {
            actions: [({ self }) => self.send(actions.logout())],
          },
          [actions.tokenExpired.type]: {
            actions: [
              sendTo(refreshTokensMachine.id, ({ context }) => {
                const { client, refresh } = context;
                return refreshTokensTrigger({ client, token: refresh! });
              }),
            ],
          },
          [getJourneyParticipationDataSuccess.type]: {
            actions: [
              assign({
                profile: ({ event, context }) => ({
                  ...context.profile!,
                  workspace: {
                    ...context.profile!.workspace,
                    workspace: {
                      ...context.profile!.workspace.workspace,
                      journeyParticipationData:
                        event.payload.output.workspace.workspace
                          .journeyParticipationData,
                      availableJourneys:
                        event.payload.output.workspace.workspace
                          .availableJourneys,
                    },
                  },
                }),
              }),
            ],
          },
          [actions.logout.type]: {
            target: AuthState.Unauthenticated,
            actions: assign({
              profile: null,
              token: null,
              refresh: null,
              tokenExpiryTimeoutId: ({ context }) => {
                if (context.tokenExpiryTimeoutId)
                  clearTimeout(context.tokenExpiryTimeoutId);
                return null;
              },
            }),
          },
          [actions.updateProfile.type]: {
            actions: [
              sendTo(
                updateProfileMachine.id,
                ({
                  event,
                  context,
                }: {
                  event: ReturnType<AllAuthActionCreators["updateProfile"]>;
                  context: AuthMachineContext;
                }) => {
                  return updateProfileTrigger({
                    force: true,
                    client: context.client,
                    payload: event.payload,
                  });
                }
              ),
            ],
          },
          [updateProfileSuccess.type]: {
            actions: [
              assign({
                profile: ({ event, context }) => {
                  return {
                    ...context.profile,
                    ...event.payload.output,
                  };
                },
              }),
              raise(() => actions.updateProfileDialog({ open: false })),
              "asyncSaveLoggedUserData",
            ],
          },
          [actions.refreshMyProfile.type]: {
            actions: [
              sendTo(getMyProfileMachine.id, ({ context }) => {
                return getMyProfileTrigger({
                  client: context.client,
                });
              }),
            ],
          },
          [actions.refreshJourneyData.type]: {
            actions: [
              sendTo(
                getJourneyParticipationDataMachine.id,
                ({ context }: { context: AuthMachineContext }) => {
                  return getJourneyParticipationDataTrigger({
                    client: context.client,
                  });
                }
              ),
            ],
          },
          [getMyProfileSuccess.type]: [
            {
              target: AuthState.Authenticated,
              guard: ({ event }) => {
                return event.payload.output !== null;
              },
              actions: assign({
                profile: ({ event }) => event.payload.output,
              }),
            },
            {
              target: AuthState.Unauthenticated,
              actions: assign({
                profile: null,
                token: null,
                refresh: null,
              }),
            },
          ],
          [getMyProfileFailure.type]: {
            target: AuthState.Unauthenticated,
            actions: assign({
              profile: null,
            }),
          },
        },
      },
      [AuthState.TechnicalSetup]: {
        entry: [
          assign({
            jitsiInstance: () => new Jitsi(),
          }),
        ],
        on: {
          [actions.configureAudio.type]: {
            actions: [
              assign({
                availableAudioDevices: ({ event }) =>
                  event.payload.availableAudioSources,
                selectedAudioDevice: ({ event, context }) => {
                  const current = context.selectedAudioDevice;
                  const update = event.payload.selectedAudioSourceData;
                  if (current && current.isMuted !== update.isMuted) {
                    const audioTrack =
                      context.jitsiInstance?.getLocalAudioTrack();
                    if (update.isMuted) {
                      audioTrack.mute();
                    } else {
                      audioTrack.unmute();
                    }
                  }
                  return update;
                },
              }),
            ],
          },
          [actions.configureVideo.type]: {
            actions: [
              assign({
                availableVideoDevices: ({ event }) =>
                  event.payload.availableVideoSources,
                selectedVideoDevice: ({ event, context }) => {
                  const current = context.selectedVideoDevice;
                  const update = event.payload.selectedVideoSourceData;
                  if (current && current.isMuted !== update.isMuted) {
                    const videoTrack =
                      context.jitsiInstance?.getLocalVideoTrack();
                    if (update.isMuted) {
                      videoTrack.mute();
                    } else {
                      videoTrack.unmute();
                    }
                  }
                  return update;
                },
              }),
            ],
          },
          [actions.technicalSetupInit.type]: {
            actions: [
              ({ context, event, self }) => {
                const { jitsiInstance } = context;
                if (!jitsiInstance) return;

                const onLocalVideoTrackReady = () => {
                  jitsiInstance
                    .getAvailableSources()
                    .then((availableSources) => {
                      const availableVideoSources: SourceData[] =
                        availableSources.video.map((s) => ({
                          label: s.label,
                          sourceId: s.deviceId,
                          isMuted: undefined,
                        }));
                      const currentVideo = jitsiInstance.localTracks.find(
                        (t) => t.getType() === "video"
                      );
                      if (!currentVideo) return;
                      const videoMatch = availableVideoSources.find(
                        (t) => currentVideo?.track.label === t.label
                      );
                      const selectedVideoSourceData = {
                        label: videoMatch?.label || null,
                        isMuted: currentVideo?.isMuted() || null,
                        sourceId: videoMatch?.sourceId || null,
                      };

                      jitsiInstance.removeEventListener(
                        JitsiEvents.LOCAL_VIDEO_TRACK_READY,
                        onLocalVideoTrackReady
                      );

                      const data = {
                        availableVideoSources,
                        selectedVideoSourceData,
                      };

                      self.send(actions.configureVideo(data));
                    });
                };
                const onLocalAudioTrackReady = async () => {
                  const availableSources =
                    await jitsiInstance.getAvailableSources();
                  const availableAudioSources: SourceData[] =
                    availableSources.audio.map((s) => ({
                      label: s.label,
                      sourceId: s.deviceId,
                      isMuted: undefined,
                    }));
                  const currentAudio = jitsiInstance.localTracks.find(
                    (t) => t.getType() === "audio"
                  );
                  if (!currentAudio) return;
                  const audioMatch = availableAudioSources.find(
                    (t) => currentAudio.track.label === t.label
                  );
                  const selectedAudioSourceData: SourceData = {
                    label: audioMatch?.label || null,
                    isMuted: currentAudio?.isMuted() || null,
                    sourceId: audioMatch?.sourceId || null,
                  };

                  jitsiInstance.removeEventListener(
                    JitsiEvents.LOCAL_AUDIO_TRACK_READY,
                    onLocalAudioTrackReady
                  );

                  const data = {
                    availableAudioSources,
                    selectedAudioSourceData,
                  };

                  self.send(actions.configureAudio(data));
                };
                const onConnectionEstablished = () => {
                  self.send(actions.setConnectionStatus({ success: true }));
                };
                const onConnectionFailure = () => {
                  self.send(actions.setConnectionStatus({ success: false }));
                };

                jitsiInstance.addEventListener(
                  JitsiEvents.LOCAL_AUDIO_TRACK_READY,
                  onLocalAudioTrackReady
                );
                jitsiInstance.addEventListener(
                  JitsiEvents.LOCAL_VIDEO_TRACK_READY,
                  onLocalVideoTrackReady
                );

                jitsiInstance.addEventListener(
                  JitsiEvents.CONNECTION_ESTABLISHED,
                  onConnectionEstablished
                );

                jitsiInstance.addEventListener(
                  JitsiEvents.CONNECTION_FAILURE,
                  onConnectionFailure
                );

                jitsiInstance.init(
                  event.payload.profileId,
                  event.payload.htmlVideoElement
                );

                // TODO: Check this logic if we using v4 an error was occur
                jitsiInstance.setupConnection(
                  `technical-setup-${event.payload.profileId}`
                );
              },
            ],
          },
          [actions.setConnectionStatus.type]: {
            actions: [
              assign({
                connectionSuccess: ({
                  event: {
                    payload: { success },
                  },
                }) => success,
              }),
            ],
          },
        },
      },
    },
    on: {
      [actions.technicalSetupClear.type]: [
        {
          actions: [
            raise(({ context }) => {
              const { selectedAudioDevice, selectedVideoDevice } = context;
              // Maybe we should display message to the user before we redirect

              return actions.saveDeviceSetup({
                selectedAudioDevice: selectedAudioDevice || null,
                selectedVideoDevice: selectedVideoDevice || null,
              });
            }),
            assign({
              technicalSetupHelpOutcome: null,
            }),
          ],
          guard: ({ context }) =>
            context.technicalSetupHelpOutcome ===
            TechnicalSetupHelpOutcome.Success,
        },
        {
          actions: assign({
            technicalSetupHelpOutcome: null,
          }),
          guard: ({ context }) =>
            context.technicalSetupHelpOutcome ===
            TechnicalSetupHelpOutcome.Failure,
        },
      ],
      [actions.technicalSetupHelp.type]: {
        actions: [
          sendTo(technicalSetupHelpMachine.id, ({ context }) => {
            return technicalSetupHelpTrigger({
              client: context.client,
            });
          }),
        ],
      },
      [technicalSetupHelpSuccess.type]: {
        actions: [
          assign({
            technicalSetupHelpOutcome: TechnicalSetupHelpOutcome.Success,
          }),
        ],
      },
      [technicalSetupHelpFailure.type]: {
        actions: [
          assign({
            technicalSetupHelpOutcome: TechnicalSetupHelpOutcome.Failure,
          }),
        ],
      },
      [actions.saveDeviceSetup.type]: {
        actions: [
          ({
            event: {
              payload: {
                selectedAudioDevice,
                selectedVideoDevice,
                connectionSuccessful,
              },
            },
            self,
            context,
          }) => {
            const data: TechnicalSetupConfig = {
              selectedAudioDevice: selectedAudioDevice
                ? {
                    ...selectedAudioDevice,
                    isMuted: false,
                  }
                : null,
              selectedVideoDevice: selectedVideoDevice
                ? {
                    ...selectedVideoDevice,
                    isMuted: false,
                  }
                : null,
              connectionSuccessful:
                connectionSuccessful || context.connectionSuccess,
            };

            putItem(data, technicalSetupConfig).then(() => {
              const snapshot = self.getSnapshot();
              if (snapshot.value !== AuthState.TechnicalSetup) return;
              const markProfileAsCompletedActor = snapshot.children[
                setProfileAsCompletedMachine.id
              ] as ReturnType<
                typeof useMachine<typeof setProfileAsCompletedMachine>
              >["2"];

              if (context.profile?.hasCompletedSetupSuccessfully)
                return void self.send(actions.saveDeviceSetupComplete());

              const subscription = markProfileAsCompletedActor.subscribe(
                ({ value }) => {
                  if (value !== FetchState.Success) return;
                  subscription.unsubscribe();
                  self.send(actions.saveDeviceSetupComplete());
                }
              );
              markProfileAsCompletedActor.send(
                setProfileAsCompletedTrigger({ client: context.client })
              );
            });
          },
        ],
      },
      [actions.saveDeviceSetupComplete.type]: {
        target: `.${AuthState.Authenticated}`,
        actions: [
          assign({
            jitsiInstance: ({ context }) => {
              context.jitsiInstance?.cleanup();
              return null;
            },
          }),
        ],
      },
      [setProfileAsCompletedSuccess.type]: {
        actions: [
          assign(({ context }) => {
            return {
              ...context,
              profile: { ...context.profile!, is_completed: true },
            };
          }),
        ],
      },
      [actions.setPageConfig.type]: {
        actions: assign(({ event, context }) => {
          const { pageData, pageTitle } = event.payload;
          return { ...context, routeData: pageData, routeTitle: pageTitle };
        }),
      },
    },
  },
  {
    actions: {
      asyncSaveLoggedUserData: ({ self }) => {
        setTimeout(() => {
          const snapshot = self.getSnapshot();
          const data = {
            token: snapshot.context.token,
            refresh: snapshot.context.refresh,
            profile: snapshot.context.profile,
          };

          putItem(data, authContextLocalStorageName);
        }, 0);
      },
    },
  }
);
