import { memo } from "react";
import ReactDatePicker from "react-datepicker";

import styles from "./TimePicker.module.css";
import "react-datepicker/dist/react-datepicker.css";

interface TimePickerProps {
  value: Date | null | undefined;
  handleTimeChange: (time: Date | null) => void;
  onCalendarClose?: () => void;
}

const TimePicker = ({
  value,
  handleTimeChange,
  onCalendarClose,
}: TimePickerProps) => {
  return (
    <ReactDatePicker
      showIcon
      selected={value}
      onChange={handleTimeChange}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      dateFormat="h:mm aa"
      showTimeCaption={false}
      className={styles.timePickerWrapper}
      placeholderText="--:--"
      onCalendarClose={onCalendarClose}
      toggleCalendarOnIconClick
      icon={<i className="fa-regular fa-clock"></i>}
      calendarIconClassName={styles.calendarIconClassName}
    />
  );
};

export default memo(TimePicker);
