export type DevicePermissions = {
  microphone: PermissionState;
  camera: PermissionState;
};

type PermissionState = "granted" | "denied" | "prompt";
type ExtendedPermissionName = PermissionName | "microphone" | "camera";

export const checkCameraAndMicPermissions = (): Promise<DevicePermissions> => {
  const permissions = navigator.permissions;

  // Helper function to safely query permissions
  const safeQueryPermission = (
    name: ExtendedPermissionName
  ): Promise<PermissionState> => {
    if (!permissions) {
      // Permissions API is not supported
      return Promise.resolve("prompt");
    }
    try {
      // Attempt to query the permission
      const queryPromise = permissions.query({ name: name as PermissionName });
      return queryPromise
        .then((status) => status.state as PermissionState)
        .catch(() => "prompt");
    } catch (e) {
      // Synchronous exception occurred (e.g., invalid permission name)
      return Promise.resolve("prompt");
    }
  };

  const micPromise = safeQueryPermission("microphone");
  const cameraPromise = safeQueryPermission("camera");

  return Promise.all([micPromise, cameraPromise]).then(
    ([micState, cameraState]) => {
      return {
        microphone: micState,
        camera: cameraState,
      };
    }
  );
};
