import { memo, useCallback, useState } from "react";
import BulkScheduleDialogWeekDay from "./BulkScheduleDialogWeekDay/BulkScheduleDialogWeekDay";
import {
  DaysOfTheWeek,
  ScheduledDay,
} from "../../../../../../../../apollo-graphql/types/slot";

import styles from "./BulkScheduleDialogWeekDays.module.css";

interface BulkScheduleDialogWeekDaysProps {
  scheduledDays: ScheduledDay;
  onSlotAdded: (id: DaysOfTheWeek, time: string) => void;
  onSlotRemoved: (id: string) => void;
  onSelectedSlotsDaysChanged: (days: DaysOfTheWeek[]) => void;
}

const items: { id: DaysOfTheWeek; day: string }[] = [
  { id: "Mon", day: "Monday" },
  { id: "Tue", day: "Tuesday" },
  { id: "Wed", day: "Wednesday" },
  { id: "Thu", day: "Thursday" },
  { id: "Fri", day: "Friday" },
];

const BulkScheduleDialogWeekDays = ({
  scheduledDays,
  onSlotAdded,
  onSlotRemoved,
  onSelectedSlotsDaysChanged,
}: BulkScheduleDialogWeekDaysProps) => {
  const [selectedItems, setSelectedItems] = useState<DaysOfTheWeek[]>([]);
  const handleSelection = useCallback(
    (item: DaysOfTheWeek) => {
      const newSelectedItems = selectedItems.includes(item)
        ? selectedItems.filter((selectedItem) => selectedItem !== item)
        : [...selectedItems, item];

      setSelectedItems(newSelectedItems);
      onSelectedSlotsDaysChanged(newSelectedItems);
    },
    [onSelectedSlotsDaysChanged, selectedItems]
  );

  return (
    <div className={styles.container}>
      {items.map((item) => {
        const { id } = item;
        const checked = selectedItems.includes(id);

        return (
          <BulkScheduleDialogWeekDay
            checked={checked}
            item={item}
            handleSelection={handleSelection}
            key={id}
            slots={scheduledDays[id]}
            onTimeSelected={(time) => onSlotAdded(id, time)}
            removeSlot={onSlotRemoved}
          />
        );
      })}
    </div>
  );
};

export default memo(BulkScheduleDialogWeekDays);
