import { PropsWithChildren, useEffect, useState } from "react";
import { ApplicationUpdateConfirmation } from "./components/Shared/ApplicationUpdateConfirmation/ApplicationUpdateConfirmation";

export const GlobalErrorBoundary = ({ children }: PropsWithChildren) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleChunkError = (error: ErrorEvent) => {
      if (error.message.includes("Loading chunk")) {
        setHasError(true);
      }
    };

    window.addEventListener("error", handleChunkError);

    return () => {
      window.removeEventListener("error", handleChunkError);
    };
  }, []);

  if (hasError) {
    return <ApplicationUpdateConfirmation />;
  }

  return children;
};
