import { createMachine, spawnChild, sendTo, assign } from "xstate";
import { AppApolloClient } from "../../../contexts/Apollo";
import * as adminDashboardSetLeadersActions from "../../actions/dashboard/admin-dashboard-set-leaders-dialog";
import { fetchMachineFactory } from "../fetch-factory";
import { getProfiles } from "../../../apollo-graphql/queries/profile";
import { setJourneyLeaders } from "../../../apollo-graphql/mutations/journey-leaders";
import { Profile } from "../../../apollo-graphql/types";
import { Pagination } from "../../../types/pagination";

export enum AdminDashboardSetLeadersDialogState {
  Idle = "idle",
  Loading = "loading",
  ProfilesLoaded = "profilesLoaded",
  Saving = "saving",
  Done = "done",
}

export interface AdminDashboardSetLeadersDialogMachineContext {
  client: AppApolloClient;
  profiles: Profile[] | null;
}

type AdminDashboardSetLeadersDialogActionCreators =
  typeof adminDashboardSetLeadersActions;
type AdminDashboardSetLeadersActionCreatorKeys =
  keyof AdminDashboardSetLeadersDialogActionCreators;
type AdminDashboardSetLeadersActions = ReturnType<
  AdminDashboardSetLeadersDialogActionCreators[AdminDashboardSetLeadersActionCreatorKeys]
>;

export const {
  machine: getProfilesMachine,
  trigger: getProfilesTrigger,
  success: getProfilesSuccess,
  failure: getProfilesFailure,
} = fetchMachineFactory({
  id: "getProfiles",
  invokeFn: ({
    client,
    workspaceId,
  }: {
    client: AppApolloClient;
    workspaceId: string;
    emails?: string[];
    ids?: string[];
    pagination?: Pagination;
    query?: string;
  }) => {
    return getProfiles(client, { workspaceId });
  },
});

export const {
  machine: setJourneyLeadersMachine,
  success: setJourneyLeadersSuccess,
  failure: setJourneyLeadersFailure,
  trigger: setJourneyLeadersTrigger,
} = fetchMachineFactory({
  id: "setJourneyLeaders",
  invokeFn: ({
    client,
    journeyLeaders,
    workspaceId,
    journeyId,
  }: {
    journeyLeaders: string[];
    journeyId: string;
    workspaceId: string;
    client: AppApolloClient;
  }) => {
    return setJourneyLeaders(client, {
      journeyLeaders,
      workspaceId,
      journeyId,
    });
  },
});

type AdminDashboardSetLeadersDialogMachineTypes = {
  context: AdminDashboardSetLeadersDialogMachineContext;
  events:
    | AdminDashboardSetLeadersActions
    | ReturnType<typeof getProfilesSuccess>
    | ReturnType<typeof getProfilesFailure>
    | ReturnType<typeof getProfilesTrigger>
    | ReturnType<typeof setJourneyLeadersSuccess>
    | ReturnType<typeof setJourneyLeadersFailure>
    | ReturnType<typeof setJourneyLeadersTrigger>;
};

export const adminDashboardSetLeadersDialogDialogMachine = createMachine({
  types: {} as AdminDashboardSetLeadersDialogMachineTypes,
  id: "admin-dashboard-set-leaders-dialog",
  initial: AdminDashboardSetLeadersDialogState.Idle,
  entry: [
    spawnChild(getProfilesMachine, {
      id: getProfilesMachine.id,
      systemId: getProfilesMachine.id,
    }),
    spawnChild(setJourneyLeadersMachine, {
      id: setJourneyLeadersMachine.id,
      systemId: setJourneyLeadersMachine.id,
    }),
  ],
  context: ({ input }): AdminDashboardSetLeadersDialogMachineContext => {
    const machineInput = input as
      | {
          client?: AppApolloClient;
        }
      | undefined;
    if (!machineInput?.client)
      throw new Error("Apollo client must be provided!");
    return {
      client: machineInput.client,
      profiles: null,
    };
  },
  states: {
    [AdminDashboardSetLeadersDialogState.Idle]: {
      on: {
        [adminDashboardSetLeadersActions.init.type]: {
          target: AdminDashboardSetLeadersDialogState.Loading,
        },
      },
    },
    [AdminDashboardSetLeadersDialogState.Loading]: {
      entry: [
        sendTo(getProfilesMachine.id, ({ context, event }) => {
          const initEvent = event as ReturnType<
            typeof adminDashboardSetLeadersActions.init
          >;
          return getProfilesTrigger({
            client: context.client,
            workspaceId: initEvent.payload.workspaceId,
          });
        }),
      ],
      on: {
        [getProfilesSuccess.type]: {
          target: AdminDashboardSetLeadersDialogState.ProfilesLoaded,
          actions: [
            assign({
              profiles: ({ event }) => event.payload.output.nodes,
            }),
          ],
        },
        [getProfilesFailure.type]: {
          target: AdminDashboardSetLeadersDialogState.ProfilesLoaded,
        },
      },
    },
    [AdminDashboardSetLeadersDialogState.ProfilesLoaded]: {
      on: {
        [adminDashboardSetLeadersActions.setLeaders.type]: {
          target: AdminDashboardSetLeadersDialogState.Saving,
        },
      },
    },
    [AdminDashboardSetLeadersDialogState.Saving]: {
      entry: [
        sendTo(setJourneyLeadersMachine.id, ({ context, event }) => {
          const setLeadersEvent = event as ReturnType<
            typeof adminDashboardSetLeadersActions.setLeaders
          >;
          const { workspaceId, journeyId, journeyLeaders } =
            setLeadersEvent.payload;

          return setJourneyLeadersTrigger({
            client: context.client,
            journeyLeaders: journeyLeaders.map(({ id }) => id),
            workspaceId,
            journeyId,
          });
        }),
      ],
      on: {
        [setJourneyLeadersSuccess.type]: {
          target: AdminDashboardSetLeadersDialogState.Done,
        },
        [setJourneyLeadersFailure.type]: {
          target: AdminDashboardSetLeadersDialogState.ProfilesLoaded,
        },
      },
    },
    [AdminDashboardSetLeadersDialogState.Done]: {
      type: "final",
    },
  },
});
