import {
  FormEvent,
  PropsWithChildren,
  memo,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

import * as Form from "@radix-ui/react-form";

import { mappedErrorMessages } from "../../../constants/mapped-error-messages";
import { ProfileUpdate } from "../../../apollo-graphql/types/profile";
import { FetchState } from "../../../+xstate/machines/fetch-factory";
import { GlobalContext } from "../../../contexts/Global";
import { UpdatePasswordErrors } from "../../../types/enums/errors";

import Password from "../../Shared/Password/Password";
import Dialog from "../../Shared/Dialog/Dialog";
import LoadingButton from "../../Shared/Buttons/LoadingButton/LoadingButton";

import styles from "./ChangePasswordModal.module.css";

const ChangePasswordModal = (
  props: PropsWithChildren<{
    closeDialogHandler: () => void;
    updateProfile: (payload: { variables: ProfileUpdate }) => void;
    profileId: string;
  }>
) => {
  const { closeDialogHandler, profileId, updateProfile } = props;

  const {
    auth: { updateProfileMachineState },
  } = useContext(GlobalContext);

  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const [error, setError] = useState<string>("");

  const isLoading = updateProfileMachineState.value === FetchState.Fetching;

  const onSubmitHandler = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      setError("");
      event.preventDefault();
      const payload = Object.fromEntries(new FormData(event.currentTarget)) as {
        currentPassword: string;
        newPassword: string;
        repeatPassword: string;
      };

      const variables = {
        currentPassword: payload.currentPassword.trim(),
        newPassword: payload.newPassword.trim(),
        repeatPassword: payload.repeatPassword.trim(),
      };

      // Validations: All fields are required and new password must be equal to repeat password
      if (
        variables.currentPassword.length === 0 ||
        variables.newPassword.length === 0 ||
        variables.repeatPassword.length === 0 ||
        !profileId
      ) {
        setError(mappedErrorMessages[UpdatePasswordErrors.MISSING_DATA]);
        return;
      }
      if (payload.newPassword !== payload.repeatPassword) {
        setError(mappedErrorMessages[UpdatePasswordErrors.PASSWORDS_MISMATCH]);
        return;
      }

      updateProfile({
        variables: {
          id: profileId,
          currentPassword: variables.currentPassword,
          newPassword: variables.newPassword,
        },
      });
    },
    [profileId, updateProfile]
  );

  const errorMessage = useMemo(() => {
    const message = updateProfileMachineState.context.error?.message;
    if (!message) return null;

    return mappedErrorMessages[message as keyof typeof mappedErrorMessages];
  }, [updateProfileMachineState.context.error?.message]);

  const actionsContent = useMemo(() => {
    return (
      <LoadingButton
        onClick={() => submitButtonRef.current?.click()}
        isLoading={isLoading}
        type="submit"
      >
        Change
      </LoadingButton>
    );
  }, [isLoading]);

  const dialogContent = useMemo(() => {
    return (
      <div className={styles.content}>
        <Form.Root className="form-container" onSubmit={onSubmitHandler}>
          <Password
            name="currentPassword"
            label="Current password"
            labelClassName="label"
          />

          <Password
            name="newPassword"
            label="New Password"
            labelClassName="label"
          />
          <Password
            name="repeatPassword"
            label="Repeat password"
            labelClassName="label"
          />

          <div className="submit-hidden">
            <button ref={submitButtonRef} type="submit" />
          </div>
        </Form.Root>
      </div>
    );
  }, [onSubmitHandler]);

  return (
    <Dialog
      open
      heading="Change Password"
      actionsContent={actionsContent}
      errorMessage={errorMessage || error}
      onClose={closeDialogHandler}
    >
      {dialogContent}
    </Dialog>
  );
};

export default memo(ChangePasswordModal);
