import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { ProfileWorkspaceAccess, ProfileWorkspaceStatus } from "../types/enums";

const mutation = gql`
  mutation Mutation($workspaceId: String, $invites: [BulkInviteItem]!) {
    bulkInvite(workspaceId: $workspaceId, invites: $invites) {
      success
      importCount
    }
  }
`;

export function bulkInvite(
  client: AppApolloClient,
  variables: {
    workspaceId?: string;
    invites: {
      name: string;
      email: string;
      jobTitle: string;
      access: ProfileWorkspaceAccess;
      status: ProfileWorkspaceStatus;
    }[];
  }
) {
  return client
    .mutate<{ bulkInvite: { success: boolean; importCount: number } }>({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.bulkInvite;
    });
}
