import { memo, PropsWithChildren } from "react";
import * as Ariakit from "@ariakit/react";
import cn from "classnames";

import styles from "./Dialog.module.css";

interface DialogProps extends PropsWithChildren {
  open: boolean;
  heading: string;
  errorMessage?: string | null;
  className?: string;
  actionsContent?: JSX.Element;
  onClose?: () => void;
}

const Dialog = (props: DialogProps) => {
  const {
    open,
    className,
    heading,
    errorMessage,
    children,
    actionsContent,
    onClose,
  } = props;
  return (
    <>
      <div className={styles.overlay} />
      <Ariakit.Dialog
        open={open}
        onClose={onClose}
        className={cn(className, styles.dialog)}
      >
        <div className={styles.header}>
          <Ariakit.DialogHeading className={styles.heading}>
            {heading}
          </Ariakit.DialogHeading>
          <Ariakit.DialogDismiss className={styles.dismiss}>
            <i className="fa fa-xmark" />
          </Ariakit.DialogDismiss>
        </div>

        <div className={styles.content}>{children}</div>
        <div className={styles.error}>{errorMessage}</div>
        <div className={styles.footer}>{actionsContent}</div>
      </Ariakit.Dialog>
    </>
  );
};
export default memo(Dialog);
