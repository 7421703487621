import { useCallback, useMemo, useRef } from "react";
import { debounce } from "lodash";
import { v4 } from "uuid";
import cn from "classnames";

import {
  activityTypeFooterTextFactoryMap,
  getEntryId,
  getTransitionData,
  parseToJson,
} from "../../../../utils";
import {
  extractMultiValueComplexValue,
  extractMultiValueResult,
  generateDefaultMultiValue,
} from "../utils/ydoc-helpers";
import { getTransitionActionFooterData } from "../utils/get-transition-footer-data";

import { useAwareness } from "../../../../hooks/useAwareness";
import { useEditorClass } from "../../../../hooks/useEditorClass";
import { UseDocComplexValueType, useDocMap } from "../../../../hooks/useDocMap";

import { ActivityCommon } from "../../../../types/activity-common";
import { IActivityResult } from "../../../../apollo-graphql/types/session-state";
import { OpenQuestionActivity } from "../../../../types/contentful/workshop/activities/open-question";
import { ActionFooterType } from "../../../../types/action-footer";
import { observerFooterData } from "../constants";
import { OutcomeMode, WorkMode } from "../../../../types/contentful/enums";
import { FooterType } from "../../../../types/enums/activity-footer";
import { ACTIVITY_TIMEOUT_VALUE } from "../../../../constants/global";
import { WorkshopActivityType } from "../../../../types/enums/activity-type";

import CaseStudy from "../../../Shared/CaseStudy/CaseStudy";
import Textarea from "../../../Shared/Textarea/Textarea";
import ActionFooter from "../../ActionFooter/ActionFooter";
import NextStepTransition from "../../NextStepTransition/NextStepTransition";
import Editor from "../../../Shared/Editor/Editor";
import ContentfulRichField from "../../../Shared/ContentfulRichField/ContentfulRichField";
import ScrollIndicator from "../../../Shared/ScrollIndicator/ScrollIndicator";

import { ActivityComplexValue } from "../../../../types/activity-complex-value";
import { ActivityComplexValueType } from "../../../../types/enums/activity-complex-value-type";
import { Profile } from "../../../../apollo-graphql/types";
import { getOpenQuestionAnswers } from "../utils/activity-helper";
import { HIGH_INPUT_DEBOUNCE_TIME } from "../../../../constants/input-debounce-time";
import ActivityInstructions from "../../../Shared/ActivityInstructions/ActivityInstructions";

import styles from "./OpenQuestion.module.css";

const editorKey = "openQuestion";
const editorDeps = [editorKey] as const;

interface OpenQuestionProps extends ActivityCommon {
  profile: Profile;
  currentActiveParticipants: string[];
  activityResultForCurrentProfile: IActivityResult["value"]["0"] | null;
  activityResults: IActivityResult["value"] | null;
  setActivityValueHandler: (args: {
    activityId: string;
    value: string;
    markAsReady?: boolean;
  }) => void;
  isViewResults: boolean;
  allActivitiesResult: IActivityResult[];
}

const OpenQuestion = (props: OpenQuestionProps) => {
  const {
    activity: rawActivity,
    activityResults,
    currentActiveParticipantCount,
    notReadyProfilesCount,
    transition,
    isReady,
    isLoading,
    isConnectionWeak,
    activityResultForCurrentProfile,
    isViewResults,
    isActivityTimeout,
    isParticipating,
    nextActivity,
    hasAhaMoments,
    profile,
    allActivitiesResult,
    setActivityValueHandler,
    setActivityReadyHandler,
  } = props;
  const activity = rawActivity as OpenQuestionActivity;

  const { awarenessState, onAwarenessUpdate } = useAwareness(editorDeps);
  const { docs, generateComplexResultValueAsString, parseComplexResultValue } =
    useDocMap(editorDeps);

  const actionFooterDataRef = useRef<ActionFooterType>();

  const isTransitioning = useMemo(() => transition > 0, [transition]);

  const editorClass = useEditorClass({
    isTransitioning,
    isReady,
    currentActiveParticipantCount,
    notReadyProfilesCount,
  });

  const isSynchronised = useMemo(
    () => activity.fields.activity.fields.workMode === WorkMode.Synchronised,
    [activity]
  );
  const isConsensusMode = useMemo(
    () => activity.fields.activity.fields.outcomeMode === OutcomeMode.Consensus,
    [activity]
  );

  const activityData = useMemo(
    () => ({
      id: getEntryId(activity),
      referenceActivity: activity.fields.referenceActivity,
      referenceValueLabel: activity.fields.referenceValueLabel,
    }),
    [activity]
  );

  const nextActivityData = useMemo(
    () => getTransitionData(nextActivity, hasAhaMoments),
    [nextActivity, hasAhaMoments]
  );

  const referenceActivityContentTypeId =
    activityData.referenceActivity?.sys?.contentType?.sys?.id;

  const referenceActivityIsOpenQuestionSynchronisedConsensus =
    referenceActivityContentTypeId === WorkshopActivityType.OpenQuestion &&
    activityData.referenceActivity.fields.activity.fields.workMode ===
      WorkMode.Synchronised &&
    activityData.referenceActivity.fields.activity.fields.outcomeMode ===
      OutcomeMode.Consensus;

  const referenceActivityResults = allActivitiesResult?.find(
    (result) => result.key === activityData.referenceActivity?.sys.id
  );

  const currentUserResults = referenceActivityResults?.value.find(
    (result) => result.profileId === profile.id
  );

  const parsedCurrentUserResultValue = useMemo(() => {
    if (
      !activityResultForCurrentProfile?.value ||
      activityResultForCurrentProfile.value === ACTIVITY_TIMEOUT_VALUE
    )
      return { id: "", value: "" };
    return parseToJson<{ id: string; value: "" }>(
      activityResultForCurrentProfile.value,
      { id: "", value: "" }
    );
  }, [activityResultForCurrentProfile?.value]);

  const answerId = useMemo(() => {
    return parsedCurrentUserResultValue.id || v4();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    instructions,
    textareaPlaceholder,
    questionInstructions,
    questionTitle,
    questionDescription,
    referenceTitle,
    tags,
    conferenceMode,
  } = useMemo(() => {
    const { fields } = activity;

    let textareaPlaceholder = fields.placeholderText;
    if (
      isSynchronised &&
      currentUserResults?.value &&
      currentUserResults?.value !== ACTIVITY_TIMEOUT_VALUE
    ) {
      const parsedResultValue = parseToJson(currentUserResults?.value, {
        value: "",
      }).value;
      const parsedQuestionResult = parsedResultValue
        ? parseToJson(parsedResultValue, { openQuestion: "" }).openQuestion
        : null;
      textareaPlaceholder = parsedQuestionResult || textareaPlaceholder;
    }

    return {
      textareaPlaceholder,
      instructions: fields.activity.fields.instructions,
      questionInstructions: fields.questionInstructions,
      questionTitle: fields.questionTitle,
      questionDescription: fields.questionDescription,
      referenceTitle: fields.referenceTitle,
      tags: fields.activity.fields.tags,
      conferenceMode: fields.activity.fields.conferenceMode,
    };
  }, [activity, currentUserResults?.value, isSynchronised]);

  const debouncedSetActivityValue = useMemo(
    () =>
      debounce((value: string, isSynchronised = false) => {
        setActivityValueHandler({
          activityId: activityData.id,
          value: isSynchronised
            ? value
            : JSON.stringify({
                id: answerId,
                value,
              }),
        });
      }, HIGH_INPUT_DEBOUNCE_TIME),
    [activityData.id, answerId, setActivityValueHandler]
  );

  const referenceValue = useMemo(() => {
    if (
      referenceActivityContentTypeId ===
      WorkshopActivityType.ClosedQuestionActivity
    ) {
      const closedQuestionAnswer = allActivitiesResult?.find(
        (a) => a.key === activity.fields.referenceActivity.sys.id
      )?.value;
      const openQuestionAnswers = getOpenQuestionAnswers(
        activity,
        allActivitiesResult
      );
      const closedQuestionAnswerResult = closedQuestionAnswer?.[0]?.value;
      const defaultClosedQuestionAnswer = {
        data: { playerSelectedValues: {} as { [key: string]: string } },
        value: "",
      };
      const parsedClosedQuestionAnswer =
        !closedQuestionAnswerResult ||
        closedQuestionAnswerResult === ACTIVITY_TIMEOUT_VALUE
          ? defaultClosedQuestionAnswer
          : parseToJson<typeof defaultClosedQuestionAnswer>(
              closedQuestionAnswerResult,
              defaultClosedQuestionAnswer
            );

      if (
        parsedClosedQuestionAnswer.value ||
        parsedClosedQuestionAnswer.data.playerSelectedValues
      ) {
        const playerSelectedValues =
          parsedClosedQuestionAnswer.data.playerSelectedValues;
        const groupSelectedValue =
          playerSelectedValues[Object.keys(playerSelectedValues)[0]];
        const selectedOpenAnswer = openQuestionAnswers?.find((answer) =>
          answer.value.includes(groupSelectedValue)
        );
        if (selectedOpenAnswer?.value) {
          return parseToJson(selectedOpenAnswer.value, { id: "", value: "" })
            ?.value;
        }
        if (!parsedClosedQuestionAnswer?.value) return "";

        return parseToJson(parsedClosedQuestionAnswer.value, {
          groupDefinition: "",
        }).groupDefinition;
      }

      return null;
    }

    if (referenceActivityContentTypeId !== WorkshopActivityType.OpenQuestion)
      return null;

    if (
      !currentUserResults?.value ||
      currentUserResults.value === ACTIVITY_TIMEOUT_VALUE
    )
      return null;

    return parseToJson<{ value: string }>(currentUserResults.value, {
      value: "",
    }).value;
  }, [
    referenceActivityContentTypeId,
    currentUserResults?.value,
    allActivitiesResult,
    activity,
  ]);

  const synchronizedValue = useMemo(
    () => (isSynchronised ? activityResults?.[0]?.value : null),
    [activityResults, isSynchronised]
  );

  const parsedSynchronizedResultData = useMemo(() => {
    const defaultResult = generateDefaultMultiValue(
      [editorKey],
      isViewResults || !isParticipating
    );

    if (
      !synchronizedValue ||
      synchronizedValue === ACTIVITY_TIMEOUT_VALUE ||
      !synchronizedValue?.includes(`"value":"${editorKey}:[`)
    )
      return defaultResult;

    const complexValue = extractMultiValueComplexValue(synchronizedValue, [
      editorKey,
    ]);

    if (!complexValue) return defaultResult;

    if (isViewResults) {
      const extractedData = extractMultiValueResult(
        complexValue,
        [editorKey],
        isViewResults || !isParticipating
      );

      return extractedData || defaultResult;
    }

    const extractedData = parseComplexResultValue(
      complexValue,
      !isParticipating
        ? UseDocComplexValueType.String
        : UseDocComplexValueType.Uint8Array
    );

    return extractedData || defaultResult;
  }, [
    isViewResults,
    isParticipating,
    synchronizedValue,
    parseComplexResultValue,
  ]);

  const onSynchronizedValueUpdate = useCallback(() => {
    if (!isParticipating || !isSynchronised) {
      return;
    }
    const activityComplexValue: ActivityComplexValue = {
      value: generateComplexResultValueAsString(),
      type: ActivityComplexValueType.yjs,
    };

    debouncedSetActivityValue(JSON.stringify(activityComplexValue), true);
  }, [
    debouncedSetActivityValue,
    generateComplexResultValueAsString,
    isParticipating,
    isSynchronised,
  ]);

  const actionFooterData: ActionFooterType = useMemo(() => {
    if (!isParticipating) return observerFooterData;
    if (isLoading && actionFooterDataRef.current)
      return actionFooterDataRef.current;
    if (isTransitioning) {
      actionFooterDataRef.current = getTransitionActionFooterData({
        text: "Everyone is ready. Continuing forward...",
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Ready,
        isActivityTimeout,
      });
      return actionFooterDataRef.current;
    }

    if (!isReady) {
      const isButtonDisabled = isSynchronised
        ? editorDeps.every(
            (key) => docs[key]?.getText("codemirror")?.toString().length === 0
          )
        : false;
      const playersClicked =
        currentActiveParticipantCount - notReadyProfilesCount;
      const playerHasAnswered =
        !!parsedCurrentUserResultValue.value &&
        activityResultForCurrentProfile?.value !== ACTIVITY_TIMEOUT_VALUE;

      actionFooterDataRef.current = {
        text: isSynchronised
          ? isButtonDisabled
            ? "Write your collective answer before you can continue."
            : `Everyone must agree to continue!`
          : activityTypeFooterTextFactoryMap[WorkshopActivityType.OpenQuestion](
              playersClicked
            ),
        buttonText: "Continue",
        disabledButton: false,
        type: FooterType.Notice,
      };

      if (!isConsensusMode) {
        actionFooterDataRef.current.disabledButton = !playerHasAnswered;
        actionFooterDataRef.current.text = playerHasAnswered ? (
          <>
            Click on “<span className="accent green">Continue</span>” to submit
            your individual opinion.
            {playersClicked > 0 && (
              <>
                {" "}
                <span className="accent">
                  {playersClicked} player{playersClicked > 1 && "s"} clicked.
                </span>
              </>
            )}
          </>
        ) : (
          activityTypeFooterTextFactoryMap[WorkshopActivityType.OpenQuestion](
            playersClicked
          )
        );

        actionFooterDataRef.current.buttonText = "Continue";
      }

      return actionFooterDataRef.current;
    }

    actionFooterDataRef.current = {
      text: (
        <>
          Waiting for{" "}
          <span className="accent">
            {notReadyProfilesCount} more player
            {notReadyProfilesCount > 1 && "s"}...
          </span>
        </>
      ),
      buttonText: !isConsensusMode ? "Go to discussion" : "Continue",
      disabledButton: true,
      type: FooterType.Waiting,
    };
    return actionFooterDataRef.current;
  }, [
    isParticipating,
    isLoading,
    isTransitioning,
    isReady,
    notReadyProfilesCount,
    isConsensusMode,
    isActivityTimeout,
    isSynchronised,
    currentActiveParticipantCount,
    parsedCurrentUserResultValue.value,
    activityResultForCurrentProfile?.value,
    docs,
  ]);

  return (
    <>
      <ScrollIndicator key={activityData.id} className="activity-container">
        <div className={cn(styles.container, "main-container")}>
          <ActivityInstructions
            instructions={instructions}
            tags={tags}
            type={conferenceMode}
          />
          <div className="details">
            <div className={styles.leftWrapper}>
              {referenceTitle ? (
                <>
                  <ContentfulRichField
                    content={questionInstructions}
                    className={cn(styles.referenceInstructions)}
                  />
                  <div className={styles.referenceTitle}>{referenceTitle}</div>
                  <div className={styles.referenceAnswer}>
                    <div className={styles.referenceAnswerTitle}>
                      Aligned strategy:
                    </div>
                    <div className={styles.groupAlignmentValue}>
                      {referenceValue}
                    </div>
                  </div>
                </>
              ) : (
                <CaseStudy
                  questionInstructions={questionInstructions}
                  questionTitle={questionTitle}
                  questionDescription={questionDescription}
                />
              )}
            </div>

            <div className={styles.inputContainer}>
              {!isSynchronised && (
                <Textarea
                  className={styles.textArea}
                  disabled={!!isViewResults || !isParticipating}
                  onInput={({ target: { value } }) => {
                    if (!!isViewResults) return;
                    debouncedSetActivityValue(value);
                  }}
                  isLoading={false}
                  defaultValue={parsedCurrentUserResultValue.value}
                  placeholder={textareaPlaceholder}
                />
              )}
              {isSynchronised && (
                <div className={styles.editorWrapper}>
                  {!isViewResults && isParticipating ? (
                    <Editor
                      doc={docs[editorKey]}
                      name={editorKey}
                      key={editorKey}
                      onValueUpdate={onSynchronizedValueUpdate}
                      onAwarenessUpdate={onAwarenessUpdate}
                      value={
                        parsedSynchronizedResultData[editorKey] as Uint8Array
                      }
                      awareness={awarenessState[0]?.awareness}
                      profileName={profile.name}
                      placeholder={textareaPlaceholder}
                      isInput={false}
                      className={editorClass}
                    />
                  ) : (
                    <div
                      className={cn(
                        "text",
                        "textarea-sync-disabled",
                        !parsedSynchronizedResultData[editorKey] &&
                          "placeholder"
                      )}
                      key={editorKey}
                    >
                      {(parsedSynchronizedResultData[editorKey] as string) ||
                        textareaPlaceholder}
                    </div>
                  )}
                </div>
              )}
              {referenceValue &&
                !referenceTitle &&
                !referenceActivityIsOpenQuestionSynchronisedConsensus && (
                  <div className={styles.referenceValueContainer}>
                    {activityData.referenceValueLabel && (
                      <p className={cn(styles.referenceValueLabel, "text")}>
                        {activityData.referenceValueLabel}{" "}
                        <span className="faded">(visible only to you)</span>
                      </p>
                    )}
                    <Textarea
                      className={styles.textArea}
                      onInput={() => {}}
                      isLoading={false}
                      value={referenceValue}
                      disabled
                      placeholder={textareaPlaceholder}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
        {!isViewResults && isTransitioning && (
          <NextStepTransition
            nextStep={nextActivityData.transitionText}
            sessionType={nextActivityData.conferenceMode}
            isActivityTimeout={isActivityTimeout}
            transition={transition}
          />
        )}
      </ScrollIndicator>
      {!isViewResults && (
        <ActionFooter
          buttonText={actionFooterData.buttonText}
          type={actionFooterData.type}
          disabledButton={actionFooterData.disabledButton}
          isLoading={isLoading}
          isConnectionWeak={isConnectionWeak}
          buttonClickHandler={() =>
            setActivityReadyHandler({ activityId: activityData.id })
          }
        >
          {actionFooterData.text}
        </ActionFooter>
      )}
    </>
  );
};

export default OpenQuestion;
