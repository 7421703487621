import {
  memo,
  PropsWithChildren,
  useCallback,
  useState,
  CSSProperties,
  useMemo,
} from "react";
import * as RadixTooltip from "@radix-ui/react-tooltip";

import styles from "./Tooltip.module.css";
import cn from "classnames";

type TooltipVariants = "info" | "warning";

const mapVariantToStyle: Record<
  TooltipVariants,
  { className: string; icon: string }
> = {
  info: {
    className: styles.info,
    icon: "fa-regular fa-info-circle",
  },
  warning: {
    className: styles.warning,
    icon: "fa-regular fa-exclamation-triangle",
  },
};

const Tooltip = (
  props: PropsWithChildren<{
    title?: string;
    description: string;
    variant?: TooltipVariants;
    delayDuration?: number;
    containerClass?: string;
    containerStyle?: CSSProperties;
    withClose?: boolean;
    opened?: boolean;
    withBorder?: boolean;
  }>
) => {
  const {
    title,
    variant,
    description,
    containerClass,
    containerStyle,
    delayDuration,
    withClose,
    opened,
    withBorder,
    children,
  } = props;
  const [open, setOpen] = useState<boolean>(opened || false);
  const handleClose = useCallback(() => setOpen(false), []);

  const content = useMemo(
    () => (
      <>
        {title && (
          <p className={cn("text medium bold", styles.title)}>
            <i
              className={cn(
                mapVariantToStyle[variant || "info"].icon,
                mapVariantToStyle[variant || "info"].className
              )}
            />
            {title}
          </p>
        )}
        <p className={cn("text", "small", styles.description)}>{description}</p>
        {withClose && (
          <i
            className={cn(styles.closeBtn, "fa fa-xmark")}
            onClick={handleClose}
          />
        )}
      </>
    ),
    [description, handleClose, title, variant, withClose]
  );

  return (
    <RadixTooltip.Provider delayDuration={delayDuration ?? 700}>
      <RadixTooltip.Root open={open} onOpenChange={setOpen}>
        <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            className={cn(
              styles.tooltipContent,
              containerClass,
              withBorder && styles.tooltipBorder
            )}
            style={containerStyle}
            sideOffset={5}
          >
            {content}

            <RadixTooltip.Arrow className={styles.tooltipArrow} />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

export default memo(Tooltip);
