import {
  PropsWithChildren,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

import { getEntryId, getTransitionData, parseToJson } from "../../../../utils";
import { GroupingActivity } from "../../../../types/contentful/workshop/activities/grouping";
import { useReadyRemovedDueToChange } from "../../../../hooks/useReadyRemovedDueToChange";
import { getTransitionActionFooterData } from "../utils/get-transition-footer-data";
import { ActivityCommon } from "../../../../types/activity-common";
import { ActionFooterType } from "../../../../types/action-footer";
import { FooterType } from "../../../../types/enums/activity-footer";
import { WorkshopActivityType } from "../../../../types/enums/activity-type";
import { IActivityResult } from "../../../../apollo-graphql/types/session-state";
import { ACTIVITY_TIMEOUT_VALUE } from "../../../../constants/global";
import { IDragAndDropCard } from "../../../Shared/DragAndDrop/types";
import { observerFooterData } from "../constants";
import { IGroupingItem } from "./types";

import ActionFooter from "../../ActionFooter/ActionFooter";
import NextStepTransition from "../../NextStepTransition/NextStepTransition";
import GroupingItem from "./components/GroupingItem/GroupingItem";
import ScrollIndicator from "../../../Shared/ScrollIndicator/ScrollIndicator";
import ContentfulRichField from "../../../Shared/ContentfulRichField/ContentfulRichField";
import ActivityInstructions from "../../../Shared/ActivityInstructions/ActivityInstructions";

import cn from "classnames";
import styles from "./Grouping.module.css";

interface GroupingProps extends ActivityCommon {
  currentActiveParticipants: string[];
  activityResultForCurrentProfile: IActivityResult["value"]["0"] | null;
  activityResults: IActivityResult["value"] | null;
  isViewResults?: boolean;
  profileId: string;
  getReferenceActivityResult: (
    activityType: WorkshopActivityType,
    activityId: string
  ) => IActivityResult["value"] | null;
  setActivityValueHandler: (args: {
    activityId: string;
    value: string;
  }) => void;
}

interface RawGroupingItem extends IGroupingItem {
  timestamp: number;
}

const Grouping = (props: PropsWithChildren<GroupingProps>) => {
  const {
    transition,
    isReady,
    activity,
    nextActivity,
    isLoading,
    activityResults,
    activityResultForCurrentProfile,
    isConnectionWeak,
    currentActiveParticipants,
    currentActiveParticipantCount,
    profileId,
    notReadyProfilesCount,
    isViewResults,
    isActivityTimeout,
    isParticipating,
    hasAhaMoments,
    getReferenceActivityResult,
    setActivityValueHandler,
    setActivityReadyHandler,
  } = props;

  const { changeStateText, userValueChangeHandler } =
    useReadyRemovedDueToChange(isReady);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [lastCorrectAnswerIdx, setLastCorrectAnswerIdx] = useState<number>(0);
  const currentActivityFields = (activity as GroupingActivity)?.fields;

  const isTransitioning = useMemo(() => transition > 0, [transition]);
  const answers = useMemo(() => {
    return currentActivityFields?.predefinedCategories?.map((cat) => ({
      id: cat.sys.id,
      text: cat.fields.title,
    }));
  }, [currentActivityFields?.predefinedCategories]);

  const items: IGroupingItem[] = useMemo(() => {
    if (Array.isArray(currentActivityFields?.predefinedList))
      return currentActivityFields.predefinedList.map((item) => {
        return {
          id: getEntryId(item),
          title: item?.fields?.title,
          description: item?.fields?.description,
          explanation: item?.fields?.explanation,
          questionLabel: currentActivityFields.labelForItems,
          questionNode: <ContentfulRichField content={item?.fields?.title} />,
          answerLabel: currentActivityFields.labelForCategories,
          correctAnswers: item.fields.categories.map((cat) => getEntryId(cat)),
        } as IGroupingItem;
      });

    const rawResult: RawGroupingItem[] = [];

    if (
      currentActivityFields?.activityForItems &&
      !currentActivityFields?.enforceMatchement
    ) {
      const referenceActivityType = currentActivityFields?.activityForItems?.sys
        .contentType?.sys?.id as WorkshopActivityType;
      const referenceActivityId =
        currentActivityFields?.activityForItems?.sys?.id;
      const referenceActivityResult =
        referenceActivityType && referenceActivityId
          ? getReferenceActivityResult(
              referenceActivityType,
              referenceActivityId
            )
          : null;
      const isOpenQuestionActivity =
        referenceActivityType === WorkshopActivityType.OpenQuestion;
      if (!Array.isArray(referenceActivityResult)) return rawResult;

      referenceActivityResult.forEach((item) => {
        if (!item?.value || item.value === ACTIVITY_TIMEOUT_VALUE) return;

        if (isOpenQuestionActivity) {
          const parsedResult = parseToJson(item.value, { id: "", value: "" });
          const parsedQuestionResult = parseToJson(parsedResult.value, {
            openQuestion: "",
          });
          const title =
            parsedQuestionResult.openQuestion || parsedResult.value || "";

          const isReferenceSynchronised = parsedQuestionResult.openQuestion;

          if (isReferenceSynchronised && rawResult.length) {
            // skip every user answer, except the first one
            return;
          }

          rawResult.push({
            id: isReferenceSynchronised ? "openQuestion" : parsedResult?.id,
            title,
            questionLabel: currentActivityFields.labelForItems,
            questionNode: <ContentfulRichField content={title} />,
            answerLabel: currentActivityFields.labelForCategories,
            timestamp: 0,
          });
        } else {
          const parsedResult = parseToJson<IDragAndDropCard[]>(item.value, []);

          parsedResult.forEach((item) => {
            rawResult.push({
              id: item.id,
              title: item.text || "",
              questionLabel: currentActivityFields.labelForItems,
              questionNode: <ContentfulRichField content={item.text || ""} />,
              answerLabel: currentActivityFields.labelForCategories,
              timestamp: item.timestamp!,
            });
          });
        }
      });

      if (isOpenQuestionActivity) {
        return rawResult;
      }

      return rawResult
        .sort((x, y) => y.timestamp - x.timestamp)
        .map(({ timestamp, ...rest }) => rest);
    }

    return rawResult;
  }, [
    currentActivityFields?.activityForItems,
    currentActivityFields?.enforceMatchement,
    currentActivityFields?.predefinedList,
    currentActivityFields.labelForCategories,
    currentActivityFields.labelForItems,
    getReferenceActivityResult,
  ]);

  const itemIds = useMemo(() => items.map(({ id }) => id), [items]);

  const activityData = useMemo(() => {
    const filteringActivity = activity as GroupingActivity;
    return {
      id: getEntryId(filteringActivity),
    };
  }, [activity]);

  const nextActivityData = useMemo(
    () => getTransitionData(nextActivity, hasAhaMoments),
    [nextActivity, hasAhaMoments]
  );

  const defaultSelectedOptionsData = useMemo(() => {
    const defaultResult = itemIds.reduce(
      (acc, id) => ({ ...acc, [id]: null }),
      {} as { [key: string]: string | null }
    );
    return defaultResult;
  }, [itemIds]);

  const currentProfileSelectedOptions = useMemo(() => {
    const resultValue = activityResultForCurrentProfile?.value;

    if (!resultValue || resultValue === ACTIVITY_TIMEOUT_VALUE)
      return defaultSelectedOptionsData;

    const data = parseToJson<{ [key: string]: string | null }>(
      activityResultForCurrentProfile.value,
      {}
    );

    return itemIds.reduce((acc, id) => {
      const value = data[id] || null;
      return { ...acc, [id]: value };
    }, {} as { [key: string]: string | null });
  }, [
    activityResultForCurrentProfile?.value,
    defaultSelectedOptionsData,
    itemIds,
  ]);

  const otherProfileSelectedOptions = useMemo(() => {
    return (activityResults || [])
      .filter(
        (result) =>
          currentActiveParticipants.includes(result.profileId) &&
          result.profileId !== profileId
      )
      .map((profileData) => {
        const data = parseToJson<{ [key: string]: string | null }>(
          profileData.value,
          defaultSelectedOptionsData
        );
        const value = itemIds.reduce((acc, id) => {
          const value = data[id] || null;
          return { ...acc, [id]: value };
        }, {} as { [key: string]: string | null });

        return { profileId: profileData.profileId, value };
      });
  }, [
    activityResults,
    currentActiveParticipants,
    defaultSelectedOptionsData,
    itemIds,
    profileId,
  ]);

  const isButtonDisabled = useMemo(() => {
    const parsedCurrentProfileSelectedOptions = Object.entries(
      currentProfileSelectedOptions
    );
    if (
      itemIds.some(
        (id) => !parsedCurrentProfileSelectedOptions.find(([key]) => key === id)
      ) ||
      parsedCurrentProfileSelectedOptions.some(([, value]) => !value)
    )
      return true;

    if (currentActiveParticipantCount === 1)
      return parsedCurrentProfileSelectedOptions.some(
        ([, value]) => value === ACTIVITY_TIMEOUT_VALUE
      );

    const allParticipantsHaveAnswered = currentActiveParticipants
      .filter((d) => d !== profileId)
      .every((participant) => {
        const participantAnswer = otherProfileSelectedOptions.find(
          (res) => res.profileId === participant
        )?.value;
        if (!participantAnswer) return false;
        return itemIds.every((id) => {
          const otherValue = participantAnswer[id];
          return otherValue !== ACTIVITY_TIMEOUT_VALUE;
        });
      });

    const allParticipantsHaveAnsweredCorrectly = currentActiveParticipants
      .filter((d) => d !== profileId)
      .every((participant) => {
        const participantAnswer = otherProfileSelectedOptions.find(
          (res) => res.profileId === participant
        )?.value;
        if (!participantAnswer) return false;
        return items.every((item, idx) => {
          const otherValue = participantAnswer[item.id];
          const isWrong = currentActivityFields?.enforceMatchement
            ? !item.correctAnswers?.includes(otherValue || "")
            : false;
          return !isWrong;
        });
      });

    return (
      !allParticipantsHaveAnswered ||
      !allParticipantsHaveAnsweredCorrectly ||
      !otherProfileSelectedOptions.every((otherProfileData) => {
        if (!otherProfileData?.value) return false;
        const otherProfileParsedData = Object.entries(otherProfileData.value);
        return parsedCurrentProfileSelectedOptions.every(([key, value]) => {
          const otherValue = otherProfileParsedData.find(
            ([k]) => k === key
          )?.[1];

          return value === otherValue && value !== ACTIVITY_TIMEOUT_VALUE;
        });
      })
    );
  }, [
    currentActiveParticipantCount,
    currentActiveParticipants,
    currentProfileSelectedOptions,
    items,
    itemIds,
    otherProfileSelectedOptions,
    profileId,
    currentActivityFields?.enforceMatchement,
  ]);

  const actionFooterData: ActionFooterType = useMemo(() => {
    if (!isParticipating) return observerFooterData;
    if (isTransitioning) {
      return getTransitionActionFooterData({
        text: <>Everyone is ready! Continuing forward...</>,
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Ready,
        isActivityTimeout,
      });
    }
    if (isReady) {
      return {
        text: (
          <>
            Waiting for{" "}
            <span className="accent">
              {notReadyProfilesCount} more player
              {notReadyProfilesCount > 1 && "s"}...
            </span>
          </>
        ),
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Waiting,
      };
    }

    const playersClicked =
      currentActiveParticipantCount - notReadyProfilesCount;

    const parsedCurrentProfileSelectedOptions = Object.entries(
      currentProfileSelectedOptions
    );
    const playerHasNotAnsweredAllItems =
      items.some(
        ({ id }) =>
          !parsedCurrentProfileSelectedOptions.find(([key]) => key === id)
      ) ||
      parsedCurrentProfileSelectedOptions.some(
        ([, value]) => !value || value === ACTIVITY_TIMEOUT_VALUE
      );

    const buttonText = playerHasNotAnsweredAllItems
      ? `Align on the ${
          currentActivityFields?.enforceMatchement ? "correct" : "collective"
        } answers to continue.`
      : isButtonDisabled
      ? `${changeStateText}You have to match your opinions to continue.`
      : `You've aligned on the ${
          currentActivityFields?.enforceMatchement ? "correct" : "collective"
        } answers! Click continue to submit them!`;

    return {
      text: (
        <>
          {buttonText}{" "}
          {playersClicked > 0 && (
            <span className="accent">
              {playersClicked} player{playersClicked > 1 && "s"} clicked!
            </span>
          )}
        </>
      ),
      buttonText: "Continue",
      disabledButton: isButtonDisabled,
      type: FooterType.Notice,
    };
  }, [
    isParticipating,
    isTransitioning,
    isReady,
    currentActiveParticipantCount,
    notReadyProfilesCount,
    currentProfileSelectedOptions,
    items,
    currentActivityFields?.enforceMatchement,
    isButtonDisabled,
    changeStateText,
    isActivityTimeout,
  ]);

  const handleSelection = useCallback(
    (optionId: string, sectionType: string) => {
      if (isLoading || !isParticipating || isViewResults) return;

      const value = JSON.stringify({
        ...currentProfileSelectedOptions,
        [sectionType]: optionId,
      });

      setActivityValueHandler({ activityId: activityData.id, value });
      userValueChangeHandler();
    },
    [
      activityData?.id,
      isLoading,
      isParticipating,
      isViewResults,
      currentProfileSelectedOptions,
      setActivityValueHandler,
      userValueChangeHandler,
    ]
  );

  const headerContent = useMemo(() => {
    const html = activity?.fields?.activity?.fields?.instructions;
    const conferenceMode = activity?.fields.activity.fields.conferenceMode;
    const tags = activity?.fields?.activity?.fields?.tags;

    return (
      <div>
        <ActivityInstructions
          instructions={html}
          tags={tags}
          type={conferenceMode}
        />
      </div>
    );
  }, [
    activity?.fields.activity.fields.conferenceMode,
    activity?.fields.activity.fields?.instructions,
    activity?.fields.activity.fields?.tags,
  ]);

  const handleLastCorrectAnswerChange = useCallback((index: number) => {
    const element =
      scrollContainerRef?.current?.children?.[0]?.children?.[0]?.children?.[0]
        ?.children?.[1]?.children?.[index];

    if (element) {
      element.scrollIntoView();
    }

    setLastCorrectAnswerIdx(index);
  }, []);

  const content = useMemo(() => {
    if (!items.length) return null;

    const parsedItemData = items.map((item, idx) => {
      let disabled = true;
      let shouldBeOpen = false;

      const currentUserAnswer = currentProfileSelectedOptions[item.id];
      const otherProfileAnswers = otherProfileSelectedOptions.map(
        (d) => d.value[item.id]
      );

      const allGroupMembersHaveAnswered =
        otherProfileAnswers.filter(Boolean).length >=
        currentActiveParticipants.length - 1;
      const groupIsAligned =
        !!currentUserAnswer &&
        allGroupMembersHaveAnswered &&
        otherProfileAnswers.every((res) => res === currentUserAnswer);
      const groupIsCorrect = currentActivityFields.enforceMatchement
        ? item.correctAnswers?.includes(currentUserAnswer || "")
        : groupIsAligned;

      if (idx === 0) {
        // first is always enabled
        disabled = false;
        if ((!groupIsAligned || !groupIsCorrect) && !isViewResults) {
          shouldBeOpen = true;
        }
      } else {
        const previousItem = items[idx - 1];

        const currentUserAnswerOnPrevious =
          currentProfileSelectedOptions[previousItem.id];
        const otherProfileAnswersOnPrevious = otherProfileSelectedOptions.map(
          (d) => d.value[previousItem.id]
        );

        const allGroupMembersHaveAnsweredPrevious =
          otherProfileAnswersOnPrevious.filter(Boolean).length >=
          currentActiveParticipants.length - 1;
        const groupIsAlignedOnPrevious =
          !!currentUserAnswerOnPrevious &&
          allGroupMembersHaveAnsweredPrevious &&
          otherProfileAnswersOnPrevious.every(
            (res) => res === currentUserAnswerOnPrevious
          );
        const groupIsWrongOnPrevious = currentActivityFields?.enforceMatchement
          ? !previousItem.correctAnswers?.includes(
              currentUserAnswerOnPrevious || ""
            )
          : !groupIsAlignedOnPrevious;
        const groupIsCorrectOnPrevious =
          groupIsAlignedOnPrevious && !groupIsWrongOnPrevious;

        disabled = !groupIsAlignedOnPrevious || groupIsWrongOnPrevious;
        shouldBeOpen =
          groupIsCorrectOnPrevious && !(groupIsAligned && groupIsCorrect);
      }

      return {
        enforceMatchement: currentActivityFields?.enforceMatchement,
        groupIsAligned,
        groupIsCorrect,
        index: idx,
        key: item.id,
        groupingItem: item,
        answers,
        currentActiveParticipants,
        profileId,
        isParticipating,
        currentProfileSelectedOptions,
        otherProfileSelectedOptions,
        disabled,
        shouldBeOpen,
        handleSelection,
      };
    });

    const currentLastCorrectAnswerKey = [...parsedItemData]
      .reverse()
      .find((d) => d.groupIsCorrect && d.groupIsAligned && !d.disabled)?.key;
    const currentLastCorrectAnswerIdx = items.findIndex(
      (d) => d.id === currentLastCorrectAnswerKey
    );

    if (lastCorrectAnswerIdx !== currentLastCorrectAnswerIdx) {
      handleLastCorrectAnswerChange(currentLastCorrectAnswerIdx);
    }

    return parsedItemData.map((data) => (
      <GroupingItem {...data} isViewResults={isViewResults} />
    ));
  }, [
    currentActiveParticipants,
    currentProfileSelectedOptions,
    handleSelection,
    isParticipating,
    isViewResults,
    items,
    otherProfileSelectedOptions,
    profileId,
    answers,
    lastCorrectAnswerIdx,
    handleLastCorrectAnswerChange,
    currentActivityFields?.enforceMatchement,
  ]);

  return (
    <>
      <ScrollIndicator
        key={activityData.id}
        className="activity-container"
        externalContentRef={scrollContainerRef}
      >
        <div className={cn(styles.container, "main-container")}>
          <div className={styles.infoContainer}>
            {headerContent}
            <div className={styles.itemsContainer}>{content}</div>
          </div>
        </div>
        {!isViewResults && isTransitioning && (
          <NextStepTransition
            nextStep={nextActivityData?.transitionText}
            isActivityTimeout={isActivityTimeout}
            sessionType={nextActivityData?.conferenceMode}
            transition={transition}
          />
        )}
      </ScrollIndicator>
      {!isViewResults && (
        <ActionFooter
          buttonText={actionFooterData.buttonText}
          type={actionFooterData.type}
          disabledButton={actionFooterData.disabledButton}
          isLoading={isLoading}
          isConnectionWeak={isConnectionWeak}
          buttonClickHandler={() =>
            setActivityReadyHandler({ activityId: activityData.id })
          }
        >
          {actionFooterData.text}
        </ActionFooter>
      )}
    </>
  );
};

export default memo(Grouping);
